import React from 'react';
import { Grid, Typography } from '@material-ui/core';

interface IProps {
  iconLeft: string;
  iconRight: string;
  contentLeftTop: string;
  contentLeftBottom: string;
  contentRightTop: string;
  contentRightBottom: string;
}
export const FooterCalculator = (props: IProps) => {
  const {
    iconLeft,
    iconRight,
    contentLeftTop,
    contentLeftBottom,
    contentRightTop,
    contentRightBottom
  } = props;
  return (
    <Grid container style={{ marginTop: 30 }}>
      <Grid
        item
        xs={6}
        style={{
          display: 'grid',
          gridTemplateColumns: '64px calc(100% - 64px)',
          alignItems: 'center'
        }}
      >
        <img src={iconLeft} width={50} height={50} alt="icon" />
        <Grid>
          <Typography
            variant="h5"
            style={{ fontWeight: 600, marginBottom: 5, color: '#A5B2C0' }}
          >
            {contentLeftTop}
          </Typography>
          <Typography
            variant="h5"
            style={{ fontWeight: 600, color: '#A5B2C0' }}
          >
            {contentLeftBottom}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          display: 'grid',
          gridTemplateColumns: '64px calc(100% - 64px)',
          alignItems: 'center'
        }}
      >
        <img src={iconRight} width={50} height={50} alt="icon" />
        <Grid>
          <Typography
            variant="h5"
            style={{ fontWeight: 600, marginBottom: 5, color: '#A5B2C0' }}
          >
            {contentRightTop}
          </Typography>
          <Typography
            variant="h5"
            style={{ fontWeight: 600, color: '#A5B2C0' }}
          >
            {contentRightBottom}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
