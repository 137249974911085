import { Store } from 'redux';
import { IStoreState } from '../types';
import { Dispatch } from 'react';
import {
  ILoadTransactionAction,
  setTransactionAction,
  ILoadAssetTransactionAction,
  setAssetTransactionAction
} from '../actions/transaction.action';
import {
  LOAD_TRANSACTION_ACTION,
  LOAD_ASSET_TRANSACTION_ACTION
} from '../constants/transaction.constant';
import Axios, { AxiosResponse } from 'axios';
import { IObjectTransaction } from '../types/transaction.type';
import { ICurrency } from '../types/currency.type';

export const transactionMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<ILoadTransactionAction | ILoadAssetTransactionAction>
) => async (action: ILoadTransactionAction | ILoadAssetTransactionAction) => {
  switch (action.type) {
    case LOAD_TRANSACTION_ACTION: {
      Axios.get(
        `/transactions/${action.payload.asset}/${action.payload.type}`,
        {
          params: {
            limit: action.payload.limit,
            offset: action.payload.offset
          }
        }
      )
        .then((data: AxiosResponse<IObjectTransaction>) => {
          if (data.data) {
            store.dispatch(setTransactionAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case LOAD_ASSET_TRANSACTION_ACTION: {
      Axios.get(`/currencies`, {
        params: {
          limit: action.payload.limit,
          offset: action.payload.offset,
          asset: action.payload.asset
        }
      })
        .then((data: AxiosResponse<ICurrency[]>) => {
          if (data.data) {
            store.dispatch(setAssetTransactionAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
  }
  return dispatch(action);
};
