import React from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      width: '100%',
      '& .MuiButton-root': {
        borderRadius: 5,
        width: '100%',
        height: 50,
        fontSize: 14,
        lineHeight: 1.5,
        fontWeight: 600,
        color: '#fff',
        textTransform: 'capitalize',
        fontFamily: 'Montserrat'
      }
    },
    contained: {
      backgroundColor: '#F2994A',
      '&:hover': {
        backgroundColor: '#F2994A',
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: '#DA771F',
        boxShadow: 'none'
      },
      '&:active': {
        backgroundColor: '#DA771F',
        boxShadow: 'none'
      },
      '&:disabled': {
        color: '#fff',
        background: '#C4C4C4',
        boxShadow: 'none'
      }
    },
    outlined: {
      border: '1px solid #2577E4',
      color: '#2577E4',
      '&:hover': {
        background: 'linear-gradient(292.65deg, #67ACF1 -18.8%, #156EE4 100%)',
        boxShadow: 'none'
      },
      '&:focus': {
        background: 'linear-gradient(292.65deg, #67ACF1 -18.8%, #156EE4 100%)',
        boxShadow: 'none'
      },
      '&:active': {
        background: 'linear-gradient(292.65deg, #67ACF1 -18.8%, #156EE4 100%)',
        boxShadow: 'none'
      },
      '&:disabled': {
        color: '#fff',
        background: '#C4C4C4',
        boxShadow: 'none'
      }
    },
    containedPrimary: {
      background:
        'linear-gradient(145.66deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(137.53deg, #67ACF1 -10.95%, #156EE4 104.81%)',
      boxShadow: 'none',
      '&:hover': {
        background:
          'linear-gradient(145.66deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(137.53deg, #5392D1 -10.95%, #115EC5 104.81%)',
        boxShadow: 'none'
      },
      '&:focus': {
        background:
          'linear-gradient(145.66deg, #3C5CAE -10.08%, #301E78 96.47%)',
        boxShadow: 'none'
      },
      '&:active': {
        background:
          'linear-gradient(145.66deg, #3C5CAE -10.08%, #301E78 96.47%)',
        boxShadow: 'none'
      },
      '&:disabled': {
        color: '#fff',
        background: '#C4C4C4',
        boxShadow: 'none'
      }
    },
    containedSecondary: {
      backgroundColor: '#34CEA9',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#34CEA9',
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: '#1BB791',
        boxShadow: 'none'
      },
      '&:active': {
        backgroundColor: '#1BB791',
        boxShadow: 'none'
      },
      '&:disabled': {
        color: '#fff',
        background: '#C4C4C4',
        boxShadow: 'none'
      }
    },
    outlinedPrimary: {
      color: '#050F47 !important',
      border: '1px solid #D7DCE0',
      '&:hover': {
        color: '#FFFFFF !important',
        background: '#050F47',
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: '#282B2F',
        color: '#FFFFFF !important',
        boxShadow: 'none'
      },
      '&:active': {
        backgroundColor: '#282B2F',
        color: '#FFFFFF !important',
        boxShadow: 'none'
      },
      '&:disabled': {
        color: '#fff',
        background: '#C4C4C4',
        boxShadow: 'none',
        border: 'transparent'
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  title: string;
  variant: 'contained' | 'outlined';
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  heightButton?: number;
  handleRedirect?: () => void;
}

class NexoButtonComponent extends React.Component<IProps, {}> {
  public getClassNameForButton = () => {
    const { classes, variant, color } = this.props;
    let className = classes.contained;
    switch (color) {
      case 'primary':
        className =
          variant === 'contained'
            ? classes.containedPrimary
            : classes.outlinedPrimary;
        break;
      case 'secondary':
        className = classes.containedSecondary;
        break;
      default:
        className =
          variant === 'contained' ? classes.contained : classes.outlined;
        break;
    }

    return className;
  };

  public render() {
    const {
      classes,
      title,
      variant,
      color,
      size,
      disabled,
      handleRedirect,
      heightButton
    } = this.props;
    return (
      <div className={classes.root}>
        <Button
          className={this.getClassNameForButton()}
          variant={variant}
          color={color}
          size={size}
          style={{ textTransform: 'none', height: heightButton }}
          disabled={disabled}
          onClick={handleRedirect}
        >
          {title}
        </Button>
      </div>
    );
  }
}

export const NexoButton = withStyles(styles)(NexoButtonComponent);
