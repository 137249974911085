import {
  IObjectCalculatorInfo,
  IRatesInfo,
  IInterestInfo
} from '../types/calculator.type';
import {
  ISetCalculatorAction,
  ISetRatesAction,
  ISetInterestAction
} from '../actions/calculator.action';
import {
  SET_CALCULATOR_ACTION,
  SET_RATES_ACTION,
  SET_INTEREST_ACTION
} from '../constants/culculator.constant';

const calculatorInfoEmpty: IObjectCalculatorInfo = {
  loan: [],
  interest: []
};

export const calculators = (
  state: IObjectCalculatorInfo = calculatorInfoEmpty,
  action: ISetCalculatorAction
): IObjectCalculatorInfo => {
  if (action.type === SET_CALCULATOR_ACTION) {
    return action.payload;
  }
  return state;
};

export const rates = (
  state: IRatesInfo[] = [],
  action: ISetRatesAction
): IRatesInfo[] => {
  if (action.type === SET_RATES_ACTION) {
    return action.payload;
  }
  return state;
};

export const interests = (
  state: IInterestInfo[] = [],
  action: ISetInterestAction
): IInterestInfo[] => {
  if (action.type === SET_INTEREST_ACTION) {
    return action.payload;
  }
  return state;
};
