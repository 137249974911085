import React, { useRef, useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  MenuItem,
  Button,
  TextField,
  IconButton
} from '@material-ui/core';
import { CalculatorCardComponent } from './share/CalculatorCard.dialog.component';
import {
  formatNumberToFixed,
  selecTotalAPR,
  checkErrorInputCalculaotr,
  getConfigsRate,
  getConfigsCoinLtv,
  formatCurrencyInput
} from '../helper';
import Slider from 'react-slick';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import iconInstant from '../assets/images/iconInstant.svg';
import classNames from 'classnames';
import iconGuaranteed from '../assets/images/iconGuaranteed.svg';
import iconNoHiddenFees from '../assets/images/iconNoHiddenFees.svg';

import { useOutSideClick } from './share/useOutsideClick.component';
import { FooterCalculator } from './calculator/FooterCalculator.component';
import { LoadingPage } from './share/LoadingPage';
import { ICoinInfo, IInterestInfo } from '../types/calculator.type';
import { NexoInputCurrency } from './share/NexoInputCurrency';
import { dataSelectCurrency } from '../constants/culculator.constant';
import { ICurrencyBalance } from '../types/currency.type';
const styles = makeStyles({
  root: {
    marginTop: 15,
    '&:focus': {
      '& .slick-initialized.slick-slide': {
        outlineColor: 'transparent'
      }
    },
    padding: '0px 30px',
    '& .slick-list': {
      marginLeft: 6
    }
  },
  '& .MuiGrid-root': {
    outlineColor: 'transparent'
  },
  slickPrev: {
    '& .slick-arrow.slick-prev': {
      display: 'none !important'
    }
  },
  slickPrevActive: {
    '& .slick-arrow.slick-prev': {
      left: '-31px',
      '&:before': {
        color: '#A5B2C0'
      }
    }
  },
  slickNext: {
    '& .slick-arrow.slick-next': {
      display: 'none !important'
    }
  },
  slickNextActive: {
    '& .slick-arrow.slick-next': {
      right: '-31px',
      '&:before': {
        color: '#A5B2C0'
      }
    }
  },

  inputError: {
    border: '1px solid #F2994A !important'
  },
  inputNormal: {
    border: '1px solid #D7DCE0',
    height: 50,
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: 'Montserrat',
    '&:hover': {
      border: '1px solid #34CEA9'
    },
    '& .MuiInputAdornment-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F9F9FB',
      minWidth: 125,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      margin: '0 auto',
      padding: '10px 0px',
      borderRight: '1px solid #D7DCE0',
      height: 50,
      fontWeight: 600
    },
    '& .MuiInput-input': {
      backgroundColor: '#fff',
      height: 46,
      padding: '0px 10px',
      borderRadius: '0px 5px 5px 0px',
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
      color: '#050F47'
    }
  },
  buttonIconButton: {
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: 8,
    padding: 0,
    cursor: 'pointer',
    color: '#F2994A'
  },

  selectTextField: {
    '& .MuiInputBase-input': {
      color: '#A5B2C0',
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      background: 'transparent'
    }
  },
  buttonGetStarted: {
    background: 'linear-gradient(167.33deg, #FFB26F 3.5%, #DF8A03 83.05%)',
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 600,
    fontFamily: 'Montserrat',
    height: 50,
    '&:hover': {
      boxShadow: 'none'
    }
  }
});

interface IProps {
  dataLoan: ICoinInfo[];
  dataCurrencyBalances: ICurrencyBalance[];
  dataInterests: IInterestInfo[];
}

export const InstantCryptoCard = (props: IProps) => {
  const wrapperRef = useRef(null);
  const valueUSdInfo: ICoinInfo = {
    disabled: false,
    hidden: false,
    img: '',
    name: 'USD',
    symbol: 'USD',
    value: ''
  };

  const [usd, setUsd] = useState<any>('');
  const [valueSelectAPR, setValueSelectAPR] = useState<string>('DAILY');
  const [valueSelectCurrency, setValueSelectCurrency] = useState<string>('BTC');
  const [valueAPR, setValueAPR] = useState<string>('0');
  const [errorInputUsd, setErrorInputUsd] = useState(false);
  const [slickPrev, setSlickPrev] = useState(false);
  const [slickNext, setSlickNext] = useState(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [valueCoin, setValueCoin] = useState<any>({
    BTC: '',
    ETH: '',
    XRP: ''
  });

  const [valueCoinToUsd, setValueCoinToUsd] = useState<any>({
    BTC: '',
    ETH: '',
    XRP: ''
  });

  const [activeInput, setActiveInput] = useState<any>({
    BTC: false,
    ETH: false,
    XRP: false
  });

  const settings = {
    dots: false,
    slidesToShow: 3,
    afterChange: (currentSlide: any) => {
      if (currentSlide > 0) {
        setSlickPrev(true);
      } else {
        setSlickPrev(false);
      }
      if (currentSlide > 2) {
        setSlickNext(true);
      } else {
        setSlickNext(false);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(true);
    }, 500);
  }, []);
  // eslint-disable-next-line

  const setStateCoinToUsdInput = (
    key: string,
    newValueCoin: string,
    ltv: string
  ) => {
    const valueCoinUsdPrice = getConfigsRate(key, props.dataCurrencyBalances);
    const newValueToUsd = String(
      (Number(ltv) / 100) *
        Number(String(newValueCoin).replace(/,/g, '')) *
        valueCoinUsdPrice
    );
    setValueCoin({
      ...valueCoin,
      [key]: newValueCoin
    });

    setValueCoinToUsd({
      ...valueCoinToUsd,
      [key]: newValueToUsd
    });

    let totalUsd: number = 0;
    switch (key) {
      case 'BTC':
        totalUsd =
          Number(valueCoinToUsd.ETH) +
          Number(valueCoinToUsd.XRP) +
          Number(newValueToUsd);
        break;
      case 'ETH':
        totalUsd =
          Number(valueCoinToUsd.BTC) +
          Number(valueCoinToUsd.XRP) +
          Number(newValueToUsd);
        break;
      case 'XRP':
        totalUsd =
          Number(valueCoinToUsd.BTC) +
          Number(valueCoinToUsd.ETH) +
          Number(newValueToUsd);
        break;
    }
    setUsd(totalUsd > 0 ? formatNumberToFixed(totalUsd, 2) : '');

    setErrorInputUsd(
      totalUsd > 0 ? checkErrorInputCalculaotr(totalUsd) : false
    );
    setValueAPR(
      String(
        selecTotalAPR(
          String(totalUsd).replace(/,/g, ''),
          valueSelectAPR,
          props.dataInterests
        )
      )
    );
  };
  const handleChangeInput = (key: string, ltv: string, format: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = formatCurrencyInput(event.target.value, format);
    if (newValue === false) {
      return;
    }
    setStateCoinToUsdInput(key, String(newValue), ltv);
  };

  const handleChangeLimitInputCoin = (
    ltv: string,
    keyboard: string,
    key: string,
    format: number,
    min: number,
    step: number,
    max?: number
  ) => () => {
    let newValueCurrency = 0;
    switch (keyboard) {
      case 'arrowUp':
        if (max && Number(valueCoin[key].replace(/,/g, '')) < max + step) {
          newValueCurrency = max;
        } else {
          newValueCurrency = Number(valueCoin[key].replace(/,/g, '')) + step;
        }
        break;
      default:
        if (Number(valueCoin[key].replace(/,/g, '')) < min + step) {
          newValueCurrency = min;
        } else {
          newValueCurrency = Number(valueCoin[key].replace(/,/g, '')) - step;
        }
        break;
    }
    setStateCoinToUsdInput(key, String(newValueCurrency), ltv);
  };

  const setInputUsd = (key: string, totalUsd: string) => {
    const totalValue = Number(totalUsd.replace(/,/g, ''));
    const valueLtv = getConfigsCoinLtv(key, props.dataLoan);

    const valueConfigCoinToUSD = getConfigsRate(
      key,
      props.dataCurrencyBalances
    );
    const newValueCoin = String(
      (totalValue * 100) / (valueLtv * valueConfigCoinToUSD)
    );
    setValueCoin({
      ...valueCoin,
      [key]: newValueCoin
    });
    setValueCoinToUsd({
      ...valueCoin,
      [key]: totalValue
    });
  };

  const handleChangeInputUsd = (key: string, value: string, format: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = formatCurrencyInput(event.target.value, format);
    if (newValue === false) {
      return;
    }
    setUsd(newValue);
    setInputUsd(valueSelectCurrency, String(newValue));
    if (event.target.value === '') {
      setActiveInput({
        BTC: false,
        ETH: false,
        XRP: false
      });
    } else {
      setActiveInput({
        ...activeInput,
        [valueSelectCurrency]: true
      });
    }
    setValueAPR(
      String(
        selecTotalAPR(
          String(newValue).replace(/,/g, ''),
          valueSelectAPR,
          props.dataInterests
        )
      )
    );
    setErrorInputUsd(
      checkErrorInputCalculaotr(Number(String(newValue).replace(/,/g, '')))
    );
  };

  const handleChangeLimitInputUsd = (
    ltv: string,
    keyboard: string,
    key: string,
    format: number,
    min: number,
    step: number,
    max?: number
  ) => () => {
    let newValueUsd = 0;
    switch (keyboard) {
      case 'arrowUp':
        if (max && Number(String(usd).replace(/,/g, '')) > max + step) {
          newValueUsd = max;
        } else {
          newValueUsd = Number(String(usd).replace(/,/g, '')) + step;
        }
        break;
      default:
        if (Number(String(usd).replace(/,/g, '')) < min + step) {
          newValueUsd = min;
        } else {
          newValueUsd = Number(String(usd).replace(/,/g, '')) - step;
        }
        break;
    }
    if (activeInput.BTC === true) {
      setInputUsd('BTC', String(newValueUsd));
    } else if (activeInput.ETH === true) {
      setInputUsd('ETH', String(newValueUsd));
    } else {
      setInputUsd('XRP', String(newValueUsd));
    }
    setValueAPR(
      String(
        selecTotalAPR(
          String(newValueUsd).replace(/,/g, ''),
          valueSelectAPR,
          props.dataInterests
        )
      )
    );
    setUsd(formatNumberToFixed(newValueUsd, format));
  };

  const handleChangeActiveInput = (key: string) => () => {
    setActiveInput({
      ...activeInput,
      [key]: true
    });
  };

  const onBlurTextFiedOutSide = (key: string, valueInput: string) => () => {
    if (valueInput === '') {
      setActiveInput({
        ...activeInput,
        [key]: false
      });
    } else {
      setActiveInput({
        ...activeInput,
        [key]: true
      });
    }
  };

  const handleChangeSelectAPR = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueSelectAPR(event.target.value);
    setValueAPR(
      String(
        selecTotalAPR(
          String(usd).replace(/,/g, ''),
          event.target.value,
          props.dataInterests
        )
      )
    );
  };
  const handleChangeSelectCurrency = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueSelectCurrency(event.target.value);
    setActiveInput({
      [event.target.value]: true
    });

    const totalValue = Number(String(usd).replace(/,/g, ''));
    const valueLtv = getConfigsCoinLtv(event.target.value, props.dataLoan);

    const valueConfigCoinToUSD = getConfigsRate(
      event.target.value,
      props.dataCurrencyBalances
    );
    const newValueCoin = String(
      (totalValue * 100) / (valueLtv * valueConfigCoinToUSD)
    );
    let valueCoinBtc: string = '';
    let valueCoinEth: string = '';
    let valueCoinXrp: string = '';

    let valueCoinBtcToUSd: number = 0;
    let valueCoinEthToUSd: number = 0;
    let valueCoinXrpToUSd: number = 0;

    switch (event.target.value) {
      case 'BTC':
        valueCoinBtc = newValueCoin;
        valueCoinBtcToUSd = totalValue;
        break;
      case 'ETH':
        valueCoinEth = newValueCoin;
        valueCoinEthToUSd = totalValue;
        break;
      case 'XRP':
        valueCoinXrp = newValueCoin;
        valueCoinXrpToUSd = totalValue;
        break;
    }
    setValueCoin({
      BTC: valueCoinBtc,
      ETH: valueCoinEth,
      XRP: valueCoinXrp
    });
    setValueCoinToUsd({
      BTC: valueCoinBtcToUSd,
      ETH: valueCoinEthToUSd,
      XRP: valueCoinXrpToUSd
    });
  };

  useOutSideClick(wrapperRef, () => {
    // if (valueCoin.BTC === '' && activeInput.BTC === true) {
    //   setActiveInput({
    //     ...activeInput,
    //     BTC: false
    //   });
    // }
    // if (valueCoin.ETH === '' && activeInput.ETH === true) {
    //   setActiveInput({
    //     ...activeInput,
    //     ETH: false
    //   });
    // }
    // if (valueCoin.XRP === '' && activeInput.XRP === true) {
    //   setActiveInput({
    //     ...activeInput,
    //     XRP: false
    //   });
    // }
  });

  const classes = styles();
  const { dataLoan, dataInterests } = props;
  return (
    <div>
      {loadingPage ? (
        <Grid>
          <Typography
            variant="h3"
            style={{
              color: ' #3E399F'
            }}
          >
            Select Your Crypto Assets
          </Typography>
          <div
            ref={wrapperRef}
            className={classNames(
              classes.root,
              slickPrev === false ? classes.slickPrev : classes.slickPrevActive,
              slickNext === false ? classes.slickNextActive : classes.slickNext
            )}
          >
            <Slider {...settings}>
              {dataLoan.map(el => (
                <React.Fragment key={el.symbol}>
                  <CalculatorCardComponent
                    min={0}
                    step={0.0001}
                    formatNumber={8}
                    active={activeInput[el.symbol]}
                    detailCoin={el}
                    value={valueCoin[el.symbol]}
                    handleChangeInput={handleChangeInput}
                    handleChangeLimitInput={handleChangeLimitInputCoin}
                    handleChangeActiveInput={handleChangeActiveInput}
                    onBlurTextFiedOutSide={onBlurTextFiedOutSide}
                  />
                </React.Fragment>
              ))}
            </Slider>
          </div>

          <Grid container alignItems="center" style={{ marginTop: 32 }}>
            <Typography
              variant="h3"
              style={{
                color: ' #3E399F'
              }}
            >
              Instant Crypto Credit Line
            </Typography>
            <img
              src={iconInstant}
              width={20}
              height={20}
              alt="icon instant"
              style={{ marginLeft: 8 }}
            />
          </Grid>
          <Grid
            style={{
              marginTop: 12,
              marginBottom: 12,
              display: 'grid',
              gridTemplateColumns: 'calc(100% - 163px) 43px 120px'
            }}
          >
            <NexoInputCurrency
              value={usd}
              detailCoin={valueUSdInfo}
              min={500}
              step={0.01}
              max={2000000}
              errorInput={errorInputUsd}
              titleError="Please, enter between $500 and $2,000,000"
              placeholder="From $500 to $2,000,000"
              formatNumber={2}
              handleChangeInput={handleChangeInputUsd}
              handleChangeLimitInput={handleChangeLimitInputUsd}
            />
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ border: '1px solid #D7DCE0', borderRadius: 5 }}
            >
              <IconButton style={{ padding: 0 }} disabled={true}>
                <ArrowRightAlt />
              </IconButton>
            </Grid>
            <Grid style={{ height: '100%', marginLeft: 17 }}>
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                style={{
                  border: '1px solid #D7DCE0',
                  borderRadius: 5,
                  height: 43
                }}
              >
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  style={{ width: '90%' }}
                  className={classes.selectTextField}
                  select
                  value={valueSelectCurrency}
                  onChange={handleChangeSelectCurrency}
                >
                  {dataSelectCurrency.map(option => (
                    <MenuItem key={option.id} value={option.symbol}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={6} style={{ marginTop: 14 }}>
              <Typography
                variant="h5"
                style={{
                  color: '#A5B2C0',
                  fontWeight: 600
                }}
              >
                Interest from 5.9% per year (APR)
              </Typography>
              <Typography
                variant="h5"
                style={{
                  color: '#A5B2C0',
                  marginTop: 5
                }}
              >
                No minimum repayments required
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                borderLeft: '1px solid #D7DCE0',
                paddingLeft: 20
              }}
            >
              <TextField
                className={classes.selectTextField}
                select
                value={valueSelectAPR}
                onChange={handleChangeSelectAPR}
              >
                {dataInterests.map(option => (
                  <MenuItem key={option.id} value={option.type}>
                    {option.type}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                variant="h4"
                style={{ fontWeight: 500, marginTop: 10 }}
              >
                $ {formatNumberToFixed(valueAPR, 2)}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            style={{
              marginTop: 30
            }}
          >
            <Button
              className={classes.buttonGetStarted}
              variant="contained"
              color="primary"
            >
              Get Started
            </Button>
          </Grid>
          <FooterCalculator
            iconLeft={iconGuaranteed}
            contentLeftTop="Guaranteed Approval"
            contentLeftBottom="No Credit Checks"
            iconRight={iconNoHiddenFees}
            contentRightTop="No Hidden Fees"
            contentRightBottom="Flexible Repayment"
          />
        </Grid>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
};
