import { Store } from 'redux';
import { IStoreState } from '../types';
import { Dispatch } from 'react';
import {
  ILoginAction,
  ISignUpAction,
  IForgotPasswordAction,
  IResetPasswordAction,
  ILogoutAction,
  IVerifyUserAction,
  IChangePasswordAction,
  IVerifyCodeAction,
  IRefreshTokenAction,
  IResendEmailAction
} from '../actions/auth.action';
import {
  LOGIN,
  SIGN_UP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGOUT,
  VERIFY_USER_ACTION,
  CHANGE_PASSWORD,
  VERIFY_CODE,
  REFRESH_TOKEN_ACTION,
  RESEND_EMAIL_ACTION
} from '../constants/auth.constant';
import Axios from 'axios';
import { fireNotification } from '../actions/Notification.action';
import {
  MESSAGE_NOTIFICATION_ERROR,
  MESSAGE_NOTIFICATION_SUCCESS
} from '../constants/Notification.constant';
import Variant from '../types/Variant.type';
import { PATH } from '../constants/Path.constant';
import { push } from 'connected-react-router';
export const loginMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | ILoginAction
    | ISignUpAction
    | IForgotPasswordAction
    | IResetPasswordAction
    | ILogoutAction
    | IVerifyUserAction
    | IChangePasswordAction
    | IVerifyCodeAction
    | IRefreshTokenAction
    | IResendEmailAction
  >
) => (
  action:
    | ILoginAction
    | ISignUpAction
    | IForgotPasswordAction
    | IResetPasswordAction
    | ILogoutAction
    | IVerifyUserAction
    | IChangePasswordAction
    | IVerifyCodeAction
    | IRefreshTokenAction
    | IResendEmailAction
) => {
  switch (action.type) {
    case LOGIN: {
      Axios.post('/auth', action.payload)
        .then(res => {
          const token = res.data;
          if (token.token) {
            localStorage.setItem('token', token.token);
            localStorage.setItem('refresh-token', token.refreshToken);
            localStorage.setItem('exp', token.exp);
            localStorage.setItem('refresh-exp', token.refreshExp);
            localStorage.setItem('role', token.role);
            window.location.href = PATH.nexo.account;
          }
          if (token.twoFactorEnabled) {
            store.dispatch(
              push(PATH.nexo.login, {
                loginRequest: action.payload,
                twoFactorEnabled: true
              })
            );
          } else {
            window.location.href = PATH.nexo.account;
          }
        })
        .catch(err => {
          const error =
            err?.response?.data?.error?.message?.message ||
            err?.response?.data?.error?.message ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;

          store.dispatch(
            push(PATH.nexo.login, {
              disbleButtonLogin: false,
              loginRequest: action.payload,
              twoFactorEnabled: false
            })
          );
          store.dispatch(
            fireNotification({
              message: error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }

    case SIGN_UP: {
      Axios.post('/signup', action.payload)
        .then(res => {
          const token = res.data;
          if (token.token) {
            store.dispatch(
              push(PATH.nexo.verifyAccount, {
                email: action.payload.email
              })
            );
          }
        })
        .catch(err => {
          const error =
            err?.response?.data?.error?.message?.message ||
            err?.response?.data?.error?.message ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;

          store.dispatch(
            fireNotification({
              message: error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case RESEND_EMAIL_ACTION: {
      Axios.post(`/signup/resend`, {
        email: action.payload
      })
        .then(() =>
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.RESEND_MAIL,
              variant: Variant.SUCCESS
            })
          )
        )
        .catch(err => {
          const error =
            err?.response?.data?.error?.message?.message ||
            err?.response?.data?.error?.message ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;
          store.dispatch(
            fireNotification({
              message: error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }

    case VERIFY_USER_ACTION: {
      Axios.post(`/users/verify`, {
        token: action.payload
      })
        .then(res =>
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.VERIFY_USER,
              variant: Variant.SUCCESS,
              link: PATH.nexo.login
            })
          )
        )
        .catch(err => {
          const error =
            err?.response?.data?.error?.message?.message ||
            err?.response?.data?.error?.message ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;
          store.dispatch(
            fireNotification({
              message: error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }

    case FORGOT_PASSWORD: {
      Axios.post(`/forgot/password`, action.payload)
        .then(res =>
          store.dispatch(
            push({
              pathname: PATH.nexo.verifyForgotPassword
            })
          )
        )
        .catch(err => {
          const error =
            err?.response?.data?.error?.message?.message ||
            err?.response?.data?.error?.message ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;
          store.dispatch(
            fireNotification({
              message: error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case RESET_PASSWORD: {
      Axios.post(`/reset-password/${action.payload.token}`, {
        password: action.payload.password,
        repassword: action.payload.repassword
      })
        .then(() =>
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.RESET_PASSWORD,
              variant: Variant.SUCCESS,
              link: PATH.nexo.login
            })
          )
        )
        .catch(err => {
          const error =
            err?.response?.data?.error?.message?.message ||
            err?.response?.data?.error?.message ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;
          store.dispatch(
            fireNotification({
              message: error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case LOGOUT: {
      localStorage.clear();
      window.location.href = PATH.nexo.login;
      break;
    }
    case CHANGE_PASSWORD: {
      Axios.post(`/users/change-password`, action.payload)
        .then(() =>
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.RESET_PASSWORD,
              variant: Variant.SUCCESS,
              link: PATH.nexo.security
            })
          )
        )
        .catch(err => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message:
                error === 'Password incorrect'
                  ? MESSAGE_NOTIFICATION_ERROR.CHANGE_PASSWORD
                  : error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }

    case VERIFY_CODE: {
      Axios.post('/auth/2nd-factor', action.payload)
        .then(res => {
          const token = res.data;
          if (token) {
            localStorage.setItem('token', token.token);
            localStorage.setItem('refresh-token', token.refreshToken);
            localStorage.setItem('exp', token.exp);
            localStorage.setItem('refresh-exp', token.refreshExp);
            localStorage.setItem('role', token.role);
            window.location.href = PATH.nexo.account;
          }
        })
        .catch(err =>
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          )
        );
      break;
    }
    case REFRESH_TOKEN_ACTION: {
      Axios.post(`/refresh-token/${action.payload}`)
        .then(response => {
          if (response.data) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('refresh-token', response.data.refreshToken);
            localStorage.setItem('exp', response.data.exp);
            localStorage.setItem('refresh-exp', response.data.refreshExp);
          }
        })
        .catch(err =>
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          )
        );
      break;
    }
  }
  return dispatch(action);
};
