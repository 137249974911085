import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  root: {
    background: '#FFFFFF',
    border: '1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: 5,
    minHeight: 660,
    marginTop: 21
  },
  header: {
    background: '#F9FAFB',
    boxSizing: 'border-box',
    height: 80,
    borderBottom: '1px solid #D7DCE0',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingLeft: 30
  },
  textFlexible: {
    color: '#050F47'
  },
  rootContent: {
    padding: '47px 30px 30px 30px'
  },
  textContent: {
    paddingBottom: 30,
    borderBottom: '1px dashed #B2B6BA'
  },
  textDeposit: {
    color: '#0A2F5B'
  },
  textAsset: {
    color: '#A5B2C0',
    fontWeight: 500,
    marginTop: 10
  }
});

export const FlexibleInstantComponent = () => {
  const classes = styles();
  return (
    <Grid
      data-aos="fade-left"
      data-aos-offset="300"
      data-aos-duration="300"
      className={classes.root}
    >
      <Grid container alignItems="center" className={classes.header}>
        <Grid>
          <Typography variant="subtitle1" className={classes.textFlexible}>
            Flexible Instant
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.textFlexible}
            style={{ marginTop: 5 }}
          >
            Crypto Credit Lines
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.rootContent}>
        <Grid className={classes.textContent}>
          <Typography className={classes.textDeposit} variant="h4">
            1. Deposit Crypto Assets to Your Insured & Secure Crypto Account
          </Typography>
          <Typography variant="h5" className={classes.textAsset}>
            Your assets are secured and insured up to $100M by audited custodian
            BitGo
          </Typography>
        </Grid>

        <Grid
          className={classes.textContent}
          style={{
            paddingTop: 25
          }}
        >
          <Typography variant="h4" className={classes.textDeposit}>
            2. Receive a Credit Line Instantly. Automated and No Credit Checks.
          </Typography>
          <Typography variant="h5" className={classes.textAsset}>
            Your credit line limit is based on the value of your deposited
            crypto assets
          </Typography>
        </Grid>

        <Grid
          style={{
            paddingTop: 25
          }}
          className={classes.textContent}
        >
          <Typography variant="h4" className={classes.textDeposit}>
            3. Spend Money Instantly by Withdraw to Bank Account
          </Typography>
          <Typography variant="h5" className={classes.textAsset}>
            Spend from the credit line at any time. From 5.9% per year APR on
            what you use
          </Typography>
        </Grid>

        <Grid
          style={{
            paddingTop: 25
          }}
        >
          <Typography variant="h4" className={classes.textDeposit}>
            No Minimum Loan Repayments No Hidden Fees
          </Typography>
          <Typography variant="h5" className={classes.textAsset}>
            Interest is debited from your available limit. Make repayments at
            any time
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
