import { EARN_INTEREST } from '../constants/Coin.constant';
import { ICoinInfo, IInterestInfo } from '../types/calculator.type';
import { ICurrencyBalance } from '../types/currency.type';
import { INotificationMenuInfo } from '../types/Notification.type';

export const validateEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function formatDateTime(date: string) {
  const data = new Date(date);
  const montNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const day = data.getDate();
  const monthIndex = data.getMonth();
  const year = data.getFullYear();
  const hours = `0${data.getHours()}`.slice(-2);
  const minutes = `0${data.getMinutes()}`.slice(-2);

  return (
    montNames[monthIndex] +
    ' ' +
    day +
    ' ' +
    year +
    ', ' +
    hours +
    ':' +
    minutes
  );
}

export function checkErrorInputCalculaotr(textInput: number) {
  let checkErrorInputUsd: boolean = false;
  if (textInput === 0) {
    checkErrorInputUsd = false;
  } else if (500 > textInput) {
    checkErrorInputUsd = true;
  } else if (textInput > 2000000) {
    checkErrorInputUsd = true;
  } else {
    checkErrorInputUsd = false;
  }
  return checkErrorInputUsd;
}

export const formatNumberToFixed = (
  n: number | string,
  fix: number
): string => {
  if (n === '') {
    return '';
  }

  if (n === '0' || n === 0) {
    return '0';
  }

  const formatedN = parseFloat(
    typeof n === 'string' ? parseFloat(n).toFixed(fix) : n.toFixed(fix)
  )
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  const parts = formatedN.split('.');
  if (parts[1]) {
    return parts[0] + '.' + parts[1].replace(/,/g, '');
  } else {
    return parts[0];
  }
};

export const selecTotalAPR = (
  totalUsd: string,
  typeSelect: string,
  interests: IInterestInfo[]
) => {
  const valueConfig: IInterestInfo[] = interests.filter(
    propEl => propEl.type === typeSelect
  );
  if (valueConfig.length === 0) {
    return 0;
  }
  const valueInterest = valueConfig[0].value;
  let total: number = 0;
  total = (Number(totalUsd) / 100) * Number(valueInterest);

  return total;
};

export const getEarnInterest = (
  totalUsd: string,
  typeSelect: string
): number => {
  let total: number = 0;
  if (typeSelect === '1') {
    total = (Number(totalUsd) / 100) * EARN_INTEREST.DAILY;
  } else if (typeSelect === '2') {
    total = (Number(totalUsd) / 100) * EARN_INTEREST.MONTHLY;
  } else {
    total = (Number(totalUsd) / 100) * EARN_INTEREST.YEARLY;
  }
  return total;
};

export function getConfigsRate(name: string, rates: ICurrencyBalance[]) {
  const valueConfig: ICurrencyBalance[] = rates.filter(
    propEl => propEl.currency.symbol === name
  );
  if (valueConfig.length === 0) {
    return 0;
  }

  const valueUsdPrice = valueConfig[0].usdPrice;
  return Number(valueUsdPrice);
}

export function getConfigsCoinLtv(name: string, loan: ICoinInfo[]) {
  const valueConfig: ICoinInfo[] = loan.filter(
    propEl => propEl.symbol === name
  );
  if (valueConfig.length === 0) {
    return 0;
  }
  const valueUsdPrice = valueConfig[0].value;
  return Number(valueUsdPrice);
}

export function formatAddress(format: string) {
  const lengthText = format.length;
  let newValue = '';
  if (lengthText <= 5) {
    newValue = format;
  } else if (5 < lengthText && lengthText <= 10) {
    newValue = format.substring(0, 10) + '...';
  } else if (lengthText > 10) {
    newValue =
      format.substring(0, 7) + '...' + format.substring(format.length - 7);
  } else {
    newValue = '';
  }
  return newValue;
}

export function getBlockchainExplorerLink(currency: string, hash: string) {
  let blockchainExplorerURL: string = '';

  switch (currency) {
    case 'BTC':
      blockchainExplorerURL = `${process.env.REACT_APP_URL_BTC_NETWORK}/${hash}`;
      break;
    case 'XRP':
      blockchainExplorerURL = `${process.env.REACT_APP_URL_XRP_NETWORK}/${hash}`;
      break;
    case 'ETH':
      blockchainExplorerURL = `${process.env.REACT_APP_URL_ETH_NETWORK}/${hash}`;
      break;
  }
  return blockchainExplorerURL;
}

export const formatCurrencyInput = (
  value: string,
  format: number
): string | boolean => {
  const numReg = /^[0-9]+$/;
  if (
    value &&
    numReg.test(value[value.length - 1]) === false &&
    value[value.length - 1] !== '.' &&
    value[value.length - 1] !== ','
  ) {
    return false;
  }
  const newValueCurrency =
    value[value.length - 1] === '.' || value[value.length - 1] === '0'
      ? value
      : formatNumberToFixed(value.replace(/,/g, ''), format);
  return newValueCurrency;
};

export function getConfigsCurrencyUsd(
  name: string,
  dataCurrency: ICurrencyBalance[]
) {
  const valueConfig: ICurrencyBalance[] = dataCurrency.filter(
    propEl => propEl.currency.symbol === name
  );
  if (valueConfig.length === 0) {
    return 0;
  }
  const currencyIdUsd = valueConfig[0].currency.id;
  return currencyIdUsd;
}

export function getConfigsUsdtToUsd(
  name: string,
  dataCurrency: ICurrencyBalance[]
) {
  const valueConfig: ICurrencyBalance[] = dataCurrency.filter(
    propEl => propEl.currency.symbol === name
  );

  if (valueConfig.length === 0) {
    return 0;
  }
  const valueUsdtPrice = valueConfig[0].usdPrice;

  return valueUsdtPrice;
}

export function totalMarketCurrency(dataCurrency: ICurrencyBalance[]) {
  let newValueMarketCurrency = 0;
  // tslint:disable-next-line
  for (let i = 0; i < dataCurrency.length; i++) {
    newValueMarketCurrency =
      newValueMarketCurrency +
      Number(dataCurrency[i].usdPrice) * Number(dataCurrency[i].balance);
  }
  return formatNumberToFixed(newValueMarketCurrency, 2);
}
export function countNotificationMenu(
  dataNotification: INotificationMenuInfo[]
) {
  let countNoti = 0;
  // tslint:disable-next-line
  for (let i = 0; i < dataNotification.length; i++) {
    if (dataNotification[i].isView === false) {
      countNoti = countNoti + 1;
    }
  }
  return countNoti;
}

export function checkErrorInputAmount(symbol: string, value: number) {
  let errorInput: boolean = false;
  switch (symbol) {
    case 'BTC':
      if (value > 0 && value < 0.001) {
        errorInput = true;
      } else {
        errorInput = false;
      }
      break;
    case 'ETH':
      if (value > 0 && value < 0.01) {
        errorInput = true;
      } else {
        errorInput = false;
      }
      break;
    case 'XRP':
      if (value > 0 && value < 1) {
        errorInput = true;
      } else {
        errorInput = false;
      }
      break;
  }
  return errorInput;
}
export function placeHolderInput(symbol: string) {
  let valueInput: string = '';
  switch (symbol) {
    case 'BTC':
      valueInput = 'BTC 0.001';
      break;
    case 'ETH':
      valueInput = 'ETH 0.01';

      break;
    case 'XRP':
      valueInput = 'XRP 1.0';

      break;
  }
  return valueInput;
}
