export const INIT_AXIOS_INTERCEPTOR = 'INIT_AXIOS_INTERCEPTOR';
export type INIT_AXIOS_INTERCEPTOR = typeof INIT_AXIOS_INTERCEPTOR;

export const LOGIN = 'LOGIN';
export type LOGIN = typeof LOGIN;

export const SIGN_UP = 'SIGN_UP';
export type SIGN_UP = typeof SIGN_UP;

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export type FORGOT_PASSWORD = typeof FORGOT_PASSWORD;

export const RESET_PASSWORD = 'RESET_PASSWORD';
export type RESET_PASSWORD = typeof RESET_PASSWORD;

export const VERIFY_USER_ACTION = 'VERIFY_USER_ACTION';
export type VERIFY_USER_ACTION = typeof VERIFY_USER_ACTION;

export const LOGOUT = 'LOGOUT';
export type LOGOUT = typeof LOGOUT;

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export type CHANGE_PASSWORD = typeof CHANGE_PASSWORD;

export const VERIFY_CODE = 'VERIFY_CODE';
export type VERIFY_CODE = typeof VERIFY_CODE;

export const REFRESH_TOKEN_ACTION = 'REFRESH_TOKEN_ACTION';
export type REFRESH_TOKEN_ACTION = typeof REFRESH_TOKEN_ACTION;

export const RESEND_EMAIL_ACTION = 'RESEND_EMAIL_ACTION';
export type RESEND_EMAIL_ACTION = typeof RESEND_EMAIL_ACTION;
