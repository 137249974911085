import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
const styles = makeStyles({
  rootText: {
    '& ul': {
      paddingLeft: 15,
      color: 'rgb(5, 15, 71)',
      margin: 5
    }
  }
});

interface IProps {
  content: string;
}

export const TextProcessing = (props: IProps) => {
  const classes = styles();
  const { content } = props;
  return (
    <Grid className={classes.rootText} container alignItems="center">
      <ul>
        <li>
          <Typography variant="h5">{content}</Typography>
        </li>
      </ul>
    </Grid>
  );
};
