import React from 'react';
import { Grid, Typography, Theme, makeStyles } from '@material-ui/core';
import iconAccount from '../../assets/images/iconAccount.svg';

const styles = makeStyles((theme: Theme) => ({
  root: {
    background: '#F4FAFC',
    border: '1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: 460,
    marginTop: 20,
    paddingLeft: 22,
    paddingRight: 32,
    paddingTop: 26,
    [theme.breakpoints.down(1280)]: {
      height: 450
    }
  },
  text: {
    fontWeight: 500,
    lineHeight: '24px'
  }
}));

export const CardAttentionAccount = () => {
  const classes = styles();
  return (
    <div>
      <Grid>
        <Grid container className={classes.root}>
          <img src={iconAccount} width={40} height={40} alt="icon account" />
          <Grid>
            <Typography variant="h4" className={classes.text}>
              Verification is required to comply with KYC/AML regulations and to
              protect your account from unauthorized access. Client
              verifications are managed by Onfido - the world’s most advanced
              KYC enterprise, and are usually completed within minutes. In rare
              cases it may take up to 24 hours.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
