import { ISelectInfo } from '../components/share/NexoSelect.compoent';

export const WITHDRAW_ASSET_ACTION = 'WITHDRAW_ASSET_ACTION';
export type WITHDRAW_ASSET_ACTION = typeof WITHDRAW_ASSET_ACTION;

export const WITHDRAW_ASSET_APPROVED_ACTION = 'WITHDRAW_ASSET_APPROVED_ACTION';
export type WITHDRAW_ASSET_APPROVED_ACTION = typeof WITHDRAW_ASSET_APPROVED_ACTION;

export const WITHDRAW_SAVING_APPROVED_ACTION =
  'WITHDRAW_SAVING_APPROVED_ACTION';
export type WITHDRAW_SAVING_APPROVED_ACTION = typeof WITHDRAW_SAVING_APPROVED_ACTION;
export const DataSpeed: ISelectInfo[] = [
  {
    id: '0',
    title: 'Fast',
    symbol: 'fast'
  },
  {
    id: '1',
    title: 'Average',
    symbol: 'average'
  },
  {
    id: '2',
    title: 'Slow',
    symbol: 'slow'
  }
];

export enum WITHDRAWAL_TYPE {
  LOAN = 'Loan Withdrawal',
  ASSET = 'Asset Withdrawal',
  SAVING = 'Saving Withdrawal'
}
