import { Action } from 'redux';
import {
  LOAD_USER_PROFILE,
  SET_USER_PROFILE,
  EDIT_USER_PROFILE,
  LOAD_USER_HISTORY_LOGIN,
  SET_USER_HISTORY_LOGIN,
  LOAD_DATA_GOOGLE_AUTH_OF_USER,
  SET_DATA_GOOGLE_AUTH_OF_USER,
  PROCESS_GOOGLE_AUTH_FOR_USER,
  PROCESS_UPLOAD_IMAGE_FOR_VERIFY,
  SET_DATA_CODE_GOOGLE_AUTH_FOR_USER
} from '../constants/user.constant';
import {
  IUserInfo,
  IEditUserProfileRequest,
  IObjectLoginHistoryInfo,
  IDataGoogleAuthenticatorInfo,
  IProcessGoogleAuthRequest,
  IUploadImagesForVerifyRequest,
  IGoogleAuthCodeInfo
} from '../types/user.type';
import { ILoginHistoryRequest } from '../types/user.type';

export interface ILoadUserProfileAction extends Action<LOAD_USER_PROFILE> {}

export const loadUserProfile = (): ILoadUserProfileAction => ({
  type: LOAD_USER_PROFILE
});

export interface ISetUserProfileAction extends Action<SET_USER_PROFILE> {
  payload: IUserInfo;
}

export function setUserProfileAction(
  userManagement: IUserInfo
): ISetUserProfileAction {
  return {
    type: SET_USER_PROFILE,
    payload: userManagement
  };
}

export interface IEditUserProfileAction extends Action<EDIT_USER_PROFILE> {
  payload: IEditUserProfileRequest;
}

export const editUserProfileAction = (
  request: IEditUserProfileRequest
): IEditUserProfileAction => ({
  type: EDIT_USER_PROFILE,
  payload: request
});

export interface ILoadUserLoginHistoryAction
  extends Action<LOAD_USER_HISTORY_LOGIN> {
  payload: ILoginHistoryRequest;
}

export const loadUserLoginHistoryAction = (
  request: ILoginHistoryRequest
): ILoadUserLoginHistoryAction => ({
  type: LOAD_USER_HISTORY_LOGIN,
  payload: request
});

export interface ISetUserLoginHistoryAction
  extends Action<SET_USER_HISTORY_LOGIN> {
  payload: IObjectLoginHistoryInfo;
}

export const setUserLoginHistoryAction = (
  userManagement: IObjectLoginHistoryInfo
): ISetUserLoginHistoryAction => ({
  type: SET_USER_HISTORY_LOGIN,
  payload: userManagement
});

export interface ILoadDataGoogleAuthenticatorAction
  extends Action<LOAD_DATA_GOOGLE_AUTH_OF_USER> {}

export const loadDataGoogleAuthenticatorAction = (): ILoadDataGoogleAuthenticatorAction => ({
  type: LOAD_DATA_GOOGLE_AUTH_OF_USER
});

export interface ISetDataGoogleAuthenticatorAction
  extends Action<SET_DATA_GOOGLE_AUTH_OF_USER> {
  payload: IDataGoogleAuthenticatorInfo;
}

export const setDataGoogleAuthenticatorAction = (
  request: IDataGoogleAuthenticatorInfo
): ISetDataGoogleAuthenticatorAction => ({
  type: SET_DATA_GOOGLE_AUTH_OF_USER,
  payload: request
});

export interface IProcessAuthGoogleAction
  extends Action<PROCESS_GOOGLE_AUTH_FOR_USER> {
  payload: IProcessGoogleAuthRequest;
}

export const processAuthGoogleAction = (
  request: IProcessGoogleAuthRequest
): IProcessAuthGoogleAction => ({
  type: PROCESS_GOOGLE_AUTH_FOR_USER,
  payload: request
});

export interface IProcessUploadImageForVerifyAction
  extends Action<PROCESS_UPLOAD_IMAGE_FOR_VERIFY> {
  payload: IUploadImagesForVerifyRequest;
}

export const processUploadImageForVerifyAction = (
  request: IUploadImagesForVerifyRequest
): IProcessUploadImageForVerifyAction => ({
  type: PROCESS_UPLOAD_IMAGE_FOR_VERIFY,
  payload: request
});

export interface ISetGoogleAuthCodeAction
  extends Action<SET_DATA_CODE_GOOGLE_AUTH_FOR_USER> {
  payload: IGoogleAuthCodeInfo;
}

export const setGoogleAuthCodeAction = (
  request: IGoogleAuthCodeInfo
): ISetGoogleAuthCodeAction => ({
  type: SET_DATA_CODE_GOOGLE_AUTH_FOR_USER,
  payload: request
});
