import React, { useState } from 'react';
import { Dialog, Grid, Typography, makeStyles } from '@material-ui/core';
import { NexoItemCoinCredit, NexoButton } from '../share';
import { InputWithdraw } from '../share/InputWithdraw.component';
import { IUserInfo } from '../../types/user.type';
import { BasicVerifNotification } from '../calculator/BasicVerifNotification.component';
import { TwoFactorAuthNotification } from '../calculator/TwoFactorAuthNotification.component';
import { WITHDRAWAL_TYPE } from '../../constants/withdrawAsset.constant';
import { IWithdrawAssetRequest } from '../../types/withdrawAsset.type';
import { useDispatch } from 'react-redux';
import { withdrawAssetAction } from '../../actions/withdrawAsset.action';
import { ICurrencyBalance } from '../../types/currency.type';
import {
  formatNumberToFixed,
  checkErrorInputAmount,
  placeHolderInput
} from '../../helper';
import { IMAGE } from '../../constants/image.constant';
import { PATH } from '../../constants/Path.constant';
import { useHistory } from 'react-router';
import { NexoInputVerifyCode } from '../share/NexoInputVerifyCode.component';

const styles = makeStyles({
  rootDialog: {
    '& .MuiDialog-paperWidthLg': {
      width: 600,
      maxWidth: 600
    }
  },
  dialogTitle: {
    padding: 0
  },
  rootHeader: {
    height: 80,
    borderBottom: '1px solid #E4EAEE',
    background: '#F9FAFB',
    boxSizing: 'border-box',
    paddingLeft: 35,
    paddingRight: 35
  },
  code: {
    textAlign: 'center',
    color: '#050F47',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 40,
    lineHeight: '40px',
    letterSpacing: '0.3em'
  },
  rootAdvanced: {
    height: 50,
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5
  },

  cusCode: {
    '& .MuiFormControl-fullWidth': {
      height: '100%'
    },
    '& .MuiOutlinedInput-root': {
      height: '100%'
    },
    '& .MuiInputBase-input': {
      color: '#050F47',
      fontSize: 14,
      fontFamily: 'Montserrat',
      fontWeight: 600
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #34CEA9'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: ' #34CEA9'
    }
  },
  errorGroup: {
    fontSize: 12,
    color: '#E3001E',
    fontFamily: 'Montserrat'
  },
  errorIcon: {
    marginRight: 10,
    width: 15.11,
    height: 13.33
  }
});

interface IProps {
  userProfile: IUserInfo;
  showDialog: boolean;
  handleClose: () => void;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  handleRedirectTopUP?: () => void;
  itemCurrencies: ICurrencyBalance;
}

export const WithDrawDialogComponent = (props: IProps) => {
  const dispatch = useDispatch();
  const [withdrawAsset, setWithdrawAsset] = useState<IWithdrawAssetRequest>({
    amount: 0,
    recipientAddress: '',
    withdrawalType: '',
    code: ''
  });
  const [errorInputCode, setErrorInputCode] = useState<boolean>(false);

  const history = useHistory();

  const handleChangeInput = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newValueSubString: string = '';
    if (
      event.target.value.substring(0, 1) === '0' &&
      props.itemCurrencies.currency.symbol === 'USDT'
    ) {
      newValueSubString = event.target.value.substring(1);
    } else {
      newValueSubString = event.target.value;
    }
    setWithdrawAsset({
      ...withdrawAsset,
      [key]: newValueSubString
    });
  };

  const handleVerifyWithDrawAsset = () => {
    const request: IWithdrawAssetRequest = {
      amount: Number(withdrawAsset.amount),
      recipientAddress: withdrawAsset.recipientAddress,
      currencyId: props.itemCurrencies.currency.id,
      withdrawalType:
        props.itemCurrencies.currency.stablecoin === false
          ? WITHDRAWAL_TYPE.ASSET
          : WITHDRAWAL_TYPE.SAVING,
      code: withdrawAsset.code
    };
    if (withdrawAsset.code === '') {
      setErrorInputCode(true);
    } else {
      dispatch(withdrawAssetAction(request));
    }
  };

  const handleTextCodeVerifyChange = (codeInput: string) => {
    setWithdrawAsset({
      ...withdrawAsset,
      code: codeInput
    });
  };

  const renderWithdrawAmount = () => {
    // tslint:disable-next-line
    const { itemCurrencies } = props;
    const disabledButtonVerify: boolean =
      itemCurrencies.withdrawLimit - Number(withdrawAsset.amount) < 0 ||
      withdrawAsset.recipientAddress === '' ||
      Number(withdrawAsset.amount) === 0
        ? true
        : false;
    return (
      <Grid className={classes.dialogTitle}>
        <Grid container alignItems="center" className={classes.rootHeader}>
          <Grid container alignItems="center" item xs={6}>
            <Typography
              variant="subtitle1"
              style={{ color: '#050F47', marginRight: 14 }}
            >
              Withdraw
            </Typography>
            <NexoItemCoinCredit
              id=""
              name={props.itemCurrencies.currency.name}
              imgSrc={props.itemCurrencies.currency.image}
            />
          </Grid>
        </Grid>
        <Grid
          style={{
            padding: 35
          }}
        >
          <Grid
            style={{
              background: '#FFFFFF',
              border: ' 1px solid #D7DCE0',
              boxSizing: 'border-box',
              borderRadius: 5,
              padding: 15
            }}
          >
            <Grid
              style={{
                border: '1px solid #D7DCE0',
                boxSizing: 'border-box',
                borderRadius: 5,
                height: 50,
                background: '#F9FAFB'
              }}
              container
              alignItems="center"
              justify="center"
            >
              <Typography variant="h2" style={{ color: '#050F47' }}>
                You can withdraw up to
                <span style={{ color: '#20bcff', marginLeft: 3 }}>
                  {itemCurrencies.currency.symbol}{' '}
                  {formatNumberToFixed(
                    itemCurrencies.withdrawLimit,
                    itemCurrencies.currency.symbol === 'USDT' ? 2 : 8
                  )}
                </span>
              </Typography>
            </Grid>
            <Grid style={{ marginTop: 20 }}>
              <InputWithdraw
                name=""
                contentLeft=" From"
                disabledInput={true}
                iconInputLeft={false}
                valueInput={props.itemCurrencies.depositAddress}
              />
            </Grid>
            <Grid style={{ marginTop: 20 }}>
              <InputWithdraw
                valueInput={withdrawAsset.amount}
                name="amount"
                errorInput={checkErrorInputAmount(
                  itemCurrencies.currency.symbol,
                  withdrawAsset.amount
                )}
                placeHolderInput={
                  checkErrorInputAmount(
                    itemCurrencies.currency.symbol,
                    withdrawAsset.amount
                  ) === true
                    ? `The minimum amount of crypto assets you can withdraw is: ${placeHolderInput(
                        itemCurrencies.currency.symbol
                      )}`
                    : ''
                }
                contentLeft=" Amount"
                disabledInput={false}
                iconInputLeft={true}
                minMaxInput={{
                  min: '0',
                  max: String(props.itemCurrencies.withdrawLimit),
                  step: '0.0001'
                }}
                title={props.itemCurrencies.currency.symbol}
                type="number"
                handleChangeInput={handleChangeInput}
              />
            </Grid>

            <Grid style={{ marginTop: 20 }}>
              <InputWithdraw
                valueInput={withdrawAsset.recipientAddress}
                name="recipientAddress"
                contentLeft="Recipient Address"
                disabledInput={false}
                iconInputLeft={false}
                handleChangeInput={handleChangeInput}
              />
            </Grid>
            <Grid
              container
              justify="center"
              alignContent="center"
              style={{
                marginTop: 20,
                marginBottom: 20
              }}
            >
              <Grid container justify="center" item xs={12}>
                <Typography variant="h4">
                  Enter the two-step Authentication code
                </Typography>
              </Grid>
              <NexoInputVerifyCode
                handleTextChangeCode={handleTextCodeVerifyChange}
              />

              {errorInputCode ? (
                <Grid container justify="center">
                  <Typography className={classes.errorGroup}>
                    <img
                      className={classes.errorIcon}
                      src={IMAGE.ICON_ERROR}
                      alt="errorIcon"
                    />
                    Please enter verify code
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid container style={{ marginTop: 32 }}>
              <Grid item xs={6} style={{ paddingRight: 10 }}>
                <NexoButton
                  title="Cancel"
                  variant="outlined"
                  color="primary"
                  handleRedirect={handleCloseDialog}
                  heightButton={40}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 10 }}>
                <NexoButton
                  disabled={
                    disabledButtonVerify ||
                    checkErrorInputAmount(
                      itemCurrencies.currency.symbol,
                      withdrawAsset.amount
                    )
                  }
                  title="Verify"
                  handleRedirect={handleVerifyWithDrawAsset}
                  variant="contained"
                  color="primary"
                  heightButton={40}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSendAmount = () => {
    // tslint:disable-next-line
    const { handleRedirectTopUP } = props;
    return (
      <Grid className={classes.dialogTitle}>
        <Grid container alignItems="center" className={classes.rootHeader}>
          <Grid container alignItems="center" item xs={6}>
            <Typography
              variant="subtitle1"
              style={{ color: '#050F47', marginRight: 14 }}
            >
              Send
            </Typography>
            <NexoItemCoinCredit
              id=""
              name={props.itemCurrencies.currency.name}
              imgSrc={props.itemCurrencies.currency.image}
            />
          </Grid>
        </Grid>
        <Grid
          style={{
            padding: 35
          }}
        >
          <Grid
            style={{
              background: '#FFFFFF',
              border: ' 1px solid #D7DCE0',
              boxSizing: 'border-box',
              borderRadius: 5,
              padding: 15,
              textAlign: 'center'
            }}
          >
            <Typography variant="h2" style={{ color: '#050F47' }}>
              You can withdraw up to
              <span style={{ color: '#20bcff' }}>
                {' '}
                {props.itemCurrencies.currency.symbol} 0.00000000{' '}
              </span>
            </Typography>
            <Grid style={{ marginTop: 20 }}>
              <img
                data-aos="zoom-out"
                data-aos-duration="1000"
                src={IMAGE.ICON_WITHDRAW_EMPTY}
                alt="icon withdraw"
                width={150}
                height={150}
                style={{ marginRight: 50 }}
              />
            </Grid>
            <Grid container justify="center" style={{ marginTop: 20 }}>
              <Grid style={{ width: 200 }}>
                <NexoButton
                  title="Top Up"
                  variant="contained"
                  color="primary"
                  handleRedirect={handleRedirectTopUP}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const handleCloseDialog = () => {
    setWithdrawAsset({
      amount: 0,
      recipientAddress: '',
      withdrawalType: '',
      code: ''
    });
    props.handleClose();
  };
  const { showDialog, maxWidth, userProfile, itemCurrencies } = props;

  const classes = styles();
  const handleRedirectMyProfileAdvanced = () => {
    history.push(PATH.nexo.myProfile, {
      pageNumber: 2
    });
  };
  const handleRedirectMyProfileBasic = () => {
    history.push(PATH.nexo.myProfile, {
      pageNumber: 1
    });
  };
  const handleRedirectSecurity = () => {
    history.push(PATH.nexo.security);
  };
  return (
    <Dialog
      className={classes.rootDialog}
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      maxWidth={maxWidth}
    >
      {userProfile.verificationLevel1 === false ? (
        <BasicVerifNotification
          message="You first need to complete Basic Verification"
          handleClose={handleRedirectMyProfileBasic}
        />
      ) : userProfile.verificationLevel2 === false ? (
        <BasicVerifNotification
          message="You second need to complete Advanced Verification"
          handleClose={handleRedirectMyProfileAdvanced}
        />
      ) : userProfile.isGoogleAuthenticator === false ? (
        <TwoFactorAuthNotification handleClose={handleRedirectSecurity} />
      ) : Number(itemCurrencies.withdrawLimit) > 0 ? (
        renderWithdrawAmount()
      ) : (
        renderSendAmount()
      )}
    </Dialog>
  );
};
