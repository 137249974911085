import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { NexoDialogComponent } from '../share/NexoDialog.component';
import iconPassportHover from '../../assets/images/iconPassport.svg';
import iconGoto from '../../assets/images/iconGoto.svg';
import iconPassportLeave from '../../assets/images/iconPassportLeave.svg';
import iconLeave from '../../assets/images/iconLeave.svg';
import iconDriverHover from '../../assets/images/iconDriverHover.svg';
import iconDriverLeave from '../../assets/images/iconDriverLeave.svg';
import iconCardHover from '../../assets/images/iconCardHover.svg';
import iconCardLeave from '../../assets/images/iconCardLeave.svg';

import classNames from 'classnames';
const styles = makeStyles({
  root: {
    background: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: 5,
    marginTop: 10,
    height: 80,
    paddingLeft: 18,
    paddingRight: 26,
    display: 'grid',
    gridTemplateColumns: '73px calc(100% - 78px) 5px',
    cursor: 'pointer'
  },
  rootHover: {
    background: '#4E99ED',
    border: '1px solid #2577E4'
  },
  rootLeave: {
    background: '#FFFFFF',
    border: '1px solid #D7DCE0'
  },
  textPassport: {
    color: '#FFFFFF'
  },
  textPassportLeave: {
    color: '#050F47'
  }
});
interface IProps {
  showDialog: boolean;
  handleCloseDialog: () => void;
  handleShowPhotopage: (key: string) => () => void;
  textHeader: string;
}

const dataCard = [
  {
    id: 0,
    key: 'passport',
    name: 'Passport',
    title: 'Face photo page',
    imageHover: iconPassportHover,
    imageLeave: iconPassportLeave
  },
  {
    id: 1,
    key: 'driver',
    name: 'Driver’s License',
    title: 'Front and back',
    imageHover: iconDriverHover,
    imageLeave: iconDriverLeave
  },
  {
    id: 2,
    key: 'card',
    name: 'Identity Card',
    title: 'Front and back',
    imageHover: iconCardHover,
    imageLeave: iconCardLeave
  }
];

export const VerifyYourIdentityDialog = (props: IProps) => {
  const classes = styles();
  const {
    handleCloseDialog,
    showDialog,
    textHeader,
    handleShowPhotopage
  } = props;
  const [checkHoverCard, setCheckHoverCard] = useState<any>({
    passport: false,
    card: false,
    driver: false
  });

  const handleOnMouseOver = (key: string) => () => {
    setCheckHoverCard({
      ...checkHoverCard,
      [key]: true
    });
  };
  const handleOnMouseLeave = (key: string) => () => {
    setCheckHoverCard({
      ...checkHoverCard,
      [key]: false
    });
  };

  return (
    <NexoDialogComponent
      handleCloseDialog={handleCloseDialog}
      showDialog={showDialog}
      textHeader={textHeader}
      maxWidth="md"
      hiddenback={false}
    >
      <Grid style={{ marginTop: 48 }}>
        <Grid container justify="center">
          <Typography
            variant="h5"
            style={{ color: '#050F47', fontWeight: 600 }}
          >
            Select the type of document you would like to upload
          </Typography>
        </Grid>
        {dataCard.map((el, index) => (
          <Grid
            key={index}
            container
            alignItems="center"
            onMouseEnter={handleOnMouseOver(el.key)}
            onMouseLeave={handleOnMouseLeave(el.key)}
            onClick={handleShowPhotopage(el.key)}
            className={classNames(
              checkHoverCard[el.key] ? classes.rootHover : classes.rootLeave,
              classes.root
            )}
          >
            <img
              src={checkHoverCard[el.key] ? el.imageHover : el.imageLeave}
              alt="icon passport"
              width={34}
              height={50}
            />
            <Grid>
              <Grid>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 600 }}
                  className={
                    checkHoverCard[el.key]
                      ? classes.textPassport
                      : classes.textPassportLeave
                  }
                >
                  {el.name}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  variant="h5"
                  className={
                    checkHoverCard[el.key]
                      ? classes.textPassport
                      : classes.textPassportLeave
                  }
                >
                  {el.title}
                </Typography>
              </Grid>
            </Grid>
            <img
              src={checkHoverCard[el.key] ? iconGoto : iconLeave}
              alt="icon go"
            />
          </Grid>
        ))}
      </Grid>
    </NexoDialogComponent>
  );
};
