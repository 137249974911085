import React from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0
    },
    header: {
      height: 80,
      backgroundColor: '#F9FAFB',
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
      borderTopRightRadius: 5,
      borderTopLeftRadius: 5,
      borderBottom: '1px solid #E4EAEE',
      '& .MuiTypography-root': {
        fontSize: 20,
        fontFamily: 'Montserrat',
        color: '#050F47'
      }
    },
    content: {
      padding: '30px 35px'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  title: string;
  numberElevation: number;
}

class NexoPaperComponent extends React.Component<IProps, {}> {
  public render() {
    const { classes, title, children, numberElevation } = this.props;
    return (
      <div
        data-aos="zoom-out"
        data-aos-duration="1000"
        className={classes.root}
      >
        <Paper elevation={numberElevation}>
          <Grid className={classes.header}>
            <Typography variant="h1">{title}</Typography>
          </Grid>
          <Grid className={classes.content}>{children}</Grid>
        </Paper>
      </div>
    );
  }
}

export const NexoPaper = withStyles(styles)(NexoPaperComponent);
