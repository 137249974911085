import React from 'react';
import { Snackbar, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  snackbar: {
    borderRadius: 5,
    '& .MuiSnackbarContent-root': {
      backgroundColor: 'transparent',
      background:
        'linear-gradient(136.04deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(127.73deg, #67ACF1 -10.95%, #156EE4 104.81%)',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: 13
    }
  }
});

interface IProps {
  openCopy: boolean;
  handleCloseCopy: () => void;
}

export const SnackbarDialogComponent = (props: IProps) => {
  const { openCopy, handleCloseCopy } = props;
  const classes = styles();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={openCopy}
      className={classes.snackbar}
      onClose={handleCloseCopy}
      autoHideDuration={2000}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
      message="Copied to Address"
    />
  );
};
