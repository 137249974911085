import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputWithdraw } from './InputWithdraw.component';
import { NexoSelectCountry } from '../../components/share/NexoSelectCountry.component';
import ICountry from '../../types/country.type';

interface IProps {
  handleChangeInput: (
    key: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  loanCreditLine: any;
  handleOnChangeCountry: (selectedCountry: ICountry[]) => void;
  selectedCountry: ICountry[];
}

export const AccountWithDrawCard = (props: IProps) => {
  const { handleChangeInput, loanCreditLine } = props;
  return (
    <Grid>
      <Grid container style={{ marginTop: 20 }}>
        <Grid item xs={6} style={{ paddingRight: 5 }}>
          <InputWithdraw
            name="recepientName"
            contentLeft="Recipient name"
            iconInputLeft={false}
            disabledInput={false}
            valueInput={loanCreditLine.recepientName}
            handleChangeInput={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 5 }}>
          <InputWithdraw
            valueInput={loanCreditLine.transferType}
            name="transferType"
            contentLeft="Transfer Type"
            iconInputLeft={false}
            disabledInput={true}
            handleChangeInput={handleChangeInput}
          />
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 20 }}>
        <Grid item xs={6} style={{ paddingRight: 5 }}>
          <InputWithdraw
            valueInput={loanCreditLine.recipientType}
            name="recipientType"
            contentLeft="Recipient type"
            iconInputLeft={false}
            disabledInput={true}
            handleChangeInput={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 5 }}>
          <InputWithdraw
            valueInput={loanCreditLine.swiftCode}
            name="swiftCode"
            contentLeft="SWIFT code"
            iconInputLeft={false}
            disabledInput={false}
            handleChangeInput={handleChangeInput}
          />
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 20 }}>
        <Grid item xs={6} style={{ paddingRight: 5 }}>
          <InputWithdraw
            valueInput={loanCreditLine.accountNumber}
            name="accountNumber"
            contentLeft="Account number"
            iconInputLeft={false}
            disabledInput={false}
            handleChangeInput={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 5 }}>
          <Typography variant="h5" style={{ fontSize: 14, marginBottom: 4 }}>
            Country
          </Typography>
          <NexoSelectCountry
            hiddenText={false}
            handleOnChangeCountry={props.handleOnChangeCountry}
            title="Country"
            placeholder=""
            countryId={loanCreditLine.countryId}
            selectedCountry={props.selectedCountry}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} style={{ paddingRight: 5 }}>
          <InputWithdraw
            valueInput={loanCreditLine.city}
            name="city"
            contentLeft="City"
            iconInputLeft={false}
            disabledInput={false}
            handleChangeInput={handleChangeInput}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 5 }}>
          <InputWithdraw
            valueInput={loanCreditLine.postCode}
            name="postCode"
            contentLeft="Postal code"
            iconInputLeft={false}
            disabledInput={false}
            handleChangeInput={handleChangeInput}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 20 }}>
        <InputWithdraw
          valueInput={loanCreditLine.address}
          name="address"
          contentLeft="Address"
          iconInputLeft={false}
          disabledInput={false}
          handleChangeInput={handleChangeInput}
        />
      </Grid>
    </Grid>
  );
};
