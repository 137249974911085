export const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE';
export type LOAD_USER_PROFILE = typeof LOAD_USER_PROFILE;

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export type SET_USER_PROFILE = typeof SET_USER_PROFILE;

export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE';
export type EDIT_USER_PROFILE = typeof EDIT_USER_PROFILE;

export const LOAD_USER_HISTORY_LOGIN = 'LOAD_USER_HISTORY_LOGIN';
export type LOAD_USER_HISTORY_LOGIN = typeof LOAD_USER_HISTORY_LOGIN;

export const SET_USER_HISTORY_LOGIN = 'SET_USER_HISTORY_LOGIN';
export type SET_USER_HISTORY_LOGIN = typeof SET_USER_HISTORY_LOGIN;

export const LOAD_DATA_GOOGLE_AUTH_OF_USER = 'LOAD_DATA_GOOGLE_AUTH_OF_USER';
export type LOAD_DATA_GOOGLE_AUTH_OF_USER = typeof LOAD_DATA_GOOGLE_AUTH_OF_USER;

export const SET_DATA_GOOGLE_AUTH_OF_USER = 'SET_DATA_GOOGLE_AUTH_OF_USER';
export type SET_DATA_GOOGLE_AUTH_OF_USER = typeof SET_DATA_GOOGLE_AUTH_OF_USER;

export const PROCESS_GOOGLE_AUTH_FOR_USER = 'PROCESS_GOOGLE_AUTH_FOR_USER';
export type PROCESS_GOOGLE_AUTH_FOR_USER = typeof PROCESS_GOOGLE_AUTH_FOR_USER;

export const PROCESS_UPLOAD_IMAGE_FOR_VERIFY =
  'PROCESS_UPLOAD_IMAGE_FOR_VERIFY';
export type PROCESS_UPLOAD_IMAGE_FOR_VERIFY = typeof PROCESS_UPLOAD_IMAGE_FOR_VERIFY;

export const SET_DATA_CODE_GOOGLE_AUTH_FOR_USER =
  'SET_DATA_CODE_GOOGLE_AUTH_FOR_USER';
export type SET_DATA_CODE_GOOGLE_AUTH_FOR_USER = typeof SET_DATA_CODE_GOOGLE_AUTH_FOR_USER;
