import React from 'react';
import { Grid, Typography, FormControlLabel, Switch } from '@material-ui/core';

interface IProps {
  checkedSwitchAuth: boolean;
  checkedSwitchSMS: boolean;
  handleChangeSwitchAuth: () => void;
  handleChangeSwitchSMS: () => void;
}

export const CardAuthentication = (props: IProps) => {
  const { checkedSwitchAuth, handleChangeSwitchAuth, checkedSwitchSMS } = props;
  return (
    <Grid style={{ marginTop: 31 }}>
      <Grid>
        <Typography variant="h4" style={{ color: '#3E399F' }}>
          Two-Factor Authentication
        </Typography>
      </Grid>

      <Grid style={{ marginTop: 36 }}>
        <Typography variant="h4" style={{ color: '#050F47' }}>
          Google Authenticator / Authy
        </Typography>
      </Grid>

      <Grid style={{ marginTop: 2 }}>
        <Typography variant="h5" style={{ color: '#A5B2C0' }}>
          Used for withdrawals and changes to security settings
        </Typography>
      </Grid>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        <FormControlLabel
          disabled={checkedSwitchSMS}
          style={{ marginLeft: 0 }}
          control={<Switch checked={checkedSwitchAuth} />}
          onClick={handleChangeSwitchAuth}
          label=""
        />
      </Grid>

      {/* <Grid style={{ marginTop: 36 }}>
          <Typography variant="h4" style={{ color: '#050F47' }}>
            SMS Authentication
          </Typography>
        </Grid>

        <Grid style={{ marginTop: 2 }}>
          <Typography variant="h5" style={{ color: '#A5B2C0' }}>
            Used for withdrawals and changes to security settings
          </Typography>
        </Grid> */}
      {/* <Grid container item xs={12} style={{ marginTop: 10 }}>
          <FormControlLabel
            disabled={checkedSwitchAuth}
            style={{ marginLeft: 0 }}
            control={<Switch checked={checkedSwitchSMS} />}
            onClick={handleChangeSwitchSMS}
            label=""
          />
        </Grid> */}
    </Grid>
  );
};
