import * as React from 'react';
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Dialog,
  Grid,
  Typography
} from '@material-ui/core/';
import INotificationInfo from '../types/Notification.type';
import { connect } from 'react-redux';
import { IStoreState } from '../types';
import {
  ceaseNotification,
  IEaseNotification
} from '../actions/Notification.action';
import { Dispatch } from 'react';
import iconsuccesDialog from '../assets/images/iconSussesly.svg';
import iconErrorDialog from '../assets/images/errorNotification.svg';
import Variant from '../types/Variant.type';
import { NexoButton } from './share';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { History } from 'history';

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      height: 100,
      padding: 0
    },
    dialogContent: {
      height: 'calc(100% - 200px )',
      paddingLeft: 30,
      paddingRight: 30
    },
    textWithDraw: {
      fontSize: 20,
      color: '#000000',
      lineHeight: '24px',
      fontWeight: 600
    },
    dialogActions: {
      height: 100,
      borderTop: '1px solid #DCE0E4'
    },
    buttonYesWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize'
    },
    buttonNoWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize',
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCE0E4',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: '#F4F4F4',
        border: '1px solid #E6E6E6'
      }
    },
    heightSucces: {
      height: 197
    },
    imgIcon: {
      marginLeft: 30
    },
    textSusses: {
      fontWeight: 500,
      color: '#36BCA4'
    },
    textError: {
      fontWeight: 500,
      color: '#E3001E'
    },
    heightMessage: {
      height: 'calc(100% - 297px)',
      textAlign: 'center'
    },
    textMessage: {
      fontWeight: 500,
      textAlign: 'center',
      paddingLeft: 15,
      paddingRight: 15
    },
    heightButton: {
      height: 100,
      width: '100%'
    },
    rootDialog: {
      '& .MuiDialog-paperWidthLg': {
        width: 700,
        maxWidth: 700,
        height: 500,
        padding: '52px 125px 52px 125px'
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  notification: INotificationInfo;
  history: History;
  closeNotification: () => void;
}

class NotificationComponent extends React.Component<IProps> {
  handleCloseNotification = () => {
    this.props.closeNotification();
    if (this.props.notification.link) {
      this.props.history.push(this.props.notification.link, {
        turnOffDialog: true
      });
    }
  };

  public render() {
    const { classes, notification } = this.props;
    return (
      <div>
        <Dialog
          onClose={this.handleCloseNotification}
          aria-labelledby="customized-dialog-title"
          open={!!notification.isOpen}
          maxWidth="lg"
          className={classes.rootDialog}
        >
          {notification.variant === Variant.SUCCESS ? (
            <Grid container justify="center" className={classes.heightSucces}>
              <img
                data-aos="zoom-out"
                data-aos-duration="1000"
                src={iconsuccesDialog}
                alt="icon succes"
                className={classes.imgIcon}
                width={240}
                height={162}
              />
            </Grid>
          ) : (
            <Grid
              container
              className={classes.heightSucces}
              alignItems="center"
              justify="center"
            >
              <img
                data-aos="zoom-out"
                data-aos-duration="1000"
                src={iconErrorDialog}
                alt="icon succes"
                className={classes.imgIcon}
              />
            </Grid>
          )}

          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.heightMessage}
          >
            <Typography variant="subtitle1">{notification.message}</Typography>
          </Grid>
          <Grid
            container
            alignItems="flex-end"
            justify="center"
            className={classes.heightButton}
          >
            <NexoButton
              title="Continue Using"
              variant="contained"
              color="primary"
              handleRedirect={this.handleCloseNotification}
            />
          </Grid>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(
  { notification }: IStoreState,
  { history }: RouteComponentProps
) {
  return {
    notification,
    history
  };
}

function mapDispatchToProps(dispatch: Dispatch<IEaseNotification>) {
  return {
    closeNotification: () => {
      dispatch(ceaseNotification());
    }
  };
}

export default withRouter(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(NotificationComponent)
  )
);
