import React, { Dispatch } from 'react';
import {
  Grid,
  Typography,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { IStoreState } from '../../../types';
import TwoAuthenticationDialogComponent from '../../../components/dialog/TwoAuthentication.dialog.component';
import {
  loadUserProfile,
  ILoadUserProfileAction,
  ILoadDataGoogleAuthenticatorAction,
  loadDataGoogleAuthenticatorAction,
  processAuthGoogleAction,
  IProcessAuthGoogleAction
} from '../../../actions/user.action';
import {
  IUserInfo,
  IObjectLoginHistoryInfo,
  IDataGoogleAuthenticatorInfo,
  IProcessGoogleAuthRequest,
  IGoogleAuthCodeInfo
} from '../../../types/user.type';
import { History } from 'history';
import { VerifyPhoneNumberDialogComponent } from '../../../components/dialog/VerifyPhoneNumber.dialog.component';
import Axios from 'axios';
import { EnterVerifyPhoneDialogComponent } from '../../../components/dialog/EnterVerifyPhone.dialog.component';
import INotificationInfo from '../../../types/Notification.type';
import {
  IUpdateNotification,
  fireNotification
} from '../../../actions/Notification.action';
import { MESSAGE_NOTIFICATION_ERROR } from '../../../constants/Notification.constant';
import Variant from '../../../types/Variant.type';
import { ContactInformationComponent } from '../../../components/share/ContactInformation.componnent';
import { LastLoginTable } from '../../../components/LastLoginTable.component';
import { ChangePasswordCard } from '../../../components/ChangePasswordCard.component';
import { CardAuthentication } from '../../../components/CardAuthentication.component';
function mapStateToProps(
  { userManagement }: IStoreState,
  { history }: RouteComponentProps
) {
  return {
    userProfile: userManagement.userProfile,
    dataGoogleAuthenticator: userManagement.dataGoogleAuthenticator,
    googleAuthCode: userManagement.googleAuthCode,
    history
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<
    | ILoadUserProfileAction
    | ILoadDataGoogleAuthenticatorAction
    | IProcessAuthGoogleAction
    | IUpdateNotification
  >
) => {
  return {
    loadUserProfile: () => dispatch(loadUserProfile()),

    loadDataGoogleAuthenticator: () =>
      dispatch(loadDataGoogleAuthenticatorAction()),
    setSecondFactorCode: (request: IProcessGoogleAuthRequest) =>
      dispatch(processAuthGoogleAction(request)),
    setNotification: (noti: INotificationInfo) =>
      dispatch(fireNotification(noti))
  };
};

const styles = () =>
  createStyles({
    rootSecurity: {
      background: '#FFFFFF',
      border: '1px solid #D7DCE0',
      boxSizing: 'border-box',
      borderRadius: 5,
      height: 'auto'
    },
    rootHeaderSecurity: {
      background: '#F9FAFB',
      height: 80,
      borderBottom: '1px solid #D7DCE0',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5
    },
    textSecurity: {
      color: '#050F47',
      marginLeft: 30
    },
    rootContact: {
      padding: '26px 30px 26px 30px'
    },
    rootLastLogin: {
      background: ' #FFFFFF',
      border: '1px solid #D7DCE0',
      boxSizing: 'border-box',
      borderRadius: 5
    }
  });
interface IProps extends WithStyles<typeof styles> {
  history: History;
  loadUserProfile: () => void;
  loadDataGoogleAuthenticator: () => void;
  setSecondFactorCode: (request: IProcessGoogleAuthRequest) => void;
  setNotification: (noti: INotificationInfo) => void;
  userLoginHistory: IObjectLoginHistoryInfo;
  userProfile: IUserInfo;
  dataGoogleAuthenticator: IDataGoogleAuthenticatorInfo;
  googleAuthCode: IGoogleAuthCodeInfo;
}

interface IState {
  checkedSwitchAuth: boolean;
  checkedSwitchSMS: boolean;
  userProfile: IUserInfo;
  showEnterVerifyPhone: boolean;
  errorVerify: boolean;
  phone: string;
  errorEnterVerifyPhone: boolean;
  codeSMS: string;
  googleAuthCode: IGoogleAuthCodeInfo;
}
class Security extends React.Component<IProps, IState> {
  public state: IState = {
    checkedSwitchAuth: false,
    checkedSwitchSMS: false,
    userProfile: this.props.userProfile,

    showEnterVerifyPhone: false,
    errorVerify: false,
    phone: '',
    errorEnterVerifyPhone: false,
    codeSMS: '',
    googleAuthCode: {
      code: ''
    }
  };

  // tslint:disable-next-line:variable-name
  UNSAFE_componentWillMount = () => {
    this.props.loadUserProfile();
  };

  // tslint:disable-next-line
  UNSAFE_componentWillReceiveProps = (props: IProps) => {
    this.setState({
      userProfile: props.userProfile,
      googleAuthCode: props.googleAuthCode
    });
  };

  public handleChangeSwitchAuth = () => {
    const {
      isGoogleAuthenticator,
      isVerified,
      isSmsAuthentication
    } = this.props.userProfile;
    if (isSmsAuthentication) {
      return;
    }

    if (!isGoogleAuthenticator && isVerified) {
      this.props.loadDataGoogleAuthenticator();
    }

    this.setState({
      checkedSwitchAuth: !this.state.checkedSwitchAuth
    });
  };

  public handleChangeSwitchSMS = () => {
    const { isGoogleAuthenticator } = this.props.userProfile;
    if (isGoogleAuthenticator) {
      return;
    }
    this.setState({
      checkedSwitchSMS: !this.state.checkedSwitchSMS
    });
  };

  public handleClose = () => {
    this.setState({
      checkedSwitchAuth: false,
      checkedSwitchSMS: false,
      showEnterVerifyPhone: false
    });
  };

  public processSecondFactorCode = (request: IProcessGoogleAuthRequest) => {
    const { googleAuthCode } = this.state;
    if (request.code === googleAuthCode.code) {
      return this.showNotificationDuplicateCode();
    }
    this.props.setSecondFactorCode(request);
    this.setState({ checkedSwitchAuth: false });
  };

  protected showNotificationDuplicateCode = () => {
    const notify: INotificationInfo = {
      message: MESSAGE_NOTIFICATION_ERROR.DUPLICATE_CODE_GOOGLE_AUTH,
      variant: Variant.ERROR
    };
    return this.props.setNotification(notify);
  };

  public handleChangeInputVerifyPhone = (phone: string) => {
    this.setState({
      phone
    });
  };

  public onChangeShowDialogEnterVerifyPhone = () => {
    Axios.post('/users/verify/phone', {
      phone: this.state.phone
    })
      .then(() => {
        this.setState({
          showEnterVerifyPhone: true,
          checkedSwitchSMS: false
        });
      })
      .catch(err => {
        const errorMsg: string =
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.message;
        if (errorMsg === 'Invalid value for param: number') {
          this.setState({
            errorVerify: true,
            showEnterVerifyPhone: false,
            checkedSwitchSMS: true
          });
        } else {
          this.setState({
            showEnterVerifyPhone: true,
            checkedSwitchSMS: false
          });
        }
      });
  };

  public handleChangeInputCodeSMS = (event: any) => {
    const code: string = event.target.value;
    if (code.match(/\D/g) || code.length > 4) {
      return;
    }
    this.setState({ codeSMS: code });
  };

  public onChangeShowDialogVerifyPhoneNumber = () => {
    this.setState({
      checkedSwitchSMS: true
    });
  };

  public handleVerifySMS = () => {
    console.log(123);
  };

  public render() {
    const { userProfile } = this.state;
    const { classes, dataGoogleAuthenticator } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Grid container>
          <Grid
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-duration="300"
            item
            xs={6}
            style={{ paddingRight: 10 }}
          >
            <Grid className={classes.rootSecurity}>
              <Grid
                container
                alignItems="center"
                className={classes.rootHeaderSecurity}
              >
                <Typography
                  variant="subtitle1"
                  className={classes.textSecurity}
                >
                  Security
                </Typography>
              </Grid>

              <Grid className={classes.rootContact}>
                <ContactInformationComponent userProfile={userProfile} />
                <ChangePasswordCard />
                <CardAuthentication
                  checkedSwitchAuth={
                    userProfile.isGoogleAuthenticator
                      ? userProfile.isGoogleAuthenticator
                      : false
                  }
                  checkedSwitchSMS={this.state.checkedSwitchSMS}
                  handleChangeSwitchAuth={this.handleChangeSwitchAuth}
                  handleChangeSwitchSMS={this.handleChangeSwitchSMS}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-duration="300"
            style={{
              paddingLeft: 10
            }}
          >
            <Grid className={classes.rootLastLogin}>
              <LastLoginTable />
            </Grid>
          </Grid>
        </Grid>
        <TwoAuthenticationDialogComponent
          showIsVerify={userProfile.isVerified === null ? false : true}
          showContent={
            userProfile.isGoogleAuthenticator === true ? false : true
          }
          setSecondFactorCode={this.processSecondFactorCode}
          dataGoogleAuthenticator={dataGoogleAuthenticator}
          showDialog={this.state.checkedSwitchAuth}
          handleCloseDialog={this.handleClose}
        />
        <VerifyPhoneNumberDialogComponent
          value={this.state.phone}
          handleChangeInputVerifyPhone={this.handleChangeInputVerifyPhone}
          handleEnterVerifyPhone={this.onChangeShowDialogEnterVerifyPhone}
          handleCloseDialog={this.handleClose}
          showDialog={this.state.checkedSwitchSMS}
          errorVerify={this.state.errorVerify}
          textHeader="Verify Phone Number"
        />

        <EnterVerifyPhoneDialogComponent
          errorVerify={this.state.errorEnterVerifyPhone}
          handleChangeInputCodeSMS={this.handleChangeInputCodeSMS}
          valueCodeSms={this.state.codeSMS}
          handleShowVerifyPhone={this.onChangeShowDialogVerifyPhoneNumber}
          onChangeShowDialogBasicVerification={this.handleVerifySMS}
          handleCloseDialog={this.handleClose}
          showDialog={this.state.showEnterVerifyPhone}
          textHeader="Verify Phone Number"
        />
      </div>
    );
  }
}

export const SecurityComponent = withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Security))
);
