export const LOAD_CURRENCIES_ACTION = 'LOAD_CURRENCIES_ACTION';
export type LOAD_CURRENCIES_ACTION = typeof LOAD_CURRENCIES_ACTION;

export const SET_CURRENCIES_ACTION = 'SET_CURRENCIES_ACTION';
export type SET_CURRENCIES_ACTION = typeof SET_CURRENCIES_ACTION;

export const LOAD_DEPOSIT_ADDRESS_ACTION = 'LOAD_DEPOSIT_ADDRESS_ACTION';
export type LOAD_DEPOSIT_ADDRESS_ACTION = typeof LOAD_DEPOSIT_ADDRESS_ACTION;

export const SET_DEPOSIT_ADDRESS_ACTION = 'SET_DEPOSIT_ADDRESS_ACTION';
export type SET_DEPOSIT_ADDRESS_ACTION = typeof SET_DEPOSIT_ADDRESS_ACTION;
