import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@material-ui/core';
import { NexoDialogComponent } from '../share/NexoDialog.component';
import { NexoButton, NexoPhoneInput } from '../share';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { loadUserProfile } from '../../actions/user.action';
import { NotificationVerifyPhonePage } from '../verifyPhone/NotificationVerifyPhonePage';
import { NotificationErrorPhone } from '../verifyPhone/NotificationErrorPhone';
import { NotificationSuccesPhone } from '../verifyPhone/NotificationSuccesPhone';
import { EnterVerificationCode } from '../verifyPhone/EnterVerificationCode';

const styles = makeStyles({
  buttonCancel: {
    width: 150
  },
  buttonChangNumber: {
    width: 190
  },
  rootStepper: {
    '& .MuiPaper-elevation0': {
      width: '100%',
      border: 'none',
      boxShadow: 'none',
      height: 200
    },
    '& .MuiStepper-root': {
      padding: 0
    },
    '& .MuiStepConnector-vertical': {
      display: 'none'
    },
    '& .MuiStepContent-root': {
      paddingRight: 0,
      marginBottom: 20,
      marginTop: 20
    },
    '& .MuiStepLabel-label.MuiStepLabel-active': {
      color: '#050F47',
      fontFamily: 'Montserrat',
      fontWeight: 600
    },
    '& .MuiStepLabel-label': {
      color: '#A5B2C0',
      fontFamily: 'Montserrat',
      fontWeight: 600
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: '#34cea9'
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: '#34cea9'
    },
    '& .MuiStepIcon-root': {
      color: '#D7DCE0'
    }
  },
  buttonVerify: {
    width: 150,
    marginLeft: 10
  }
});

interface IProps {
  handleCloseDialog: () => void;
  showDialog: boolean;
  textHeader: string;
}

export const VerificationPhoneDialog = (props: IProps) => {
  const dispatch = useDispatch();
  const classes = styles();
  const getSteps = () => {
    return [
      'Enter your phone number:',
      'Enter the SMS verification code you received:'
    ];
  };
  const steps = getSteps();

  const { showDialog, textHeader } = props;
  // Create State
  const [activeStep, setActiveStep] = useState(0);
  const [showDialogVerifyBasic, setShowDialogVerifyBasic] = useState(false);
  const [showInputVerify, setShowInputVerify] = useState(false);
  const [time, setTimeString] = useState(180);
  const [disableResend, setDisableResend] = useState(false);
  const [inputPhone, setInputPhone] = useState<{
    numberPhone: string;
    code: string;
  }>({
    numberPhone: '',
    code: ''
  });
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  }>({
    error: false,
    message: ''
  });

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Grid>
            <NexoPhoneInput
              value={inputPhone.numberPhone}
              handleChangeInputVerifyPhone={handleChangeInputVerifyPhone}
              title="Phone"
            />
          </Grid>
        );
      case 1:
        return (
          <EnterVerificationCode
            disableResend={disableResend}
            handleChangeInputCodeSMS={handleChangeInputCodeSMS}
            handleResendCodePhone={handleResendCodePhone}
            time={time}
          />
        );
    }
  };

  const handleVerifyNumbetPhone = () => {
    Axios.post('/users/re-verify/phone', {
      phone: inputPhone.numberPhone
    })
      .then(() => {
        setError({
          ...error,
          error: false
        });
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      })
      .catch(err => {
        const errorMsg =
          err?.response?.data?.error?.message?.message ||
          err?.response?.data?.error?.message ||
          'Phone number not valid. Please enter your phone number without area code';
        setError({
          ...error,
          error: true,
          message: errorMsg
        });
      });
  };

  const handleVerifyCode = () => {
    const request = {
      code: inputPhone.code,
      phone: inputPhone.numberPhone
    };
    Axios.patch('/users/phone', request)
      .then(() => {
        setShowDialogVerifyBasic(true);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setError({
          ...error,
          error: false
        });
      })
      .catch(err => {
        const errorMsg =
          err?.response?.data?.error?.message?.message ||
          err?.response?.data?.error?.message ||
          'Phone number not valid. Please enter your phone number without area code';
        setError({
          ...error,
          error: true,
          message: errorMsg
        });
      });
  };

  const handleChangePhoneNumber = () => {
    setActiveStep(0);
    setError({
      ...error,
      error: false
    });
  };

  const handleChangeInputVerifyPhone = (phone: string) => {
    setInputPhone({
      ...inputPhone,
      numberPhone: phone
    });
  };
  const handleChangeInputCodeSMS = (codeInput: string) => {
    setInputPhone({
      ...inputPhone,
      code: codeInput
    });
  };
  const handleOpenVerify = () => {
    setShowInputVerify(true);
  };
  const handleCancelDialog = () => {
    setShowInputVerify(false);
  };

  const countTimeResend = () => {
    let timeString = time;
    const interval = setInterval(() => {
      const currentTime = timeString - 1;
      if (currentTime === 0) {
        setTimeString(180);
        setDisableResend(false);
        clearInterval(interval);
        return;
      }
      timeString = currentTime;
      setTimeString(timeString);
    }, 1000);
  };

  const handleResendCodePhone = () => {
    Axios.post('/users/re-verify/phone', {
      phone: inputPhone.numberPhone
    })
      .then(() => {
        setDisableResend(true);
        countTimeResend();
        setError({
          ...error,
          error: false
        });
      })
      .catch(err => {
        const errorMsg =
          err?.response?.data?.error?.message?.message ||
          err?.response?.data?.error?.message ||
          'Phone number not valid. Please enter your phone number without area code';
        setError({
          ...error,
          error: true,
          message: errorMsg
        });
      });
  };

  const handleClose = () => {
    dispatch(loadUserProfile());
  };

  const handleGotit = () => {
    props.handleCloseDialog();
    dispatch(loadUserProfile());
  };

  return (
    <NexoDialogComponent
      handleCloseDialog={handleClose}
      showDialog={showDialog}
      textHeader={textHeader}
      maxWidth="lg"
      hiddenback={false}
    >
      {showInputVerify === false ? (
        <NotificationVerifyPhonePage handleOpenVerify={handleOpenVerify} />
      ) : (
        <Grid className={classes.rootStepper} style={{ marginTop: 30 }}>
          {error.error === true ? (
            <NotificationErrorPhone message={error.message} />
          ) : null}

          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>{getStepContent(index)}</StepContent>
              </Step>
            ))}
          </Stepper>
          {showDialogVerifyBasic === true ? <NotificationSuccesPhone /> : null}
          {activeStep === steps.length ? (
            <Grid container justify="center" style={{ marginBottom: 30 }}>
              <Grid className={classes.buttonCancel}>
                <NexoButton
                  title="Got it"
                  variant="outlined"
                  color="primary"
                  heightButton={40}
                  handleRedirect={handleGotit}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="center" style={{ marginBottom: 30 }}>
              <Grid
                className={
                  activeStep === 0
                    ? classes.buttonCancel
                    : classes.buttonChangNumber
                }
              >
                <NexoButton
                  title={activeStep === 0 ? 'Cancel' : 'Change phone number'}
                  variant="outlined"
                  color="primary"
                  heightButton={40}
                  handleRedirect={
                    activeStep === 0
                      ? handleCancelDialog
                      : handleChangePhoneNumber
                  }
                />
              </Grid>

              <Grid className={classes.buttonVerify}>
                <NexoButton
                  title={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  variant="contained"
                  color="primary"
                  heightButton={40}
                  handleRedirect={
                    activeStep === steps.length - 1
                      ? handleVerifyCode
                      : handleVerifyNumbetPhone
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </NexoDialogComponent>
  );
};
