import React from 'react';
import { Grid, makeStyles, Typography, Paper } from '@material-ui/core';
import { NexoButton } from '../../../components/share';
import { RouteChildrenProps } from 'react-router';
import { PATH } from '../../../constants/Path.constant';
import { historyBrowser } from '../../../index';
import iconSussesly from '../../../assets/images/iconSussesly.svg';
import { useDispatch } from 'react-redux';
import { approveAssetWithdrawalAction } from '../../../actions/withdrawAsset.action';

const styles = makeStyles({
  root: {
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%'
  },
  rootPaper: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  contentText: {
    margin: '15px 0px',
    '& .MuiTypography-root': {
      fontSize: 12
    }
  },
  textPrivacy: {
    color: '#3E399F'
  },
  alreadyAccount: {
    fontWeight: 600,
    lineHeight: '24px',
    color: '#050F47'
  },
  textVerify: {
    marginTop: 33,
    marginBottom: 10
  },
  textReceive: {
    marginTop: 105,
    marginBottom: 10
  },
  widthButton: {
    width: 450
  },
  buttonCancel: {
    width: 150,
    marginRight: 7
  },
  buttonVerify: {
    width: 150
  }
});

export const WithdrawAssetApproved = (
  props: RouteChildrenProps<{ token: string }>
) => {
  const history = historyBrowser;
  const token: string | undefined = props.match?.params.token;
  const handleRedirectPageAcount = () => {
    history.push(PATH.nexo.account);
  };
  const classes = styles();

  const dispatch = useDispatch();

  const handleWithdrawAssetApproved = () => {
    if (token) {
      dispatch(approveAssetWithdrawalAction(token));
    }
  };

  return (
    <div className={classes.root}>
      <Paper elevation={2} className={classes.rootPaper}>
        <Grid container justify="center">
          <img
            data-aos="zoom-out"
            data-aos-duration="1000"
            src={iconSussesly}
            style={{ marginLeft: 50 }}
            alt=" verify account"
          />
        </Grid>
        <Grid container justify="center" className={classes.textVerify}>
          <Typography variant="h4" style={{ fontSize: 18 }}>
            Asset Withdrawal has been sent for processing.
          </Typography>
        </Grid>
        <Grid container justify="center" style={{ marginBottom: 10 }}>
          <Typography variant="h4" style={{ fontSize: 18 }}>
            You can track its progress on the Transaction page.
          </Typography>
        </Grid>
        <Grid container justify="center">
          <Typography variant="h4" style={{ fontSize: 18 }}>
            Thank you.
          </Typography>
        </Grid>

        <Grid container justify="center" style={{ marginTop: 30 }}>
          <Grid className={classes.buttonCancel}>
            <NexoButton
              handleRedirect={handleRedirectPageAcount}
              title="Cancel"
              variant="outlined"
              color="primary"
              heightButton={40}
            />
          </Grid>

          <Grid className={classes.buttonVerify}>
            <NexoButton
              title="Approve"
              variant="contained"
              color="primary"
              heightButton={40}
              handleRedirect={handleWithdrawAssetApproved}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
