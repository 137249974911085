import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { ICoinInfo } from '../../types/calculator.type';
import { SlickSoonCoin } from '../calculator/SlickSoonCoin.component';
import { NexoInputCurrency } from './NexoInputCurrency';

const styles = makeStyles({
  sliderNoActive: {
    cursor: 'pointer',
    display: 'flex !important',
    alignItems: 'center',
    border: '1px solid #D7DCE0 !important',
    borderRadius: 5,
    width: '95% !important',
    height: 41,
    '&:focus': {
      outlineColor: '#FFFFFF'
    }
  },
  sliderActive: {
    cursor: 'pointer',
    display: 'flex !important',
    alignItems: 'center',
    border: '1px solid #D7DCE0 ',
    '&:hover': {
      border: '1px solid #34CEA9 '
    },
    borderRadius: 5,
    width: '95% !important',
    height: 41,
    '&:focus': {
      outlineColor: '#FFFFFF'
    }
  },
  rootTextField: {
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: 'inherit'
      }
    },
    '& .MuiInputBase-root': {
      color: '#050F47',
      fontSize: 14,
      fontFamily: 'Montserrat',
      fontWeight: 600
    }
  },
  iconIput: {
    '& .MuiIconButton-root': {
      padding: 0
    },
    '& .MuiSvgIcon-root': {
      fontSize: 15
    }
  }
});

interface IProps {
  active: boolean;
  min: number;
  step: number;
  max?: number;
  formatNumber: number;
  value: string;
  handleChangeInput: (
    key: string,
    value: string,
    formatNumber: number
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeActiveInput: (key: string) => () => void;
  onBlurTextFiedOutSide: (key: string, valueInput: string) => () => void;
  detailCoin: ICoinInfo;
  handleChangeLimitInput?: (
    ltv: string,
    keyboard: string,
    key: string,
    formatNumber: number,
    min: number,
    step: number,
    max?: number
  ) => () => void;
}

export const CalculatorCardComponent = (props: IProps) => {
  const {
    active,
    handleChangeInput,
    value,
    detailCoin,
    handleChangeActiveInput,
    onBlurTextFiedOutSide,
    handleChangeLimitInput,
    min,
    step,
    max,
    formatNumber
  } = props;
  const classes = styles();
  return (
    <div>
      {active && detailCoin.disabled === false ? (
        <NexoInputCurrency
          value={value}
          detailCoin={detailCoin}
          min={min}
          step={step}
          max={max}
          errorInput={false}
          formatNumber={formatNumber}
          handleChangeInput={handleChangeInput}
          handleChangeLimitInput={handleChangeLimitInput}
          onBlurTextFiedOutSide={onBlurTextFiedOutSide}
        />
      ) : (
        <Grid
          onClick={handleChangeActiveInput(detailCoin.symbol)}
          className={classes.sliderNoActive}
          style={{ position: 'relative' }}
        >
          <img
            src={(process.env.REACT_APP_API_URL_IMAGE || '') + detailCoin.img}
            width={25}
            height={25}
            alt="bitcon"
            style={{ marginLeft: 7 }}
          />
          <Typography variant="h4" style={{ marginLeft: 10 }}>
            {detailCoin.name}
          </Typography>
          {detailCoin.disabled ? <SlickSoonCoin /> : null}
        </Grid>
      )}
    </div>
  );
};
