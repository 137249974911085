import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

const styles = makeStyles({
  ldsRipple: {
    display: 'inline-block',
    position: 'relative',
    width: 30,
    height: 30,
    '& div': {
      position: 'absolute',
      border: '4px solid #20bcfe',
      opacity: 1,
      borderRadius: '50%',
      animation: 'ldsRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite',
      '&:nth-child(2)': {
        animationDelay: '-0.5s'
      }
    }
  }
});

export const LoadingPage = () => {
  const classes = styles();
  return (
    <Grid
      style={{ height: 100 }}
      container
      justify="center"
      alignItems="center"
    >
      <div className={classes.ldsRipple}>
        <div />
        <div />
      </div>
    </Grid>
  );
};
