import React from 'react';
import {
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  makeStyles
} from '@material-ui/core';
import { IAccount } from '../../types/currency.type';
import { formatNumberToFixed, totalMarketCurrency } from '../../helper';

const styles = makeStyles({
  tableCellEstimated: {
    background: '#F9FAFB',
    fontFamily: 'Montserrat',
    fontSize: 12,
    color: '#3E399F',
    borderRight: '1px solid #D7DCE0',
    fontStyle: 'italic'
  },
  tableCellAvailable: {
    background: '#f5fafc',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 600,
    color: '#3E399F'
  },
  tableCellBefore: {
    background: '#F9FAFB',
    fontFamily: 'Montserrat',
    fontSize: 12,
    color: '#050F47',
    borderRight: '1px solid #D7DCE0'
  }
});

interface IProps {
  currencies: IAccount;
  valueUsd: string;
}

export const TableRepayment = (props: IProps) => {
  const classes = styles();
  const currentInterest =
    Number(props.currencies.totalInterest) - Number(props.valueUsd);
  const newValueOutstandingLoan =
    Number(props.currencies.totalLoan) +
    (currentInterest < 0 ? 0 : currentInterest);
  const newValueAvailable =
    Number(props.currencies.creditLine) - Number(newValueOutstandingLoan);
  return (
    <Table
      style={{
        border: '1px solid #D7DCE0',
        boxSizing: 'border-box',
        borderRadius: 5
      }}
    >
      <TableHead>
        <TableRow style={{ width: '100%' }}>
          <TableCell
            style={{ width: '10%' }}
            className={classes.tableCellEstimated}
          >
            Estimated
          </TableCell>
          <TableCell
            style={{ width: '20%' }}
            className={classes.tableCellAvailable}
            align="right"
          >
            Total Assets
          </TableCell>
          <TableCell
            style={{ width: '20%' }}
            className={classes.tableCellAvailable}
            align="right"
          >
            Credit Line
          </TableCell>
          <TableCell
            style={{ width: '20%' }}
            className={classes.tableCellAvailable}
            align="right"
          >
            Outstanding Loan
          </TableCell>
          <TableCell
            style={{ width: '20%' }}
            className={classes.tableCellAvailable}
            align="right"
          >
            Available Credit Line
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.tableCellBefore}>Before</TableCell>
          <TableCell
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              color: '#050F47'
            }}
            align="right"
          >
            ${''} {totalMarketCurrency(props.currencies.currencyBalances)}
          </TableCell>
          <TableCell
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              color: '#050F47'
            }}
            align="right"
          >
            $ {''}
            {formatNumberToFixed(props.currencies.creditLine, 2)}
          </TableCell>
          <TableCell
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              color: '#050F47'
            }}
            align="right"
          >
            $ {''}
            {formatNumberToFixed(
              Number(props.currencies.totalInterest) +
                Number(props.currencies.totalLoan),
              2
            )}
          </TableCell>
          <TableCell
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              color: '#050F47'
            }}
            align="right"
          >
            $ {''}
            {formatNumberToFixed(
              Number(props.currencies.creditLine) -
                Number(props.currencies.totalInterest) -
                Number(props.currencies.totalLoan),
              2
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tableCellBefore}>
            After Repayment
          </TableCell>
          <TableCell
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              color: '#48c4fc'
            }}
            align="right"
          >
            ${''} {totalMarketCurrency(props.currencies.currencyBalances)}
          </TableCell>
          <TableCell
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              color: '#48c4fc'
            }}
            align="right"
          >
            $ {''}
            {formatNumberToFixed(props.currencies.creditLine, 2)}
          </TableCell>
          <TableCell
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              color: '#48c4fc'
            }}
            align="right"
          >
            ${''} {formatNumberToFixed(newValueOutstandingLoan, 2)}
          </TableCell>
          <TableCell
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              color: '#48c4fc'
            }}
            align="right"
          >
            ${''} {formatNumberToFixed(newValueAvailable, 2)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
