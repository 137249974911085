import React from 'react';
import { Grid } from '@material-ui/core';
// tslint:disable-next-line
const QRCode = require('qrcode.react');

interface IProps {
  valueQrCode: string;
}

export const QrCodeCard = (props: IProps) => {
  const { valueQrCode } = props;
  return (
    <Grid
      style={{
        marginTop: 20,
        border: '1px solid #D7DCE0',
        boxSizing: 'border-box',
        borderRadius: 5,
        width: 170,
        height: 170,
        padding: 10
      }}
    >
      <QRCode size={150} fgColor="#050F47" value={valueQrCode} />
    </Grid>
  );
};
