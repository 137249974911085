import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import iconsucces from '../../assets/images/iconsuccesDialog.svg';

const styles = makeStyles({
  notification: {
    height: 50,
    border: '1px solid #34CEA9',
    background: 'rgba(52, 206, 169, 0.05)',
    boxSizing: 'border-box',
    borderRadius: 5,
    marginBottom: 20,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '60px calc(100% - 60px)'
  },
  imgIconSucces: {
    marginLeft: 21,
    marginRight: 15
  },
  textCheckMail: {
    color: '#34CEA9',
    fontWeight: 500,
    fontSize: 12
  }
});

export const NotificationSuccesPhone = () => {
  const classes = styles();
  return (
    <Grid className={classes.notification}>
      <img
        src={iconsucces}
        width={25}
        height={25}
        className={classes.imgIconSucces}
        alt="icon succes"
      />
      <Typography variant="h4" className={classes.textCheckMail}>
        Basic verification completed successfully!
      </Typography>
    </Grid>
  );
};
