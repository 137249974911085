import React, { useState } from 'react';
import {
  Typography,
  Dialog,
  Grid,
  IconButton,
  makeStyles
} from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import { NexoButton, NexoItemCoinCredit } from '../share';
import { SnackbarDialogComponent } from './Snackbar.dialog.component';
import { TwoFactorAuthNotifCard } from '../calculator/TwoFactorAuthNotifCard.component';
import { QrCodeCard } from '../QrCodeCard';
import { ICurrencyBalance } from '../../types/currency.type';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Cancel from '@material-ui/icons/Cancel';

const styles = makeStyles({
  dialogTitle: {
    padding: 0
  },
  content: {
    padding: 35
  },
  textProcessing: {
    color: '#050F47',
    fontWeight: 600
  },
  rootHeader: {
    height: 80,
    borderBottom: '1px solid #E4EAEE',
    background: '#F9FAFB',
    boxSizing: 'border-box',
    paddingLeft: 35,
    paddingRight: 5
  },
  rootContent: {
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: 50,
    paddingLeft: 18
  },
  fontWeightText: {
    fontWeight: 500
  },
  rootDialog: {
    '& .MuiDialog-paperWidthLg': {
      width: 600,
      maxWidth: 600
    }
  },

  errorIcon: {
    marginRight: 10,
    width: 15.11,
    height: 13.33
  },

  activeCopy: {
    background:
      'linear-gradient(136.04deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(127.73deg, #67ACF1 -10.95%, #156EE4 104.81%)',
    color: '#FFFFFF'
  },

  code: {
    textAlign: 'center',
    color: '#050F47',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 40,
    lineHeight: '40px',
    letterSpacing: '0.3em'
  },
  cusCode: {
    '& .MuiFormControl-fullWidth': {
      height: '100%'
    },
    '& .MuiOutlinedInput-root': {
      height: '100%'
    },
    '& .MuiInputBase-input': {
      color: '#050F47',
      fontSize: 14,
      fontFamily: 'Montserrat',
      fontWeight: 600
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #34CEA9'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: ' #34CEA9'
    }
  }
});

interface IProps {
  showDialog: boolean;
  handleClose: () => void;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  isGoogleAuthenticator: boolean;
  itemCurrencies: ICurrencyBalance;
}

export const DepositDialogComponent = (props: IProps) => {
  const [openCopy, setOpenCopy] = useState(false);

  const copyDepositAddress = () => {
    setOpenCopy(true);
  };

  const handleCloseCopy = () => {
    setOpenCopy(false);
  };

  const renderNotificationTwoFactorAuth = () => {
    return (
      <Grid>
        <TwoFactorAuthNotifCard />
        <Grid container justify="center" style={{ marginTop: 20 }}>
          <Grid style={{ width: 300 }}>
            <NexoButton
              title="OK"
              variant="contained"
              color="primary"
              handleRedirect={handleClose}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDepositPage = () => {
    return (
      <div>
        <Grid
          style={{
            border: '1px solid #D7DCE0',
            boxSizing: 'border-box',
            borderRadius: 5,
            padding: 15
          }}
        >
          <Typography variant="h4">Deposit Address</Typography>
          <Grid
            style={{
              display: 'grid',
              gridTemplateColumns: 'calc(100% - 60px) 50px',
              gridColumnGap: 10,
              marginTop: 10
            }}
          >
            <Grid
              style={{
                border: '1px solid #D7DCE0',
                boxSizing: 'border-box',
                borderRadius: 5,
                height: 50,
                paddingLeft: 10,
                background: '#F9FAFB'
              }}
              container
              alignItems="center"
            >
              <Typography style={{ fontWeight: 500 }} variant="h4">
                {props.itemCurrencies.depositAddress}
              </Typography>
            </Grid>
            <CopyToClipboard
              text={props.itemCurrencies.depositAddress}
              onCopy={copyDepositAddress}
            >
              <IconButton
                style={{
                  border: '1px solid #D7DCE0',
                  borderRadius: 5
                }}
                className={openCopy ? classes.activeCopy : ''}
              >
                <FileCopy />
              </IconButton>
            </CopyToClipboard>
          </Grid>

          <Grid container justify="center">
            <QrCodeCard valueQrCode={props.itemCurrencies.depositAddress} />
          </Grid>
        </Grid>
      </div>
    );
  };

  const {
    showDialog,
    handleClose,
    maxWidth,
    isGoogleAuthenticator,
    itemCurrencies
  } = props;
  const classes = styles();
  return (
    <Dialog
      className={classes.rootDialog}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      maxWidth={maxWidth}
    >
      <Grid className={classes.dialogTitle}>
        <Grid container className={classes.rootHeader}>
          <Grid container alignItems="center" item xs={6}>
            <Typography
              variant="subtitle1"
              style={{ color: '#050F47', marginRight: 14 }}
            >
              Deposit:
            </Typography>
            <NexoItemCoinCredit
              id=""
              name={itemCurrencies.currency.name}
              imgSrc={itemCurrencies.currency.image}
            />
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            alignItems="flex-start"
            xs={6}
            style={{ marginTop: 5 }}
          >
            <IconButton onClick={handleClose} style={{ padding: 5 }}>
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.content}>
        {isGoogleAuthenticator
          ? renderDepositPage()
          : renderNotificationTwoFactorAuth()}
      </Grid>
      <SnackbarDialogComponent
        openCopy={openCopy}
        handleCloseCopy={handleCloseCopy}
      />
    </Dialog>
  );
};
