import React from 'react';
import {
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Typography
} from '@material-ui/core';
import { NexoDialogComponent } from '../share/NexoDialog.component';
import { NexoButton, NexoPhoneInput } from '../share';
import iconAdvanced from '../../assets/images/iconAdvanced.svg';

const styles = () =>
  createStyles({
    button: {
      marginTop: 15
    },
    buttonCancel: {
      width: 150,
      marginRight: 7
    },
    buttonVerify: {
      width: 150
    },
    rootContent: {
      background: 'rgba(242, 153, 74, 0.05)',
      border: '1px solid #F2994A',
      boxSizing: 'border-box',
      borderRadius: 5,
      height: 50,
      paddingLeft: 18,
      marginTop: 10
    }
  });
interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  handleCloseDialog: () => void;
  handleEnterVerifyPhone: () => void;
  textHeader: string;
  value: string;
  errorVerify: boolean;
  handleChangeInputVerifyPhone: (phone: string) => void;
}

class VerifyPhoneNumber extends React.Component<IProps> {
  public render() {
    const {
      classes,
      handleCloseDialog,
      showDialog,
      textHeader,
      value,
      errorVerify
    } = this.props;
    return (
      <NexoDialogComponent
        handleCloseDialog={handleCloseDialog}
        showDialog={showDialog}
        textHeader={textHeader}
        maxWidth="xs"
        hiddenback={false}
      >
        <Grid>
          {errorVerify ? (
            <Grid container alignItems="center" className={classes.rootContent}>
              <img
                src={iconAdvanced}
                alt="icon advanced"
                width={15}
                height={15}
              />
              <Typography
                variant="h5"
                style={{
                  color: '#F2994A',
                  marginLeft: 8,
                  fontSize: 10,
                  fontWeight: 600
                }}
              >
                Phone number not valid. Please enter your phone number without
                area code
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        <Grid className={classes.button}>
          <NexoPhoneInput
            value={value ? value : ''}
            handleChangeInputVerifyPhone={
              this.props.handleChangeInputVerifyPhone
            }
            title="Phone"
          />
        </Grid>
        <Grid container justify="flex-end" style={{ marginBottom: 30 }}>
          <Grid className={classes.buttonCancel}>
            <NexoButton
              title="Cancel"
              variant="outlined"
              color="primary"
              heightButton={40}
              handleRedirect={this.props.handleCloseDialog}
            />
          </Grid>

          <Grid className={classes.buttonVerify}>
            <NexoButton
              title="Verify code"
              variant="contained"
              color="primary"
              heightButton={40}
              handleRedirect={this.props.handleEnterVerifyPhone}
            />
          </Grid>
        </Grid>
      </NexoDialogComponent>
    );
  }
}

export const VerifyPhoneNumberDialogComponent = withStyles(styles)(
  VerifyPhoneNumber
);
