import React from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TablePagination,
  makeStyles,
  Typography
} from '@material-ui/core';
import {
  formatAddress,
  getBlockchainExplorerLink,
  formatNumberToFixed,
  formatDateTime
} from '../helper';
import { Link } from 'react-router-dom';
import PaginationComponent from './table/component/Pagination.component';
import { IObjectTransaction } from '../types/transaction.type';
import iconTransactionApprove from '../assets/images/iconTransactionApprove.svg';
import iconTransactionPedding from '../assets/images/iconTransactionPedding.svg';
import iconTransactionRejected from '../assets/images/iconTransactionRejected.svg';
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  SYMBOL_DECIMAL
} from '../constants/transaction.constant';
import { IMAGE } from '../constants/image.constant';
const styles = makeStyles({
  tableHead: {
    paddingLeft: 0,
    fontSize: 12,
    color: '#3E399F !important',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    borderBottom: '1px dashed #B2B6BA'
  },
  tableBody: {
    paddingLeft: 0,
    fontSize: 12,
    color: '#050F47',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    borderBottom: 'none',
    lineHeight: '18px'
  },
  rootPagination: {
    borderTop: '1px dashed #B2B6BA',
    '& .MuiIconButton-root': {
      border: '1px solid #F2F5F8',
      boxSizing: 'border-box',
      borderRadius: 3,
      padding: 0,
      width: 30,
      height: 30,
      marginLeft: 10
    }
  },
  rootHeaderSecurity: {
    background: '#F9FAFB',
    height: 80,
    borderBottom: '1px solid #D7DCE0',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  textSecurity: {
    color: '#050F47',
    marginLeft: 30
  },
  pagination: {
    borderTop: '1px dashed #B2B6BA',

    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent'
    },
    '& .MuiTablePagination-toolbar': {
      padding: 0
    },
    '& .MuiInputBase-input': {
      color: '#A5B2C0',
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeigth: 500
    }
  },
  textAmountIn: {
    color: '#45BB83'
  },
  textAmountOut: {
    color: '#C61615'
  }
});

interface IProps {
  dataTransaction: IObjectTransaction;
  handleChangePage: (event: any, page: number) => void;
  handleChangeRowsPerPage: (event: any) => void;
  rowsPerPage: number;
}

export const TransactionTable = (props: IProps) => {
  const classes = styles();
  const {
    dataTransaction,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage
  } = props;
  // const renderIconTransactionTypeClosed = (closed: boolean, type: string) => {
  //   if (
  //     closed === true &&
  //     (type === TRANSACTION_TYPE.SAVING ||
  //       type === TRANSACTION_TYPE.SAVING_INTEREST)
  //   ) {
  //     return (
  //       <Typography
  //         variant="h5"
  //         style={{
  //           background: '#EB5757',
  //           borderRadius: 4,
  //           padding: '1px 2px',
  //           color: '#FFFFFF',
  //           fontSize: 10,
  //           marginLeft: 10
  //         }}
  //       >
  //         Closed
  //       </Typography>
  //     );
  //   }
  // };

  const renderAmountTransaction = (
    type: string,
    amount: number,
    symbol: string
  ) => {
    let colorAmount: boolean = false;
    let textAmount: string = '-';
    if (
      type === TRANSACTION_TYPE.DEPOSIT ||
      type === TRANSACTION_TYPE.REPAYMENT ||
      type === TRANSACTION_TYPE.SAVING ||
      type === TRANSACTION_TYPE.SAVING_INTEREST
    ) {
      colorAmount = true;
      textAmount = '+';
    } else {
      colorAmount = false;
      textAmount = '-';
    }
    return (
      <span
        className={
          colorAmount === true ? classes.textAmountIn : classes.textAmountOut
        }
      >
        {`${textAmount} ${formatNumberToFixed(
          amount,
          Number(SYMBOL_DECIMAL[symbol as any]) || 8
        )} ${symbol}`}
      </span>
    );
  };

  return (
    <div>
      <Grid>
        <Grid data-aos="fade-up" data-aos-offset="300" data-aos-duration="300">
          <Table style={{ borderCollapse: 'inherit' }}>
            <TableHead>
              <TableRow style={{ width: '100%' }}>
                <TableCell
                  className={classes.tableHead}
                  style={{
                    width: '15%'
                  }}
                >
                  Transaction
                </TableCell>
                <TableCell
                  className={classes.tableHead}
                  style={{ width: '15%' }}
                >
                  Type
                </TableCell>

                <TableCell
                  className={classes.tableHead}
                  style={{ width: '15%', paddingRight: 0 }}
                >
                  Amount
                </TableCell>
                <TableCell
                  className={classes.tableHead}
                  style={{ width: '15%', paddingRight: 0 }}
                >
                  Outstanding Loan
                </TableCell>
                <TableCell
                  className={classes.tableHead}
                  style={{ width: '15%', paddingRight: 0 }}
                >
                  Outstanding Saving
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableHead}
                  style={{ width: '10%', paddingRight: 0 }}
                >
                  Status
                </TableCell>
                <TableCell
                  className={classes.tableHead}
                  align="right"
                  style={{ width: '15%', paddingRight: 0 }}
                >
                  Date/Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTransaction.data.length > 0 ? (
                dataTransaction.data.map(el => (
                  <TableRow style={{ width: '100%' }} key={el.id}>
                    <TableCell
                      style={{ color: '#20bcfe' }}
                      className={classes.tableBody}
                    >
                      {el.hash ? (
                        <Link
                          style={{
                            color: '#3DACEB ',
                            textDecoration: 'none'
                          }}
                          to={{
                            pathname: getBlockchainExplorerLink(
                              el.currency.symbol,
                              el.hash
                            )
                          }}
                          target="true"
                        >
                          {formatAddress(el.hash)}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      <Grid container alignItems="center">
                        {el.transactionType}
                        {/* {renderIconTransactionTypeClosed(
                          el.closed,
                          el.transactionType
                        )} */}
                      </Grid>
                    </TableCell>

                    <TableCell className={classes.tableBody}>
                      {renderAmountTransaction(
                        el.transactionType,
                        el.value,
                        el.currency.symbol
                      )}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {el.outstandingLoan
                        ? `$ ${formatNumberToFixed(
                            el.outstandingLoan,
                            Number(SYMBOL_DECIMAL[el.currency.symbol as any]) ||
                              8
                          )}`
                        : '-'}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {el.outstandingSaving
                        ? `$ ${formatNumberToFixed(el.outstandingSaving, 2)}`
                        : '-'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ paddingRight: 0 }}
                      className={classes.tableBody}
                    >
                      <img
                        src={
                          el.status === TRANSACTION_STATUS.processing
                            ? iconTransactionPedding
                            : el.status === TRANSACTION_STATUS.successful
                            ? iconTransactionApprove
                            : iconTransactionRejected
                        }
                        alt="icon status"
                      />
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ paddingRight: 0 }}
                      className={classes.tableBody}
                    >
                      {formatDateTime(el.createdAt)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className={classes.tableBody}>
                    <Grid direction="column" container alignItems="center">
                      <img
                        src={IMAGE.ICON_WITHDRAW_EMPTY}
                        width={150}
                        style={{ marginRight: 50 }}
                        alt="logo"
                      />
                      <Typography
                        variant="h4"
                        style={{ marginTop: 20, fontStyle: 'italic' }}
                      >
                        No data
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {dataTransaction.data.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              className={classes.pagination}
              count={
                dataTransaction.meta.total ? dataTransaction.meta.total : 0
              }
              rowsPerPage={rowsPerPage}
              page={
                dataTransaction.meta.currentPage
                  ? dataTransaction.meta.currentPage - 1
                  : 0
              }
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={PaginationComponent}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};
