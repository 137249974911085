export const BTC_TO_USD: number = 6686.54;
export const ETH_TO_USD: number = 136.04;
export const XRP_TO_USD: number = 0.16;
export const USD_TO_BTC: number = 0.00015;
export const USD_TO_ETH: number = 0.0074;
export const USD_TO_XRP: number = 6.266369;

export const APR = {
  DAILY: 0.016,
  MONTHLY: 0.472,
  YEARLY: 5.9
};

export const EARN_INTEREST = {
  DAILY: 0.021,
  MONTHLY: 0.635,
  YEARLY: 8
};

export const selectAPR = [
  {
    id: '1',
    name: 'DAILY'
  },
  {
    id: '2',
    name: 'MONTHLY'
  },
  {
    id: '3',
    name: 'YEARLY'
  }
];
