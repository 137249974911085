import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import NotificationComponent from './components/Notification.component';
import { PATH } from './constants/Path.constant';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import _ from 'lodash';
import { Template } from './components/Template.component';
import { useDispatch } from 'react-redux';
import { logoutAction, refreshTokenAction } from './actions/auth.action';

export const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem('token');
    const path = history.location.pathname;
    if (
      token &&
      _.includes(
        [PATH.nexo.myProfile, PATH.nexo.security, PATH.nexo.transactions],
        path
      ) === false &&
      _.includes(
        path,
        PATH.nexo.withdrawLoanApproved.replace('/:token', '')
      ) === false &&
      _.includes(
        path,
        PATH.nexo.withdrawAssetApproved.replace('/:token', '')
      ) === false &&
      _.includes(
        path,
        PATH.nexo.withdrawSavingApproved.replace('/:token', '')
      ) === false &&
      _.includes(path, PATH.nexo.verifySucceeded) === false
    ) {
      return history.push(PATH.nexo.account);
    }
    if (
      !token &&
      _.includes([PATH.nexo.newAccount, PATH.nexo.forgotPassword], path) ===
        false &&
      _.includes(path, PATH.nexo.verifySucceeded) === false &&
      _.includes(path, PATH.nexo.resetPassword) === false
    ) {
      return history.push(PATH.nexo.login);
    }
  }, [history]);

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  const getAccessToken = () => {
    const time = 1000 * 60 * 30;
    const accessExpire = localStorage.getItem('exp');
    const refreshToken = localStorage.getItem('refresh-token');

    if (
      accessExpire !== null &&
      parseInt(accessExpire, 0) * 1000 - new Date().getTime() < 1000 * 60 * 60
    ) {
      dispatch(refreshTokenAction(refreshToken || ''));
    }
    setInterval(() => {
      if (
        accessExpire !== null &&
        parseInt(accessExpire, 0) * 1000 - new Date().getTime() < 1000 * 60 * 60
      ) {
        dispatch(refreshTokenAction(refreshToken || ''));
      }
    }, time);
  };

  const logoutWhenRefreshExpire = () => {
    const hour = 1000 * 60 * 60;
    const refreshExpire = localStorage.getItem('refresh-exp');
    if (
      refreshExpire &&
      parseInt(refreshExpire, 0) * 1000 - new Date().getTime() < hour * 2
    ) {
      dispatch(logoutAction());
    } else {
      getAccessToken();
    }
    setInterval(() => {
      if (
        refreshExpire &&
        parseInt(refreshExpire, 0) * 1000 - new Date().getTime() < hour * 2
      ) {
        dispatch(logoutAction());
      }
    }, hour);
  };

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role === 'Admin') {
      dispatch(logoutAction());
    }
    logoutWhenRefreshExpire();
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div>
      <Switch>
        <Route component={Template} />
      </Switch>
      <NotificationComponent />
    </div>
  );
};
