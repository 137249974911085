import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

const styles = makeStyles({
  sliderNoActive: {
    cursor: 'pointer',
    display: 'flex !important',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #D7DCE0 !important',
    borderRadius: 5,
    width: '95% !important',
    height: 41,
    '&:focus': {
      outlineColor: '#FFFFFF'
    }
  },
  soonCoin: {
    background: '#758698',
    border: '1px solid #E4EAEE',
    boxSizing: 'border-box',
    height: 20,
    width: 50,
    borderRadius: 5,
    position: 'absolute',
    right: 0,
    top: 0
  }
});

export const SlickSoonCoin = () => {
  const classes = styles();
  return (
    <Grid className={classes.soonCoin}>
      <Typography
        variant="h5"
        style={{ color: '#FFFFFF', fontSize: 10, marginLeft: 9 }}
      >
        SOON
      </Typography>
    </Grid>
  );
};
