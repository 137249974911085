import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { NexoButton } from '../share';
import { IMAGE } from '../../constants/image.constant';

interface IProps {
  handleClose: () => void;
}

export const WithdrawLoanEmpty = (props: IProps) => {
  return (
    <Grid
      style={{
        padding: 35
      }}
    >
      <Grid
        style={{
          background: '#FFFFFF',
          border: ' 1px solid #D7DCE0',
          boxSizing: 'border-box',
          borderRadius: 5,
          padding: 15,
          textAlign: 'center'
        }}
      >
        <Typography variant="h2" style={{ color: '#050F47' }}>
          You can withdraw up to{' '}
          <span style={{ color: '#20bcff' }}> $ 0.00 </span>
        </Typography>
        <Grid style={{ marginTop: 20 }}>
          <img
            src={IMAGE.ICON_WITHDRAW_EMPTY}
            alt="icon withdraw"
            width={150}
            height={150}
            style={{ marginRight: 50 }}
          />
        </Grid>
        <Grid container justify="center" style={{ marginTop: 20 }}>
          <Grid style={{ width: 300 }}>
            <NexoButton
              handleRedirect={props.handleClose}
              title="Top Up Crypto Assets"
              variant="contained"
              color="primary"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
