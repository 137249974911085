export const PATH = {
  nexo: {
    dashboard: '/',
    login: '/login',
    newAccount: '/new-account',
    verifyAccount: '/verify-account',
    verifySucceeded: '/verify-succeeded',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    verifyForgotPassword: '/verify-forgot-password',
    myProfile: '/profile',
    security: '/security',
    account: '/account',
    transactions: '/transactions',
    notFound: '/not-found',
    withdrawLoanApproved: '/withdraw/loan/approve/:token',
    withdrawAssetApproved: '/withdraw/asset/approve/:token',
    withdrawSavingApproved: '/withdraw/saving/approve/:token'
  },
  logo: process.env.REACT_APP_URL_LANDING_PAGE,
  company: `${process.env.REACT_APP_URL_LANDING_PAGE}company/`,
  new: `${process.env.REACT_APP_URL_LANDING_PAGE}news/`,
  help: `${process.env.REACT_APP_URL_LANDING_PAGE}help/`
};
