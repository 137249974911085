import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { PATH } from '../constants/Path.constant';
import {
  LoginComponent,
  NewAccountComponent,
  VerifyNewAccount
} from '../scenes/auth/components';
import { FooterComponent } from './layout/Footer.component';
import { VerifiedSussesAccountComponent } from '../scenes/auth/components/VerifiedSussesAccount.component';
import { ForgotPasswordComponent } from '../scenes/auth/components/ForgotPassword.component';
import { VerifyForgotPasswordComponent } from '../scenes/auth/components/VerifyForgotPassword.component';
import { ResetPasswordComponent } from '../scenes/auth/components/ResetPassword.component';
import { MyProfileComponent } from '../scenes/dashboard/components/MyProfile.component';
import { SecurityComponent } from '../scenes/dashboard/components/Security.component';
import { AccountComponent } from '../scenes/dashboard/components/Account.component';
import { TransactionsComponent } from '../scenes/dashboard/components/Transactions.component';
import { NotFoundComponent } from './layout/NotFound.component';
import { Header } from './layout/Header.component';
import { WithdrawLoanApproved } from '../scenes/dashboard/components/WithdrawLoanApproved';
import { WithdrawAssetApproved } from '../scenes/dashboard/components/WithdrawAssetApproved';
import { WithdrawSavingApprove } from '../scenes/dashboard/components/WithdrawSavingApprove';

const styles = makeStyles((theme: Theme) => ({
  childrenRoot: {
    marginTop: 34,
    marginBottom: 30
  },
  headerRoot: {
    marginTop: 41,
    marginBottom: 20,
    margin: '0px auto',
    minHeight: 'calc(100vh - 202px)',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      width: 1170
    },
    [theme.breakpoints.down('md')]: {
      width: 970
    },
    [theme.breakpoints.down('sm')]: {
      width: 750
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  }
}));

export const Template = () => {
  const classes = styles();
  return (
    <div>
      <Header />
      <Grid className={classes.headerRoot}>
        <Switch>
          <Route exact path={PATH.nexo.login} component={LoginComponent} />
          <Route
            exact
            path={PATH.nexo.newAccount}
            component={NewAccountComponent}
          />
          <Route
            exact
            path={PATH.nexo.verifyAccount}
            component={VerifyNewAccount}
          />
          <Route
            path={PATH.nexo.verifySucceeded}
            component={VerifiedSussesAccountComponent}
          />
          <Route
            exact
            path={PATH.nexo.forgotPassword}
            component={ForgotPasswordComponent}
          />
          <Route
            exact
            path={PATH.nexo.verifyForgotPassword}
            component={VerifyForgotPasswordComponent}
          />
          <Route
            path={PATH.nexo.resetPassword}
            component={ResetPasswordComponent}
          />
          <Route
            exact
            path={PATH.nexo.myProfile}
            component={MyProfileComponent}
          />
          <Route
            exact
            path={PATH.nexo.security}
            component={SecurityComponent}
          />
          <Route exact path={PATH.nexo.account} component={AccountComponent} />
          <Route
            exact
            path={PATH.nexo.transactions}
            component={TransactionsComponent}
          />
          <Route
            path={PATH.nexo.withdrawLoanApproved}
            component={WithdrawLoanApproved}
          />
          <Route
            path={PATH.nexo.withdrawAssetApproved}
            component={WithdrawAssetApproved}
          />
          <Route
            path={PATH.nexo.withdrawSavingApproved}
            component={WithdrawSavingApprove}
          />
          <Route path="*" component={NotFoundComponent} />
        </Switch>
      </Grid>
      <FooterComponent />
    </div>
  );
};
