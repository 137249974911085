import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';

const styles = makeStyles({
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  img: {
    marginRight: 7,
    width: 25,
    height: 25
  },

  text: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 2,
    color: '#050F47',
    textTransform: 'capitalize'
  },
  textTitle: {
    fontSize: 14
  }
});

interface IProps {
  id: string;
  name: string;
  imgSrc?: string;
}

export const NexoItemCoinCredit = (props: IProps) => {
  const { name, imgSrc } = props;
  const classes = styles();
  return (
    <Grid className={classes.content}>
      <img
        className={classes.img}
        src={(process.env.REACT_APP_API_URL_IMAGE || '') + imgSrc}
        alt="coin"
        title="coin"
      />
      <Typography className={classNames(classes.text, classes.textTitle)}>
        {name}
      </Typography>
    </Grid>
  );
};
