import React, { useState } from 'react';
import { Paper, Grid, Typography, Link, makeStyles } from '@material-ui/core';
import imageVerifyAccount from '../../../assets/images/imageVerifyAccount.svg';
import { NexoButton } from '../../../components/share';
import { useHistory } from 'react-router';
import { resendEmailAction } from '../../../actions/auth.action';
import { useDispatch } from 'react-redux';
const styles = makeStyles({
  root: {
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%'
  },
  rootPaper: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  contentText: {
    margin: '15px 0px',
    '& .MuiTypography-root': {
      fontSize: 12
    }
  },
  textPrivacy: {
    color: '#3E399F'
  },
  alreadyAccount: {
    fontWeight: 600,
    lineHeight: '24px',
    color: '#050F47'
  },
  textVerify: {
    marginTop: 33
  },
  textReceive: {
    marginTop: 105,
    marginBottom: 10
  },
  widthButton: {
    width: 450
  }
});

export const VerifyNewAccount = () => {
  const history = useHistory<{ email: string }>();
  const classes = styles();
  const dispatch = useDispatch();

  // create state
  const [disableResend, setDisableResend] = useState(false);
  const [time, setTimeString] = useState(180);

  const resendMail = () => {
    if (history.location.state && history.location.state.email !== '') {
      dispatch(resendEmailAction(history.location.state.email));
      setDisableResend(true);
      countTimeResend();
    }
  };

  const countTimeResend = () => {
    let timeString = time;
    const interval = setInterval(() => {
      const currentTime = timeString - 1;
      if (currentTime === 0) {
        setTimeString(180);
        setDisableResend(false);
        clearInterval(interval);
        return;
      }
      timeString = currentTime;
      setTimeString(timeString);
    }, 1000);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={2} className={classes.rootPaper}>
        <Grid container justify="center">
          <img
            data-aos="zoom-out"
            data-aos-duration="1000"
            src={imageVerifyAccount}
            alt=" verify account"
          />
        </Grid>
        <Grid container justify="center" className={classes.textVerify}>
          <Typography variant="h4">
            Please verify your email address by clicking on the verification
            link
          </Typography>
        </Grid>
        <Grid container justify="center">
          <Typography variant="h4">in the confirmation email.</Typography>
        </Grid>

        <Grid container justify="center" className={classes.textReceive}>
          <Typography variant="h4">
            Didn't receive the email?{' '}
            {disableResend === true ? (
              <span style={{ color: '#3e8deb', fontStyle: 'italic' }}>
                {''} Resend email {`(${time}s)`}
              </span>
            ) : null}
          </Typography>
        </Grid>
        <Grid container justify="center" className={classes.widthButton}>
          <NexoButton
            title="Resend email"
            variant="contained"
            color="primary"
            disabled={disableResend}
            handleRedirect={resendMail}
          />
        </Grid>
      </Paper>
      <Grid container justify="center" className={classes.contentText}>
        <Typography variant="h5" className={classes.alreadyAccount}>
          Having problems using? {''}
          <Link href="" className={classes.textPrivacy}>
            talk to us
          </Link>
        </Typography>
      </Grid>
    </div>
  );
};
