import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import iconWithdrawal from '../assets/images/iconWithdrawal.svg';
import iconCreditLine from '../assets/images/iconCreditLine.svg';
import { LoanWithdrawDialogComponent } from './dialog/LoanWithdraw.dialog.component';
import { IUserInfo } from '../types/user.type';
import { RepayCreditLineDialog } from './dialog/RepayCreditLine.dialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { loadWithdrawFundConfigAction } from '../actions/withdrawFund.action';
import { IStoreState } from '../types';
import { useHistory } from 'react-router';
const styles = makeStyles({
  rootWithdrawal: {
    height: 150,
    background: '#FFFFFF',
    border: ' 1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: 5
  },
  paddingWithDrawal: {
    padding: '0px 30px',
    position: 'relative'
  },
  card: {
    height: 100,
    '&:hover': {
      border: '1px solid #3E399F',
      cursor: 'pointer',
      borderStyle: 'solid',
      borderWidth: '1px 1px 1px 1px',
      borderColor: '#F5F5F5',
      borderRadius: 10,
      boxShadow: '0px 0px 40px 0px rgba(0, 0, 0, 0.07)'
    }
  }
});

interface IProps {
  userProfile: IUserInfo;
  available: number;
  creditLine: number;
}

export const LoanWithdrawalCard = (props: IProps) => {
  const history = useHistory<{ turnOffDialog: boolean }>();

  const [showDialogLoanWithdrawl, setShowDialogLoanWithdrawl] = useState<
    boolean
  >(false);
  const [showDialogRepayment, setShowDialogRepayment] = useState<boolean>(
    false
  );
  const withdrawTypes = useSelector(
    (state: IStoreState) => state.withdrawTypes
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadWithdrawFundConfigAction());
  }, [dispatch]);

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.turnOffDialog === true
    ) {
      setShowDialogLoanWithdrawl(false);
    }
  }, [history, history.location.state]);
  const handleShowDialogLoanWithdraw = () => {
    setShowDialogLoanWithdrawl(true);
  };

  const handleShowDialogRepayment = () => {
    setShowDialogRepayment(true);
  };
  const handleClose = () => {
    setShowDialogLoanWithdrawl(false);
    setShowDialogRepayment(false);
  };

  const { userProfile, available } = props;
  const classes = styles();
  return (
    <Grid
      data-aos="fade-right"
      data-aos-offset="300"
      data-aos-duration="300"
      container
      alignItems="center"
      className={classes.rootWithdrawal}
    >
      <Grid item xs={6} className={classes.paddingWithDrawal}>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.card}
          onClick={handleShowDialogLoanWithdraw}
        >
          <img
            data-aos="zoom-out"
            src={iconWithdrawal}
            alt="icon withdrawal"
            width={40}
            height={40}
            style={{ marginRight: 17 }}
          />
          <Grid>
            <Typography
              variant="h5"
              style={{ color: '#0A2F5B', fontWeight: 600 }}
            >
              Withdraw Funds From Credit Line
            </Typography>
            <Typography variant="h5" style={{ color: '#A5B2C0', marginTop: 7 }}>
              By Local Bank Transfer or Stablecoin
            </Typography>
          </Grid>
        </Grid>
        <Grid
          style={{
            borderRight: '1px dashed #B2B6BA',
            height: 50,
            position: 'absolute',
            right: 0,
            top: 26
          }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          padding: '0px 30px'
        }}
      >
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.card}
          onClick={handleShowDialogRepayment}
        >
          <img
            data-aos="zoom-out"
            src={iconCreditLine}
            alt="icon withdrawal"
            width={40}
            height={40}
            style={{ marginRight: 17 }}
          />
          <Grid>
            <Typography
              variant="h5"
              style={{ color: '#0A2F5B', fontWeight: 600 }}
            >
              Repay Credit Line
            </Typography>
            <Typography variant="h5" style={{ color: '#A5B2C0', marginTop: 7 }}>
              By Bank Transfer or Crypto
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <LoanWithdrawDialogComponent
        userProfile={userProfile}
        showDialog={showDialogLoanWithdrawl}
        handleClose={handleClose}
        maxWidth="lg"
        available={available}
        dataWithdrawTypes={withdrawTypes}
      />
      <RepayCreditLineDialog
        userProfile={userProfile}
        maxWidth="lg"
        showDialog={showDialogRepayment}
        handleClose={handleClose}
      />
    </Grid>
  );
};
