import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        width: '100%'
      }
    },
    MuiTypography: {
      subtitle1: {
        fontSize: 20,
        fontFamily: 'Montserrat',
        fontWeight: 600,
        lineHeight: '20px',
        color: '#3E399F'
      },
      h1: {
        fontSize: 24,
        fontFamily: 'Montserrat',
        fontWeight: 600
      },
      h2: {
        fontSize: 18,
        fontFamily: 'Montserrat',
        fontWeight: 600
      },
      h3: {
        fontSize: 16,
        fontFamily: 'Montserrat',
        fontWeight: 600,
        color: '#050F47'
      },
      h4: {
        fontSize: 14,
        fontFamily: 'Montserrat',
        fontWeight: 600,
        lineHeight: '20px',
        color: '#050F47'
      },
      h5: {
        fontSize: 12,
        fontFamily: 'Montserrat',
        fontWeight: 500,
        lineHeight: '18px',
        color: '#5E6368'
      }
    },
    MuiButton: {
      contained: {
        boxShadow: 'none'
      }
    },

    MuiPaper: {
      elevation0: {
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.1)',
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '1px solid #E4EAEE',
        width: 520,
        height: 420
      },
      elevation1: {
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.1)',
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '1px solid #E4EAEE',
        width: 520,
        height: 'auto'
      },
      elevation2: {
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.1)',
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '1px solid #E4EAEE',
        width: '100%',
        height: 650
      },
      elevation3: {
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.1)',
        borderRadius: 10,
        boxSizing: 'border-box',
        border: '1px solid #E4EAEE',
        width: 520,
        height: 345
      }
    },
    MuiBottomNavigation: {
      root: {
        height: '100%',
        backgroundColor: 'inherit'
      }
    },
    MuiBottomNavigationAction: {
      root: {
        width: 170,
        paddingLeft: 0,
        paddingRight: 0,
        '& .Mui-selected': {
          color: '#3E399F',
          fontFamily: 'Montserrat',
          fontWeight: 600,
          lineHeight: '20px',
          '&:after': {
            content: "''",
            backgroundColor: '#F09F3C',
            position: 'absolute',
            width: 60,
            height: 3,
            bottom: 0,
            left: 50,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3
          }
        }
      },
      label: {
        color: '#A5B2C0',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        lineHeight: '20px'
      }
    },
    MuiDialog: {
      paper: {
        margin: 0,
        background: ' #FFFFFF',
        border: '1px solid #E4EAEE',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.1)',
        borderRadius: 10
      },
      paperWidthXs: {
        width: 520,
        maxWidth: '520px !important',
        height: 'auto'
      },
      paperWidthSm: {
        maxWidth: 520,
        height: 345,
        width: 520
      },
      paperWidthMd: {
        width: 520,
        maxWidth: 520,
        height: 500
      },
      paperWidthLg: {
        width: 520,
        maxWidth: 520,
        height: 'auto'
      },
      paperWidthXl: {
        width: 520,
        maxWidth: 520,
        height: 650
      }
    },
    MuiTooltip: {
      tooltip: {
        color: '#FFFFFF',
        background: 'linear-gradient(167.33deg, #FFB26F 3.5%, #DF8A03 83.05%)',
        backgroundColor: 'transparent',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 12,
        borderRadius: 10,
        padding: 15,
        boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.1)',
        fontStyle: 'italic'
      }
    },
    MuiButtonBase: {
      root: {
        color: '#A5B2C0',
        fontFamily: 'Montserrat',
        fontSize: '12px !important',
        fontWeight: 500
      }
    },
    // MuiMenu: {
    //   paper: {
    //     borderRadius: 35
    //   }
    // },
    MuiSwitch: {
      root: {
        width: 50,
        height: 26,
        padding: 0,
        borderRadius: 20
      },
      switchBase: {
        top: '-6px',
        left: '-4px',
        color: '#FFFFFF !important',
        '&$track': {
          opacity: 1,
          background:
            'linear-gradient(118.38deg, #9BA0A6 9.63%, #C4C4C4 85.81%) !important'
        },
        '&$checked': {
          color: 'red',
          '& + $track': {
            opacity: 1,
            background:
              'linear-gradient(118.38deg, #47CAB2 9.63%, #36BCA4 85.81%) !important'
          }
        }
      }
    },
    MuiTablePagination: {
      toolbar: {
        padding: 0
      },
      caption: {
        color: '#A5B2C0',
        fontFamily: 'Montserrat',
        fontSize: 14,
        fontWeigth: 500
      },
      spacer: {
        flex: 'inherit'
      },
      input: {
        width: 60
      }
    }
  }
});

export default theme;
