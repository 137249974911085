import React, { Dispatch } from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  NexoInput,
  NexoPaper,
  NexoButton
} from '../../../components/share/index';
import { History } from 'history';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { PATH } from '../../../constants/Path.constant';
import { connect } from 'react-redux';
import {
  ILoginAction,
  loginAction,
  verifyCodeAction,
  IVerifyCodeAction
} from '../../../actions/auth.action';
import { ILoginRequest, IVerifyCode } from '../../../types/auth.type';
import { ReCaptchaComponent } from '../../../components/share/ReCaptcha.component';
import { validateEmail } from '../../../helper';
import TwoAuthenticationDialogComponent from '../../../components/dialog/TwoAuthentication.dialog.component';

export interface ILoginParams {
  loginRequest: ILoginRequest;
  twoFactorEnabled: boolean;
  disbleButtonLogin: boolean;
}
const mapStateToProps = (
  // eslint-disable-next-line
  {},
  { history }: RouteComponentProps<{}, {}, ILoginParams>
) => {
  return {
    history
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<ILoginAction | IVerifyCodeAction>
) => {
  return {
    loginAction: (request: ILoginRequest) => dispatch(loginAction(request)),
    verifyCodeAction: (code: IVerifyCode) => dispatch(verifyCodeAction(code))
  };
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      alignSelf: 'center'
    },
    contentText: {
      margin: '18px 0px',
      '& .MuiTypography-root': {
        fontSize: 12
      }
    },
    textPrivacy: {
      color: '#3E399F',
      textDecoration: 'none'
    },

    reCaptcha: {
      marginBottom: 20
    },

    textFooter: {
      color: '#050F47'
    },
    ldsRipple: {
      display: 'inline-block',
      position: 'relative',
      width: 30,
      height: 30,
      '& div': {
        position: 'absolute',
        border: '4px solid #20bcfe',
        opacity: 1,
        borderRadius: '50%',
        animation: 'ldsRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite',
        '&:nth-child(2)': {
          animationDelay: '-0.5s'
        }
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History<ILoginParams>;
  loginAction: (request: ILoginRequest) => void;
  verifyCodeAction: (code: IVerifyCode) => void;
}

interface IState {
  load: boolean;
  checkCaptchaSusses: boolean;
  checkCaptchaError: boolean;
  email: string;
  password: string;
  errorEmail: boolean;
  twoFactorEnabled: boolean;
  loginTwoFactorEnabled: IVerifyCode;
  errorPassword: boolean;
  checkLoadingPage: boolean;
  disbleButtonLogin: boolean;
}
class Login extends React.Component<IProps, IState> {
  public state: IState = {
    load: false,
    checkCaptchaSusses: false,
    checkCaptchaError: false,
    email: '',
    password: '',
    errorEmail: false,
    errorPassword: false,
    twoFactorEnabled: false,
    loginTwoFactorEnabled: {
      code: '',
      password: '',
      email: ''
    },
    checkLoadingPage: false,
    disbleButtonLogin: false
  };
  // tslint:disable-next-line
  UNSAFE_componentWillReceiveProps = (nextIProps: IProps) => {
    if (nextIProps.history.location.state) {
      const {
        loginRequest,
        twoFactorEnabled,
        disbleButtonLogin
      } = nextIProps.history.location.state;
      this.setState({
        loginTwoFactorEnabled: {
          email: loginRequest.email,
          password: loginRequest.password,
          code: ''
        },
        twoFactorEnabled,
        disbleButtonLogin,
        checkLoadingPage: disbleButtonLogin,
        checkCaptchaSusses: disbleButtonLogin
      });
    }
  };

  public handleChange = () => {
    const { email, password } = this.state;
    this.setState({
      checkCaptchaSusses: true,
      checkCaptchaError: false
    });
    if (email.length > 0 && password.length > 0) {
      this.setState(
        {
          checkLoadingPage: true,
          disbleButtonLogin: true
        },
        () => {
          setTimeout(() => {
            const request: ILoginRequest = {
              email,
              password
            };
            this.props.loginAction(request);
          }, 1000);
        }
      );
    }
  };

  public onClickLogin = () => {
    if (!this.state.checkCaptchaSusses) {
      this.setState({
        checkCaptchaError: true
      });
    } else {
      const request: ILoginRequest = {
        email: this.state.email,
        password: this.state.password
      };
      this.props.loginAction(request);
      this.setState({
        checkCaptchaError: false
      });
    }
  };

  public handleChangeInputEmail = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      email: event.target.value
    });

    if (
      event.target.value.match(validateEmail) ||
      event.target.value.substring(0, 1) === ''
    ) {
      this.setState({
        errorEmail: false
      });
    } else {
      this.setState({
        errorEmail: true
      });
    }
  };

  public handleChangeInputPassword = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      event.target.value.length > 5 ||
      event.target.value.substring(0, 1) === ''
    ) {
      this.setState({
        errorPassword: false
      });
    } else {
      this.setState({
        errorPassword: true
      });
    }
    this.setState({
      password: event.target.value.trim()
    });
  };
  public handleClose = () => {
    this.setState({
      twoFactorEnabled: false,
      disbleButtonLogin: false,
      checkLoadingPage: false,
      checkCaptchaSusses: false
    });
  };

  public handleLoginTwoFactorEnabled = (request: IVerifyCode) => {
    this.props.verifyCodeAction(request);
  };

  public getLoadingPage = () => {
    return (
      <div className={this.props.classes.ldsRipple}>
        <div />
        <div />
      </div>
    );
  };
  public render() {
    const { classes } = this.props;
    const {
      checkCaptchaError,
      checkCaptchaSusses,
      errorEmail,
      email,
      password,
      errorPassword,
      disbleButtonLogin
    } = this.state;
    return (
      <div className={classes.root}>
        <NexoPaper title="Login" numberElevation={1}>
          <NexoInput
            error={errorEmail}
            msgError="Incorrect email format"
            title="Email"
            type="text"
            value={email}
            handleChangeInput={this.handleChangeInputEmail}
          />
          <NexoInput
            error={errorPassword}
            msgError="Password should be at least 6 characters"
            title="Password"
            type="password"
            value={password}
            handleChangeInput={this.handleChangeInputPassword}
          />
          <ReCaptchaComponent
            handleChange={this.handleChange}
            error={checkCaptchaError}
            susses={checkCaptchaSusses}
            checkPrivacyPolicy={true}
            titleError="Wrong Captcha, please try again"
            titleSusses="Verification succeeded"
          />

          <Grid container justify="center">
            {this.state.checkLoadingPage ? (
              <Grid style={{ marginRight: 40, marginTop: 5 }}>
                {this.getLoadingPage()}
              </Grid>
            ) : null}
            <Grid container justify="center" style={{ width: 300 }}>
              <NexoButton
                title="Login"
                variant="contained"
                disabled={
                  checkCaptchaError ||
                  errorEmail ||
                  errorPassword ||
                  disbleButtonLogin
                }
                color="primary"
                handleRedirect={this.onClickLogin}
              />
            </Grid>
          </Grid>
        </NexoPaper>
        <Grid container justify="center" className={classes.contentText}>
          <Typography variant="h4" className={classes.textFooter}>
            Don't have account? &nbsp;
            <Link to={PATH.nexo.newAccount} className={classes.textPrivacy}>
              Create new account
            </Link>
            &nbsp;<span className={classes.textPrivacy}>-</span>&nbsp;
            <Link to={PATH.nexo.forgotPassword} className={classes.textPrivacy}>
              Forgot password
            </Link>
          </Typography>
        </Grid>
        <TwoAuthenticationDialogComponent
          showIsVerify={true}
          showContent={false}
          handleLoginTwoFactorEnabled={this.handleLoginTwoFactorEnabled}
          loginTwoFactorEnabled={this.state.loginTwoFactorEnabled}
          showDialog={this.state.twoFactorEnabled}
          handleCloseDialog={this.handleClose}
        />
      </div>
    );
  }
}

export const LoginComponent = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))
);
