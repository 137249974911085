import {
  IObjectWithdrawType,
  ILoanContractSummaryInfo
} from '../types/withdrawFund.type';
import {
  ISetWithdrawFundConfigAction,
  ISetLoanContractSummaryAction
} from '../actions/withdrawFund.action';
import {
  SET_WITHDRAWFUND_CONFIG_ACTION,
  SET_LOAN_CONTRACT_SUMMARY_ACTION
} from '../constants/withdrawFund.constant';

const emptyWithdrawType: IObjectWithdrawType = {
  recipientType: [],
  transferType: []
};

const emptyLoanContractSummary: ILoanContractSummaryInfo = {
  accountNumber: '',
  address: '',
  amount: 0,
  city: '',
  countryId: '',
  description: '',
  id: '',
  isApproved: false,
  postCode: '',
  recipientName: '',
  recipientType: '',
  swiftCode: '',
  transferType: '',
  userId: '',
  recipientAddress: '',
  currency: {
    id: '',
    image: '',
    loanDisabled: false,
    loanHidden: false,
    name: '',
    symbol: '',
    interestDisabled: false,
    interestHidden: false,
    isShow: false,
    stablecoin: false
  },
  name: ''
};

export const withdrawTypes = (
  state: IObjectWithdrawType = emptyWithdrawType,
  action: ISetWithdrawFundConfigAction
): IObjectWithdrawType => {
  if (action.type === SET_WITHDRAWFUND_CONFIG_ACTION) {
    return action.payload;
  }
  return state;
};

export const loanContractSummary = (
  state: ILoanContractSummaryInfo = emptyLoanContractSummary,
  action: ISetLoanContractSummaryAction
): ILoanContractSummaryInfo => {
  if (action.type === SET_LOAN_CONTRACT_SUMMARY_ACTION) {
    return action.payload;
  }
  return state;
};
