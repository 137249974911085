import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { IStoreState } from '../../../types';
import { VerifyUserDialog } from '../../../components/dialog/VerifyUser.dialog.component';
import { PATH } from '../../../constants/Path.constant';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { historyBrowser } from '../../../index';
import { NexoSelectComponent } from '../../../components/share/NexoSelect.compoent';
import {
  loadTransactionAction,
  loadAssetTransactionAction
} from '../../../actions/transaction.action';
import { TransactionTable } from '../../../components/TransactionTable';
import {
  DataAllTypes,
  DataAllAsset
} from '../../../constants/transaction.constant';
import { ITransactionRequest } from '../../../types/transaction.type';
const styles = makeStyles({
  rootTransactions: {
    background: '#FFFFFF',
    border: '1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: 5,
    '& .MuiPaper-elevation0': {
      boxShadow: 'none',
      height: 'auto',
      border: 'none',
      '& .MuiTablePagination-input': {
        width: 50
      },
      '& .MuiTablePagination-toolbar': {
        paddingLeft: 16
      },
      '& .MuiSelect-select.MuiSelect-select': {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  },
  rootAdvanced: {
    height: 50,
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5,
    paddingRight: 20
  },
  iconAdvanced: {
    paddingLeft: 18,
    paddingRight: 8
  },
  textAdvanced: {
    color: '#F2994A'
  },
  keyboardDatePicker: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    textTransform: 'uppercase',
    '& :disabled': {
      color: '#000000'
    }
  },
  inputLabelProps: {
    color: '#9BA0A6 !important',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  rootKeyboardDate: {
    marginTop: 0,
    marginBottom: 0,
    width: 180,
    height: 50,
    '& .MuiInputBase-input': {
      fontFamily: 'Montserrat',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '12px',
      color: '#A5B2C0'
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '12px',
      color: '#050F47'
    },
    '& .MuiOutlinedInput-root': {
      height: '100%'
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #34CEA9'
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #34CEA9'
    }
  },
  rightPart: {
    width: '180px',
    border: '1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9FB',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    height: 50,
    fontWeight: 600,
    '&:hover': {
      border: '1px solid #34CEA9'
    },
    '& .rbt.clearfix.open': {
      width: '100%',
      height: 50,
      '& .sr-only.rbt-sr-status': {
        display: 'none'
      },
      '& .rbt-input-hint-container': {
        display: 'flex',
        flex: '1',
        position: 'relative'
      },
      '& .rbt-input-hint': {
        '& input': {
          display: 'none'
        }
      },
      '& .rbt-input-main.form-control.rbt-input': {
        fontFamily: 'Montserrat',
        fontSize: 14,
        display: 'block',
        width: '100%',
        height: 35,
        padding: '.375rem .75rem',
        lineHeight: '1.5',
        fontWeight: '500',
        color: '#050F47',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        borderStyle: 'none',
        borderRadius: '5px 5px 5px 5px',
        outline: 'none !important'
      }
    },
    '& .rbt-menu.dropdown-menu.show': {
      zIndex: 1000,
      listStyle: 'none',
      position: 'absolute',
      display: 'block',
      maxHeight: 300,
      overflowY: 'auto',
      overflowX: 'hidden',
      willChange: 'transform',
      top: '-4px !important',
      left: '-1px !important',
      transform: 'translate3d(0px, 38px, 0px)',
      float: 'left',
      padding: '15px 0px',
      margin: '.125rem 0 0',
      textAlign: 'left',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #D7DCE0',
      borderRadius: '0px 0px 5px 5px',
      '& .dropdown-item': {
        fontFamily: 'Montserrat',
        fontSize: 12,
        fontWeight: 500,
        textOverflow: 'ellipsis',
        display: 'block',
        width: '162px',
        padding: 8,
        clear: 'both',
        color: '#A5B2C0',
        textAlign: 'inherit',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        borderStyle: 'none',
        '&:hover': {
          backgroundColor: '#F9FAFB'
        }
      }
    }
  },
  rootHeader: {
    background: '#F9FAFB',
    borderBottom: '1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: '5px 5px 0px 0px',
    height: 100,
    paddingLeft: 30
  },
  ldsRipple: {
    display: 'inline-block',
    position: 'relative',
    width: 100,
    height: 100,
    '& div': {
      position: 'absolute',
      border: '5px solid #20bcfe',
      opacity: 1,
      borderRadius: '50%',
      animation:
        'ldsRippleTransaction 1.15s cubic-bezier(0, 0.2, 0.8, 1) infinite',
      '&:nth-child(2)': {
        animationDelay: '-0.5s'
      }
    }
  }
});

export const TransactionsComponent = () => {
  const [metaTable, setMetaTable] = useState<ITransactionRequest>({
    asset: 'ALL',
    limit: 10,
    offset: 0,
    type: 'ALL'
  });
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = historyBrowser;
  const userProfile = useSelector(
    (state: IStoreState) => state.userManagement.userProfile
  );
  // const assetTransactions: ICurrency[] = useSelector(
  //   (state: IStoreState) => state.assetTransactions
  // );
  const transactions = useSelector((state: IStoreState) => state.transactions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTransactionAction(metaTable));
  }, [metaTable, dispatch]);

  useEffect(() => {
    dispatch(
      loadAssetTransactionAction({
        asset: true,
        limit: 10,
        offset: 0
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(true);
    }, 800);
  }, []);

  const redirectTo = () => {
    history.push(PATH.nexo.security);
  };

  const handleChangePage = (event: any, page: number) => {
    setMetaTable({
      ...metaTable,
      limit: transactions.meta.limit,
      offset: page * transactions.meta.limit
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(true);
    }, 1000);
  }, []);

  const handleChangeSelectTypes = (event: any, values: any) => {
    setMetaTable({
      ...metaTable,
      type: values ? values.symbol : 'ALL'
    });
  };
  const handleChangeSelectAssets = (event: any, values: any) => {
    setMetaTable({
      ...metaTable,
      asset: values ? values.symbol : 'ALL'
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setMetaTable({
      ...metaTable,
      limit: parseInt(event.target.value, 10),
      offset: 0
    });
  };

  const getLoadingPage = () => {
    return (
      <div className={classes.ldsRipple}>
        <div />
        <div />
      </div>
    );
  };
  const classes = styles();
  return (
    <div className={classes.rootTransactions} style={{ width: '100%' }}>
      {loadingPage ? (
        <React.Fragment>
          <Grid
            data-aos="fade-up"
            data-aos-offset="300"
            data-aos-duration="300"
            container
            alignItems="center"
            className={classes.rootHeader}
          >
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                className={classes.rootKeyboardDate}
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                autoOk
                label="Start"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="YYYY-MM-DD"
                value={dateStart}
                inputVariant="outlined"
                onChange={handleChangeDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
              <KeyboardDatePicker
                className={classes.rootKeyboardDate}
                style={{ marginLeft: 20 }}
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                autoOk
                label="End"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="YYYY-MM-DD"
                value={dateStart}
                onChange={handleChangeDate}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider> */}
            <Grid
              style={{
                height: 50,
                marginLeft: 20,
                width: 180
              }}
            >
              <NexoSelectComponent
                content=""
                placeholderInput="All Types"
                dataSelect={DataAllTypes}
                handleChange={handleChangeSelectTypes}
              />
            </Grid>
            <Grid
              style={{
                height: 50,
                marginLeft: 20,
                width: 180
              }}
            >
              <NexoSelectComponent
                content=""
                placeholderInput="All Assets "
                dataSelect={DataAllAsset}
                handleChange={handleChangeSelectAssets}
              />
            </Grid>
          </Grid>
          <Grid
            data-aos="fade-up"
            data-aos-offset="320"
            data-aos-duration="320"
            style={{ padding: 30 }}
          >
            <TransactionTable
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              dataTransaction={transactions}
              rowsPerPage={rowsPerPage}
            />
          </Grid>
        </React.Fragment>
      ) : (
        <Grid
          style={{ height: '100%' }}
          container
          justify="center"
          alignItems="center"
        >
          {getLoadingPage()}
        </Grid>
      )}

      <VerifyUserDialog
        showDialog={userProfile.isVerified === null ? true : false}
        handleCloseDialog={redirectTo}
        textHeader="Required"
      />
    </div>
  );
};
