import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';

const styles = makeStyles({
  textProcessing: {
    color: '#050F47',
    fontWeight: 600
  },
  fontWeightText: {
    fontWeight: 500
  }
});

export const ContentAttentionTopUp = () => {
  const classes = styles();
  return (
    <Grid
      style={{
        marginTop: 27
      }}
    >
      <Typography variant="h5" className={classes.textProcessing}>
        Important: Crypto is not responsible for any coins sent to the wrong
        address.
      </Typography>
      <Typography
        variant="h5"
        className={classes.textProcessing}
        style={{ marginTop: 20 }}
      >
        Processing
      </Typography>
      <Typography variant="h5" className={classes.textProcessing}>
        • The security of the assets held in your Account is guaranteed by a
        $100 million custodian insurance policy
        <span className={classes.fontWeightText}>
          and are held in multi-signature wallets and cold storage at world
          renowned and audited qualified custodian BitGo, which is
          Cryptocurrency Security Standard Level 3 as well as SOC 2 compliant.
        </span>
      </Typography>
      <Typography variant="h5" className={classes.textProcessing}>
        •
        <span className={classes.fontWeightText}>
          You can add funds to your Crypto Account from any wallet, including
          from exchange wallets.
        </span>
      </Typography>
      <Typography variant="h5" className={classes.textProcessing}>
        •
        <span className={classes.fontWeightText}>
          All stablecoin deposits and withdrawals enjoy Crypto's 1-to-1
          conversion guarantee to U.S. Dollars. This means that 1
          USDT/USDC/TUSD/SAI/PAX always equals $1.
        </span>
      </Typography>
      <Typography variant="h5" className={classes.textProcessing}>
        •
        <span className={classes.fontWeightText}>
          After making a deposit you can track its progress on the Transactions
          page. To find out how many network confirmations your deposit needs to
          be completed click here.
        </span>
      </Typography>
      <Typography variant="h5" className={classes.textProcessing}>
        •
        <span className={classes.fontWeightText}>
          No interest is charged until you make a loan withdrawal from your
          available loan limit.
        </span>
      </Typography>
    </Grid>
  );
};
