import React, { useState } from 'react';
import {
  Dialog,
  makeStyles,
  Typography,
  Grid,
  IconButton
} from '@material-ui/core';
import { InputWithdraw } from '../share/InputWithdraw.component';
import SyncAlt from '@material-ui/icons/SyncAlt';
import Warning from '@material-ui/icons/Warning';
import { TableRepayment } from '../share/TableRepayment.component';
import { TextProcessing } from '../share/TextProcessing.component';
import {
  TEXT_PROCESSING,
  TEXT_REPAYMENT
} from '../../constants/Processing.constant';
import { Scrollbars } from 'react-custom-scrollbars';
import { IUserInfo } from '../../types/user.type';
import { TwoFactorAuthNotification } from '../calculator/TwoFactorAuthNotification.component';
import { BasicVerifNotification } from '../calculator/BasicVerifNotification.component';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../types';
import { ICurrencyBalance } from '../../types/currency.type';
import { formatNumberToFixed } from '../../helper';
import { RepaymentCurrency } from './RepaymentCurrency.dialog';
import { IRepaymentMethodsInfo } from '../../types/repayment.type';
import { NexoButton } from '../share';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { PATH } from '../../constants/Path.constant';
const styles = makeStyles({
  rootDialog: {
    '& .MuiDialog-paperWidthLg': {
      width: 650,
      maxWidth: 650
    }
  },
  rootHeader: {
    height: 80,
    borderBottom: '1px solid #E4EAEE',
    background: '#F9FAFB',
    boxSizing: 'border-box',
    paddingLeft: 35,
    paddingRight: 35
  },
  dialogTitle: {
    padding: 0
  },
  methodsCard: {
    backgroundColor: '#ecf6fa',
    height: 50,
    borderRadius: 5,
    width: '100%',
    '&:hover': {
      border: '1px solid #D7DCE0'
    }
  },
  methodsCardDisable: {
    backgroundColor: '#E4EAEE',
    height: 50,
    borderRadius: 5,
    width: '100%',
    '&:hover': {
      border: 'transparent'
    }
  },

  methodsCardActive: {
    backgroundColor: '#25c4ff',
    height: 50,
    borderRadius: 5,
    width: '100%'
  },
  textActiveMethod: {
    color: '#FFFFFF'
  },
  bodyTotal: {
    border: '1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: 50,
    background: '#F9FAFB'
  },
  buttonVerify: {
    width: 150
  }
});

interface IProps {
  handleClose: () => void;
  showDialog: boolean;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  userProfile: IUserInfo;
}

export const RepayCreditLineDialog = (props: IProps) => {
  const history = useHistory();
  const [showInputMethod, setShowInputMethod] = useState<boolean>(false);
  const currencies = useSelector((state: IStoreState) => state.currencies);
  const [repaymentMethods, setRepaymentMethods] = useState<
    IRepaymentMethodsInfo
  >({
    repaymentAddress: '',
    symbol: '',
    disabled: false,
    usd: '',
    valueCurrency: '',
    usdPrice: '',
    image: '',
    nameCurrency: ''
  });
  const [showDialogRepayment, setShowDialogRepayment] = useState<boolean>(
    false
  );

  const handleChangeMethods = (data: ICurrencyBalance) => () => {
    if (
      data.currency.symbol === 'BTC' ||
      data.currency.symbol === 'ETH' ||
      data.currency.symbol === 'XRP' ||
      data.currency.symbol === 'USDT'
    ) {
      setRepaymentMethods({
        ...repaymentMethods,
        repaymentAddress: data.repaymentAddress,
        symbol: data.currency.symbol,
        disabled: data.currency.loanDisabled,
        usdPrice: data.usdPrice,
        image: data.currency.image,
        nameCurrency: data.currency.name,
        valueCurrency: String(
          formatNumberToFixed(
            Number(repaymentMethods.usd) / Number(data.usdPrice),
            8
          )
        )
      });
      setShowInputMethod(true);
    } else {
      setShowInputMethod(false);
    }
  };
  const handleChangeInput = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    const newValueCurrency = String(
      Number(newValue) / Number(repaymentMethods.usdPrice)
    );
    setRepaymentMethods({
      ...repaymentMethods,
      usd: newValue,
      valueCurrency: formatNumberToFixed(newValueCurrency, 8)
    });
  };

  const handleCloseDialog = () => {
    setShowDialogRepayment(false);
  };

  const handleShowDialogRepayment = () => {
    setShowDialogRepayment(true);
  };

  // tslint:disable-next-line
  const ListCoin = (props: { listCoin: ICurrencyBalance[] }) => {
    return (
      <Grid
        container
        style={{
          padding: 5,
          border: '1px solid #D7DCE0',
          boxSizing: 'border-box',
          borderRadius: 5,
          marginTop: 10
        }}
      >
        {props.listCoin &&
          props.listCoin.map((el, key) => (
            <Grid
              key={key}
              container
              item
              xs={3}
              onClick={handleChangeMethods(el)}
              alignItems="center"
              justify="center"
              style={{
                padding: 5,
                cursor: 'pointer'
              }}
            >
              <Grid
                container
                alignItems="center"
                className={classNames(
                  el.currency.symbol === repaymentMethods.symbol
                    ? classes.methodsCardActive
                    : classes.methodsCard,
                  !el.currency.isShow === true ? classes.methodsCardDisable : ''
                )}
              >
                <Grid container justify="center">
                  <Typography
                    variant="h4"
                    className={
                      el.currency.symbol === repaymentMethods.symbol &&
                      el.currency.loanDisabled === false
                        ? classes.textActiveMethod
                        : ''
                    }
                  >
                    {el.currency.symbol}
                  </Typography>
                </Grid>
                <Grid container justify="center">
                  <Typography
                    variant="h5"
                    className={
                      el.currency.symbol === repaymentMethods.symbol &&
                      el.currency.loanDisabled === false
                        ? classes.textActiveMethod
                        : ''
                    }
                  >
                    {el.currency.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    );
  };

  const RepaymentMethods = () => {
    const newValueOutstandingLoan = formatNumberToFixed(
      Number(currencies.totalInterest) + Number(currencies.totalLoan),
      2
    );
    return (
      <Grid className={classes.dialogTitle}>
        <Grid container alignItems="center" className={classes.rootHeader}>
          <Grid container alignItems="center" item xs={12}>
            <Typography
              variant="subtitle1"
              style={{ color: '#050F47', marginRight: 14 }}
            >
              Flexible Credit Line Repayment
            </Typography>
          </Grid>
        </Grid>
        <Scrollbars
          style={{
            overflow: 'hidden',
            width: '100%',
            height: 600
          }}
        >
          <Grid style={{ padding: 35 }}>
            <Grid
              className={classes.bodyTotal}
              container
              alignItems="center"
              justify="center"
            >
              <Typography variant="h2" style={{ color: '#050F47' }}>
                Your outstanding loan is
                <span style={{ color: '#20bcff' }}>
                  {' '}
                  $ {newValueOutstandingLoan}{' '}
                </span>
              </Typography>
            </Grid>

            <Grid
              style={{
                border: '1px solid #D7DCE0',
                boxSizing: 'border-box',
                borderRadius: 5,
                padding: 15,
                marginTop: 20
              }}
            >
              <Typography variant="h5">Available Repayment Methods </Typography>

              <ListCoin listCoin={currencies.currencyBalances} />

              <Grid style={{ marginTop: 10 }}>
                <Typography
                  variant="h5"
                  style={{ textAlign: 'center', fontWeight: 600 }}
                >
                  <span style={{ fontStyle: 'italic', color: '#3E399F' }}>
                    Tip
                  </span>
                  : You can make repayments using all of the available funds in
                  your Crypto Account, including the collateral
                </Typography>
              </Grid>
              {showInputMethod ? (
                <Grid container style={{ marginTop: 30 }}>
                  <Grid item xs={5}>
                    <InputWithdraw
                      valueInput={repaymentMethods.usd}
                      contentLeft="Repayment Amount"
                      iconInputLeft={true}
                      title="USD"
                      name="usd"
                      type="number"
                      minMaxInput={{
                        min: '0',
                        step: '0.01',
                        max: String(newValueOutstandingLoan)
                      }}
                      disabledInput={false}
                      handleChangeInput={handleChangeInput}
                    />
                  </Grid>
                  <Grid
                    container
                    alignItems="flex-end"
                    justify="center"
                    item
                    xs={2}
                  >
                    <IconButton disabled={true}>
                      <SyncAlt />
                    </IconButton>
                  </Grid>
                  <Grid item xs={5}>
                    <InputWithdraw
                      valueInput={repaymentMethods.valueCurrency}
                      contentLeft="Estimated Repayment Amount"
                      iconInputLeft={true}
                      title={repaymentMethods.symbol}
                      name="valueCurrency"
                      disabledInput={true}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid style={{ marginTop: 20 }}>
              <TableRepayment
                currencies={currencies}
                valueUsd={repaymentMethods.usd}
              />
            </Grid>
            {repaymentMethods.usd !== '' ? (
              <Grid container justify="flex-end" style={{ marginTop: 30 }}>
                <Grid className={classes.buttonVerify}>
                  <NexoButton
                    title="Make Repayment"
                    variant="contained"
                    color="primary"
                    heightButton={40}
                    handleRedirect={handleShowDialogRepayment}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            style={{
              borderTop: '1px solid #D7DCE0',
              boxSizing: 'border-box',
              background: '#F9FAFB',
              padding: '15px 35px 15px 35px ',
              display: 'grid',
              gridTemplateColumns: '40px calc(100% - 40px)',
              alignItems: 'flex-start'
            }}
          >
            <IconButton style={{ paddingLeft: 0 }} disabled={true}>
              <Warning />
            </IconButton>
            <Grid style={{ marginTop: 20 }}>
              <Typography
                variant="h5"
                style={{ color: '#050F47', fontSize: 14, fontWeight: 600 }}
              >
                Processing
              </Typography>
              {TEXT_PROCESSING.map((el, key) => (
                <Grid key={key}>
                  <TextProcessing content={el.name} />
                </Grid>
              ))}
              <Typography
                variant="h5"
                style={{ color: '#050F47', fontSize: 14, fontWeight: 600 }}
              >
                Credit Line Repayments
              </Typography>
              {TEXT_REPAYMENT.map((el, key) => (
                <Grid key={key}>
                  <TextProcessing content={el.name} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Scrollbars>
      </Grid>
    );
  };
  const handleRedirectMyProfileAdvanced = () => {
    history.push(PATH.nexo.myProfile, {
      pageNumber: 2
    });
  };
  const handleRedirectMyProfileBasic = () => {
    history.push(PATH.nexo.myProfile, {
      pageNumber: 1
    });
  };
  const handleRedirectSecurity = () => {
    history.push(PATH.nexo.security);
  };
  const classes = styles();
  const { handleClose, showDialog, maxWidth, userProfile } = props;
  return (
    <Dialog
      className={classes.rootDialog}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      maxWidth={maxWidth}
    >
      {userProfile.verificationLevel1 === false ? (
        <BasicVerifNotification
          message="You first need to complete Basic Verification"
          handleClose={handleRedirectMyProfileBasic}
        />
      ) : userProfile.verificationLevel2 === false ? (
        <BasicVerifNotification
          message="You second need to complete Advanced Verification"
          handleClose={handleRedirectMyProfileAdvanced}
        />
      ) : userProfile.isGoogleAuthenticator === false ? (
        <TwoFactorAuthNotification handleClose={handleRedirectSecurity} />
      ) : (
        RepaymentMethods()
      )}
      <RepaymentCurrency
        handleClose={handleCloseDialog}
        maxWidth="lg"
        showDialog={showDialogRepayment}
        repaymentMethods={repaymentMethods}
      />
    </Dialog>
  );
};
