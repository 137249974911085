import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputWithdraw } from '../share/InputWithdraw.component';
import { formatNumberToFixed } from '../../helper';

interface IProps {
  handleChangeInput: (
    key: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  loanCreditLine: any;
  maxValueUSDT: number;
  errorInput: boolean;
}

export const WithdrawUSDTCard = (props: IProps) => {
  const { handleChangeInput, loanCreditLine, maxValueUSDT, errorInput } = props;
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={6}>
          <InputWithdraw
            valueInput={loanCreditLine.usdt}
            contentLeft="Withdrawal Amount"
            iconInputLeft={true}
            title="USDT"
            name="usdt"
            type="number"
            errorInput={errorInput}
            placeHolderInput={
              errorInput === true
                ? 'Please, enter amount in range $500 => $2.000.000'
                : ''
            }
            minMaxInput={{
              min: '0',
              step: '0.01',
              max: String(maxValueUSDT)
            }}
            disabledInput={false}
            handleChangeInput={handleChangeInput}
          />
        </Grid>
        <Grid container item xs={6}>
          <Grid
            justify="center"
            alignItems="flex-end"
            container
            direction="column"
            item
            xs={6}
          >
            <Typography
              variant="h5"
              style={{ color: '#A5B2C0', fontWeight: 600 }}
            >
              Fixed
            </Typography>
            <Typography
              variant="h5"
              style={{ color: '#A5B2C0', fontWeight: 600 }}
            >
              Transaction Fee
            </Typography>
            <Typography variant="h5" style={{ fontWeight: 600 }}>
              You will get
            </Typography>
          </Grid>
          <Grid
            alignItems="flex-end"
            container
            direction="column"
            item
            style={{ marginTop: 20 }}
            xs={6}
          >
            <Typography
              variant="h5"
              style={{
                color: '#EB5757',
                fontWeight: 600,
                marginBottom: 10
              }}
            >
              $ 0.00
            </Typography>

            <Typography variant="h5" style={{ fontWeight: 600 }}>
              USDT{' '}
              {formatNumberToFixed(
                loanCreditLine.usdt ? loanCreditLine.usdt : 0,
                2
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
