import { ISelectInfo } from '../components/share/NexoSelect.compoent';

export const LOAD_TRANSACTION_ACTION = 'LOAD_TRANSACTION_ACTION';
export type LOAD_TRANSACTION_ACTION = typeof LOAD_TRANSACTION_ACTION;

export const SET_TRANSACTION_ACTION = 'SET_TRANSACTION_ACTION';
export type SET_TRANSACTION_ACTION = typeof SET_TRANSACTION_ACTION;

export const SET_ASSET_TRANSACTION_ACTION = 'SET_ASSET_TRANSACTION_ACTION';
export type SET_ASSET_TRANSACTION_ACTION = typeof SET_ASSET_TRANSACTION_ACTION;

export const LOAD_ASSET_TRANSACTION_ACTION = 'LOAD_ASSET_TRANSACTION_ACTION';
export type LOAD_ASSET_TRANSACTION_ACTION = typeof LOAD_ASSET_TRANSACTION_ACTION;

export const DataAllTypes: ISelectInfo[] = [
  {
    id: '0',
    title: 'All Type ',
    symbol: 'ALL'
  },
  {
    id: '1',
    title: 'Deposit',
    symbol: 'Deposit'
  },
  {
    id: '2',
    title: 'Asset',
    symbol: 'Asset'
  },
  {
    id: '3',
    title: 'Loan',
    symbol: 'Loan'
  },
  {
    id: '4',
    title: 'Repayment',
    symbol: 'Repayment'
  },
  {
    id: '5',
    title: 'Saving',
    symbol: 'Saving'
  }
];

export const DataAllAsset: ISelectInfo[] = [
  {
    id: '0',
    title: 'All Asset',
    symbol: 'ALL'
  },
  {
    id: '1',
    title: '(BTC) Bitcoin',
    symbol: 'BTC'
  },
  {
    id: '2',
    title: '(ETH) Ether',
    symbol: 'ETH'
  },
  {
    id: '5',
    title: '(USD) Dolar',
    symbol: 'USD'
  },
  {
    id: '6',
    title: '(USDT) Tether',
    symbol: 'USDT'
  },
  {
    id: '8',
    title: '(XRP) XRP',
    symbol: 'XRP'
  }
];

export enum TRANSACTION_STATUS {
  processing = '0',
  successful = '1',
  rejected = '2'
}

export enum TRANSACTION_TYPE {
  ALL = 'ALL',
  DEPOSIT = 'Deposit',
  REPAYMENT = 'Repayment',
  ASSET_WITHDRAWAL = 'Asset Withdrawal',
  LOAN_WITHDRAWAL = 'Loan Withdrawal',
  EARNED_INTEREST = 'Earned Interest',
  LOAN_INTEREST = 'Loan Interest',
  AUTO_LOAN_REPAYMENT = 'Partial Automatic Loan Repayment',
  ACCOUNTING = 'Accounting',
  DIVIDEND = 'Dividend',
  GATHERING_ASSET = 'Gathering Asset',
  SAVING = 'Saving',
  SAVING_INTEREST = 'Saving Interest',
  SAVING_WITHDRAWAL = 'Saving Withdrawal',
  SAVING_INTEREST_WITHDRAWAL = 'Saving Interest Withdrawal'
}

export enum SYMBOL_DECIMAL {
  USD = 2,
  USDT = 2,
  XRP = 6
}
