import { ISelectCurrencyInfo } from '../types/calculator.type';

export const LOAD_CALCULATOR_ACTION = 'LOAD_CALCULATOR_ACTION';
export type LOAD_CALCULATOR_ACTION = typeof LOAD_CALCULATOR_ACTION;

export const SET_CALCULATOR_ACTION = 'SET_CALCULATOR_ACTION';
export type SET_CALCULATOR_ACTION = typeof SET_CALCULATOR_ACTION;

export const LOAD_RATES_ACTION = 'LOAD_RATES_ACTION';
export type LOAD_RATES_ACTION = typeof LOAD_RATES_ACTION;

export const SET_RATES_ACTION = 'SET_RATES_ACTION';
export type SET_RATES_ACTION = typeof SET_RATES_ACTION;

export const LOAD_INTEREST_ACTION = 'LOAD_INTEREST_ACTION';
export type LOAD_INTEREST_ACTION = typeof LOAD_INTEREST_ACTION;

export const SET_INTEREST_ACTION = 'SET_INTEREST_ACTION';
export type SET_INTEREST_ACTION = typeof SET_INTEREST_ACTION;

export const dataSelectCurrency: ISelectCurrencyInfo[] = [
  {
    id: 1,
    symbol: 'BTC',
    name: 'Bitcoin',
    active: true
  },
  {
    id: 2,
    symbol: 'ETH',
    name: 'Ethereum',
    active: true
  },
  {
    id: 3,
    symbol: 'XRP',
    name: 'Ripple',
    active: true
  }
];
