import INotificationInfo, {
  IOjectNotificationMenu
} from '../types/Notification.type';
import Variant from '../types/Variant.type';
import {
  IUpdateNotification,
  IEaseNotification,
  ISetNotificationMenuAction
} from '../actions/Notification.action';
import {
  UPDATE_NOTIFICATION,
  EASE_NOTIFICATION,
  SET_NOTIFICATION_MENU_ACTION
} from '../constants/Notification.constant';

const initState: INotificationInfo = {
  isOpen: false,
  variant: Variant.SUCCESS,
  message: ''
};

export function notification(
  state = initState,
  action: IUpdateNotification | IEaseNotification
): INotificationInfo {
  switch (action.type) {
    case UPDATE_NOTIFICATION:
      return action.payload;
    case EASE_NOTIFICATION:
      return {
        ...state,
        isOpen: false
      };
  }
  return state;
}

const emptyOjectNotification: IOjectNotificationMenu = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0
  }
};

export const notificationMenu = (
  state: IOjectNotificationMenu = emptyOjectNotification,
  action: ISetNotificationMenuAction
): IOjectNotificationMenu => {
  if (action.type === SET_NOTIFICATION_MENU_ACTION) {
    return action.payload;
  }
  return state;
};
