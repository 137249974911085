import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import qrCode from '../../assets/images/qrCode.svg';
import { NexoButton } from '../share';
import {
  IDataGoogleAuthenticatorInfo,
  IProcessGoogleAuthRequest
} from '../../types/user.type';
import { IVerifyCode } from '../../types/auth.type';
import { NotificationPaperComponent } from '../share/NotificationPaper.component';
import Variant from '../../types/Variant.type';
import { NexoInputVerifyCode } from '../share/NexoInputVerifyCode.component';

interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  handleCloseDialog: () => void;
  dataGoogleAuthenticator?: IDataGoogleAuthenticatorInfo;
  setSecondFactorCode?: (request: IProcessGoogleAuthRequest) => void;
  handleLoginTwoFactorEnabled?: (request: IVerifyCode) => void;
  loginTwoFactorEnabled?: IVerifyCode;
  showContent: boolean;
  showIsVerify?: boolean;
}

interface IState {
  code: string;
}
const styles = () =>
  createStyles({
    dialogTitle: {
      height: 80,
      padding: 0
    },
    closeButton: {
      position: 'absolute',
      right: 30,
      top: 28,
      color: 'black'
    },
    dialogContent: {
      height: 'calc(100% - 260px )',
      paddingLeft: 30,
      paddingRight: 30,
      borderBottom: '1px solid #F1F1F4'
    },
    dialogActions: {
      height: 100,
      borderTop: '1px solid #DCE0E4'
    },
    buttonYesWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize'
    },
    buttonNoWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize',
      backgroundColor: '#C5021C',
      '&:hover': {
        backgroundColor: '#C5021C'
      }
    },
    textWithDraw: {
      fontSize: 20,
      color: '#000000',
      lineHeight: '24px',
      fontWeight: 600
    },
    code: {
      textAlign: 'center',
      color: '#222222',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 40,
      lineHeight: '40px',
      letterSpacing: '0.3em'
    },
    // buttonCanel: {
    // 	color: '#222222',
    // 	border: '1px solid #DCE0E4',
    // 	width: 195,
    // 	textTransform: 'capitalize',
    // 	marginRight: 5,
    // 	backgroundColor: '#FFFFFF',
    // 	'&:hover': {
    // 		backgroundColor: '#FFFFFF'
    // 	}
    // },
    buttonLogin: {
      width: 195,
      textTransform: 'capitalize',
      marginLeft: 5
    },
    textAllYou: {
      color: '#050F47'
    },
    textGoogle: {
      color: '#3E399F',
      fontWeight: 600,
      cursor: 'pointer'
    },
    imgQrcode: {
      width: 200,
      height: 200,
      border: '1px solid #DCE0E4',
      padding: 15,
      boxSizing: 'border-box',
      borderRadius: 3,
      backgroundColor: '#FAFAFB'
    },
    buttonCancel: {
      width: 150,
      marginRight: 7
    },
    buttonVerify: {
      width: 150
    },
    buttonCalculator: {
      width: 150,
      height: 40,
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 12,
      background:
        'linear-gradient(149.76deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(142.01deg, #67ACF1 -10.95%, #156EE4 104.81%)',
      boxShadow: 'none',
      textTransform: 'capitalize',
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'none'
      }
    },
    buttonCanel: {
      width: 150,
      height: 40,
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 12,
      background: '#FFFFFF',
      boxShadow: 'none',
      textTransform: 'capitalize',
      borderRadius: 5,
      border: '1px solid #DCE0E4',
      color: '#363C42',
      '&:hover': {
        backgroundColor: '#363C42',
        boxShadow: 'none',
        color: '#FFFFFF'
      }
    }
  });

class TwoAuthentication extends React.Component<IProps, IState> {
  public state: IState = {
    code: ''
  };

  public handleTextChangeCode = (codeInput: string) => {
    this.setState({ code: codeInput });
  };

  clearText = () => {
    this.setState({ code: '' });
  };

  closeDialog = () => {
    this.clearText();
    this.props.handleCloseDialog();
  };

  public verifyCode = () => {
    const { showContent } = this.props;
    if (this.state.code.length < 6) {
      return;
    }
    // tslint:disable-next-line
    this.props.setSecondFactorCode &&
      this.props.setSecondFactorCode({
        code: this.state.code,
        off: !showContent
      });
    this.clearText();
  };

  public loginTwoFactorEnabled = () => {
    const { loginTwoFactorEnabled, handleLoginTwoFactorEnabled } = this.props;
    if (loginTwoFactorEnabled && handleLoginTwoFactorEnabled) {
      // tslint:disable-next-line
      handleLoginTwoFactorEnabled &&
        handleLoginTwoFactorEnabled({
          email: loginTwoFactorEnabled.email,
          password: loginTwoFactorEnabled.password,
          code: this.state.code
        });
      this.clearText();
    }
  };

  public render() {
    const {
      showDialog,
      classes,
      dataGoogleAuthenticator,
      showContent,
      handleCloseDialog,
      showIsVerify
    } = this.props;
    return (
      <Dialog
        onClose={this.closeDialog}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        maxWidth="lg"
      >
        <Grid className={classes.dialogTitle}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              height: '100%',
              position: 'relative',
              borderBottom: '1px solid #DCE0E4'
            }}
          >
            <Typography variant="subtitle1" style={{ color: '#050F47' }}>
              Two-Factor Authentication
            </Typography>
          </Grid>
        </Grid>
        {showIsVerify ? (
          <div>
            {showContent ? (
              <Grid
                container
                justify="center"
                alignContent="center"
                className={classes.dialogContent}
              >
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  style={{ marginTop: 5 }}
                >
                  <Typography variant="h4">
                    Two-Factor authentication increases
                  </Typography>
                </Grid>
                <Grid container justify="center" item xs={12}>
                  <Typography variant="h4">
                    the security of your account.
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  style={{ marginTop: 14 }}
                >
                  <Typography variant="h5" className={classes.textAllYou}>
                    All you need is a compatible app on your smartphone, for
                    example:
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  style={{ marginTop: 5 }}
                >
                  <Typography variant="h5" className={classes.textGoogle}>
                    Google Authenticator
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  style={{ marginTop: 12 }}
                >
                  <Grid className={classes.imgQrcode}>
                    <img
                      src={
                        dataGoogleAuthenticator &&
                        dataGoogleAuthenticator.dataURI
                          ? dataGoogleAuthenticator.dataURI
                          : qrCode
                      }
                      alt="icon qrcode"
                      width="100%"
                      height="100%"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  style={{ marginTop: 16 }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      color: '#A5B2C0'
                    }}
                  >
                    If you have any problems with the QR code,
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="center"
                  item
                  xs={12}
                  style={{ marginTop: 5 }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      color: '#A5B2C0'
                    }}
                  >
                    enter this code manually in to the app:
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignContent="center"
                  justify="center"
                  style={{
                    height: 50,
                    background: '#FAFAFB',
                    border: '1px solid #DCE0E4',
                    boxSizing: 'border-box',
                    borderRadius: 3,
                    marginTop: 5
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      cursor: 'pointer',
                      fontWeight: 500
                    }}
                  >
                    {dataGoogleAuthenticator && dataGoogleAuthenticator.secret
                      ? dataGoogleAuthenticator.secret
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            <Grid
              container
              justify="center"
              alignContent="center"
              style={{ height: 190, paddingLeft: 30, paddingRight: 30 }}
            >
              <Grid container justify="center" alignContent="center">
                <Grid container justify="center" item xs={12}>
                  <Typography variant="h4">
                    Enter the two-step Authentication code
                  </Typography>
                </Grid>

                <NexoInputVerifyCode
                  handleTextChangeCode={this.handleTextChangeCode}
                />
              </Grid>
              <Grid container justify="center" style={{ marginTop: 20 }}>
                <Grid className={classes.buttonCancel}>
                  <NexoButton
                    heightButton={40}
                    title="Cancel"
                    variant="outlined"
                    color="primary"
                    handleRedirect={handleCloseDialog}
                  />
                </Grid>

                <Grid className={classes.buttonVerify}>
                  <NexoButton
                    heightButton={40}
                    title="Verify code"
                    variant="contained"
                    color="primary"
                    handleRedirect={
                      this.props.setSecondFactorCode
                        ? this.verifyCode
                        : this.loginTwoFactorEnabled
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ margin: 40 }}>
            <NotificationPaperComponent
              notification={Variant.ERROR}
              title="Please complete the email verification first"
            />
            <Grid container justify="center" style={{ marginTop: 20 }}>
              <Grid className={classes.buttonCancel}>
                <NexoButton
                  heightButton={40}
                  title="Ok"
                  variant="outlined"
                  color="primary"
                  handleRedirect={handleCloseDialog}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Dialog>
    );
  }
}

export default connect(
  undefined,
  undefined
)(withStyles(styles)(TwoAuthentication));
