import React from 'react';
import {
  Grid,
  Typography,
  InputAdornment,
  Input,
  makeStyles
} from '@material-ui/core';
import classNames from 'classnames';
const styles = makeStyles({
  inputNormal: {
    border: '1px solid #D7DCE0',
    height: 50,
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: 'Montserrat',
    '&:hover': {
      border: '1px solid #34CEA9'
    },
    '& .MuiInputAdornment-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 50,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      margin: '0 auto',
      padding: '10px 0px',
      height: 50,
      fontWeight: 600
    },
    '& .MuiInput-input': {
      backgroundColor: '#fff',
      height: 46,
      padding: '0px 10px',
      borderRadius: '0px 5px 5px 0px',
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
      color: '#050F47'
    }
  },
  inputError: {
    border: '1px solid #F2994A !important'
  },
  inputDisabled: {
    backgroundColor: '#F9F9FB',
    '&:hover': {
      border: '1px solid #D7DCE0'
    },
    '& .MuiInput-input': {
      backgroundColor: '#F9F9FB !important'
    }
  }
});

interface IProps {
  contentLeft: string;
  iconInputLeft: boolean;
  disabledInput: boolean;
  title?: string;
  name: string;
  type?: string;
  valueInput: any;
  errorInput?: boolean;
  placeHolderInput?: string;
  minMaxInput?: {
    min: string;
    max: string;
    step: string;
  };
  handleChangeInput?: (
    key: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputWithdraw = (props: IProps) => {
  const {
    contentLeft,
    iconInputLeft,
    disabledInput,
    title,
    valueInput,
    handleChangeInput,
    name,
    type,
    minMaxInput,
    errorInput,
    placeHolderInput
  } = props;
  const classes = styles();
  return (
    <Grid style={{ width: '100%' }}>
      <Typography variant="h5" style={{ fontSize: 14 }}>
        {contentLeft}
      </Typography>
      <Input
        style={{ marginTop: 5 }}
        className={classNames(
          classes.inputNormal,
          disabledInput
            ? classes.inputDisabled
            : errorInput
            ? classes.inputError
            : ''
        )}
        inputProps={{
          min: minMaxInput?.min,
          max: minMaxInput?.max,
          step: minMaxInput?.step
        }}
        onChange={handleChangeInput && handleChangeInput(name)}
        disableUnderline={true}
        disabled={disabledInput}
        error={errorInput}
        value={valueInput}
        type={type ? type : ''}
        startAdornment={
          iconInputLeft ? (
            <InputAdornment position="start">
              <Typography variant="h4" style={{ fontSize: 12 }}>
                {title}
              </Typography>
            </InputAdornment>
          ) : null
        }
      />
      <Typography
        variant="h4"
        style={{
          color: '#F2994A',
          fontSize: 12,
          marginTop: 5,
          fontStyle: 'italic'
        }}
      >
        {placeHolderInput}
      </Typography>
    </Grid>
  );
};
