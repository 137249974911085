import React from 'react';
import {
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Typography
} from '@material-ui/core';
import iconsucces from '../../assets/images/iconsuccesDialog.svg';
import iconError from '../../assets/images/iconErrorDialog.svg';
import Variant from '../../types/Variant.type';

const styles = () =>
  createStyles({
    rootSusses: {
      height: 50,
      border: '1px solid #34CEA9',
      background: 'rgba(52, 206, 169, 0.05)',
      boxSizing: 'border-box',
      borderRadius: 5
    },
    rootError: {
      height: 50,
      border: '1px solid #EB5757',
      background: 'rgba(235, 87, 87, 0.05)',
      boxSizing: 'border-box',
      borderRadius: 5
    },
    iconsucces: {
      marginLeft: 21,
      marginRight: 15
    },
    textTitle: {
      color: '#34CEA9',
      fontWeight: 500
    },
    textTitleError: {
      color: '#EB5757',
      fontWeight: 500
    },
    widthRoot: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center'
    }
  });
interface IProps extends WithStyles<typeof styles> {
  notification: Variant;
  title: string;
}

class NotificationPaper extends React.Component<IProps> {
  public render() {
    const { classes, notification, title } = this.props;
    return (
      <div className={classes.widthRoot}>
        {notification === Variant.SUCCESS ? (
          <Grid container alignItems="center" className={classes.rootSusses}>
            <img
              src={iconsucces}
              width={15}
              height={15}
              className={classes.iconsucces}
              alt="icon succes"
            />
            <Typography variant="h4" className={classes.textTitle}>
              {title}
            </Typography>
          </Grid>
        ) : (
          <Grid container alignItems="center" className={classes.rootError}>
            <img
              src={iconError}
              width={15}
              height={15}
              className={classes.iconsucces}
              alt="icon error"
            />
            <Typography variant="h4" className={classes.textTitleError}>
              {title}
            </Typography>
          </Grid>
        )}
      </div>
    );
  }
}

export const NotificationPaperComponent = withStyles(styles)(NotificationPaper);
