import React from 'react';
import { Grid, Typography } from '@material-ui/core';

interface IProps {
  title: string;
  value: string;
}

export const WithdrawApprovalContent = (props: IProps) => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ marginTop: 20 }}
    >
      <Grid item xs={6}>
        <Typography variant="h5" style={{ fontSize: 14, fontStyle: 'italic' }}>
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={6} container justify="flex-end">
        <Typography variant="h4" style={{ fontSize: 16 }}>
          {props.value}
        </Typography>
      </Grid>
    </Grid>
  );
};
