import {
  LOAD_CALCULATOR_ACTION,
  SET_CALCULATOR_ACTION,
  LOAD_RATES_ACTION,
  SET_RATES_ACTION,
  LOAD_INTEREST_ACTION,
  SET_INTEREST_ACTION
} from '../constants/culculator.constant';
import { Action } from 'redux';
import {
  IObjectCalculatorInfo,
  IRatesInfo,
  IInterestInfo
} from '../types/calculator.type';

export interface ILoadCalculatorAction extends Action<LOAD_CALCULATOR_ACTION> {}

export const loadCalculaotrAction = (): ILoadCalculatorAction => ({
  type: LOAD_CALCULATOR_ACTION
});

export interface ISetCalculatorAction extends Action<SET_CALCULATOR_ACTION> {
  payload: IObjectCalculatorInfo;
}

export const setCalculatorAction = (
  culculatorManagement: IObjectCalculatorInfo
): ISetCalculatorAction => ({
  type: SET_CALCULATOR_ACTION,
  payload: culculatorManagement
});

export interface ILoadRatesAction extends Action<LOAD_RATES_ACTION> {}

export const loadRatesAction = (): ILoadRatesAction => ({
  type: LOAD_RATES_ACTION
});

export interface ISetRatesAction extends Action<SET_RATES_ACTION> {
  payload: IRatesInfo[];
}

export function setRatesAction(rateManagement: IRatesInfo[]): ISetRatesAction {
  return {
    type: SET_RATES_ACTION,
    payload: rateManagement
  };
}

export interface ILoadInterestAction extends Action<LOAD_INTEREST_ACTION> {}

export const loadInterestAction = (): ILoadInterestAction => ({
  type: LOAD_INTEREST_ACTION
});

export interface ISetInterestAction extends Action<SET_INTEREST_ACTION> {
  payload: IInterestInfo[];
}

export function setInterestAction(
  interestManagement: IInterestInfo[]
): ISetInterestAction {
  return {
    type: SET_INTEREST_ACTION,
    payload: interestManagement
  };
}
