import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TablePagination,
  Typography,
  makeStyles
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { IStoreState } from '../types';
import PaginationComponent from './table/component/Pagination.component';
import { loadUserLoginHistoryAction } from '../actions/user.action';
import { LoadingPage } from './share/LoadingPage';
import { formatDateTime } from '../helper';

const styles = makeStyles({
  tableHead: {
    paddingLeft: 0,
    fontSize: 12,
    color: '#3E399F !important',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    borderBottom: '1px dashed #B2B6BA'
  },
  tableBody: {
    paddingLeft: 0,
    fontSize: 12,
    color: '#050F47 !important',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    borderBottom: 'none'
  },
  rootPagination: {
    borderTop: '1px dashed #B2B6BA',
    '& .MuiIconButton-root': {
      border: '1px solid #F2F5F8',
      boxSizing: 'border-box',
      borderRadius: 3,
      padding: 0,
      width: 30,
      height: 30,
      marginLeft: 10
    }
  },
  rootHeaderSecurity: {
    background: '#F9FAFB',
    height: 80,
    borderBottom: '1px solid #D7DCE0',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  textSecurity: {
    color: '#050F47',
    marginLeft: 30
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px dashed #B2B6BA',
    '& .MuiTypography-colorInherit': {
      display: 'none'
    },
    '& .MuiTablePagination-input': {
      display: 'none'
    },
    '& .MuiTablePagination-toolbar': {
      padding: 0
    }
  }
});

export const LastLoginTable = () => {
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const userLoginHistory = useSelector(
    (state: IStoreState) => state.userManagement.userLoginHistory
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loadUserLoginHistoryAction({
        limit: 10,
        offset: 0
      })
    );
    setTimeout(() => {
      setLoadingPage(true);
    }, 800);
  }, [dispatch]);

  const handleChangePage = (event: any, page: number) => {
    dispatch(
      loadUserLoginHistoryAction({
        limit: userLoginHistory.meta.limit,
        offset: page * userLoginHistory.meta.limit
      })
    );
  };

  const classes = styles();
  return (
    <div>
      <Grid
        container
        alignItems="center"
        className={classes.rootHeaderSecurity}
      >
        <Typography variant="subtitle1" className={classes.textSecurity}>
          Last Login
        </Typography>
      </Grid>
      {loadingPage ? (
        <Grid style={{ padding: '0px 30px' }}>
          <Grid
            data-aos="fade-up"
            data-aos-offset="300"
            data-aos-duration="300"
          >
            <Table>
              <TableHead>
                <TableRow style={{ width: '100%' }}>
                  <TableCell
                    className={classes.tableHead}
                    style={{
                      width: '40%'
                    }}
                  >
                    Time
                  </TableCell>
                  <TableCell
                    className={classes.tableHead}
                    style={{ width: '20%' }}
                  >
                    Location
                  </TableCell>
                  <TableCell
                    className={classes.tableHead}
                    align="right"
                    style={{ width: '40%', paddingRight: 0 }}
                  >
                    IP Address
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userLoginHistory.data.map(el => (
                  <TableRow key={el.id}>
                    <TableCell className={classes.tableBody}>
                      {formatDateTime(el.createdAt)}
                    </TableCell>
                    <TableCell className={classes.tableBody}>
                      {el.location}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ paddingRight: 0 }}
                      className={classes.tableBody}
                    >
                      {el.ipAddress}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              className={classes.pagination}
              count={
                userLoginHistory.meta.total ? userLoginHistory.meta.total : 0
              }
              rowsPerPage={
                userLoginHistory.meta.limit ? userLoginHistory.meta.limit : 10
              }
              page={
                userLoginHistory.meta.currentPage
                  ? userLoginHistory.meta.currentPage - 1
                  : 0
              }
              onChangePage={handleChangePage}
              ActionsComponent={PaginationComponent}
            />
          </Grid>
        </Grid>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
};
