import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { IMAGE } from '../../constants/image.constant';
import iconAdvanced from '../../assets/images/iconAdvanced.svg';
import { NexoButton } from '../share';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../actions/auth.action';

const styles = makeStyles({
  rootAdvanced: {
    height: 50,
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5,
    display: 'grid',
    gridTemplateColumns: '50px calc(100% - 50px)',
    marginTop: 20,
    marginBottom: 20
  },
  iconAdvanced: {
    paddingLeft: 18,
    paddingRight: 8
  },
  textAdvanced: {
    color: '#F2994A'
  },
  buttonCancel: {
    width: 150
  },
  buttonVerify: {
    width: 150,
    marginLeft: 10
  }
});

interface IProps {
  handleOpenVerify: () => void;
}

export const NotificationVerifyPhonePage = (props: IProps) => {
  const classes = styles();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutAction());
  };
  return (
    <Grid style={{ marginTop: 30 }}>
      <Grid container justify="center">
        <img
          src={IMAGE.VERIFY_PHONE}
          alt="icon verify phone"
          width={200}
          height={200}
        />
      </Grid>
      <Grid container alignItems="center" className={classes.rootAdvanced}>
        <img
          src={iconAdvanced}
          alt="icon advanced"
          width={15}
          height={15}
          className={classes.iconAdvanced}
        />
        <Typography variant="h5" className={classes.textAdvanced}>
          You have to verify your number to continue using
        </Typography>
      </Grid>

      <Grid container justify="center" style={{ marginBottom: 30 }}>
        <Grid className={classes.buttonCancel}>
          <NexoButton
            heightButton={40}
            title="Logout"
            variant="outlined"
            color="primary"
            handleRedirect={handleLogout}
          />
        </Grid>

        <Grid className={classes.buttonVerify}>
          <NexoButton
            title="Verify now"
            variant="contained"
            color="primary"
            heightButton={40}
            handleRedirect={props.handleOpenVerify}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
