import React from 'react';
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { NexoButton } from '.';
import { IUploadImagesForVerifyRequest } from '../../types/user.type';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      width: '100%'
    },
    top: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 20
    },
    box: {
      border: '3px dashed #d7dce0',
      marginLeft: 10,
      marginRight: 10,
      position: 'relative',
      '& .MuiIconButton-root': {
        color: '#4867ca',
        position: 'absolute',
        right: '-10px',
        top: '-8px',
        padding: '0px'
      }
    },
    bottom: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      cursor: 'pointer',
      borderRadius: 5,
      textAlign: 'center',
      '& .MuiButton-root': {
        width: 250
      },
      '& input[type=file]': {
        fontSize: 42
      }
    },
    input: {
      fontSize: '100px',
      position: 'absolute',
      left: 0,
      top: 0,
      opacity: 0,
      cursor: 'pointer'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  maxFiles: number;
  handleUploadFile: (files: IUploadImagesForVerifyRequest) => void;
}

interface IState {
  files: any;
}

class NexoUploadFileComponent extends React.Component<IProps, IState> {
  public state: IState = {
    files: []
  };

  public fileSelectedHandler = (e: any) => {
    const { files } = this.state;
    const { maxFiles } = this.props;
    const currentFiles = files;
    if (e.target.files) {
      // eslint-disable-next-line
      Object.entries(e.target.files).map(([key, file], index) => {
        if (currentFiles.length < maxFiles) {
          currentFiles.push(file);
        }
      });
    }
    this.setState({ files: currentFiles });
  };

  public handleDeleteFile = (key: any) => (e: any) => {
    const { files } = this.state;
    const currentFiles = files;
    currentFiles.splice(key, 1);
    this.setState({
      files: currentFiles
    });
  };

  public handleRedirectUploadFile = () => {
    const { files } = this.state;
    this.props.handleUploadFile(files);
    this.setState({ files: [] });
  };

  render() {
    const { classes, maxFiles } = this.props;
    const { files } = this.state;
    return (
      <div className={classes.root}>
        <Grid className={classes.top}>
          {this.state.files &&
            [...this.state.files].map((file, key) => (
              <div key={key} className={classes.box}>
                <img
                  width="80"
                  height="100"
                  src={URL.createObjectURL(file)}
                  alt="icon"
                />
                <IconButton
                  onClick={this.handleDeleteFile(key)}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CancelIcon />
                </IconButton>
              </div>
            ))}
        </Grid>
        {files && files.length < maxFiles ? (
          <Grid className={classes.bottom}>
            <NexoButton
              title={`Select ${maxFiles} Files`}
              variant="contained"
              color="secondary"
            />
            <input
              multiple
              onChange={this.fileSelectedHandler}
              accept="image/*"
              type="file"
              className={classes.input}
            />
          </Grid>
        ) : (
          <Grid className={classes.bottom}>
            <NexoButton
              title="Upload File"
              variant="contained"
              color="primary"
              handleRedirect={this.handleRedirectUploadFile}
            />
          </Grid>
        )}
      </div>
    );
  }
}
export const NexoUploadFile = withStyles(styles)(NexoUploadFileComponent);
