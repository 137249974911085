import { Action } from 'redux';
import { IWithdrawAssetRequest } from '../types/withdrawAsset.type';
import {
  WITHDRAW_ASSET_ACTION,
  WITHDRAW_ASSET_APPROVED_ACTION,
  WITHDRAW_SAVING_APPROVED_ACTION
} from '../constants/withdrawAsset.constant';

export interface IWithdrawAssetAction extends Action<WITHDRAW_ASSET_ACTION> {
  payload: IWithdrawAssetRequest;
}

export const withdrawAssetAction = (
  request: IWithdrawAssetRequest
): IWithdrawAssetAction => ({
  type: WITHDRAW_ASSET_ACTION,
  payload: request
});

export interface IWithdrawAssetApprovedAction
  extends Action<WITHDRAW_ASSET_APPROVED_ACTION> {
  payload: string;
}

export const approveAssetWithdrawalAction = (
  request: string
): IWithdrawAssetApprovedAction => ({
  type: WITHDRAW_ASSET_APPROVED_ACTION,
  payload: request
});

export interface IWithdrawSavingApprovedAction
  extends Action<WITHDRAW_SAVING_APPROVED_ACTION> {
  payload: string;
}

export const approveSavingWithdrawalAction = (
  request: string
): IWithdrawSavingApprovedAction => ({
  type: WITHDRAW_SAVING_APPROVED_ACTION,
  payload: request
});
