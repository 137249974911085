import React, { useState, useEffect } from 'react';
import {
  Badge,
  IconButton,
  makeStyles,
  Menu,
  Grid,
  Typography
} from '@material-ui/core';
import Notifications from '@material-ui/icons/Notifications';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import notificationHeader from '../assets/images/notificationHeader.png';
import { useSelector, useDispatch } from 'react-redux';
import { IStoreState } from '../types';
import {
  loadNotificationMenuAction,
  notificationMenuTurnOff,
  notificationMenuTurnOffAll
} from '../actions/Notification.action';
import { countNotificationMenu, formatDateTime } from '../helper';
import Scrollbars from 'react-custom-scrollbars';

const styles = makeStyles({
  rootBadge: {
    borderRadius: 35,
    marginRight: 10,
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      top: 5,
      right: 10
    },
    '& .MuiBadge-colorPrimary': {
      backgroundColor: '#050F47'
    }
  },
  buttonActive: {
    width: 30,
    height: 30,
    margin: '0 5px',
    padding: 0,
    cursor: 'pointer',
    color: '#050F47'
  },
  button: {
    width: 30,
    height: 30,
    margin: '0 5px',
    padding: 0,
    cursor: 'pointer',
    color: '#a5b2cb'
  },
  menuNotification: {
    '& .MuiList-padding': {
      padding: 0
    }
  },
  rootChip: {
    marginBottom: 10,
    '& .MuiChip-outlinedPrimary': {
      color: '#A5B2C0',
      border: 'transparent',
      borderBottom: '1px dashed #B2B6BA'
    },
    '& .MuiChip-root': {
      borderRadius: 'inherit',
      fontSize: 11
    },
    '& .MuiButtonBase-root': {
      justifyContent: 'left'
    },
    '& .MuiChip-root .MuiChip-avatarColorPrimary': {
      color: '#3E399F',
      backgroundColor: '#F9FAFB',
      border: '1px solid #3E399F'
    },
    '& .MuiChip-label': {
      color: '#050F47',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: 12
    },
    '& .MuiChip-clickable.MuiChip-outlinedPrimary': {
      '&:hover': {
        backgroundColor: 'transparent !important'
      }
    }
  },
  rootPageAllNotification: {
    cursor: 'pointer',
    '& :hover': {
      border: '1px solid #34CEA9',
      boxSizing: 'border-box',
      borderRadius: 5
    }
  },
  contentBorderMenu: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f9fafb'
    }
  },
  newNotification: {
    display: 'flex',
    alignItems: 'center',
    transform: 'scale(1)',
    '&:after': {
      content: "''",
      backgroundColor: 'red',
      position: 'absolute',
      width: 10,
      height: 10,
      borderRadius: 10
    }
  },
  viewContent: {
    color: '#A5B2C0'
  },
  buttonApprove: {
    color: '#34CEA9 !important',
    padding: 0,
    border: '1px solid #34CEA9'
  },
  buttonResult: {
    color: '#EB5757 !important',
    padding: 0,
    border: '1px solid #EB5757'
  },
  borderBottomContent: {
    borderBottom: '1px dashed #B2B6BA'
  },
  heightViewAllActive: {
    height: '500px !important'
  },
  heightViewAll: {
    height: '160px !important'
  }
});

export const NotificationMenu = () => {
  const [showNotification, setShowNotification] = useState(null);

  const [showViewAll, setShowViewAll] = useState(false);

  const notificationMenu = useSelector(
    (state: IStoreState) => state.notificationMenu
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loadNotificationMenuAction({
        limit: 50,
        offset: 0
      })
    );
    const loadNotificationMenu = setInterval(() => {
      dispatch(
        loadNotificationMenuAction({
          limit: 50,
          offset: 0
        })
      );
    }, 60000);
    return () => {
      clearInterval(loadNotificationMenu);
    };
  }, [dispatch]);

  const handleClickNotificationsActive = (event: any) => {
    setShowNotification(event.currentTarget);
  };

  const handleClose = () => {
    setShowNotification(null);
  };

  const turnOffNotification = (id: string) => () => {
    dispatch(notificationMenuTurnOff(id));
  };

  const turnOffNotificationAll = () => {
    dispatch(notificationMenuTurnOffAll());
  };
  const getIconNotification = (content: string, configs: string) => {
    const searchContent = content.match(configs);
    if (searchContent) {
      return searchContent[0];
    }
  };
  const handleViewAllNotification = () => {
    if (showViewAll === false) {
      setShowViewAll(true);
    } else {
      setShowViewAll(false);
    }
  };
  const classes = styles();

  return (
    <React.Fragment>
      <Badge
        badgeContent={countNotificationMenu(notificationMenu.data)}
        color="primary"
        className={classes.rootBadge}
      >
        <IconButton
          className={
            Boolean(showNotification) ? classes.buttonActive : classes.button
          }
          onClick={handleClickNotificationsActive}
        >
          <Notifications />
        </IconButton>
      </Badge>

      <Menu
        anchorEl={showNotification}
        keepMounted
        style={{
          top: 45
        }}
        open={Boolean(showNotification)}
        onClose={handleClose}
        className={classes.menuNotification}
      >
        <Grid
          style={{
            width: 400,
            outlineColor: 'transparent'
          }}
        >
          <Grid
            container
            alignItems="center"
            style={{
              height: 50,

              borderBottom: '1px solid #D7DCE0',
              paddingLeft: 15,
              paddingRight: 15
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h4" style={{ color: '#050F47' }}>
                Notifications
              </Typography>
            </Grid>
            <Grid
              className={classes.rootPageAllNotification}
              container
              item
              xs={6}
              justify="flex-end"
              onClick={turnOffNotificationAll}
            >
              <Typography
                variant="h5"
                style={{ fontStyle: 'italic', padding: '5px 10px' }}
              >
                Mark All as Read
              </Typography>
            </Grid>
          </Grid>
          <Scrollbars
            style={{
              overflow: 'hidden',
              width: '100%'
            }}
            className={
              showViewAll ? classes.heightViewAllActive : classes.heightViewAll
            }
          >
            <Grid style={{ paddingTop: 20, paddingBottom: 20 }}>
              {notificationMenu.data.length > 0 ? (
                notificationMenu.data.map((el, index) => (
                  <Grid
                    key={index}
                    className={classes.contentBorderMenu}
                    onClick={turnOffNotification(el.id)}
                    style={{
                      height: 60,
                      display: 'grid',

                      paddingLeft: 20,
                      paddingRight: 20,
                      gridTemplateColumns: '5px 50px calc(100% - 55px)'
                    }}
                  >
                    <Grid
                      className={
                        el.isView === false ? classes.newNotification : ''
                      }
                    />
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ position: 'relative' }}
                    >
                      <Grid
                        style={{
                          border: '1px solid #D7DCE0',
                          height: 10,
                          position: 'absolute',
                          top: 0
                        }}
                      />
                      <IconButton
                        disabled={true}
                        className={
                          getIconNotification(el.content, 'denied') === 'denied'
                            ? classes.buttonResult
                            : classes.buttonApprove
                        }
                      >
                        {getIconNotification(el.content, 'denied') ===
                        'denied' ? (
                          <Cancel />
                        ) : (
                          <CheckCircle />
                        )}
                      </IconButton>
                      <Grid
                        style={{
                          border: '1px solid #D7DCE0',
                          height: 10,
                          position: 'absolute',
                          bottom: 0
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      className={
                        index + 1 === notificationMenu.data.length
                          ? ''
                          : classes.borderBottomContent
                      }
                    >
                      <Typography
                        variant="h4"
                        className={
                          el.isView === true ? classes.viewContent : ''
                        }
                        style={{ fontWeight: 500, fontSize: 12 }}
                      >
                        {el.content}
                      </Typography>
                      <Typography
                        variant="h4"
                        style={{
                          fontWeight: 500,
                          fontSize: 10,
                          fontStyle: 'italic',
                          color: '#A5B2C0'
                        }}
                      >
                        {formatDateTime(el.createdAt)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Grid style={{ textAlign: 'center', margin: 30 }}>
                  <img
                    data-aos-anchor-placement="bottom-bottom"
                    src={notificationHeader}
                    alt="notification"
                    width={150}
                    height={150}
                    style={{ marginRight: 45 }}
                  />
                  <Typography
                    variant="h5"
                    style={{ marginTop: 5, fontStyle: 'italic' }}
                  >
                    No new notifications
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Scrollbars>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              height: 50,
              borderTop: '1px solid #D7DCE0',
              paddingLeft: 15,
              paddingRight: 15
            }}
            onClick={handleViewAllNotification}
            className={classes.rootPageAllNotification}
          >
            <Typography
              variant="h5"
              style={{ fontStyle: 'italic', padding: '5px 10px' }}
            >
              {!showViewAll ? 'See All in Messenger' : 'Close All in Messenger'}
            </Typography>
          </Grid>
        </Grid>
      </Menu>
    </React.Fragment>
  );
};
