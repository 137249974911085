import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { NexoItemCoinCredit, NexoButton } from '../share';
import iconAdvanced from '../../assets/images/iconAdvanced.svg';

const styles = makeStyles({
  dialogTitle: {
    padding: 0
  },
  rootHeader: {
    height: 80,
    borderBottom: '1px solid #E4EAEE',
    background: '#F9FAFB',
    boxSizing: 'border-box',
    paddingLeft: 35,
    paddingRight: 35
  },
  rootAdvanced: {
    height: 50,
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5
  },
  iconAdvanced: {
    paddingLeft: 18,
    paddingRight: 8
  },
  textAdvanced: {
    color: '#F2994A'
  }
});

interface IProps {
  handleClose: () => void;
  name?: string;
  image?: string;
  message: string;
}

export const BasicVerifNotification = (props: IProps) => {
  const { handleClose, name, image, message } = props;
  const classes = styles();
  return (
    <Grid className={classes.dialogTitle}>
      <Grid container alignItems="center" className={classes.rootHeader}>
        <Grid container alignItems="center" item xs={6}>
          <Typography
            variant="subtitle1"
            style={{ color: '#050F47', marginRight: 14 }}
          >
            Required
          </Typography>
          {name ? (
            <NexoItemCoinCredit
              id=""
              name={name ? name : ''}
              imgSrc={image ? image : ''}
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid
        style={{
          padding: 35
        }}
      >
        <Grid container alignItems="center" className={classes.rootAdvanced}>
          <img
            src={iconAdvanced}
            alt="icon advanced"
            width={15}
            height={15}
            className={classes.iconAdvanced}
          />
          <Typography variant="h5" className={classes.textAdvanced}>
            {message}
          </Typography>
        </Grid>
        <Grid container justify="center" style={{ marginTop: 20 }}>
          <Grid style={{ width: 300 }}>
            <NexoButton
              title="OK"
              variant="contained"
              color="primary"
              handleRedirect={handleClose}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
