import { combineReducers } from 'redux';
import {
  IUserInfo,
  IObjectLoginHistoryInfo,
  IDataGoogleAuthenticatorInfo,
  IGoogleAuthCodeInfo
} from '../types/user.type';
import {
  ISetUserProfileAction,
  ISetUserLoginHistoryAction,
  ISetDataGoogleAuthenticatorAction,
  ISetGoogleAuthCodeAction
} from '../actions/user.action';
import {
  SET_USER_PROFILE,
  SET_USER_HISTORY_LOGIN,
  SET_DATA_GOOGLE_AUTH_OF_USER,
  SET_DATA_CODE_GOOGLE_AUTH_FOR_USER
} from '../constants/user.constant';

export const EmtyUserProfile: IUserInfo = {
  id: '',
  roleId: '',
  isGoogleAuthenticator: false,
  isSmsAuthentication: false,
  pendingImage: false,
  isVerified: false,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  dob: new Date(),
  address: '',
  postCode: '',
  city: '',
  countryId: '',
  verificationImages: [],
  verificationLevel1: false,
  verificationLevel2: false,
  bankAccount: {
    accountNumber: '',
    address: '',
    city: '',
    countryId: '',
    description: '',
    id: '',
    name: '',
    postalCode: '',
    recipientType: '',
    swiftCode: '',
    transferType: '',
    userId: ''
  }
};

function userProfile(
  state: IUserInfo = EmtyUserProfile,
  action: ISetUserProfileAction
): IUserInfo {
  switch (action.type) {
    case SET_USER_PROFILE:
      return action.payload;
    default:
      return state;
  }
}

const userLoginHistoryEmpty: IObjectLoginHistoryInfo = {
  data: [],
  meta: {}
};

function userLoginHistory(
  state: IObjectLoginHistoryInfo = userLoginHistoryEmpty,
  action: ISetUserLoginHistoryAction
): IObjectLoginHistoryInfo {
  switch (action.type) {
    case SET_USER_HISTORY_LOGIN:
      return action.payload;
    default:
      return state;
  }
}

const dataGoogleAuthenticatorEmpty: IDataGoogleAuthenticatorInfo = {
  secret: '',
  dataURI: ''
};

function dataGoogleAuthenticator(
  state: IDataGoogleAuthenticatorInfo = dataGoogleAuthenticatorEmpty,
  action: ISetDataGoogleAuthenticatorAction
): IDataGoogleAuthenticatorInfo {
  switch (action.type) {
    case SET_DATA_GOOGLE_AUTH_OF_USER:
      return action.payload;
    default:
      return state;
  }
}

const googleAuthCodeEmpty: IGoogleAuthCodeInfo = {
  code: ''
};

function googleAuthCode(
  state: IGoogleAuthCodeInfo = googleAuthCodeEmpty,
  action: ISetGoogleAuthCodeAction
): IGoogleAuthCodeInfo {
  switch (action.type) {
    case SET_DATA_CODE_GOOGLE_AUTH_FOR_USER:
      return action.payload;
    default:
      return state;
  }
}

export const userManagement = combineReducers({
  userProfile,
  userLoginHistory,
  dataGoogleAuthenticator,
  googleAuthCode
});
