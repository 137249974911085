import { Action } from 'redux';
import {
  IObjectWithdrawType,
  ILoanCreditLineRequest,
  ILoanContractSummaryInfo,
  ILoadLoanContractSummaryRequest,
  ILoanTetherRequest
} from '../types/withdrawFund.type';
import {
  LOAD_WITHDRAWFUND_CONFIG_ACTION,
  SET_WITHDRAWFUND_CONFIG_ACTION,
  LOAN_CREDIT_LINE_ACTION,
  LOAD_LOAN_CONTRACT_SUMMARY_ACTION,
  SET_LOAN_CONTRACT_SUMMARY_ACTION,
  WITHDRAW_LOAN_APPROVED_ACTION,
  LOAN_TETHER_ACTION
} from '../constants/withdrawFund.constant';

export interface ILoadWithdrawFundConfigAction
  extends Action<LOAD_WITHDRAWFUND_CONFIG_ACTION> {}

export const loadWithdrawFundConfigAction = (): ILoadWithdrawFundConfigAction => ({
  type: LOAD_WITHDRAWFUND_CONFIG_ACTION
});

export interface ISetWithdrawFundConfigAction
  extends Action<SET_WITHDRAWFUND_CONFIG_ACTION> {
  payload: IObjectWithdrawType;
}

export const setWithdrawFundConfigAction = (
  withdrawTypeManagement: IObjectWithdrawType
): ISetWithdrawFundConfigAction => ({
  type: SET_WITHDRAWFUND_CONFIG_ACTION,
  payload: withdrawTypeManagement
});

export interface ILoanCreditLineAction extends Action<LOAN_CREDIT_LINE_ACTION> {
  payload: ILoanCreditLineRequest;
}

export const loanCreditLineAction = (
  request: ILoanCreditLineRequest
): ILoanCreditLineAction => ({
  type: LOAN_CREDIT_LINE_ACTION,
  payload: request
});

export interface ILoadLoanContractSummaryAction
  extends Action<LOAD_LOAN_CONTRACT_SUMMARY_ACTION> {
  payload: ILoadLoanContractSummaryRequest;
}

export const loadLoanContractSummaryAction = (
  request: ILoadLoanContractSummaryRequest
): ILoadLoanContractSummaryAction => ({
  type: LOAD_LOAN_CONTRACT_SUMMARY_ACTION,
  payload: request
});

export interface ISetLoanContractSummaryAction
  extends Action<SET_LOAN_CONTRACT_SUMMARY_ACTION> {
  payload: ILoanContractSummaryInfo;
}

export const setLoanContractSummaryAction = (
  request: ILoanContractSummaryInfo
): ISetLoanContractSummaryAction => ({
  type: SET_LOAN_CONTRACT_SUMMARY_ACTION,
  payload: request
});

export interface IWithdrawLoanApprovedAction
  extends Action<WITHDRAW_LOAN_APPROVED_ACTION> {
  payload: string;
}

export const approveLoanWithdrawalAction = (
  request: string
): IWithdrawLoanApprovedAction => ({
  type: WITHDRAW_LOAN_APPROVED_ACTION,
  payload: request
});

export interface ILoanTetherAction extends Action<LOAN_TETHER_ACTION> {
  payload: ILoanTetherRequest;
}

export const loanTetherAction = (
  request: ILoanTetherRequest
): ILoanTetherAction => ({
  type: LOAN_TETHER_ACTION,
  payload: request
});
