import React, { useState } from 'react';
import {
  Grid,
  BottomNavigation,
  BottomNavigationAction,
  Typography,
  Theme,
  makeStyles
} from '@material-ui/core';
import { NexoInput } from './NexoInput.component';
import iconAdvanced from '../../assets/images/iconAdvanced.svg';
import { NexoButton } from './NexoButton.component';
import { PassportPhotoPageDialogComponent } from '../dialog/PassportPhotoPage.dialog.component';
import { IUserInfo } from '../../types/user.type';
import { NexoPhoneInput } from './NexoInputPhone.component';
import { NexoSelectCountry } from './NexoSelectCountry.component';
import ICountry from '../../types/country.type';
import iconsucces from '../../assets/images/iconsuccesDialog.svg';
import iconSussesly from '../../assets/images/iconSussesly.svg';
import { VerifyYourIdentityDialog } from '../dialog/VerifyYourIdentity.dialog.component';

const styles = makeStyles((theme: Theme) => ({
  formVerification: {
    height: 530,
    border: '1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: 5,
    [theme.breakpoints.down(1280)]: {
      height: 520
    }
  },
  menuHeader: {
    height: 80,
    background: '#F9FAFB',
    width: '100%',
    borderBottom: '1px solid #D7DCE0',
    display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  rootBody: {
    height: 'calc(100% - 80px )',
    padding: '40px 32px',
    backgroundColor: '#FFFFFF',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  firstName: {
    height: 50
  },
  paddingInputRight: {
    paddingRight: 10
  },
  paddingInputLeft: {
    paddingLeft: 10
  },
  inputCity: {
    marginTop: 30,
    height: 50
  },
  rootAdvanced: {
    height: 50,
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5
  },
  iconAdvanced: {
    paddingLeft: 18,
    paddingRight: 8
  },
  textAdvanced: {
    color: '#F2994A'
  },
  buttonAdvanced: {
    width: 250
  },
  rootAdvancedVerification: {
    height: '100%'
  },
  pleaseCheck: {
    marginTop: 18
  },
  textCountry: {
    color: '#050F47'
  },
  textImportant: {
    fontWeight: 600
  },
  textWhichDocument: {
    color: '#2577E4'
  },
  pleaseContact: {
    color: '#050F47',
    marginTop: 3
  },
  notification: {
    height: 60,
    border: '1px solid #34CEA9',
    background: 'rgba(52, 206, 169, 0.05)',
    boxSizing: 'border-box',
    borderRadius: 5,
    marginBottom: 58,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '60px calc(100% - 60px)'
  },
  imgIconSucces: {
    marginLeft: 21,
    marginRight: 15
  },
  textCheckMail: {
    color: '#34CEA9',
    fontWeight: 500,
    paddingRight: 47
  }
}));
interface IProps {
  userProfile: IUserInfo;
  handleTextChange: (
    key: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInputVerifyPhone: (phone: string) => void;
  handleOnChangeCountry: (selectedCountry: ICountry[]) => void;
  selectedCountry: ICountry[];
  pageNumber: number;
}

export const CardAccountInfo = (props: IProps) => {
  const classes = styles();
  const {
    userProfile,
    handleChangeInputVerifyPhone,
    handleOnChangeCountry,
    handleTextChange,
    selectedCountry
  } = props;

  const [
    showDialogVerifyYourIdentity,
    setShowDialogVerifyYourIdentity
  ] = useState(false);
  const [value, setValue] = useState(props.pageNumber ? props.pageNumber : 1);
  const [showDialogPassportPhoto, setShowDialogPassportPhoto] = useState(false);
  const [contentPhotoPage, setContentPhotoPage] = useState('');

  const handleChange = () => {
    if (value === 1) {
      setValue(2);
    } else {
      setValue(1);
    }
  };

  const handleShowDialog = () => {
    setShowDialogVerifyYourIdentity(true);
  };

  const handleShowDialogPassportPhoto = (key: string) => () => {
    setShowDialogPassportPhoto(true);
    setShowDialogVerifyYourIdentity(false);
    setContentPhotoPage(key);
  };

  const handleBack = () => {
    setShowDialogPassportPhoto(false);
    setShowDialogVerifyYourIdentity(true);
  };

  const onClose = () => {
    setShowDialogVerifyYourIdentity(false);
    setShowDialogPassportPhoto(false);
  };

  const renderChildrenBasic = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        {userProfile.verificationLevel1 ? (
          <div>
            <Grid container justify="center" style={{ marginBottom: 40 }}>
              <img
                data-aos="zoom-in-down"
                data-aos-offset="600"
                data-aos-duration="600"
                src={iconSussesly}
                alt="icon"
              />
            </Grid>
            <Grid className={classes.notification}>
              <img
                src={iconsucces}
                width={25}
                height={25}
                className={classes.imgIconSucces}
                alt="icon succes"
              />
              <Typography variant="h4" className={classes.textCheckMail}>
                Basic verification completed successfully!
              </Typography>
            </Grid>
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <Grid container className={classes.firstName}>
              <Grid item xs={6} className={classes.paddingInputRight}>
                <NexoInput
                  error={userProfile.firstName === '' ? true : false}
                  msgError={
                    userProfile.firstName === '' ? 'Field is required' : ''
                  }
                  title="First Name"
                  type="text"
                  value={userProfile.firstName}
                  handleChangeInput={handleTextChange}
                  name="firstName"
                />
              </Grid>
              <Grid item xs={6} className={classes.paddingInputLeft}>
                <NexoInput
                  title="Last name"
                  error={userProfile.lastName === '' ? true : false}
                  msgError={
                    userProfile.lastName === '' ? 'Field is required' : ''
                  }
                  type="text"
                  value={userProfile.lastName}
                  handleChangeInput={handleTextChange}
                  name="lastName"
                />
              </Grid>
            </Grid>

            <Grid container className={classes.inputCity}>
              <Grid item xs={6} className={classes.paddingInputRight}>
                <NexoInput
                  title="City"
                  error={userProfile.city === '' ? true : false}
                  msgError={userProfile.city === '' ? 'Field is required' : ''}
                  type="text"
                  value={userProfile.city}
                  handleChangeInput={handleTextChange}
                  name="city"
                />
              </Grid>
              <Grid item xs={6} className={classes.paddingInputLeft}>
                <NexoInput
                  title="Postal Code"
                  error={userProfile.postCode === '' ? true : false}
                  msgError={
                    userProfile.postCode === '' ? 'Field is required' : ''
                  }
                  type="text"
                  value={userProfile.postCode}
                  handleChangeInput={handleTextChange}
                  name="postCode"
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} className={classes.inputCity}>
              <NexoInput
                title="Address"
                error={userProfile.address === '' ? true : false}
                msgError={userProfile.address === '' ? 'Field is required' : ''}
                type="text"
                value={userProfile.address}
                handleChangeInput={handleTextChange}
                name="address"
              />
            </Grid>

            <Grid container item xs={12} className={classes.inputCity}>
              <NexoSelectCountry
                hiddenText={true}
                handleOnChangeCountry={handleOnChangeCountry}
                title="Country"
                placeholder=""
                selectedCountry={selectedCountry}
              />
            </Grid>
            <Grid container item xs={12} className={classes.inputCity}>
              <NexoPhoneInput
                handleChangeInputVerifyPhone={handleChangeInputVerifyPhone}
                title="Phone"
                value={userProfile.phone ? userProfile.phone : ''}
              />
            </Grid>
          </div>
        )}
      </div>
    );
  };

  const renderChildrenAdvanced = () => {
    return (
      <div
        style={{
          width: '100%'
        }}
      >
        {userProfile.verificationLevel1 === false ? (
          <Grid container alignItems="center" className={classes.rootAdvanced}>
            <img
              src={iconAdvanced}
              alt="icon advanced"
              width={15}
              height={15}
              className={classes.iconAdvanced}
            />
            <Typography variant="h5" className={classes.textAdvanced}>
              You first need to complete Basic Verification
            </Typography>
          </Grid>
        ) : (
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.rootAdvancedVerification}
          >
            {userProfile.verificationLevel2 === false ? (
              <Grid>
                <Grid container item xs={12} justify="center">
                  <Grid className={classes.buttonAdvanced}>
                    {userProfile.pendingImage === true &&
                    userProfile.verificationLevel2 === false ? (
                      <NexoButton
                        title="Please wait for processing..."
                        variant="contained"
                        color="secondary"
                      />
                    ) : (
                      <NexoButton
                        title="Start Advanced Verification"
                        variant="contained"
                        color="primary"
                        handleRedirect={handleShowDialog}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="center"
                  className={classes.pleaseCheck}
                >
                  <Typography variant="h5" className={classes.textCountry}>
                    <span className={classes.textImportant}>Important:</span>{' '}
                    Please check {''}
                    <span className={classes.textWhichDocument}>
                      which documents
                    </span>{' '}
                    are supported for your country.
                  </Typography>
                  <Typography variant="h5" className={classes.pleaseContact}>
                    If you cannot find your country in the list, please contact
                    <span className={classes.textWhichDocument}> Support.</span>
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <div>
                <Grid container justify="center" style={{ marginBottom: 40 }}>
                  <img
                    data-aos="zoom-in-down"
                    data-aos-offset="600"
                    data-aos-duration="600"
                    src={iconSussesly}
                    alt="icon"
                  />
                </Grid>
                <Grid className={classes.notification}>
                  <img
                    src={iconsucces}
                    width={25}
                    height={25}
                    className={classes.imgIconSucces}
                    alt="icon succes"
                  />
                  <Typography variant="h4" className={classes.textCheckMail}>
                    Advanced verification successfully!
                  </Typography>
                </Grid>
              </div>
            )}
          </Grid>
        )}

        <VerifyYourIdentityDialog
          showDialog={showDialogVerifyYourIdentity}
          handleCloseDialog={onClose}
          handleShowPhotopage={handleShowDialogPassportPhoto}
          textHeader="Verify your identity"
        />
        <PassportPhotoPageDialogComponent
          handleBack={handleBack}
          showDialog={showDialogPassportPhoto}
          handleCloseDialog={onClose}
          contentPhotoPage={contentPhotoPage}
          textHeader="The photo page"
        />
      </div>
    );
  };

  return (
    <Grid className={classes.formVerification}>
      <Grid container className={classes.menuHeader}>
        <BottomNavigation showLabels value={value} onChange={handleChange}>
          <BottomNavigationAction label="Basic Verification" value={1} />
          <BottomNavigationAction label="Advanced Verification" value={2} />
        </BottomNavigation>
      </Grid>
      <Grid container className={classes.rootBody}>
        {value === 1 ? renderChildrenBasic() : renderChildrenAdvanced()}
      </Grid>
    </Grid>
  );
};
