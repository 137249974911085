import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PinInput from 'react-pin-input';

interface IProps {
  handleChangeInputCodeSMS: (codeInput: string) => void;
  disableResend: boolean;
  time: number;
  handleResendCodePhone: () => void;
}

export const EnterVerificationCode = (props: IProps) => {
  const {
    handleChangeInputCodeSMS,
    disableResend,
    time,
    handleResendCodePhone
  } = props;
  return (
    <Grid direction="column" container alignItems="center">
      <PinInput
        length={4}
        focus
        secret={false}
        type="numeric"
        onChange={handleChangeInputCodeSMS}
        style={{
          padding: '10px'
        }}
        inputStyle={{
          borderColor: '#D7DCE0',
          width: 40,
          height: 40,
          color: '#050F47',
          fontSize: 16
        }}
        inputFocusStyle={{ borderColor: '#34CEA9' }}
      />
      <Typography variant="h4" style={{ fontSize: 12, cursor: 'pointer' }}>
        Do not receive the OTP?{' '}
        {disableResend === true ? (
          <span style={{ color: '#a5b2c0', fontStyle: 'italic' }}>
            {''} Resend message {`(${time}s)`}
          </span>
        ) : (
          <span
            onClick={handleResendCodePhone}
            style={{ color: '#3e8deb', fontStyle: 'italic' }}
          >
            {''} Resend message
          </span>
        )}
      </Typography>
    </Grid>
  );
};
