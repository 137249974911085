import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Button, Hidden, IconButton, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import iconLogo from '../../assets/images/logoHeader.svg';
import iconArrowDown from '../../assets/images/arrowDown.svg';
import iconArrowRight from '../../assets/images/arrowRight.svg';
import { PATH } from '../../constants/Path.constant';
import { useSelector, useDispatch } from 'react-redux';
import { logoutAction } from '../../actions/auth.action';
import VectorHeader from '../../assets/images/VectorHeader.svg';
import iconLanguage from '../../assets/images/iconLanguage.svg';
import { IStoreState } from '../../types';
import { loadUserProfile } from '../../actions/user.action';
import { Link } from 'react-router-dom';
import { historyBrowser } from '../..';
import { NotificationMenu } from '../NotificationMenu';

const listMenu = [
  {
    id: 0,
    text: 'Account',
    link: PATH.nexo.account
  },
  {
    id: 1,
    text: 'Transaction',
    link: PATH.nexo.transactions
  }
];

const styles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    height: 70,
    borderBottom: '1px solid #D7DCE0'
  },
  content: {
    [theme.breakpoints.up('lg')]: {
      width: 1170
    },
    [theme.breakpoints.down('md')]: {
      width: 970
    },
    [theme.breakpoints.down('sm')]: {
      width: 750
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  },
  leftPart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
    '& img.logo': {
      cursor: 'pointer'
    }
  },
  centerPart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5
  },
  rightPart: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
    position: 'relative',
    '& .MuiButton-root': {
      marginLeft: 0,
      marginRight: 0
    }
  },
  itemNormal: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 2,
    textTransform: 'capitalize',
    border: '1px solid #ffffff',
    marginLeft: 5,
    marginRight: 5
  },
  itemNoneActive: {
    color: '#A5B2C0',
    '&:hover': {
      color: '#3E399F',
      background: '#F9FAFB',
      borderColor: '#E3EBF1',
      boxSizing: 'border-box',
      borderRadius: '5px'
    }
  },
  itemActive: {
    color: '#3E399F',
    background: '#F9FAFB',
    borderColor: '#E3EBF1',
    boxSizing: 'border-box',
    borderRadius: '5px'
  },
  user: {
    fontSize: 12,
    background: '#F9FAFB',
    border: '1px solid #E4EAEE',
    boxSizing: 'border-box',
    color: '#A5B2C0',
    paddingLeft: 15,
    paddingRight: 30,
    position: 'relative'
  },
  arrowDown: {
    position: 'absolute',
    right: 10
  },
  dropDownMenu: {
    top: 48,
    position: 'absolute',
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F9FAFB',
    border: '1px solid #E4EAEE',
    boxSizing: 'border-box',
    borderRadius: '0px 0px 5px 5px',
    width: 178,
    zIndex: 9999
  },
  dropDownitem: {
    color: '#A5B2C0',
    fontSize: 12,
    padding: '10px 25px',
    border: '1px solid transparent',
    '& .MuiButton-label': {
      justifyContent: 'flex-start'
    },
    '&:hover': {
      borderRadius: 0,
      background: '#EBF0F2',
      borderColor: '#E4EAEE',
      color: '#050F47'
    }
  },
  drawerMenuNoActiveLayout: {
    color: '#A5B2C0',
    fontSize: 14,
    padding: '10px 20px',
    border: '1px solid transparent',
    '& .MuiButton-label': {
      justifyContent: 'flex-start'
    },
    '&:hover': {
      borderRadius: 5,
      background: '#FFFFFF',
      color: '#050F47'
    }
  },
  drawerMenuActiveLayout: {
    color: '#050F47',
    fontSize: 14,
    padding: '10px 20px',
    border: '1px solid transparent',
    '& .MuiButton-label': {
      justifyContent: 'flex-start'
    },
    '&:hover': {
      borderRadius: 5,
      background: '#FFFFFF',
      color: '#050F47'
    }
  },
  drawerMenu: {
    paddingTop: 80,
    width: '200px',
    '& .MuiButton-root': {
      marginLeft: 0,
      marginRight: 0,
      width: '100%'
    }
  },
  buttonCreateAccount: {
    background: 'linear-gradient(147.4deg, #FFB26F 3.5%, #DF8A03 83.05%)',
    width: 160,
    height: 50,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 600,
    fontFamily: 'Montserrat',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  linkCompany: {
    textDecoration: 'none',
    color: '#A5B2C0',
    fontSize: 14,
    '&:hover': {
      color: '#050F47'
    }
  }
}));

const token = localStorage.getItem('token');

export const Header = () => {
  const [open, setOpen] = useState(false);
  const [openToogle, setOpenToogle] = useState(false);

  const userProfile = useSelector(
    (state: IStoreState) => state.userManagement.userProfile
  );
  const history = historyBrowser;

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(loadUserProfile());
    }
  }, [dispatch]);

  const redirectTo = (path: string) => (event: any) => {
    history.push(path);
  };

  const handleOpenCloseDropDown = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setOpen(!open);
  };

  const handleOpenToogle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenToogle(true);
  };

  const handleCloseToogle = (event: any) => {
    setOpenToogle(false);
  };

  const handleSignout = () => {
    dispatch(logoutAction());
  };

  const handleMouseLeaveGrid = (event: any) => {
    setOpen(false);
  };

  if (!userProfile) {
    return null;
  }
  const classes = styles();
  return (
    <div>
      <Grid container className={classes.root} justify="center">
        <Grid container className={classes.content}>
          <Hidden smDown>
            <Grid className={classes.leftPart} item sm={3}>
              <Link
                to={{
                  pathname: PATH.logo
                }}
                className={classes.linkCompany}
                target="true"
              >
                <img
                  src={iconLogo}
                  alt="logo"
                  title="logo"
                  data-aos="fade-down"
                />
              </Link>
            </Grid>
            {history.location.pathname.match(PATH.nexo.myProfile) ||
            history.location.pathname.match(PATH.nexo.security) ||
            history.location.pathname.match(PATH.nexo.account) ||
            history.location.pathname.match(
              PATH.nexo.withdrawLoanApproved.replace('/:token', '')
            ) ||
            history.location.pathname.match(
              PATH.nexo.withdrawAssetApproved.replace('/:token', '')
            ) ||
            history.location.pathname.match(
              PATH.nexo.withdrawSavingApproved.replace('/:token', '')
            ) ||
            history.location.pathname.match(PATH.nexo.transactions) ||
            history.location.pathname.match(PATH.nexo.notFound) ? (
              <Grid className={classes.centerPart} item sm={5}>
                {listMenu.map((el, key) => (
                  <Button
                    key={key}
                    onClick={redirectTo(el.link)}
                    className={
                      el.link === history.location.pathname
                        ? classNames(classes.itemNormal, classes.itemActive)
                        : classNames(classes.itemNormal, classes.itemNoneActive)
                    }
                  >
                    <span> {el.text}</span>
                  </Button>
                ))}
              </Grid>
            ) : (
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                item
                sm={9}
              >
                <Button
                  className={classNames(
                    classes.itemNormal,
                    classes.drawerMenuNoActiveLayout
                  )}
                >
                  <Link
                    to={{
                      pathname: PATH.company
                    }}
                    className={classes.linkCompany}
                    target="true"
                  >
                    Company
                  </Link>
                  <img
                    style={{ marginLeft: 8, marginTop: 2 }}
                    src={VectorHeader}
                    alt="vector header"
                  />
                </Button>

                <Button
                  className={classNames(
                    classes.itemNormal,
                    classes.drawerMenuNoActiveLayout
                  )}
                >
                  <Link
                    to={{
                      pathname: PATH.new
                    }}
                    className={classes.linkCompany}
                    target="true"
                  >
                    News
                  </Link>
                </Button>

                <Button
                  className={classNames(
                    classes.itemNormal,
                    classes.drawerMenuNoActiveLayout
                  )}
                >
                  <Link
                    to={{
                      pathname: PATH.help
                    }}
                    className={classes.linkCompany}
                    target="true"
                  >
                    Help
                  </Link>
                  <img
                    style={{ marginLeft: 8, marginTop: 2 }}
                    src={VectorHeader}
                    alt="vector header"
                  />
                </Button>
                <Button
                  onClick={redirectTo(PATH.nexo.login)}
                  className={
                    PATH.nexo.login === history.location.pathname
                      ? classNames(
                          classes.itemNormal,
                          classes.drawerMenuActiveLayout
                        )
                      : classNames(
                          classes.itemNormal,
                          classes.drawerMenuNoActiveLayout
                        )
                  }
                >
                  <span>Login</span>
                </Button>
                <Button
                  className={classNames(
                    classes.itemNormal,
                    classes.drawerMenuNoActiveLayout
                  )}
                >
                  <img
                    src={iconLanguage}
                    alt="icon language"
                    width={20}
                    height={20}
                  />
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={redirectTo(PATH.nexo.newAccount)}
                  className={classes.buttonCreateAccount}
                >
                  Create Account
                </Button>
              </Grid>
            )}

            {history.location.pathname.match(PATH.nexo.myProfile) ||
            history.location.pathname.match(PATH.nexo.security) ||
            history.location.pathname.match(PATH.nexo.account) ||
            history.location.pathname.match(PATH.nexo.transactions) ||
            history.location.pathname.match(
              PATH.nexo.withdrawLoanApproved.replace('/:token', '')
            ) ||
            history.location.pathname.match(
              PATH.nexo.withdrawAssetApproved.replace('/:token', '')
            ) ||
            history.location.pathname.match(
              PATH.nexo.withdrawSavingApproved.replace('/:token', '')
            ) ||
            history.location.pathname.match(PATH.nexo.notFound) ? (
              <Grid
                className={classes.rightPart}
                item
                sm={4}
                onMouseLeave={handleMouseLeaveGrid}
              >
                <Grid container alignItems="center" justify="flex-end">
                  <NotificationMenu />
                  <Button
                    className={classNames(classes.user, classes.itemNormal)}
                    aria-haspopup="true"
                    onClick={handleOpenCloseDropDown}
                    style={
                      open
                        ? { borderRadius: '5px 5px 0px 0px' }
                        : { borderRadius: '5px 5px 5px 5px' }
                    }
                  >
                    <span>
                      {userProfile.firstName !== null
                        ? ` Hello, ${userProfile.firstName} ${userProfile.lastName}`
                        : 'Hello, Guest'}
                    </span>
                    <img
                      className={classes.arrowDown}
                      src={iconArrowDown}
                      alt="dropdown"
                      title="dropdown"
                    />
                  </Button>
                </Grid>

                {open ? (
                  <Grid className={classes.dropDownMenu}>
                    <Button
                      onClick={redirectTo(PATH.nexo.myProfile)}
                      className={classNames(
                        classes.itemNormal,
                        classes.dropDownitem
                      )}
                    >
                      <span>Account</span>
                    </Button>

                    <Button
                      onClick={redirectTo(PATH.nexo.security)}
                      className={classNames(
                        classes.itemNormal,
                        classes.dropDownitem
                      )}
                    >
                      <span>Security</span>
                    </Button>
                    <Button
                      className={classNames(
                        classes.itemNormal,
                        classes.dropDownitem
                      )}
                    >
                      <span>Language: EN</span>
                    </Button>
                    <Button
                      onClick={handleSignout}
                      className={classNames(
                        classes.itemNormal,
                        classes.dropDownitem
                      )}
                    >
                      <span>Logout</span>
                      <img
                        className={classes.arrowDown}
                        src={iconArrowRight}
                        alt="arrowRight"
                        title="arrowRight"
                      />
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
          </Hidden>
          <Hidden mdUp>
            <Grid className={classes.leftPart} item xs={3}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleOpenToogle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid className={classes.centerPart} item xs={6}>
              <img
                className="logo"
                onClick={redirectTo('/')}
                src={iconLogo}
                alt="logo"
                title="logo"
              />
            </Grid>
            <Grid className={classes.drawerMenu}>
              <Drawer open={openToogle} onClose={handleCloseToogle}>
                <Grid className={classes.drawerMenu}>
                  {listMenu.map((el, key) => (
                    <Button
                      key={key}
                      onClick={redirectTo(el.link)}
                      className={classNames(
                        classes.itemNormal,
                        classes.dropDownitem
                      )}
                    >
                      <span> {el.text}</span>
                    </Button>
                  ))}
                  <Button
                    className={classNames(
                      classes.itemNormal,
                      classes.dropDownitem
                    )}
                  >
                    <span>Security</span>
                  </Button>
                  <Button
                    className={classNames(
                      classes.itemNormal,
                      classes.dropDownitem
                    )}
                  >
                    <span>Language: EN</span>
                  </Button>
                  <Button
                    className={classNames(
                      classes.itemNormal,
                      classes.dropDownitem
                    )}
                  >
                    <span>Logout</span>
                    <img
                      className={classes.arrowDown}
                      src={iconArrowRight}
                      alt="arrowRight"
                      title="arrowRight"
                    />
                  </Button>
                </Grid>
              </Drawer>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
};
