import React from 'react';
import {
  Grid,
  Typography,
  WithStyles,
  withStyles,
  createStyles
} from '@material-ui/core';
import { IUserInfo } from '../../types/user.type';
import { NexoButton } from './NexoButton.component';
import { NexoInput } from './NexoInput.component';
import { ReCaptchaComponent } from './ReCaptcha.component';
import { validateEmail } from '../../helper';

const styles = () =>
  createStyles({
    borderBottomContact: {
      paddingBottom: 37,
      borderBottom: '1px dashed #B2B6BA'
    },
    textContact: {
      color: '#3E399F'
    },
    textEmail: {
      color: '#A5B2C0',
      textTransform: 'uppercase',
      marginTop: 25
    },
    valueEmail: {
      color: '#050F47',
      marginTop: 3,
      fontWeight: 500
    },
    button: {
      width: 150
    },
    textSupport: {
      color: '#A5B2C0',
      marginTop: 11
    },
    textAddress: {
      color: '#A5B2C0'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  userProfile: IUserInfo;
}

interface IState {
  checkCaptchaSusses: boolean;
  checkCaptchaError: boolean;
  email: string;
  errorEmail: boolean;
}

class ContactInformation extends React.Component<IProps, IState> {
  public state: IState = {
    checkCaptchaSusses: false,
    checkCaptchaError: false,
    email: '',
    errorEmail: false
  };

  public handleChange = () => {
    this.setState({
      checkCaptchaSusses: true,
      checkCaptchaError: false
    });
  };

  public onClick = () => {
    if (this.state.checkCaptchaSusses === false) {
      this.setState({
        checkCaptchaError: true
      });
    } else {
      this.setState({
        checkCaptchaError: false
      });
    }
  };
  public handleChangeInputEmail = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      email: event.target.value
    });
    if (event.target.value.match(validateEmail)) {
      this.setState({
        errorEmail: false
      });
    } else if (event.target.value.substring(0, 1) === '') {
      this.setState({
        errorEmail: false
      });
    } else {
      this.setState({
        errorEmail: true
      });
    }
  };

  public render() {
    const { classes, userProfile } = this.props;
    const {
      checkCaptchaError,
      errorEmail,
      email,
      checkCaptchaSusses
    } = this.state;
    return (
      <Grid className={classes.borderBottomContact}>
        <Grid container>
          <Typography variant="h4" className={classes.textContact}>
            Contact Information
          </Typography>
          {userProfile.isVerified !== true ? (
            <Typography
              variant="h4"
              className={classes.textContact}
              style={{ color: '#EC5443', marginLeft: 10 }}
            >
              (Not Verified)
            </Typography>
          ) : null}
        </Grid>

        <Grid>
          <Typography variant="h5" className={classes.textEmail}>
            Your email
          </Typography>
        </Grid>

        <Grid>
          <Typography variant="h4" className={classes.valueEmail}>
            {userProfile.email}
          </Typography>
        </Grid>
        {userProfile.isVerified === true ? (
          <div>
            <Grid>
              <Typography variant="h5" className={classes.textSupport}>
                For security reasons, please contact our Support
              </Typography>
            </Grid>

            <Grid>
              <Typography variant="h5" className={classes.textAddress}>
                if you want to change your email address
              </Typography>
            </Grid>
          </div>
        ) : (
          <div>
            <Grid container style={{ marginTop: 20 }}>
              <Grid>
                <NexoButton
                  heightButton={40}
                  title="Resend Confirmation Email"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
            </Grid>
            <Grid style={{ marginTop: 20 }}>
              <NexoInput
                handleChangeInput={this.handleChangeInputEmail}
                error={errorEmail}
                value={email}
                msgError="Incorrect email format"
                title="New Email"
                type="text"
              />
            </Grid>
            <Grid>
              <ReCaptchaComponent
                handleChange={this.handleChange}
                error={checkCaptchaError}
                susses={checkCaptchaSusses}
                checkPrivacyPolicy={true}
                titleError="Wrong Captcha, please try again"
                titleSusses="Verification succeeded"
              />
            </Grid>

            <Grid container justify="flex-end">
              <Grid className={classes.button}>
                <NexoButton
                  title="Change"
                  heightButton={40}
                  variant="contained"
                  color="primary"
                  size="large"
                  handleRedirect={this.onClick}
                  disabled={checkCaptchaError}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    );
  }
}

export const ContactInformationComponent = withStyles(styles)(
  ContactInformation
);
