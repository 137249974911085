import { Store } from 'redux';
import { IStoreState } from '../types';
import { Dispatch } from 'react';
import {
  ILoadNotificationMenuAction,
  setNotificationMenuAction,
  INotificationMenuTurnOffAction,
  loadNotificationMenuAction,
  INotificationMenuTurnOffAllAction
} from '../actions/Notification.action';
import {
  LOAD_NOTIFICATION_MENU_ACTION,
  NOTIFICATION_MENU_TURN_OFF_ACTION,
  NOTIFICATION_MENU_TURN_OFF__ALL_ACTION
} from '../constants/Notification.constant';
import Axios, { AxiosResponse } from 'axios';
import { IOjectNotificationMenu } from '../types/Notification.type';

export const NotificationMenuMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | ILoadNotificationMenuAction
    | INotificationMenuTurnOffAction
    | INotificationMenuTurnOffAllAction
  >
) => async (
  action:
    | ILoadNotificationMenuAction
    | INotificationMenuTurnOffAction
    | INotificationMenuTurnOffAllAction
) => {
  switch (action.type) {
    case LOAD_NOTIFICATION_MENU_ACTION: {
      Axios.get(`/notifications`, {
        params: {
          limit: action.payload.limit,
          offset: action.payload.offset
        }
      })
        .then((data: AxiosResponse<IOjectNotificationMenu>) => {
          if (data.data) {
            store.dispatch(setNotificationMenuAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case NOTIFICATION_MENU_TURN_OFF_ACTION: {
      Axios.delete(`/notifications/${action.payload}`, {
        params: {
          id: action.payload
        }
      })
        .then(() => {
          store.dispatch(
            loadNotificationMenuAction({
              limit: 1000,
              offset: 0
            })
          );
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case NOTIFICATION_MENU_TURN_OFF__ALL_ACTION: {
      Axios.delete(`/notifications/clear`)
        .then(() => {
          store.dispatch(
            loadNotificationMenuAction({
              limit: 1000,
              offset: 0
            })
          );
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
  }
  return dispatch(action);
};
