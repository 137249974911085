export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export type UPDATE_NOTIFICATION = typeof UPDATE_NOTIFICATION;

export const EASE_NOTIFICATION = 'EASE_NOTIFICATION';
export type EASE_NOTIFICATION = typeof EASE_NOTIFICATION;

export const LOAD_NOTIFICATION_MENU_ACTION = 'LOAD_NOTIFICATION_MENU_ACTION';
export type LOAD_NOTIFICATION_MENU_ACTION = typeof LOAD_NOTIFICATION_MENU_ACTION;

export const SET_NOTIFICATION_MENU_ACTION = 'SET_NOTIFICATION_MENU_ACTION';
export type SET_NOTIFICATION_MENU_ACTION = typeof SET_NOTIFICATION_MENU_ACTION;

export const NOTIFICATION_MENU_TURN_OFF_ACTION =
  'NOTIFICATION_MENU_TURN_OFF_ACTION';
export type NOTIFICATION_MENU_TURN_OFF_ACTION = typeof NOTIFICATION_MENU_TURN_OFF_ACTION;

export const NOTIFICATION_MENU_TURN_OFF__ALL_ACTION =
  'NOTIFICATION_MENU_TURN_OFF__ALL_ACTION';
export type NOTIFICATION_MENU_TURN_OFF__ALL_ACTION = typeof NOTIFICATION_MENU_TURN_OFF__ALL_ACTION;

export enum MESSAGE_NOTIFICATION_SUCCESS {
  EDIT_PROFILE = ' Your Profile has been successfully updated.',
  RESET_PASSWORD = 'Your password has been successfully updated.',
  SETUP_TWOFACTOR = 'Your second factor has been successfully updated.',
  FORGOT_PASSWORD = 'Please check your email and click on the provided link to reset your password.',
  EDIT_AVATAR = 'Images has been successfully uploaded.',
  VERIFY_USER = ' Verify user successfully.',
  GOOGLE_AUTH = 'Two-Factor Authentication has been successfully updated.',
  RESEND_MAIL = 'Sent! Please check your email and click on the verification link'
}

export enum MESSAGE_NOTIFICATION_ERROR {
  EDIT_PROFILE = ' Sorry, something went wrong. Please try Again.',
  DUPLICATE_CODE_GOOGLE_AUTH = 'Verification code has already been used or has expired. Please try again with a new code.',
  CHANGE_PASSWORD = 'Current password is incorrect.',
  GOOGLE_AUTH = 'Verification code is not valid.'
}
