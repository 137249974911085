import { Action } from 'redux';
import {
  INIT_AXIOS_INTERCEPTOR,
  LOGIN,
  SIGN_UP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGOUT,
  VERIFY_USER_ACTION,
  CHANGE_PASSWORD,
  VERIFY_CODE,
  REFRESH_TOKEN_ACTION,
  RESEND_EMAIL_ACTION
} from '../constants/auth.constant';
import {
  ILoginRequest,
  ISignUpRequest,
  IForgotPasswordRequest,
  IResetPassword,
  IChangePasswordRequest,
  IVerifyCode
} from '../types/auth.type';

export interface IInitialAxiosInterceptorAction
  extends Action<INIT_AXIOS_INTERCEPTOR> {}
export const initialAxiosInterceptorAction = (): IInitialAxiosInterceptorAction => ({
  type: INIT_AXIOS_INTERCEPTOR
});

export interface ILoginAction extends Action<LOGIN> {
  payload: ILoginRequest;
}

export const loginAction = (request: ILoginRequest): ILoginAction => ({
  type: LOGIN,
  payload: request
});

export interface ISignUpAction extends Action<SIGN_UP> {
  payload: ISignUpRequest;
}

export const signUp = (request: ISignUpRequest): ISignUpAction => ({
  type: SIGN_UP,
  payload: request
});

export interface IForgotPasswordAction extends Action<FORGOT_PASSWORD> {
  payload: IForgotPasswordRequest;
}

export const forgotPasswordAction = (
  request: IForgotPasswordRequest
): IForgotPasswordAction => ({
  type: FORGOT_PASSWORD,
  payload: request
});

export interface IResetPasswordAction extends Action<RESET_PASSWORD> {
  payload: IResetPassword;
}

export const resetPasswordAction = (
  request: IResetPassword
): IResetPasswordAction => ({
  type: RESET_PASSWORD,
  payload: request
});

export interface ILogoutAction extends Action<LOGOUT> {}

export const logoutAction = (): ILogoutAction => ({
  type: LOGOUT
});

export interface IChangePasswordAction extends Action<CHANGE_PASSWORD> {
  payload: IChangePasswordRequest;
}

export const changeUserPasswordAction = (
  request: IChangePasswordRequest
): IChangePasswordAction => ({
  type: CHANGE_PASSWORD,
  payload: request
});

export interface IVerifyUserAction extends Action<VERIFY_USER_ACTION> {
  payload: string;
}

export const verifyUserAction = (request: string): IVerifyUserAction => ({
  type: VERIFY_USER_ACTION,
  payload: request
});

export interface IVerifyCodeAction extends Action<VERIFY_CODE> {
  payload: IVerifyCode;
}

export const verifyCodeAction = (code: IVerifyCode): IVerifyCodeAction => ({
  type: VERIFY_CODE,
  payload: code
});

export interface IRefreshTokenAction extends Action<REFRESH_TOKEN_ACTION> {
  payload: string;
}

export const refreshTokenAction = (request: string): IRefreshTokenAction => ({
  type: REFRESH_TOKEN_ACTION,
  payload: request
});

export interface IResendEmailAction extends Action<RESEND_EMAIL_ACTION> {
  payload: string;
}

export const resendEmailAction = (request: string): IResendEmailAction => ({
  type: RESEND_EMAIL_ACTION,
  payload: request
});
