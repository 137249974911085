import { Action } from 'redux';
import {
  LOAD_CURRENCIES_ACTION,
  SET_CURRENCIES_ACTION,
  LOAD_DEPOSIT_ADDRESS_ACTION,
  SET_DEPOSIT_ADDRESS_ACTION
} from '../constants/currency.constant';
import { IAccount } from '../types/currency.type';

export interface ILoadCurrenciesAction extends Action<LOAD_CURRENCIES_ACTION> {}

export const loadCurrenciesAction = (): ILoadCurrenciesAction => ({
  type: LOAD_CURRENCIES_ACTION
});

export interface ISetCurrenciesAction extends Action<SET_CURRENCIES_ACTION> {
  payload: IAccount;
}

export function setCurrenciesAction(
  currencyManagement: IAccount
): ISetCurrenciesAction {
  return {
    type: SET_CURRENCIES_ACTION,
    payload: currencyManagement
  };
}

export interface ILoadDepositAddressAction
  extends Action<LOAD_DEPOSIT_ADDRESS_ACTION> {
  payload: string;
}

export const loadDepositAddressAction = (
  request: string
): ILoadDepositAddressAction => ({
  type: LOAD_DEPOSIT_ADDRESS_ACTION,
  payload: request
});

export interface ISetDepositAddressAction
  extends Action<SET_DEPOSIT_ADDRESS_ACTION> {
  payload: string;
}

export function setDepositAddressAction(
  depositAddress: string
): ISetDepositAddressAction {
  return {
    type: SET_DEPOSIT_ADDRESS_ACTION,
    payload: depositAddress
  };
}
