import { IAccount } from '../types/currency.type';
import {
  SET_CURRENCIES_ACTION,
  SET_DEPOSIT_ADDRESS_ACTION
} from '../constants/currency.constant';
import {
  ISetCurrenciesAction,
  ISetDepositAddressAction
} from '../actions/currency.action';

const emptyObjectICurrencyBalance: IAccount = {
  currencyBalances: [],
  available: 0,
  creditLine: 0,
  totalInterest: 0,
  totalLoan: 0,
  totalSaving: '',
  totalSavingInterest: ''
};

export const currencies = (
  state: IAccount = emptyObjectICurrencyBalance,
  action: ISetCurrenciesAction
): IAccount => {
  if (action.type === SET_CURRENCIES_ACTION) {
    return action.payload;
  }
  return state;
};

export const depositAddress = (
  state: string = '',
  action: ISetDepositAddressAction
): string => {
  if (action.type === SET_DEPOSIT_ADDRESS_ACTION) {
    return action.payload;
  }
  return state;
};
