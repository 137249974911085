import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import iconAdvanced from '../../assets/images/iconAdvanced.svg';

const styles = makeStyles({
  rootContent: {
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: 50,
    paddingLeft: 18
  }
});

export const TwoFactorAuthNotifCard = () => {
  const classes = styles();
  return (
    <Grid container alignItems="center" className={classes.rootContent}>
      <img src={iconAdvanced} alt="icon advanced" width={15} height={15} />
      <Typography variant="h5" style={{ color: '#F2994A', marginLeft: 8 }}>
        Please add Two-factor Authentication in Security to continue
      </Typography>
    </Grid>
  );
};
