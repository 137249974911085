import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { NexoDialogComponent } from '../share/NexoDialog.component';
import iconEmail from '../../assets/images/iconEmail.svg';
import iconAdvanced from '../../assets/images/iconAdvanced.svg';
import { NexoButton } from '../share';

const styles = makeStyles({
  rootAdvanced: {
    height: 50,
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5,
    display: 'grid',
    gridTemplateColumns: '50px calc(100% - 50px)',
    marginTop: 20,
    marginBottom: 20
  },
  iconAdvanced: {
    paddingLeft: 18,
    paddingRight: 8
  },
  textAdvanced: {
    color: '#F2994A'
  },
  buttonCancel: {
    width: 150
  }
});

interface IProps {
  handleCloseDialog: () => void;
  showDialog: boolean;
  textHeader: string;
}

export const VerifyUserDialog = (props: IProps) => {
  const { handleCloseDialog, showDialog, textHeader } = props;
  const classes = styles();
  return (
    <NexoDialogComponent
      handleCloseDialog={handleCloseDialog}
      showDialog={showDialog}
      textHeader={textHeader}
      maxWidth="lg"
      hiddenback={false}
    >
      <Grid style={{ marginTop: 30 }}>
        <Grid container justify="center">
          <img src={iconEmail} alt="icon email" />
        </Grid>
        <Grid container alignItems="center" className={classes.rootAdvanced}>
          <img
            src={iconAdvanced}
            alt="icon advanced"
            width={15}
            height={15}
            className={classes.iconAdvanced}
          />
          <Typography variant="h5" className={classes.textAdvanced}>
            Please verify your email address by clicking on the verification
            link in the confirmation email
          </Typography>
        </Grid>
        <Grid container justify="center" style={{ marginBottom: 30 }}>
          <Grid className={classes.buttonCancel}>
            <NexoButton
              heightButton={40}
              title="Ok"
              variant="outlined"
              color="primary"
              handleRedirect={handleCloseDialog}
            />
          </Grid>
        </Grid>
      </Grid>
    </NexoDialogComponent>
  );
};
