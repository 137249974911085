import React, { Dispatch } from 'react';
import {
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Typography
} from '@material-ui/core';
import { NexoDialogComponent } from '../share/NexoDialog.component';
import iconImage from '../../assets/images/iconImage.svg';
import iconCardLeave from '../../assets/images/iconImageLeave.svg';
import classNames from 'classnames';
import { NexoUploadFile } from '../share';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  processUploadImageForVerifyAction,
  IProcessUploadImageForVerifyAction
} from '../../actions/user.action';
import { IUploadImagesForVerifyRequest } from '../../types/user.type';
import { History } from 'history';
import { IStoreState } from '../../types';
// eslint-disable-next-line
const mapStateToProps = ({}: IStoreState, { history }: RouteComponentProps) => {
  return {
    history
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<IProcessUploadImageForVerifyAction>
) => {
  return {
    uploadFileImage: (request: IUploadImagesForVerifyRequest) =>
      dispatch(processUploadImageForVerifyAction(request))
  };
};

const styles = () =>
  createStyles({
    root: {
      background: '#FFFFFF',
      boxSizing: 'border-box',
      borderRadius: 5,
      marginTop: 10,
      height: 80,
      paddingLeft: 18,
      paddingRight: 26,
      display: 'grid',
      gridTemplateColumns: '73px calc(100% - 78px) 5px',
      cursor: 'pointer'
    },
    rootHover: {
      background: '#4E99ED',
      border: '1px solid #2577E4'
    },
    rootLeave: {
      background: '#FFFFFF',
      border: '1px solid #D7DCE0'
    },
    textPassport: {
      color: '#FFFFFF'
    },
    textPassportLeave: {
      color: '#050F47'
    }
  });
interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  handleCloseDialog: () => void;
  handleBack: () => void;
  textHeader: string;
  uploadFileImage: (request: any) => void;
  history: History;
  contentPhotoPage: string;
}

interface IState {
  checkHoverPassport: boolean;
  files: any;
}

class PassportPhotoPage extends React.Component<IProps, IState> {
  public state: IState = {
    checkHoverPassport: false,
    files: []
  };

  public handleOnMouseOver = () => {
    this.setState({
      checkHoverPassport: true
    });
  };

  public handleOnMouseLeave = () => {
    this.setState({
      checkHoverPassport: false
    });
  };

  public handleUploadFile = (files: any) => {
    const formData = new FormData();
    // eslint-disable-next-line
    files.map((file: any) => {
      formData.append('verification', file);
    });
    this.props.uploadFileImage(formData);
    this.props.handleCloseDialog();
  };

  public render() {
    const { classes, handleCloseDialog, showDialog, textHeader } = this.props;
    const { checkHoverPassport } = this.state;
    return (
      <NexoDialogComponent
        handleCloseDialog={handleCloseDialog}
        showDialog={showDialog}
        textHeader={textHeader}
        handleBack={this.props.handleBack}
        maxWidth="md"
        hiddenback={true}
      >
        <Grid style={{ marginTop: 48 }}>
          <Grid container justify="center">
            <Typography
              variant="h5"
              style={{ color: '#050F47', fontWeight: 600 }}
            >
              Select the type of document you would like to upload
            </Typography>
          </Grid>
          <Grid
            container
            alignItems="center"
            onMouseEnter={this.handleOnMouseOver}
            onMouseLeave={this.handleOnMouseLeave}
            className={classNames(
              checkHoverPassport ? classes.rootHover : classes.rootLeave,
              classes.root
            )}
          >
            <img
              src={checkHoverPassport ? iconImage : iconCardLeave}
              alt="icon passport"
              width={34}
              height={50}
            />
            <Grid>
              <Grid>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 600 }}
                  className={
                    checkHoverPassport
                      ? classes.textPassport
                      : classes.textPassportLeave
                  }
                >
                  Take photo
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  variant="h5"
                  className={
                    checkHoverPassport
                      ? classes.textPassport
                      : classes.textPassportLeave
                  }
                >
                  Securely continue verification on your mobile
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              background: ' #FFFFFF',
              border: '1px solid #D7DCE0',
              boxSizing: 'border-box',
              borderRadius: 5,
              minHeight: 240,
              marginTop: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end'
            }}
          >
            <Grid container justify="center">
              <Grid>
                <Typography variant="h5" style={{ color: '#050F47' }}>
                  Upload {this.props.contentPhotoPage} photo page from your
                  computer
                </Typography>
              </Grid>
              <Grid
                container
                justify="center"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 19,
                  marginBottom: 19
                }}
              >
                <NexoUploadFile
                  maxFiles={3}
                  handleUploadFile={this.handleUploadFile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </NexoDialogComponent>
    );
  }
}

export const PassportPhotoPageDialogComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PassportPhotoPage))
);
