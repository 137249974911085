import { Action } from 'redux';
import {
  ITransactionRequest,
  IObjectTransaction,
  IAssetTransactionRequest
} from '../types/transaction.type';
import {
  LOAD_TRANSACTION_ACTION,
  SET_TRANSACTION_ACTION,
  SET_ASSET_TRANSACTION_ACTION,
  LOAD_ASSET_TRANSACTION_ACTION
} from '../constants/transaction.constant';
import { ICurrency } from '../types/currency.type';

export interface ILoadTransactionAction
  extends Action<LOAD_TRANSACTION_ACTION> {
  payload: ITransactionRequest;
}

export const loadTransactionAction = (
  request: ITransactionRequest
): ILoadTransactionAction => ({
  type: LOAD_TRANSACTION_ACTION,
  payload: request
});

export interface ISetTransactionAction extends Action<SET_TRANSACTION_ACTION> {
  payload: IObjectTransaction;
}

export const setTransactionAction = (
  transactionManagement: IObjectTransaction
): ISetTransactionAction => ({
  type: SET_TRANSACTION_ACTION,
  payload: transactionManagement
});

export interface ILoadAssetTransactionAction
  extends Action<LOAD_ASSET_TRANSACTION_ACTION> {
  payload: IAssetTransactionRequest;
}

export const loadAssetTransactionAction = (
  request: IAssetTransactionRequest
): ILoadAssetTransactionAction => ({
  type: LOAD_ASSET_TRANSACTION_ACTION,
  payload: request
});

export interface ISetAssetTransactionAction
  extends Action<SET_ASSET_TRANSACTION_ACTION> {
  payload: ICurrency[];
}

export const setAssetTransactionAction = (
  assetTransactionManagement: ICurrency[]
): ISetAssetTransactionAction => ({
  type: SET_ASSET_TRANSACTION_ACTION,
  payload: assetTransactionManagement
});
