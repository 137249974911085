import React, { useState } from 'react';
import {
  makeStyles,
  Typography,
  Dialog,
  Grid,
  IconButton,
  Button
} from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import { NexoButton, NexoItemCoinCredit } from '../share';
import errorIcon from '../../assets/images/shape.svg';
import { SnackbarDialogComponent } from './Snackbar.dialog.component';
import { ContentAttentionTopUp } from './ContentAttentionTopUp.dialog.component';
import { Scrollbars } from 'react-custom-scrollbars';
import { TwoFactorAuthNotifCard } from '../calculator/TwoFactorAuthNotifCard.component';
import { QrCodeCard } from '../QrCodeCard';
import { ICurrencyBalance } from '../../types/currency.type';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const styles = makeStyles({
  dialogTitle: {
    padding: 0
  },
  content: {
    padding: 35
  },

  rootHeader: {
    height: 80,
    borderBottom: '1px solid #E4EAEE',
    background: '#F9FAFB',
    boxSizing: 'border-box',
    paddingLeft: 35,
    paddingRight: 35
  },
  rootContent: {
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: 50,
    paddingLeft: 18
  },

  rootDialog: {
    '& .MuiDialog-paperWidthLg': {
      width: 600,
      maxWidth: 600
    }
  },
  errorGroup: {
    marginTop: 10,
    fontSize: 13,
    color: '#E3001E',
    fontFamily: 'Montserrat'
  },
  errorIcon: {
    marginRight: 10,
    width: 15.11,
    height: 13.33
  },

  activeCopy: {
    background:
      'linear-gradient(136.04deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(127.73deg, #67ACF1 -10.95%, #156EE4 104.81%)',
    color: '#FFFFFF'
  },
  buttonCalculator: {
    width: 100,
    height: 30,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 12,
    background:
      'linear-gradient(146.74deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(138.69deg, #67ACF1 -10.95%, #156EE4 104.81%)',
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderRadius: 5
  },
  rootContentXrp: {
    display: 'grid',
    gridTemplateColumns: 'calc(100% - 60px) 50px',
    gridColumnGap: 10,
    marginTop: 10
  },
  typographyXrp: {
    border: '1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: 50,
    paddingLeft: 20,
    background: '#F9FAFB'
  },
  rootTopUp: {
    border: '1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: 5,
    padding: 15
  }
});

interface IProps {
  showDialog: boolean;
  handleClose: () => void;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  isGoogleAuthenticator: boolean;
  itemCurrencies: ICurrencyBalance;
  handleShowDialogCalculator: () => void;
}

export const TopUpDialog = (props: IProps) => {
  const [openCopy, setOpenCopy] = useState(false);
  const copyDepositAddress = () => {
    setOpenCopy(true);
  };

  const handleCloseCopy = () => setOpenCopy(false);

  const renderTopUp = () => {
    return (
      <Grid className={classes.rootTopUp}>
        <Typography variant="h4">Deposit Address</Typography>
        <Grid
          style={{
            display: 'grid',
            gridTemplateColumns: 'calc(100% - 60px) 50px',
            gridColumnGap: 10,
            marginTop: 10
          }}
        >
          <Grid
            style={{
              border: '1px solid #D7DCE0',
              boxSizing: 'border-box',
              borderRadius: 5,
              height: 50,
              paddingLeft: 10,
              background: '#F9FAFB'
            }}
            container
            alignItems="center"
          >
            <Typography style={{ fontWeight: 500 }} variant="h4">
              {props.itemCurrencies.depositAddress}
            </Typography>
          </Grid>

          <CopyToClipboard
            text={props.itemCurrencies.depositAddress}
            onCopy={copyDepositAddress}
          >
            <IconButton
              style={{
                border: '1px solid #D7DCE0',
                borderRadius: 5
              }}
              className={openCopy ? classes.activeCopy : ''}
            >
              <FileCopy />
            </IconButton>
          </CopyToClipboard>
        </Grid>
        <Typography className={classes.errorGroup}>
          <img className={classes.errorIcon} src={errorIcon} alt="errorIcon" />
          Send only{' '}
          <span style={{ textTransform: 'capitalize' }}>
            {' '}
            {props.itemCurrencies.currency.name}{' '}
          </span>{' '}
          ({props.itemCurrencies.currency.symbol}) to this deposit address
        </Typography>
        <Grid container justify="center" style={{ marginTop: 20 }}>
          <QrCodeCard valueQrCode={props.itemCurrencies.depositAddress} />
        </Grid>
      </Grid>
    );
  };

  const {
    showDialog,
    handleClose,
    maxWidth,
    isGoogleAuthenticator,
    itemCurrencies
  } = props;
  const classes = styles();
  return (
    <Dialog
      className={classes.rootDialog}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      maxWidth={maxWidth}
    >
      <Grid className={classes.dialogTitle}>
        <Grid container alignItems="center" className={classes.rootHeader}>
          <Grid container alignItems="center" item xs={6}>
            <Typography
              variant="subtitle1"
              style={{ color: '#050F47', marginRight: 14 }}
            >
              Top Up
            </Typography>
            <NexoItemCoinCredit
              id=""
              name={itemCurrencies.currency.name}
              imgSrc={itemCurrencies.currency.image}
            />
          </Grid>
          <Grid container justify="flex-end" alignItems="center" item xs={6}>
            <Grid style={{ width: 100 }}>
              <Button
                className={classes.buttonCalculator}
                variant="contained"
                color="primary"
                size="medium"
                onClick={props.handleShowDialogCalculator}
              >
                Calculator
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Scrollbars
        style={
          isGoogleAuthenticator
            ? {
                minHeight: 600
              }
            : {
                minHeight: 500
              }
        }
      >
        <Grid className={classes.content}>
          {isGoogleAuthenticator ? renderTopUp() : <TwoFactorAuthNotifCard />}

          <ContentAttentionTopUp />

          <Grid style={{ marginTop: 32 }}>
            <NexoButton
              title="Got it"
              variant="contained"
              color="primary"
              handleRedirect={props.handleClose}
            />
          </Grid>
        </Grid>
      </Scrollbars>
      <SnackbarDialogComponent
        openCopy={openCopy}
        handleCloseCopy={handleCloseCopy}
      />
    </Dialog>
  );
};
