import INotificationInfo, {
  INotificationMenuRequest,
  IOjectNotificationMenu
} from '../types/Notification.type';
import {
  UPDATE_NOTIFICATION,
  EASE_NOTIFICATION,
  LOAD_NOTIFICATION_MENU_ACTION,
  SET_NOTIFICATION_MENU_ACTION,
  NOTIFICATION_MENU_TURN_OFF_ACTION,
  NOTIFICATION_MENU_TURN_OFF__ALL_ACTION
} from '../constants/Notification.constant';
import { Action } from 'redux';

export interface IUpdateNotification extends Action<UPDATE_NOTIFICATION> {
  payload: INotificationInfo;
}
export function fireNotification(noti: INotificationInfo): IUpdateNotification {
  return {
    type: UPDATE_NOTIFICATION,
    payload: { ...noti, isOpen: true }
  };
}

export interface IEaseNotification extends Action<EASE_NOTIFICATION> {}

export function ceaseNotification(): IEaseNotification {
  return {
    type: EASE_NOTIFICATION
  };
}

export interface ILoadNotificationMenuAction
  extends Action<LOAD_NOTIFICATION_MENU_ACTION> {
  payload: INotificationMenuRequest;
}

export const loadNotificationMenuAction = (
  request: INotificationMenuRequest
): ILoadNotificationMenuAction => ({
  type: LOAD_NOTIFICATION_MENU_ACTION,
  payload: request
});

export interface ISetNotificationMenuAction
  extends Action<SET_NOTIFICATION_MENU_ACTION> {
  payload: IOjectNotificationMenu;
}

export const setNotificationMenuAction = (
  notificationMenu: IOjectNotificationMenu
): ISetNotificationMenuAction => ({
  type: SET_NOTIFICATION_MENU_ACTION,
  payload: notificationMenu
});

export interface INotificationMenuTurnOffAction
  extends Action<NOTIFICATION_MENU_TURN_OFF_ACTION> {
  payload: string;
}

export const notificationMenuTurnOff = (
  request: string
): INotificationMenuTurnOffAction => ({
  payload: request,
  type: NOTIFICATION_MENU_TURN_OFF_ACTION
});

export interface INotificationMenuTurnOffAllAction
  extends Action<NOTIFICATION_MENU_TURN_OFF__ALL_ACTION> {}

export const notificationMenuTurnOffAll = (): INotificationMenuTurnOffAllAction => ({
  type: NOTIFICATION_MENU_TURN_OFF__ALL_ACTION
});
