import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { formatNumberToFixed } from '../../helper';

const styles = makeStyles({
  root: {
    background:
      'linear-gradient(136.04deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(127.73deg, #67ACF1 -10.95%, #156EE4 104.81%)',
    borderRadius: 5,
    height: 134,
    paddingRight: 30,
    paddingTop: 16,
    paddingLeft: 30
  },
  rootSaving: {
    marginTop: 10,
    background: '#F2994A',
    borderRadius: 5,
    height: 90
  },
  textHeader: {
    color: '#FFFFFF',
    fontWeight: 400
  },
  textContent: {
    color: '#F9F9FB',
    fontWeight: 600
  }
});

interface IProps {
  available: number;
  creditLine: number;
  savingBalance: string;
  totalSavingInterest: string;
}

export const CardAvailableComponent = (props: IProps) => {
  const classes = styles();
  const totalSaving =
    Number(props.savingBalance) + Number(props.totalSavingInterest);

  return (
    <Grid data-aos="fade-left" data-aos-offset="300" data-aos-duration="300">
      <Grid className={classes.root}>
        <Grid
          container
          style={{ paddingBottom: 14, borderBottom: '1px dashed #FFFFFF' }}
        >
          <Grid container justify="flex-end">
            <Typography variant="h1" className={classes.textHeader}>
              ${' '}
              {props.available > 0
                ? formatNumberToFixed(props.available, 2)
                : 0}
            </Typography>
          </Grid>
          <Grid container justify="flex-end">
            <Typography variant="h5" className={classes.textContent}>
              Available Credit
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: 14 }}>
          <Grid container justify="flex-end">
            <Typography variant="h1" className={classes.textHeader}>
              $ {formatNumberToFixed(props.creditLine, 2)}
            </Typography>
          </Grid>
          <Grid container justify="flex-end">
            <Typography variant="h5" className={classes.textContent}>
              Credit Line
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {Number(props.savingBalance) > 0 ? (
        <Grid
          container
          direction="column"
          alignItems="flex-end"
          justify="center"
          className={classes.rootSaving}
        >
          <Typography
            variant="h1"
            style={{ marginRight: 30 }}
            className={classes.textHeader}
          >
            ${' '}
            {Number(props.savingBalance) > 0
              ? formatNumberToFixed(totalSaving, 2)
              : 0}
          </Typography>
          <Typography
            variant="h5"
            style={{ marginRight: 30 }}
            className={classes.textContent}
          >
            Saving Balance
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};
