import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { NexoInput, NexoButton } from './share';
import { IChangeUserPassword } from '../types/user.type';
import errorIcon from '../assets/images/shape.svg';
import { NexoInputVerifyCode } from './share/NexoInputVerifyCode.component';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../types';
import INotificationInfo from '../types/Notification.type';
import { MESSAGE_NOTIFICATION_ERROR } from '../constants/Notification.constant';
import Variant from '../types/Variant.type';
import { fireNotification } from '../actions/Notification.action';
import { changeUserPasswordAction } from '../actions/auth.action';
import { useHistory } from 'react-router';

const styles = makeStyles({
  root: {
    marginTop: 26,
    paddingBottom: 33,
    borderBottom: '1px dashed #B2B6BA'
  },
  textHeader: {
    color: '#3E399F'
  },
  input: {
    marginTop: 14
  },
  button: {
    width: 150
  },
  buttonCalculator: {
    width: 150,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 12,
    background: 'linear-gradient(138.69deg, #67ACF1 -10.95%, #156EE4 104.81%)',
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderRadius: 5
  },
  code: {
    textAlign: 'center',
    color: '#222222',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 40,
    lineHeight: '40px',
    letterSpacing: '0.3em'
  },
  errorGroup: {
    marginTop: 10,
    fontSize: 12,
    color: '#E3001E',
    fontFamily: 'Montserrat'
  },
  errorIcon: {
    marginRight: 10,
    width: 15.11,
    height: 13.33
  }
});

export const ChangePasswordCard = () => {
  const history = useHistory<{ turnOffDialog: boolean }>();
  const [userPassword, setUserPassword] = useState<IChangeUserPassword>({
    code: '',
    newPassword: '',
    password: '',
    reNewPassword: ''
  });
  const [errorInputCode, setErrorInputCode] = useState<boolean>(false);

  const googleAuthCode = useSelector(
    (state: IStoreState) => state.userManagement.googleAuthCode
  );
  const userProfile = useSelector(
    (state: IStoreState) => state.userManagement.userProfile
  );
  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.turnOffDialog === true
    ) {
      setUserPassword({
        code: '',
        newPassword: '',
        password: '',
        reNewPassword: ''
      });
    }
  }, [history.location.state]);

  const dispatch = useDispatch();
  const handleChangeInput = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserPassword({
      ...userPassword,
      [key]: event.target.value
    });
  };

  const handleSubmitChangePassword = () => {
    if (userPassword.code !== '' && userPassword.code === googleAuthCode.code) {
      return showNotificationDuplicateCode();
    }
    if (userProfile.isGoogleAuthenticator === true) {
      if (userPassword.code === '') {
        setErrorInputCode(true);
      } else {
        setErrorInputCode(false);
        dispatch(changeUserPasswordAction(userPassword));
      }
    } else {
      dispatch(changeUserPasswordAction(userPassword));
    }
  };
  const showNotificationDuplicateCode = () => {
    const notify: INotificationInfo = {
      message: MESSAGE_NOTIFICATION_ERROR.DUPLICATE_CODE_GOOGLE_AUTH,
      variant: Variant.ERROR
    };
    dispatch(fireNotification(notify));
  };

  const handleTextCodeVerifyChange = (codeInput: string) => {
    setUserPassword({
      ...userPassword,
      code: codeInput
    });
  };

  const classes = styles();
  const { password, newPassword, reNewPassword } = userPassword;
  const disabledButtonChangeEmpty =
    password === '' ||
    newPassword === '' ||
    reNewPassword === '' ||
    newPassword !== reNewPassword;
  const disabledButtonChangeLessThanSix =
    password.length < 6 || newPassword.length < 6 || reNewPassword.length < 6;
  return (
    <Grid className={classes.root}>
      <Grid>
        <Typography variant="h4" className={classes.textHeader}>
          Change Password
        </Typography>
      </Grid>
      <Grid className={classes.input}>
        <NexoInput
          title="Current Password"
          type="password"
          name="password"
          error={password !== '' && password.length < 6 ? true : false}
          msgError="Current password should be at least 6 characters"
          value={password}
          handleChangeInput={handleChangeInput}
        />
      </Grid>

      <Grid>
        <NexoInput
          title="New Password"
          type="password"
          name="newPassword"
          error={newPassword !== '' && newPassword.length < 6 ? true : false}
          msgError="New password should be at least 6 characters"
          value={newPassword}
          handleChangeInput={handleChangeInput}
        />
      </Grid>
      <Grid>
        <NexoInput
          title="Confirm Password"
          type="password"
          name="reNewPassword"
          error={reNewPassword !== newPassword ? true : false}
          msgError="Confirm new password does not match"
          value={reNewPassword}
          handleChangeInput={handleChangeInput}
        />
      </Grid>
      {userProfile.isGoogleAuthenticator ? (
        <Grid
          container
          justify="center"
          alignContent="center"
          style={{ marginBottom: 20 }}
        >
          <Grid container justify="center" item xs={12}>
            <Typography variant="h4">
              Enter the two-step Authentication code
            </Typography>
          </Grid>
          <NexoInputVerifyCode
            handleTextChangeCode={handleTextCodeVerifyChange}
          />

          {errorInputCode ? (
            <Grid container justify="center">
              <Typography className={classes.errorGroup}>
                <img
                  className={classes.errorIcon}
                  src={errorIcon}
                  alt="errorIcon"
                />
                Please enter verify code
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      <Grid container justify="flex-end">
        <Grid className={classes.button}>
          <NexoButton
            title="Change"
            heightButton={40}
            variant="contained"
            disabled={
              disabledButtonChangeLessThanSix || disabledButtonChangeEmpty
            }
            color="primary"
            size="large"
            handleRedirect={handleSubmitChangePassword}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
