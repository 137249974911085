import React from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import ICountry from '../../types/country.type';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 20,
      padding: 0,
      width: '100%'
    },
    content: {
      border: '1px solid #D7DCE0',
      height: 50,
      borderRadius: 5,
      fontSize: 14,
      fontWeight: 'normal',
      fontFamily: 'Montserrat',
      display: 'flex',
      flexDirection: 'row'
    },
    leftPart: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F9F9FB',
      minWidth: 100,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderRight: '1px solid #D7DCE0',
      height: 50,
      fontWeight: 600
    },
    rightPart: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F9F9FB',
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      height: 50,
      fontWeight: 600,
      '& .rbt.clearfix.open': {
        width: '100%',
        height: 50,
        '& .sr-only.rbt-sr-status': {
          display: 'none'
        },
        '& .rbt-input-hint-container': {
          display: 'flex',
          flex: '1',
          position: 'relative'
        },
        '& .rbt-input-hint': {
          '& input': {
            display: 'none'
          }
        },
        '& .rbt-input-main.form-control.rbt-input': {
          fontFamily: 'Montserrat',
          fontSize: 14,
          display: 'block',
          width: '100%',
          height: 38,
          padding: '.375rem .75rem',
          lineHeight: '1.5',
          fontWeight: '500',
          color: '#050F47',
          backgroundColor: '#fff',
          backgroundClip: 'padding-box',
          borderStyle: 'none',
          borderRadius: '0px 5px 5px 0px',
          outline: 'none !important'
        }
      },
      '& .rbt-menu.dropdown-menu.show': {
        zIndex: 1000,
        listStyle: 'none',
        position: 'absolute',
        display: 'block',
        maxHeight: 300,
        overflowY: 'auto',
        overflowX: 'hidden',
        willChange: 'transform',
        top: '-4px !important',
        left: '-1px !important',
        transform: 'translate3d(0px, 38px, 0px)',
        float: 'left',
        padding: '15px 0px',
        margin: '.125rem 0 0',
        textAlign: 'left',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        border: '1px solid #D7DCE0',
        borderRadius: '0px 0px 5px 5px',
        '& .dropdown-item': {
          fontFamily: 'Montserrat',
          fontSize: 12,
          fontWeight: 500,
          textOverflow: 'ellipsis',
          display: 'block',
          width: '100%',
          padding: 8,
          clear: 'both',
          color: '#A5B2C0',
          textAlign: 'inherit',
          whiteSpace: 'nowrap',
          textDecoration: 'none',
          backgroundColor: 'transparent',
          borderStyle: 'none',
          '&:hover': {
            backgroundColor: '#F9FAFB'
          }
        }
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  title: string;
  placeholder: string;
  countryId?: string;
  handleOnChangeCountry: (selectedCountry: ICountry[]) => void;
  selectedCountry: ICountry[];
  hiddenText: boolean;
}

interface IState {
  countries: ICountry[];
  indexOf: number;
}

class NexoSelectCountryComponent extends React.Component<IProps, IState> {
  state: IState = {
    countries: [],
    indexOf: 0
  };

  // tslint:disable-next-line
  UNSAFE_componentWillMount() {
    this.getCountries();
  }

  public handleOnChange = (selectedCountry: ICountry[]) => {
    if (selectedCountry.length > 0) {
      this.props.handleOnChangeCountry(selectedCountry);
    }
  };

  protected getCountries() {
    const { countryId } = this.props;
    axios
      .get(`/countries`)
      .then(res => {
        let indexOf = 0;
        if (countryId !== '' && res) {
          indexOf = res.data.findIndex((item: any) => item.id === countryId);
          indexOf = indexOf === -1 ? 0 : indexOf;
        }

        this.setState({ countries: res ? res.data : undefined, indexOf });
      })
      .catch(err => console.log(err));
  }

  public render() {
    const {
      classes,
      title,
      placeholder,
      hiddenText,
      selectedCountry
    } = this.props;
    const { countries, indexOf } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          {hiddenText === true ? (
            <div className={classes.leftPart}>
              <Typography variant="h4">{title}</Typography>
            </div>
          ) : null}

          <div className={classes.rightPart}>
            <Typeahead
              selected={
                selectedCountry.length > 0
                  ? selectedCountry
                  : countries.length > 0
                  ? countries.slice(indexOf, indexOf + 1)
                  : undefined
              }
              // tslint:disable-next-line
              filterBy={(option, props) => {
                if (props.selected.length) {
                  return true;
                }
                return (
                  option.name
                    .toLowerCase()
                    .indexOf(props.text.toLowerCase()) !== -1
                );
              }}
              id="selects"
              labelKey="name"
              onChange={this.handleOnChange}
              options={countries}
              placeholder={placeholder}
              flip={true}
              selectHintOnEnter={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const NexoSelectCountry = withStyles(styles)(NexoSelectCountryComponent);
