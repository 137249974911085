import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { ListItemCoinCreditComponent } from '../../../components/ListItemCoinCredit.component';
import { CardAvailableComponent } from '../../../components/share/CardAvailable.component';
import { FlexibleInstantComponent } from '../../../components/FlexibleInstant.component';
import { IStoreState } from '../../../types';
import { VerifyUserDialog } from '../../../components/dialog/VerifyUser.dialog.component';
import { PATH } from '../../../constants/Path.constant';
import { LoanWithdrawalCard } from '../../../components/LoanWithdrawalCard.component';
import { useSelector, useDispatch } from 'react-redux';
import { historyBrowser } from '../../../';
import { loadCurrenciesAction } from '../../../actions/currency.action';
import { ICurrencyBalance } from '../../../types/currency.type';
import { totalMarketCurrency } from '../../../helper';
import { VerificationPhoneDialog } from '../../../components/dialog/VerificationPhone.dialog';

const styles = makeStyles({
  root: {
    minHeight: '600px',
    padding: 0,
    width: '100%'
  },
  marginAccountInfo: {
    marginBottom: 23
  },
  paddingBasic: {
    paddingRight: 10
  },
  paddingAttention: {
    paddingLeft: 10
  }
});

export const AccountComponent = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector(
    (state: IStoreState) => state.userManagement.userProfile
  );
  const currencyBalances: ICurrencyBalance[] = useSelector(
    (state: IStoreState) => state.currencies.currencyBalances
  );

  const [showDialogVerifyNumber, setShowDialogVerifyNumber] = useState(false);

  const available: number = useSelector(
    (state: IStoreState) => state.currencies.available
  );
  const totalSaving: string = useSelector(
    (state: IStoreState) => state.currencies.totalSaving
  );
  const creditLine: number = useSelector(
    (state: IStoreState) => state.currencies.creditLine
  );
  const totalSavingInterest: string = useSelector(
    (state: IStoreState) => state.currencies.totalSavingInterest
  );
  const history = historyBrowser;
  const redirectTo = () => {
    history.push(PATH.nexo.security);
  };

  useEffect(() => {
    dispatch(loadCurrenciesAction());
    const loadCurrencies = setInterval(() => {
      dispatch(loadCurrenciesAction());
    }, 60000);
    return () => {
      clearInterval(loadCurrencies);
    };
  }, [dispatch]);

  useEffect(() => {
    if (userProfile.verificationLevel1 === true && userProfile.phone === null) {
      setShowDialogVerifyNumber(true);
    } else {
      setShowDialogVerifyNumber(false);
    }
  }, [userProfile]);

  const handleCloseDialogVerify = () => {
    setShowDialogVerifyNumber(false);
  };

  const classes = styles();

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid container className={classes.marginAccountInfo}>
          <Grid item md={9} lg={9} className={classes.paddingBasic}>
            <LoanWithdrawalCard
              userProfile={userProfile}
              available={available}
              creditLine={creditLine}
            />
            <ListItemCoinCreditComponent
              currencies={currencyBalances}
              userProfile={userProfile}
              isGoogleAuthenticator={userProfile.isGoogleAuthenticator}
            />
            <Grid style={{ textAlign: 'center', marginTop: 30 }}>
              <Typography variant="h3" style={{ fontWeight: 500 }}>
                Total Value of Crypto Assets:
                <span
                  style={{
                    color: '#3E399F',
                    fontWeight: 600,
                    fontSize: 20,
                    marginLeft: 5
                  }}
                >
                  ${''} {totalMarketCurrency(currencyBalances)}
                </span>
              </Typography>
              <Typography variant="h5" style={{ marginTop: 5 }}>
                If the total value of your crypto assets reaches{' '}
                <span style={{ fontWeight: 600 }}>
                  {' '}
                  ${''} {totalMarketCurrency(currencyBalances)}
                </span>
                , small partial loan repayments will be initiated automatically
              </Typography>
            </Grid>
          </Grid>

          <Grid item md={3} lg={3} className={classes.paddingAttention}>
            <CardAvailableComponent
              available={available}
              creditLine={creditLine}
              savingBalance={totalSaving}
              totalSavingInterest={totalSavingInterest}
            />
            <FlexibleInstantComponent />
          </Grid>
        </Grid>
      </Grid>

      <VerifyUserDialog
        showDialog={userProfile.isVerified === null ? true : false}
        handleCloseDialog={redirectTo}
        textHeader="Required"
      />
      <VerificationPhoneDialog
        showDialog={showDialogVerifyNumber}
        handleCloseDialog={handleCloseDialogVerify}
        textHeader="Verify Phone Number"
      />
    </div>
  );
};
