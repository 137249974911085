import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  makeStyles,
  IconButton,
  InputAdornment,
  Tooltip
} from '@material-ui/core';
import Error from '@material-ui/icons/Error';
import { ICoinInfo } from '../../types/calculator.type';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import classNames from 'classnames';
const styles = makeStyles({
  sliderActive: {
    cursor: 'pointer',
    display: 'flex !important',
    alignItems: 'center',
    border: '1px solid #D7DCE0 ',
    '&:hover': {
      border: '1px solid #34CEA9 '
    },
    borderRadius: 5,
    width: '95% !important',
    height: 41,
    '&:focus': {
      outlineColor: '#FFFFFF'
    }
  },
  rootTextField: {
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: 'inherit'
      }
    },
    '& .MuiInputBase-root': {
      color: '#050F47',
      fontSize: 14,
      fontFamily: 'Montserrat',
      fontWeight: 600
    }
  },
  iconIput: {
    '& .MuiIconButton-root': {
      padding: 0
    },
    '& .MuiSvgIcon-root': {
      fontSize: 15
    }
  },
  buttonIconButton: {
    width: 30,
    height: 30,
    padding: 0,
    cursor: 'pointer',
    color: '#F2994A'
  },
  inputError: {
    border: '1px solid #F2994A !important'
  }
});

interface IProps {
  value: string;
  detailCoin: ICoinInfo;
  min: number;
  step: number;
  max?: number;
  formatNumber: number;
  errorInput?: boolean;
  titleError?: string;
  placeholder?: string;
  handleChangeInput: (
    key: string,
    value: string,
    formatNumber: number
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeLimitInput?: (
    ltv: string,
    keyboard: string,
    key: string,
    formatNumber: number,
    min: number,
    step: number,
    max?: number
  ) => () => void;
  onBlurTextFiedOutSide?: (key: string, valueInput: string) => () => void;
}

export const NexoInputCurrency = (props: IProps) => {
  const {
    value,
    detailCoin,
    min,
    step,
    max,
    formatNumber,
    errorInput,
    handleChangeInput,
    handleChangeLimitInput,
    onBlurTextFiedOutSide,
    titleError,
    placeholder
  } = props;
  const classes = styles();
  return (
    <div>
      <Grid
        className={
          errorInput
            ? classNames(classes.inputError, classes.sliderActive)
            : classes.sliderActive
        }
      >
        <Typography variant="h5" style={{ marginLeft: 7, marginRight: 10 }}>
          {detailCoin.symbol}
        </Typography>
        <TextField
          label=""
          onBlur={
            onBlurTextFiedOutSide &&
            onBlurTextFiedOutSide(detailCoin.symbol, value)
          }
          onChange={handleChangeInput(
            detailCoin.symbol,
            detailCoin.value,
            formatNumber
          )}
          disabled={detailCoin.disabled}
          className={classes.rootTextField}
          style={{ marginRight: 10, width: '100%' }}
          value={value}
          placeholder={placeholder}
          error={errorInput}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment className={classes.iconIput} position="end">
                {errorInput === false ? (
                  <Grid container direction="row" justify="flex-end">
                    <IconButton
                      onClick={
                        handleChangeLimitInput &&
                        handleChangeLimitInput(
                          detailCoin.value,
                          'arrowUp',
                          detailCoin.symbol,
                          formatNumber,
                          min,
                          step,
                          max
                        )
                      }
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                    <IconButton
                      onClick={
                        handleChangeLimitInput &&
                        handleChangeLimitInput(
                          detailCoin.value,
                          'arrowDown',
                          detailCoin.symbol,
                          formatNumber,
                          min,
                          step,
                          max
                        )
                      }
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </Grid>
                ) : (
                  <Grid container justify="flex-end">
                    <Tooltip title={titleError || ''} placement="top-start">
                      <IconButton className={classes.buttonIconButton}>
                        <Error />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </InputAdornment>
            )
          }}
        />
      </Grid>
    </div>
  );
};
