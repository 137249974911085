import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Grid,
  BottomNavigation,
  BottomNavigationAction,
  makeStyles
} from '@material-ui/core';

import { InstantCryptoCard } from '../InstantCryptoCard.component';
import { EarnInterestCard } from '../EarnInterestCard.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadCalculaotrAction,
  loadRatesAction,
  loadInterestAction
} from '../../actions/calculator.action';
import { IStoreState } from '../../types';
import { ICoinInfo, IInterestInfo } from '../../types/calculator.type';
import { ICurrencyBalance } from '../../types/currency.type';

const styles = makeStyles({
  menuHeader: {
    height: 60,
    background: '#FFFFFF',
    width: '100%',
    borderBottom: '1px solid #D7DCE0',
    display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  bottomNavigation: {
    '& .Mui-selected': {
      '&:after': {
        content: "''",
        backgroundColor: '#F09F3C',
        position: 'absolute',
        width: 70,
        height: 4,
        bottom: 0,
        left: 50,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3
      }
    }
  },
  buttonInstant: {
    maxWidth: 190,
    width: 190
  },

  rootDialog: {
    '& .MuiDialog-paperWidthXl': {
      width: 600,
      maxWidth: 600
    }
  }
});

interface IProps {
  handleClose: () => void;
  showDialog: boolean;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export const CalculatorDialog = (props: IProps) => {
  const [value, setValue] = useState<number>(1);

  const loan: ICoinInfo[] = useSelector(
    (state: IStoreState) => state.calculators.loan
  );
  const interest: ICoinInfo[] = useSelector(
    (state: IStoreState) => state.calculators.interest
  );
  const currencyBalances: ICurrencyBalance[] = useSelector(
    (state: IStoreState) => state.currencies.currencyBalances
  );
  const interests: IInterestInfo[] = useSelector(
    (state: IStoreState) => state.interests
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCalculaotrAction());
    dispatch(loadRatesAction());
    dispatch(loadInterestAction());
  }, [dispatch]);
  const handleChangeBottomNavigation = () => {
    if (value === 1) {
      setValue(2);
    } else {
      setValue(1);
    }
  };

  const { handleClose, maxWidth, showDialog } = props;
  const classes = styles();
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      maxWidth={maxWidth}
      className={classes.rootDialog}
    >
      <Grid
        style={{
          padding: 30
        }}
      >
        <Grid container className={classes.menuHeader}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={handleChangeBottomNavigation}
          >
            <BottomNavigationAction
              className={classes.bottomNavigation}
              classes={{
                root: classes.buttonInstant
              }}
              label="Instant Crypto Credit Line"
              value={1}
            />
            <BottomNavigationAction
              className={classes.bottomNavigation}
              label="Earn Interest"
              value={2}
            />
          </BottomNavigation>
        </Grid>
      </Grid>
      <Grid
        style={{
          padding: '64px 30px 0px 30px'
        }}
      >
        {value === 1 ? (
          <InstantCryptoCard
            dataLoan={loan}
            dataCurrencyBalances={currencyBalances}
            dataInterests={interests}
          />
        ) : (
          <EarnInterestCard dataInterest={interest} />
        )}
      </Grid>
    </Dialog>
  );
};
