import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { NotificationPaperComponent } from './NotificationPaper.component';
import Variant from '../../types/Variant.type';
import {
  Grid,
  createStyles,
  WithStyles,
  withStyles,
  Typography
} from '@material-ui/core';

const TEST_SITE_KEY = '6LeELbEZAAAAAFlcGnUhTHd3qFIgv3ZVWDe1gB-2';

const styles = () =>
  createStyles({
    signingUp: {
      marginBottom: 8
    },
    textPrivacy: {
      color: '#3E399F',
      textDecoration: 'none'
    },
    notificationError: {
      height: 50,
      marginBottom: 10
    }
  });

interface IProps extends WithStyles<typeof styles> {
  handleChange: () => void;
  error: boolean;
  susses: boolean;
  titleError: string;
  titleSusses: string;
  checkPrivacyPolicy: boolean;
}

class ReCaptcha extends React.Component<IProps> {
  public render() {
    const {
      handleChange,
      error,
      susses,
      classes,
      titleError,
      titleSusses,
      checkPrivacyPolicy
    } = this.props;
    return (
      <div>
        <Grid
          container
          justify="center"
          style={{ height: 80, marginBottom: 20 }}
        >
          {susses ? (
            <NotificationPaperComponent
              notification={Variant.SUCCESS}
              title={titleSusses}
            />
          ) : (
            <ReCAPTCHA
              hl={'en'}
              size={'normal'}
              sitekey={TEST_SITE_KEY}
              onChange={handleChange}
              badge="bottomright"
            />
          )}
        </Grid>
        {checkPrivacyPolicy ? (
          <Grid className={classes.signingUp}>
            <Typography variant="h5">
              By signing up you agree with our
              <span className={classes.textPrivacy}> T&C</span> and
              <span className={classes.textPrivacy}> Privacy Policy </span>
            </Typography>
          </Grid>
        ) : null}

        <Grid className={classes.notificationError}>
          {error ? (
            <NotificationPaperComponent
              notification={Variant.ERROR}
              title={titleError}
            />
          ) : null}
        </Grid>
      </div>
    );
  }
}

export const ReCaptchaComponent = withStyles(styles)(ReCaptcha);
