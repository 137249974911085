import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import image404 from '../../assets/images/404.svg';
import { NexoButton } from '../share';
import { PATH } from '../../constants/Path.constant';
import { historyBrowser } from '../../index';

const token = localStorage.getItem('token');

export const NotFoundComponent = () => {
  const history = historyBrowser;
  const redirectToPage = () => {
    if (!token) {
      history.push(PATH.nexo.login);
    } else {
      history.push(PATH.nexo.account);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Grid>
        <Grid container justify="center">
          <img src={image404} alt="not found" />
        </Grid>
        <Grid container justify="center" style={{ marginTop: 50 }}>
          <Typography
            variant="h1"
            style={{
              color: '#3E399F',
              fontWeight: 500,
              fontSize: 40,
              lineHeight: '49px'
            }}
          >
            Oops Where are we?
          </Typography>
        </Grid>
        <Grid container justify="center" style={{ marginTop: 13 }}>
          <Typography
            variant="h3"
            style={{ color: '#050F47', fontWeight: 500, lineHeight: '20px' }}
          >
            The page you are looking for was moved, removed, renamed or
          </Typography>
        </Grid>
        <Grid container justify="center">
          <Typography
            variant="h3"
            style={{ color: '#050F47', fontWeight: 500, lineHeight: '20px' }}
          >
            might never existed.
          </Typography>
        </Grid>
        <Grid container justify="center" style={{ marginTop: 105 }}>
          <Grid style={{ width: 400 }}>
            <NexoButton
              title="Go home"
              variant="contained"
              color="primary"
              handleRedirect={redirectToPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
