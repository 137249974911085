import { Store } from 'redux';
import { IStoreState } from '../types';
import { Dispatch } from 'react';
import {
  ILoadCurrenciesAction,
  setCurrenciesAction,
  ILoadDepositAddressAction,
  setDepositAddressAction
} from '../actions/currency.action';
import Axios, { AxiosResponse } from 'axios';

import {
  LOAD_CURRENCIES_ACTION,
  LOAD_DEPOSIT_ADDRESS_ACTION
} from '../constants/currency.constant';
import { IAccount } from '../types/currency.type';

export const currenciesMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<ILoadCurrenciesAction | ILoadDepositAddressAction>
) => async (action: ILoadCurrenciesAction | ILoadDepositAddressAction) => {
  switch (action.type) {
    case LOAD_CURRENCIES_ACTION: {
      Axios.get(`/users/balances`)
        .then((data: AxiosResponse<IAccount>) => {
          if (data.data) {
            store.dispatch(setCurrenciesAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case LOAD_DEPOSIT_ADDRESS_ACTION: {
      Axios.get(`/currencies/${action.payload}/deposit`, {
        params: {
          asset: action.payload
        }
      })
        .then((data: AxiosResponse<string>) => {
          if (data.data) {
            if (data.data) {
              store.dispatch(setDepositAddressAction(data.data));
            }
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
  }
  return dispatch(action);
};
