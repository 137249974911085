import React, { Dispatch } from 'react';
import {
  createStyles,
  WithStyles,
  withStyles,
  Paper,
  Grid,
  Typography
} from '@material-ui/core';
import iconEmail from '../../../assets/images/iconEmail.svg';
import { NexoButton } from '../../../components/share';
import { History } from 'history';
import { PATH } from '../../../constants/Path.constant';
import {
  IVerifyUserAction,
  verifyUserAction
} from '../../../actions/auth.action';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch: Dispatch<IVerifyUserAction>) => {
  return {
    verifyUserAction: (request: string) => dispatch(verifyUserAction(request))
  };
};

const styles = () =>
  createStyles({
    root: {
      alignItems: 'center',
      alignSelf: 'center'
    },
    rootPaper: {
      alignItems: 'flex-end',
      alignSelf: 'flex-end',
      display: 'flex'
    },
    textCongrats: {
      marginTop: 55
    },
    textContinue: {
      marginTop: 116,
      marginBottom: 10
    },
    widthButton: {
      width: 450
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History;
  verifyUserAction: (request: string) => void;
}

class VerifiedSussesAccount extends React.Component<IProps> {
  public redirectTo = () => {
    this.props.history.push(PATH.nexo.login);
  };

  public onClickContinueUsing = () => {
    const verifyToken = this.props.history.location.pathname.split('/')[2];

    this.props.verifyUserAction(verifyToken);
  };

  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper elevation={2} className={classes.rootPaper}>
          <Grid container>
            <Grid container justify="center">
              <img
                data-aos="zoom-out"
                data-aos-duration="1000"
                src={iconEmail}
                alt="icon email"
              />
            </Grid>
            <Grid container justify="center" className={classes.textCongrats}>
              <Typography variant="subtitle1">
                Congrats! Your email has been verified!
              </Typography>
            </Grid>
            <Grid container justify="center" className={classes.textContinue}>
              <Typography variant="h4">
                You can now continue using your Account.
              </Typography>
            </Grid>
            <Grid container justify="center" style={{ marginBottom: 57 }}>
              <Grid className={classes.widthButton}>
                <NexoButton
                  title="Continue using"
                  variant="contained"
                  color="primary"
                  handleRedirect={this.onClickContinueUsing}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export const VerifiedSussesAccountComponent = withRouter(
  connect(
    undefined,
    mapDispatchToProps
  )(withStyles(styles)(VerifiedSussesAccount))
);
