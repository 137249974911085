import React, { Dispatch } from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { IStoreState } from '../../../types';
import {
  loadUserProfile,
  ILoadUserProfileAction,
  editUserProfileAction,
  IEditUserProfileAction
} from '../../../actions/user.action';
import { History } from 'history';
import { IUserInfo, IEditUserProfileRequest } from '../../../types/user.type';
import { NexoButton } from '../../../components/share';
import { BasicVerificationDialogComponent } from '../../../components/dialog/BasicVerification.dialog.component';
import { VerifyPhoneNumberDialogComponent } from '../../../components/dialog/VerifyPhoneNumber.dialog.component';
import { EnterVerifyPhoneDialogComponent } from '../../../components/dialog/EnterVerifyPhone.dialog.component';
import Axios from 'axios';
import ICountry from '../../../types/country.type';
import { VerifyUserDialog } from '../../../components/dialog/VerifyUser.dialog.component';
import { PATH } from '../../../constants/Path.constant';
import { ContentAccount } from '../../../components/share/ContentAccount.component';
import { CardAttentionAccount } from '../../../components/share/CardAttentionAccount.component';
import { CardAccountInfo } from '../../../components/share/CardAccountInfo.component';

interface IParams {
  pageNumber: number;
}

function mapStateToProps(
  { userManagement }: IStoreState,
  { history }: RouteComponentProps<{}, {}, IParams>
) {
  return {
    userProfile: userManagement.userProfile,
    history
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<ILoadUserProfileAction | IEditUserProfileAction>
) => {
  return {
    loadUserProfile: () => dispatch(loadUserProfile()),
    editUserProfileAction: (requset: IEditUserProfileRequest) =>
      dispatch(editUserProfileAction(requset))
  };
};

const styles = () =>
  createStyles({
    root: {
      minHeight: '600px',
      padding: 0
    },
    marginAccountInfo: {
      marginBottom: 23
    },
    paddingBasic: {
      paddingRight: 10
    },
    paddingAttention: {
      paddingLeft: 10
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History<IParams>;
  loadUserProfile: () => void;
  editUserProfileAction: (request: IEditUserProfileRequest) => void;
  userProfile: IUserInfo;
}

interface IState {
  userProfile: IUserInfo;
  showDialog: boolean;
  showVerifyPhoneNumber: boolean;
  showEnterVerifyPhone: boolean;
  errorVerify: boolean;
  errorEnterVerifyPhone: boolean;
  codeSMS: string;
  selectedCountry: ICountry[];
  isVerifyDialog: boolean;
  errorInput: {
    firstName: boolean;
    lastName: boolean;
    city: boolean;
    address: boolean;
    postCode: boolean;
  };
}

class MyProfile extends React.Component<IProps, IState> {
  public state: IState = {
    userProfile: this.props.userProfile,
    showDialog: false,
    showVerifyPhoneNumber: false,
    showEnterVerifyPhone: false,
    errorVerify: false,
    errorEnterVerifyPhone: false,
    codeSMS: '',
    selectedCountry: [],
    isVerifyDialog: false,
    errorInput: {
      firstName: false,
      lastName: false,
      city: false,
      address: false,
      postCode: false
    }
  };

  // tslint:disable-next-line
  UNSAFE_componentWillReceiveProps = (props: IProps) => {
    this.setState({
      userProfile: props.userProfile
    });
  };

  public handleTextChange = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      userProfile: {
        ...this.state.userProfile,
        [key]: event.target.value
      }
    });
  };
  public handleChangeInputVerifyPhone = (phone: string) => {
    this.setState({
      userProfile: {
        ...this.state.userProfile,
        phone
      }
    });
  };

  public handleChangeInputCodeSMS = (codeInput: string) => {
    this.setState({ codeSMS: codeInput });
  };

  public handleOnChangeCountry = (selectedCountry: ICountry[]) => {
    this.setState({
      selectedCountry
    });
  };

  public onChangeShowDialogVerifyPhoneNumber = () => {
    this.setState({
      showVerifyPhoneNumber: true,
      showEnterVerifyPhone: false
    });
  };

  public onChangeShowDialogEnterVerifyPhone = () => {
    Axios.post('/users/verify/phone', {
      phone: this.state.userProfile.phone
    })
      .then(() => {
        this.setState({
          showEnterVerifyPhone: true,
          showVerifyPhoneNumber: false,
          errorEnterVerifyPhone: false
        });
      })
      .catch(err => {
        const errorMsg: string =
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.message;
        if (errorMsg === 'Invalid value for param: number') {
          this.setState({
            errorVerify: true,
            showEnterVerifyPhone: false,
            showVerifyPhoneNumber: true
          });
        } else {
          this.setState({
            showEnterVerifyPhone: true,
            showVerifyPhoneNumber: false
          });
        }
      });
  };
  public onChangeShowDialogBasicVerification = () => {
    const { userProfile, selectedCountry } = this.state;

    const request: IEditUserProfileRequest = {
      countryId: selectedCountry[0].id,
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      phone: userProfile.phone,
      city: userProfile.city,
      address: userProfile.address,
      postCode: userProfile.postCode,
      codeSMS: this.state.codeSMS
    };
    Axios.put('/user/verification/basic', request)
      .then(() => {
        this.setState({
          showEnterVerifyPhone: false,
          showVerifyPhoneNumber: false,
          showDialog: true
        });
      })
      .catch(err => {
        this.setState({
          showEnterVerifyPhone: true,
          showVerifyPhoneNumber: false,
          errorEnterVerifyPhone: true
        });
      });
  };

  public handleShowIsVerify = () => {
    this.setState({
      isVerifyDialog: true
    });
  };

  public onClose = () => {
    this.setState({
      showDialog: false,
      showVerifyPhoneNumber: false,
      showEnterVerifyPhone: false,
      errorVerify: false,
      errorEnterVerifyPhone: false
    });
  };

  public onCloseBasicVerification = () => {
    this.setState({
      showDialog: false
    });
    this.props.loadUserProfile();
  };

  public redirectTo = () => {
    this.props.history.push(PATH.nexo.security);
  };

  public render() {
    const { classes } = this.props;
    const { userProfile, errorInput } = this.state;
    const isEmpty =
      userProfile.firstName === null ||
      userProfile.lastName === null ||
      userProfile.city === null ||
      userProfile.postCode === null ||
      userProfile.address === null ||
      userProfile.phone === null;
    const isError =
      this.state.selectedCountry.length === 0 ||
      userProfile.verificationLevel1 === true
        ? true
        : false ||
          errorInput.firstName ||
          errorInput.lastName ||
          errorInput.postCode ||
          errorInput.city ||
          errorInput.address;
    return (
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid container className={classes.marginAccountInfo}>
            <Grid
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-duration="300"
              item
              xs={9}
              className={classes.paddingBasic}
            >
              <CardAccountInfo
                pageNumber={
                  this.props.history.location.state
                    ? this.props.history.location.state.pageNumber
                    : 1
                }
                userProfile={userProfile}
                handleChangeInputVerifyPhone={this.handleChangeInputVerifyPhone}
                handleTextChange={this.handleTextChange}
                handleOnChangeCountry={this.handleOnChangeCountry}
                selectedCountry={this.state.selectedCountry}
              />
            </Grid>

            <Grid
              data-aos="fade-left"
              data-aos-offset="300"
              data-aos-duration="300"
              item
              xs={3}
              className={classes.paddingAttention}
            >
              <Grid>
                <NexoButton
                  title="Submit"
                  variant="contained"
                  color="primary"
                  disabled={isEmpty || isError}
                  handleRedirect={
                    userProfile.isVerified === true
                      ? this.onChangeShowDialogVerifyPhoneNumber
                      : this.handleShowIsVerify
                  }
                />
              </Grid>
              <CardAttentionAccount />
            </Grid>
          </Grid>
          <ContentAccount />
        </Grid>
        <BasicVerificationDialogComponent
          handleCloseDialog={this.onCloseBasicVerification}
          showDialog={this.state.showDialog}
          textHeader="Basic Verification"
        />

        <VerifyPhoneNumberDialogComponent
          value={userProfile.phone}
          handleChangeInputVerifyPhone={this.handleChangeInputVerifyPhone}
          handleEnterVerifyPhone={this.onChangeShowDialogEnterVerifyPhone}
          handleCloseDialog={this.onClose}
          showDialog={this.state.showVerifyPhoneNumber}
          errorVerify={this.state.errorVerify}
          textHeader="Verify Phone Number"
        />
        <EnterVerifyPhoneDialogComponent
          errorVerify={this.state.errorEnterVerifyPhone}
          handleChangeInputCodeSMS={this.handleChangeInputCodeSMS}
          valueCodeSms={this.state.codeSMS}
          handleShowVerifyPhone={this.onChangeShowDialogVerifyPhoneNumber}
          onChangeShowDialogBasicVerification={
            this.onChangeShowDialogBasicVerification
          }
          handleCloseDialog={this.onClose}
          showDialog={this.state.showEnterVerifyPhone}
          textHeader="Verify Phone Number"
        />
        <VerifyUserDialog
          showDialog={this.state.isVerifyDialog}
          handleCloseDialog={this.redirectTo}
          textHeader="Required"
        />
      </div>
    );
  }
}

export const MyProfileComponent = withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyProfile))
);
