import React from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { NexoPaper, NexoButton } from '../../../components/share/index';
import { Link } from 'react-router-dom';
import { PATH } from '../../../constants/Path.constant';
import iconsucces from '../../../assets/images/iconsuccesDialog.svg';
import { History } from 'history';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      alignSelf: 'center',
      '& .NexoPaper-root-3 .MuiPaper-root': {
        height: '380px'
      }
    },
    contentText: {
      margin: '15px 0px',
      '& .MuiTypography-root': {
        fontSize: 12
      }
    },
    textPrivacy: {
      color: '#3E399F',
      textDecoration: 'none'
    },
    notification: {
      height: 80,
      border: '1px solid #34CEA9',
      background: 'rgba(52, 206, 169, 0.05)',
      boxSizing: 'border-box',
      borderRadius: 5,
      marginBottom: 58,
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '60px calc(100% - 60px)'
    },
    imgIconSucces: {
      marginLeft: 21,
      marginRight: 15
    },
    textCheckMail: {
      color: '#34CEA9',
      fontWeight: 500,
      paddingRight: 47
    },
    textFooter: {
      color: '#050F47'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History;
}

class VerifyForgotPassword extends React.Component<IProps, {}> {
  public redirectTo = () => {
    this.props.history.push(PATH.nexo.login);
  };

  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <NexoPaper title="Forgot Password" numberElevation={3}>
          <Grid className={classes.notification}>
            <img
              src={iconsucces}
              width={25}
              height={25}
              className={classes.imgIconSucces}
              alt="icon succes"
            />
            <Typography variant="h4" className={classes.textCheckMail}>
              Please check your email for instructions how to reset your
              password
            </Typography>
          </Grid>

          <Grid>
            <NexoButton
              title="Got it"
              variant="contained"
              color="primary"
              size="large"
              handleRedirect={this.redirectTo}
            />
          </Grid>
        </NexoPaper>
        <Grid container justify="center" className={classes.contentText}>
          <Typography variant="h4" className={classes.textFooter}>
            Don’t have Account? &nbsp;
            <Link to={PATH.nexo.newAccount} className={classes.textPrivacy}>
              Create new account
            </Link>
            &nbsp; - &nbsp;
            <Link to={PATH.nexo.login} className={classes.textPrivacy}>
              Login
            </Link>
          </Typography>
        </Grid>
      </div>
    );
  }
}

export const VerifyForgotPasswordComponent = withStyles(styles)(
  VerifyForgotPassword
);
