import React from 'react';
import PinInput from 'react-pin-input';

interface IProps {
  handleTextChangeCode: (codeInput: string) => void;
}
export const NexoInputVerifyCode = (props: IProps) => {
  const { handleTextChangeCode } = props;
  return (
    <PinInput
      length={6}
      secret={false}
      type="numeric"
      onChange={handleTextChangeCode}
      style={{
        padding: '10px'
      }}
      inputStyle={{
        borderColor: '#D7DCE0',
        width: 40,
        height: 40,
        color: '#050F47',
        fontSize: 16
      }}
      inputFocusStyle={{ borderColor: '#34CEA9' }}
    />
  );
};
