import {
  ISetTransactionAction,
  ISetAssetTransactionAction
} from '../actions/transaction.action';
import {
  SET_TRANSACTION_ACTION,
  SET_ASSET_TRANSACTION_ACTION
} from '../constants/transaction.constant';
import { IObjectTransaction } from '../types/transaction.type';
import { ICurrency } from '../types/currency.type';

const emptyObjectTransactions: IObjectTransaction = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0
  }
};

export const transactions = (
  state: IObjectTransaction = emptyObjectTransactions,
  action: ISetTransactionAction
): IObjectTransaction => {
  if (action.type === SET_TRANSACTION_ACTION) {
    return action.payload;
  }
  return state;
};

export const assetTransactions = (
  state: ICurrency[] = [],
  action: ISetAssetTransactionAction
): ICurrency[] => {
  if (action.type === SET_ASSET_TRANSACTION_ACTION) {
    return action.payload;
  }
  return state;
};
