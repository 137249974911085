import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Grid,
  Typography,
  makeStyles,
  BottomNavigation,
  BottomNavigationAction
} from '@material-ui/core';
import { NexoButton } from '../share';
import { IUserInfo } from '../../types/user.type';
import { Scrollbars } from 'react-custom-scrollbars';
import { AccountWithDrawCard } from '../share/CardAccountWithDraw.component';
import { EstimateWithdrawCard } from '../share/CardEstimatedWithdraw.component';
import { BasicVerifNotification } from '../calculator/BasicVerifNotification.component';
import { TwoFactorAuthNotification } from '../calculator/TwoFactorAuthNotification.component';
import {
  IObjectWithdrawType,
  ILoanTetherRequest
} from '../../types/withdrawFund.type';
import { ILoanCreditLineRequest } from '../../types/withdrawFund.type';
import ICountry from '../../types/country.type';
import {
  loanCreditLineAction,
  loanTetherAction
} from '../../actions/withdrawFund.action';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatNumberToFixed,
  getConfigsCurrencyUsd,
  getConfigsUsdtToUsd
} from '../../helper';
import { WITHDRAWAL_TYPE } from '../../constants/withdrawAsset.constant';
import { IStoreState } from '../../types';
import { WithdrawLoanEmpty } from '../withdrawalFunds/WithdrawLoanEmpty';
import { InputWithdraw } from '../share/InputWithdraw.component';
import { WITHDRAWAL_LOAN_MENU } from '../../constants/withdrawFund.constant';
import { WithdrawUSDTCard } from '../withdrawalFunds/WithdrawUSDTCard';
import { useHistory } from 'react-router';
import { PATH } from '../../constants/Path.constant';
import { NexoInputVerifyCode } from '../share/NexoInputVerifyCode.component';
import { IMAGE } from '../../constants/image.constant';
const styles = makeStyles({
  rootDialog: {
    '& .MuiDialog-paperWidthLg': {
      width: 600,
      maxWidth: 600
    }
  },
  dialogTitle: {
    padding: 0
  },
  rootHeader: {
    height: 80,
    borderBottom: '1px solid #E4EAEE',
    background: '#F9FAFB',
    boxSizing: 'border-box',
    paddingLeft: 35,
    paddingRight: 35
  },

  buttonCancel: {
    width: 150,
    marginRight: 7
  },
  buttonVerify: {
    width: 150
  },
  rootScrollbar: {
    minWidth: 'calc(100%)'
  },
  rootAdvanced: {
    height: 50,
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5
  },
  iconAdvanced: {
    paddingLeft: 18,
    paddingRight: 8
  },
  textAdvanced: {
    color: '#F2994A'
  },
  menuHeader: {
    width: '100%',
    '& .MuiButtonBase-root.MuiBottomNavigationAction-root': {
      backgroundColor: '#f5fafc',
      '& .Mui-selected': {
        '&:after': {
          backgroundColor: 'transparent'
        }
      }
    },
    '& .MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected': {
      background: '#30bdfc !important',
      '& span': {
        color: '#FFFFFF !important'
      }
    },
    '& .MuiBottomNavigation-root': {
      marginTop: 2
    }
  },
  errorGroup: {
    fontSize: 12,
    color: '#E3001E',
    fontFamily: 'Montserrat'
  },
  errorIcon: {
    marginRight: 10,
    width: 15.11,
    height: 13.33
  }
});

interface IProps {
  showDialog: boolean;
  handleClose: () => void;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  userProfile: IUserInfo;
  dataWithdrawTypes: IObjectWithdrawType;
  available: number;
}

export const LoanWithdrawDialogComponent = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loanCreditLine, setLoanCreditLine] = useState({
    accountNumber: '',
    address: '',
    amount: '',
    city: '',
    countryId: '',
    description: '',
    postCode: '',
    recepientName: '',
    recipientType: '',
    swiftCode: '',
    transferType: '',
    usdt: '0',
    walletAddress: '',
    code: ''
  });
  const [valueMenu, setValueMenu] = useState(
    WITHDRAWAL_LOAN_MENU.BANK_TRANSFER
  );
  const [selectedCountry, setSelectedCountry] = useState<ICountry[]>([]);
  const [errorInputCode, setErrorInputCode] = useState<boolean>(false);
  const [errorInput, setErrorInput] = useState<boolean>(false);
  const currencyBalances = useSelector(
    (state: IStoreState) => state.currencies.currencyBalances
  );

  useEffect(() => {
    const { dataWithdrawTypes } = props;
    const defaultValue = {
      accountNumber: '',
      address: '',
      amount: '0',
      city: '',
      countryId: '',
      description: '',
      postCode: '',
      recepientName: '',
      recipientType: '',
      swiftCode: '',
      transferType: '',
      usdt: '0',
      walletAddress: '',
      code: ''
    };
    setLoanCreditLine({
      ...defaultValue,
      address: props.userProfile.bankAccount
        ? props.userProfile.bankAccount.address
        : props.userProfile.address,
      recepientName: props.userProfile.bankAccount
        ? props.userProfile.bankAccount.name
        : `${props.userProfile.firstName} ${props.userProfile.lastName} `,
      postCode: props.userProfile.bankAccount
        ? props.userProfile.bankAccount.postalCode
        : props.userProfile.postCode,
      transferType: dataWithdrawTypes.transferType[0]
        ? dataWithdrawTypes.transferType[0].type
        : '',
      recipientType: dataWithdrawTypes.recipientType[0]
        ? dataWithdrawTypes.recipientType[0].type
        : '',
      swiftCode: props.userProfile.bankAccount
        ? props.userProfile.bankAccount.swiftCode
        : '',
      accountNumber: props.userProfile.bankAccount
        ? props.userProfile.bankAccount.accountNumber
        : '',
      countryId: props.userProfile.bankAccount
        ? props.userProfile.bankAccount.countryId
        : props.userProfile.countryId,
      city: props.userProfile.bankAccount
        ? props.userProfile.bankAccount.city
        : '',
      description: props.userProfile.bankAccount
        ? props.userProfile.bankAccount.description
        : ''
    });
    // eslint-disable-next-line
  }, [props.userProfile]);

  const handleChangeInput = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoanCreditLine({
      ...loanCreditLine,
      [key]: event.target.value
    });
  };

  const handleChangeInputWithdrawalAmount = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newValueSubString: string = '';
    let isErrorInput: boolean = false;
    if (event.target.value.substring(0, 1) === '0') {
      newValueSubString = event.target.value.substring(1);
    } else {
      newValueSubString = event.target.value;
    }
    if (
      Number(newValueSubString) < 500 ||
      Number(newValueSubString) > 2000000
    ) {
      isErrorInput = true;
    } else {
      isErrorInput = false;
    }
    setErrorInput(isErrorInput);
    setLoanCreditLine({
      ...loanCreditLine,
      [key]: newValueSubString
    });
  };
  // tslint:disable-next-line
  const handleOnChangeCountry = (selectedCountry: ICountry[]) => {
    setSelectedCountry(selectedCountry);
  };

  const onClickBankTransfer = () => {
    const currencyId: string = String(
      getConfigsCurrencyUsd('USD', currencyBalances)
    );
    const request: ILoanCreditLineRequest = {
      accountNumber: loanCreditLine.accountNumber,
      address: loanCreditLine.address,
      city: loanCreditLine.city,
      countryId: selectedCountry[0]
        ? selectedCountry[0].id
        : props.userProfile.countryId,
      description: loanCreditLine.description,
      postalCode: loanCreditLine.postCode,
      recipientName: loanCreditLine.recepientName,
      recipientType: loanCreditLine.recipientType,
      swiftCode: loanCreditLine.swiftCode,
      transferType: loanCreditLine.transferType,
      amount: Number(loanCreditLine.amount),
      withdrawalType: WITHDRAWAL_TYPE.LOAN,
      currencyId,
      code: loanCreditLine.code
    };
    if (loanCreditLine.code === '') {
      setErrorInputCode(true);
    } else {
      dispatch(loanCreditLineAction(request));
    }
  };

  const onClickTether = () => {
    const currencyId: string = String(
      getConfigsCurrencyUsd('USDT', currencyBalances)
    );
    const request: ILoanTetherRequest = {
      withdrawalType: WITHDRAWAL_TYPE.LOAN,
      currencyId,
      amount: Number(loanCreditLine.usdt),
      description: loanCreditLine.description,
      recipientAddress: loanCreditLine.walletAddress,
      code: loanCreditLine.code
    };
    if (loanCreditLine.code === '') {
      setErrorInputCode(true);
    } else {
      dispatch(loanTetherAction(request));
    }
  };

  const handleTextCodeVerifyChange = (codeInput: string) => {
    setLoanCreditLine({
      ...loanCreditLine,
      code: codeInput
    });
  };

  const handleChangeBottomNavigation = () => {
    if (valueMenu === WITHDRAWAL_LOAN_MENU.BANK_TRANSFER) {
      setValueMenu(WITHDRAWAL_LOAN_MENU.TETHER);
    } else {
      setValueMenu(WITHDRAWAL_LOAN_MENU.BANK_TRANSFER);
    }
    setErrorInput(false);
    setLoanCreditLine({
      ...loanCreditLine,
      amount: '0',
      usdt: '0'
    });
  };

  const handleWithdrawAmount = (valueAmount: number) => () => {
    if (valueMenu === WITHDRAWAL_LOAN_MENU.BANK_TRANSFER) {
      setLoanCreditLine({
        ...loanCreditLine,
        amount: String(valueAmount)
      });
    } else {
      setLoanCreditLine({
        ...loanCreditLine,
        usdt: String(valueAmount)
      });
    }
  };

  const renderLoanWithdraw = () => {
    const usdPriceUsdt = getConfigsUsdtToUsd('USDT', currencyBalances);
    const valueUsdt = Number(usdPriceUsdt) * props.available;

    const disabledBankTransfer: boolean =
      loanCreditLine.accountNumber === '' ||
      loanCreditLine.address === '' ||
      loanCreditLine.amount === '0' ||
      loanCreditLine.city === '' ||
      loanCreditLine.postCode === '' ||
      loanCreditLine.recepientName === '' ||
      loanCreditLine.recipientType === '' ||
      loanCreditLine.swiftCode === '' ||
      loanCreditLine.transferType === '' ||
      errorInput === true ||
      props.available - Number(loanCreditLine.amount) < 0
        ? true
        : false;

    const disableTether: boolean =
      loanCreditLine.usdt === '0' ||
      loanCreditLine.walletAddress === '' ||
      errorInput === true ||
      valueUsdt - Number(loanCreditLine.usdt) < 0
        ? true
        : false;
    return (
      <React.Fragment>
        <Grid container alignItems="center" className={classes.rootHeader}>
          <Grid container alignItems="center">
            <Typography
              variant="subtitle1"
              style={{ color: '#050F47', marginRight: 14 }}
            >
              Withdraw Funds From Credit Line
            </Typography>
          </Grid>
        </Grid>
        <Scrollbars
          style={{
            overflow: 'hidden',
            width: '100%',
            minHeight: 600
          }}
        >
          <Grid style={{ padding: 35 }}>
            <Grid
              style={{
                border: '1px solid #D7DCE0',
                boxSizing: 'border-box',
                borderRadius: 5,
                height: 50,
                background: '#F9FAFB'
              }}
              container
              alignItems="center"
              justify="center"
            >
              <Typography variant="h2" style={{ color: '#050F47' }}>
                You can withdraw up to {''}
                <span
                  onClick={handleWithdrawAmount(
                    valueMenu === WITHDRAWAL_LOAN_MENU.BANK_TRANSFER
                      ? props.available
                      : valueUsdt
                  )}
                  style={{ color: '#20bcff', cursor: 'pointer' }}
                >
                  ${' '}
                  {valueMenu === WITHDRAWAL_LOAN_MENU.BANK_TRANSFER
                    ? formatNumberToFixed(props.available, 2)
                    : formatNumberToFixed(valueUsdt, 2)}
                </span>
              </Typography>
            </Grid>

            <Grid
              style={{
                border: '1px solid #D7DCE0',
                boxSizing: 'border-box',
                borderRadius: 5,
                padding: 15,
                marginTop: 20
              }}
            >
              <Typography variant="h4"> Choose Transfer Method</Typography>
              <Grid
                style={{
                  border: '1px solid #D7DCE0',
                  boxSizing: 'border-box',
                  borderRadius: 3,
                  padding: 5,
                  height: 50,
                  marginTop: 5
                }}
              >
                <Grid container className={classes.menuHeader}>
                  <BottomNavigation
                    showLabels
                    value={valueMenu}
                    onClick={handleChangeBottomNavigation}
                  >
                    <BottomNavigationAction
                      label="Bank Transfer"
                      value={WITHDRAWAL_LOAN_MENU.BANK_TRANSFER}
                    />
                    <BottomNavigationAction
                      label="Tether (USDT)"
                      value={WITHDRAWAL_LOAN_MENU.TETHER}
                    />
                  </BottomNavigation>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: 20 }}>
                {valueMenu === WITHDRAWAL_LOAN_MENU.BANK_TRANSFER ? (
                  <InputWithdraw
                    valueInput={loanCreditLine.amount}
                    contentLeft="Withdrawal Amount"
                    iconInputLeft={true}
                    title="USD"
                    errorInput={errorInput}
                    placeHolderInput={
                      errorInput === true
                        ? 'Please, enter amount in range $500 => $2.000.000'
                        : ''
                    }
                    name="amount"
                    type="number"
                    minMaxInput={{
                      min: '0',
                      step: '0.01',
                      max: String(available)
                    }}
                    disabledInput={false}
                    handleChangeInput={handleChangeInputWithdrawalAmount}
                  />
                ) : (
                  <WithdrawUSDTCard
                    errorInput={errorInput}
                    loanCreditLine={loanCreditLine}
                    handleChangeInput={handleChangeInputWithdrawalAmount}
                    maxValueUSDT={20}
                  />
                )}
              </Grid>
            </Grid>
            {valueMenu === WITHDRAWAL_LOAN_MENU.BANK_TRANSFER ? (
              <React.Fragment>
                <Grid style={{ marginTop: 20 }}>
                  <EstimateWithdrawCard
                    valueUsd={loanCreditLine.amount}
                    availableCredit={props.available}
                  />
                </Grid>
                <AccountWithDrawCard
                  handleChangeInput={handleChangeInput}
                  loanCreditLine={loanCreditLine}
                  handleOnChangeCountry={handleOnChangeCountry}
                  selectedCountry={selectedCountry}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid style={{ marginTop: 20, marginBottom: 20 }}>
                  <EstimateWithdrawCard
                    valueUsd={loanCreditLine.usdt}
                    availableCredit={valueUsdt}
                  />
                </Grid>
                <InputWithdraw
                  valueInput={loanCreditLine.walletAddress}
                  name="walletAddress"
                  contentLeft="Wallet Address"
                  iconInputLeft={false}
                  disabledInput={false}
                  handleChangeInput={handleChangeInput}
                />
              </React.Fragment>
            )}
            <Grid container style={{ marginTop: 20 }}>
              <Typography variant="h5">
                What is the sourse of founds for the crypto assets you are using
                as collateral for the loan?{' '}
              </Typography>
              <InputWithdraw
                valueInput={loanCreditLine.description}
                name="description"
                contentLeft=""
                iconInputLeft={false}
                disabledInput={false}
                handleChangeInput={handleChangeInput}
              />
            </Grid>
            <Grid
              container
              justify="center"
              alignContent="center"
              style={{
                marginTop: 20,
                marginBottom: 20
              }}
            >
              <Grid container justify="center" item xs={12}>
                <Typography variant="h4">
                  Enter the two-step Authentication code
                </Typography>
              </Grid>
              <NexoInputVerifyCode
                handleTextChangeCode={handleTextCodeVerifyChange}
              />

              {errorInputCode ? (
                <Grid container justify="center">
                  <Typography className={classes.errorGroup}>
                    <img
                      className={classes.errorIcon}
                      src={IMAGE.ICON_ERROR}
                      alt="errorIcon"
                    />
                    Please enter verify code
                  </Typography>
                </Grid>
              ) : null}
            </Grid>

            <Grid container justify="center" style={{ marginTop: 20 }}>
              <Grid className={classes.buttonCancel}>
                <NexoButton
                  title="Cancel"
                  variant="outlined"
                  color="primary"
                  heightButton={40}
                  handleRedirect={props.handleClose}
                />
              </Grid>

              <Grid className={classes.buttonVerify}>
                <NexoButton
                  disabled={
                    valueMenu === WITHDRAWAL_LOAN_MENU.BANK_TRANSFER
                      ? disabledBankTransfer
                      : disableTether
                  }
                  title="Withdraw"
                  variant="contained"
                  color="primary"
                  heightButton={40}
                  handleRedirect={
                    valueMenu === WITHDRAWAL_LOAN_MENU.BANK_TRANSFER
                      ? onClickBankTransfer
                      : onClickTether
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Scrollbars>
      </React.Fragment>
    );
  };

  const { handleClose, maxWidth, showDialog, available, userProfile } = props;
  const classes = styles();
  const handleRedirectMyProfileAdvanced = () => {
    history.push(PATH.nexo.myProfile, {
      pageNumber: 2
    });
  };
  const handleRedirectMyProfileBasic = () => {
    history.push(PATH.nexo.myProfile, {
      pageNumber: 1
    });
  };
  const handleRedirectSecurity = () => {
    history.push(PATH.nexo.security);
  };
  return (
    <Dialog
      className={classes.rootDialog}
      onClose={handleClose}
      open={showDialog}
      maxWidth={maxWidth}
    >
      <Grid className={classes.dialogTitle}>
        {userProfile.verificationLevel1 === false ? (
          <BasicVerifNotification
            message="You first need to complete Basic Verification"
            handleClose={handleRedirectMyProfileBasic}
          />
        ) : userProfile.verificationLevel2 === false ? (
          <BasicVerifNotification
            message="You second need to complete Advanced Verification"
            handleClose={handleRedirectMyProfileAdvanced}
          />
        ) : userProfile.isGoogleAuthenticator === false ? (
          <TwoFactorAuthNotification handleClose={handleRedirectSecurity} />
        ) : available > 0 ? (
          renderLoanWithdraw()
        ) : (
          <WithdrawLoanEmpty handleClose={handleClose} />
        )}
      </Grid>
    </Dialog>
  );
};
