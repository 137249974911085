import { Store } from 'redux';
import { IStoreState } from '../types';
import { Dispatch } from 'react';
import {
  ILoadUserProfileAction,
  setUserProfileAction,
  IEditUserProfileAction,
  loadUserProfile,
  ILoadUserLoginHistoryAction,
  setUserLoginHistoryAction,
  ILoadDataGoogleAuthenticatorAction,
  setDataGoogleAuthenticatorAction,
  IProcessAuthGoogleAction,
  IProcessUploadImageForVerifyAction,
  setGoogleAuthCodeAction
} from '../actions/user.action';
import {
  LOAD_USER_PROFILE,
  EDIT_USER_PROFILE,
  LOAD_USER_HISTORY_LOGIN,
  LOAD_DATA_GOOGLE_AUTH_OF_USER,
  PROCESS_GOOGLE_AUTH_FOR_USER,
  PROCESS_UPLOAD_IMAGE_FOR_VERIFY
} from '../constants/user.constant';
import Axios, { AxiosResponse } from 'axios';
import {
  IUserInfo,
  IObjectLoginHistoryInfo,
  IDataGoogleAuthenticatorInfo,
  IGoogleAuthCodeInfo
} from '../types/user.type';
import { fireNotification } from '../actions/Notification.action';
import {
  MESSAGE_NOTIFICATION_SUCCESS,
  MESSAGE_NOTIFICATION_ERROR
} from '../constants/Notification.constant';
import Variant from '../types/Variant.type';

export const userProfileMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | ILoadUserProfileAction
    | IEditUserProfileAction
    | ILoadUserLoginHistoryAction
    | ILoadDataGoogleAuthenticatorAction
    | IProcessAuthGoogleAction
    | IProcessUploadImageForVerifyAction
  >
) => async (
  action:
    | ILoadUserProfileAction
    | IEditUserProfileAction
    | ILoadUserLoginHistoryAction
    | ILoadDataGoogleAuthenticatorAction
    | IProcessAuthGoogleAction
    | IProcessUploadImageForVerifyAction
) => {
  switch (action.type) {
    case LOAD_USER_PROFILE: {
      Axios.get(`/user/profile`)
        .then((data: AxiosResponse<IUserInfo>) => {
          if (data.data) {
            store.dispatch(
              setUserProfileAction({
                ...data.data
              })
            );
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case EDIT_USER_PROFILE: {
      Axios.put(`/user/verification/basic`, {
        countryId: action.payload.countryId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phone: action.payload.phone,
        city: action.payload.city,
        address: action.payload.address,
        postCode: action.payload.postCode,
        codeSMS: action.payload.codeSMS
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.EDIT_PROFILE,
              variant: Variant.SUCCESS
            })
          );
          store.dispatch(loadUserProfile());
        })
        .catch((err: Error) => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case LOAD_USER_HISTORY_LOGIN: {
      Axios.get(`/loginhistories`, {
        params: {
          limit: action.payload.limit,
          offset: action.payload.offset
        }
      })
        .then((data: AxiosResponse<IObjectLoginHistoryInfo>) => {
          if (data.data) {
            store.dispatch(
              setUserLoginHistoryAction({
                ...data.data
              })
            );
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case LOAD_DATA_GOOGLE_AUTH_OF_USER: {
      Axios.get(`/users/2nd-factor`)
        .then((data: AxiosResponse<IDataGoogleAuthenticatorInfo>) => {
          if (data.data) {
            store.dispatch(
              setDataGoogleAuthenticatorAction({
                ...data.data
              })
            );
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case PROCESS_GOOGLE_AUTH_FOR_USER: {
      Axios.post(`/users/2nd-factor`, action.payload)
        .then(() => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.GOOGLE_AUTH,
              variant: Variant.SUCCESS
            })
          );
          const data: IGoogleAuthCodeInfo = {
            code: action.payload.code
          };
          store.dispatch(setGoogleAuthCodeAction(data));
          store.dispatch(loadUserProfile());
        })
        .catch((err: Error) => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_ERROR.GOOGLE_AUTH,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case PROCESS_UPLOAD_IMAGE_FOR_VERIFY: {
      Axios.post(`/users/verification/images`, action.payload, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.EDIT_AVATAR,
              variant: Variant.SUCCESS
            })
          );
          store.dispatch(loadUserProfile());
        })
        .catch((err: Error) => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
  }
  return dispatch(action);
};
