import React from 'react';
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import iconFooter from '../../assets/images/iconFooter.svg';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#363C42',
      height: 70,
      borderTop: '1px solid #D7DCE0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      [theme.breakpoints.up('lg')]: {
        width: 1170
      },
      [theme.breakpoints.down('md')]: {
        width: 970
      },
      [theme.breakpoints.down('sm')]: {
        width: 750
      },
      [theme.breakpoints.down('xs')]: {
        width: '95%'
      }
    },
    text: {
      color: '#ffffff',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 2,
      textAlign: 'center'
    }
  });

interface IProps extends WithStyles<typeof styles> {}

interface IState {
  currentYear: string;
}
class Footer extends React.Component<IProps, IState> {
  state: IState = {
    currentYear: '2020'
  };
  // tslint:disable-next-line
  UNSAFE_componentWillMount() {
    const currentTime = new Date();
    const currentYear = currentTime.getFullYear().toString();
    return this.setState({ currentYear });
  }
  public render() {
    const { classes } = this.props;
    const { currentYear } = this.state;
    return (
      <div className={classes.root}>
        <Grid className={classes.content}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <img src={iconFooter} alt="icon footer" />
            </Grid>
            <Grid container justify="flex-end" item xs={6}>
              <Typography className={classes.text}>
                © {currentYear} Crypto. All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export const FooterComponent = withStyles(styles)(Footer);
