import React, { Dispatch } from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  NexoInput,
  NexoPaper,
  NexoButton
} from '../../../components/share/index';
import { Link, withRouter } from 'react-router-dom';
import { PATH } from '../../../constants/Path.constant';
import { History } from 'history';
import { ReCaptchaComponent } from '../../../components/share/ReCaptcha.component';
import { connect } from 'react-redux';
import { ISignUpAction, signUp } from '../../../actions/auth.action';
import { ISignUpRequest } from '../../../types/auth.type';
import { validateEmail } from '../../../helper';

const mapDispatchToProps = (dispatch: Dispatch<ISignUpAction>) => {
  return {
    signUp: (request: ISignUpRequest) => dispatch(signUp(request))
  };
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      alignSelf: 'center'
    },
    content_text: {
      margin: '15px 0px',
      '& .MuiTypography-root': {
        fontSize: 12
      }
    },
    signingUp: {
      marginBottom: 58
    },
    textPrivacy: {
      color: '#3E399F',
      textDecoration: 'none'
    },
    alreadyAccount: {
      fontWeight: 600,
      lineHeight: '24px',
      color: '#050F47'
    },
    notification: {
      marginBottom: 20
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History;
  signUp: (request: ISignUpRequest) => void;
}

interface IState {
  checkCaptchaSusses: boolean;
  checkCaptchaError: boolean;
  email: string;
  password: string;
  errorEmail: boolean;
  errorPassword: boolean;
}

class NewAccount extends React.Component<IProps, IState> {
  public state: IState = {
    checkCaptchaSusses: false,
    checkCaptchaError: false,
    email: '',
    password: '',
    errorEmail: false,
    errorPassword: false
  };

  public handleChange = () => {
    this.setState({
      checkCaptchaSusses: true,
      checkCaptchaError: false
    });
  };

  public handleChangeInputEmail = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      email: event.target.value
    });
    if (
      event.target.value.match(validateEmail) ||
      event.target.value.substring(0, 1) === ''
    ) {
      this.setState({
        errorEmail: false
      });
    } else {
      this.setState({
        errorEmail: true
      });
    }
  };

  public handleChangeInputPassword = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      event.target.value.length > 5 ||
      event.target.value.substring(0, 1) === ''
    ) {
      this.setState({
        errorPassword: false
      });
    } else {
      this.setState({
        errorPassword: true
      });
    }
    this.setState({
      password: event.target.value.trim()
    });
  };

  public onClick = () => {
    if (this.state.checkCaptchaSusses === false) {
      this.setState({
        checkCaptchaError: true
      });
    } else {
      const request: ISignUpRequest = {
        email: this.state.email,
        password: this.state.password,
        url_callback: `${process.env.REACT_APP_URL}/verify-succeeded`
      };
      if (this.state.password !== '') {
        this.props.signUp(request);
      } else {
        this.setState({
          errorPassword: true
        });
      }
      this.setState({
        checkCaptchaError: false
      });
    }
  };

  public render() {
    const { classes } = this.props;
    const {
      checkCaptchaError,
      checkCaptchaSusses,
      errorEmail,
      password,
      email,
      errorPassword
    } = this.state;
    return (
      <div className={classes.root}>
        <NexoPaper title="Create New Account" numberElevation={1}>
          <NexoInput
            handleChangeInput={this.handleChangeInputEmail}
            error={errorEmail}
            value={email}
            msgError="Incorrect email format"
            title="Email"
            type="text"
          />
          <NexoInput
            error={errorPassword}
            msgError="Password should be at least 6 characters"
            handleChangeInput={this.handleChangeInputPassword}
            title="Password"
            type="password"
            value={password}
          />

          <ReCaptchaComponent
            handleChange={this.handleChange}
            error={checkCaptchaError}
            susses={checkCaptchaSusses}
            checkPrivacyPolicy={true}
            titleError="Wrong Captcha, please try again"
            titleSusses="Verification succeeded"
          />
          <Grid>
            <NexoButton
              title="Create Account"
              variant="contained"
              color="primary"
              handleRedirect={this.onClick}
              disabled={checkCaptchaError || errorPassword || errorEmail}
            />
          </Grid>
        </NexoPaper>
        <Grid container justify="center" className={classes.content_text}>
          <Typography variant="h5" className={classes.alreadyAccount}>
            Already have an account? {''}
            <Link to={PATH.nexo.login} className={classes.textPrivacy}>
              Login now
            </Link>
          </Typography>
        </Grid>
      </div>
    );
  }
}

export const NewAccountComponent = withRouter(
  connect(undefined, mapDispatchToProps)(withStyles(styles)(NewAccount))
);
