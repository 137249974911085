import { Store } from 'redux';
import { IStoreState } from '../types';
import { Dispatch } from 'react';
import Axios, { AxiosResponse } from 'axios';
import {
  ILoadWithdrawFundConfigAction,
  setWithdrawFundConfigAction,
  ILoanCreditLineAction,
  ILoadLoanContractSummaryAction,
  setLoanContractSummaryAction,
  IWithdrawLoanApprovedAction,
  ILoanTetherAction
} from '../actions/withdrawFund.action';
import {
  LOAD_WITHDRAWFUND_CONFIG_ACTION,
  LOAN_CREDIT_LINE_ACTION,
  LOAD_LOAN_CONTRACT_SUMMARY_ACTION,
  WITHDRAW_LOAN_APPROVED_ACTION,
  LOAN_TETHER_ACTION
} from '../constants/withdrawFund.constant';
import {
  IObjectWithdrawType,
  ILoanContractSummaryInfo
} from '../types/withdrawFund.type';
import { fireNotification } from '../actions/Notification.action';
import Variant from '../types/Variant.type';
import { PATH } from '../constants/Path.constant';
import { WITHDRAWAL_TYPE } from '../constants/withdrawAsset.constant';
import { MESSAGE_NOTIFICATION_ERROR } from '../constants/Notification.constant';

export const withdrawTypeMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | ILoadWithdrawFundConfigAction
    | ILoanCreditLineAction
    | ILoadLoanContractSummaryAction
    | IWithdrawLoanApprovedAction
    | ILoanTetherAction
  >
) => async (
  action:
    | ILoadWithdrawFundConfigAction
    | ILoanCreditLineAction
    | ILoadLoanContractSummaryAction
    | IWithdrawLoanApprovedAction
    | ILoanTetherAction
) => {
  switch (action.type) {
    case LOAD_WITHDRAWFUND_CONFIG_ACTION: {
      Axios.get(`/withdraw/config`)
        .then((data: AxiosResponse<IObjectWithdrawType>) => {
          if (data.data) {
            store.dispatch(setWithdrawFundConfigAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case LOAN_CREDIT_LINE_ACTION: {
      Axios.post(`/withdraw`, action.payload)
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `Please check your inbox for a confirmation email.`,
              variant: Variant.SUCCESS,
              link: PATH.nexo.account
            })
          );
        })
        .catch(err => {
          const error =
            err?.response?.data?.error?.message?.message ||
            err?.response?.data?.error?.message ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;

          store.dispatch(
            fireNotification({
              message: error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case LOAD_LOAN_CONTRACT_SUMMARY_ACTION: {
      Axios.get(`/withdraw/${action.payload.type}/${action.payload.token}`, {
        params: {
          token: action.payload,
          type: WITHDRAWAL_TYPE.LOAN
        }
      })
        .then((data: AxiosResponse<ILoanContractSummaryInfo>) => {
          if (data.data) {
            store.dispatch(setLoanContractSummaryAction(data.data));
          }
        })
        .catch(err => {
          const error =
            err?.response?.data?.error?.message?.message ||
            err?.response?.data?.error?.message ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;
          store.dispatch(
            fireNotification({
              message: error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case WITHDRAW_LOAN_APPROVED_ACTION: {
      Axios.post(`/withdraw/approve`, {
        token: action.payload,
        withdrawalType: WITHDRAWAL_TYPE.LOAN
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: ` Withdraw Funds From Credit Line Successfully`,
              variant: Variant.SUCCESS,
              link: PATH.nexo.transactions
            })
          );
        })
        .catch(err => {
          const error =
            err?.response?.data?.error?.message?.message ||
            err?.response?.data?.error?.message ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;
          store.dispatch(
            fireNotification({
              message: error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case LOAN_TETHER_ACTION: {
      Axios.post(`/withdraw`, action.payload)
        .then(() => {
          store.dispatch(
            fireNotification({
              message: `Please check your inbox for a confirmation email.`,
              variant: Variant.SUCCESS,
              link: PATH.nexo.account
            })
          );
        })
        .catch(err => {
          const error =
            err?.response?.data?.error?.message?.message ||
            err?.response?.data?.error?.message ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;

          store.dispatch(
            fireNotification({
              message: error,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
  }
  return dispatch(action);
};
