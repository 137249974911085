import React, { Dispatch } from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  NexoInput,
  NexoPaper,
  NexoButton
} from '../../../components/share/index';
import { PATH } from '../../../constants/Path.constant';
import { Link, withRouter } from 'react-router-dom';
import { History } from 'history';
import { connect } from 'react-redux';
import {
  IResetPasswordAction,
  resetPasswordAction
} from '../../../actions/auth.action';
import { IResetPassword } from '../../../types/auth.type';

const mapDispatchToProps = (dispatch: Dispatch<IResetPasswordAction>) => {
  return {
    resetPasswordAction: (request: IResetPassword) =>
      dispatch(resetPasswordAction(request))
  };
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      alignSelf: 'center',
      '& .MuiInputAdornment-root': {
        width: '200px'
      },
      '& .MuiTypography-h4': {
        fontSize: 10
      },
      '& .NexoPaper-root-3 .MuiPaper-root': {
        height: '380px'
      }
    },
    contentText: {
      margin: '15px 0px',
      '& .MuiTypography-root': {
        fontSize: 14
      }
    },
    textPrivacy: {
      color: '#3E399F',
      textDecoration: 'none'
    },
    textFooter: {
      color: '#050F47'
    },
    button: {
      marginTop: 68
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History;
  resetPasswordAction: (request: IResetPassword) => void;
}

interface IState {
  password: string;
  repassword: string;
  errorPassword: boolean;
  errorRepassword: boolean;
}

class ResetPassword extends React.Component<IProps, IState> {
  public state: IState = {
    password: '',
    repassword: '',
    errorPassword: false,
    errorRepassword: false
  };

  handleResetPassword = () => {
    const { password, repassword } = this.state;
    const location = window.location.pathname;
    if (!location) {
      return;
    }
    if (password !== repassword) {
      return;
    }
    const token = location.slice(location.lastIndexOf('/') + 1);
    const request: IResetPassword = {
      password,
      repassword,
      token
    };
    if (this.state.password !== '') {
      this.props.resetPasswordAction(request);
    } else {
      this.setState({
        errorPassword: true
      });
    }
  };

  public handleChangeInputPassword = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      event.target.value.length > 5 ||
      event.target.value.substring(0, 1) === ''
    ) {
      this.setState({
        errorPassword: false
      });
    } else {
      this.setState({
        errorPassword: true
      });
    }
    this.setState({
      password: event.target.value.trim()
    });
  };

  public handleChangeInputRepassword = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === this.state.password) {
      this.setState({
        errorRepassword: false
      });
    } else {
      this.setState({
        errorRepassword: true
      });
    }
    this.setState({
      repassword: event.target.value.trim()
    });
  };

  public render() {
    const { classes } = this.props;
    const { errorRepassword, errorPassword } = this.state;

    return (
      <div className={classes.root}>
        <NexoPaper title="Reset Password" numberElevation={0}>
          <NexoInput
            title="New password"
            error={errorPassword}
            msgError="New password should be at least 6 characters"
            type="password"
            value={this.state.password}
            handleChangeInput={this.handleChangeInputPassword}
          />
          <NexoInput
            error={errorRepassword}
            msgError="Confirm new password does not match"
            title="Reapeat password"
            type="password"
            value={this.state.repassword}
            handleChangeInput={this.handleChangeInputRepassword}
          />
          <Grid className={classes.button}>
            <NexoButton
              title="Set New Password"
              variant="contained"
              color="primary"
              size="large"
              disabled={
                this.state.password !== this.state.repassword
                  ? true
                  : false && this.state.password.length < 6
              }
              handleRedirect={this.handleResetPassword}
            />
          </Grid>
        </NexoPaper>
        <Grid container justify="center" className={classes.contentText}>
          <Typography variant="h4" className={classes.textFooter}>
            Don’t have Account? &nbsp;
            <Link to={PATH.nexo.newAccount} className={classes.textPrivacy}>
              Create new account
            </Link>
            &nbsp;<span className={classes.textPrivacy}>-</span>&nbsp;
            <Link to={PATH.nexo.login} className={classes.textPrivacy}>
              Login
            </Link>
          </Typography>
        </Grid>
      </div>
    );
  }
}

export const ResetPasswordComponent = withRouter(
  connect(undefined, mapDispatchToProps)(withStyles(styles)(ResetPassword))
);
