import React, { useState, useEffect } from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  Dialog,
  Checkbox
} from '@material-ui/core';
import { NexoButton } from '../../../components/share';
import { RouteChildrenProps } from 'react-router';
import { PATH } from '../../../constants/Path.constant';
import { historyBrowser } from '../../../index';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadLoanContractSummaryAction,
  approveLoanWithdrawalAction
} from '../../../actions/withdrawFund.action';
import { IStoreState } from '../../../types';
import {
  ILoanContractSummaryInfo,
  ILoadLoanContractSummaryRequest
} from '../../../types/withdrawFund.type';
import { formatNumberToFixed } from '../../../helper';
import { WithdrawApprovalContent } from '../../../components/share/WithdrawApprovedContent';
import { WITHDRAWAL_TYPE } from '../../../constants/withdrawAsset.constant';

const styles = makeStyles({
  root: {
    alignItems: 'center',
    alignSelf: 'center'
  },
  rootPaper: {
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    display: 'flex'
  },
  textCongrats: {
    marginTop: 30,
    border: '1px solid rgb(215, 220, 224)',
    boxSizing: 'border-box',
    borderRadius: 5,
    background: ' rgb(249, 250, 251)',
    height: 50,
    width: 450
  },
  textContinue: {
    marginTop: 30,
    marginBottom: 10
  },
  widthButton: {
    width: 450
  },
  tableHead: {
    paddingLeft: 0,
    fontSize: 12,
    color: '#3E399F !important',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    borderBottom: '1px dashed #B2B6BA'
  },
  tableBody: {
    paddingLeft: 0,
    fontSize: 12,
    color: '#050F47 !important',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    borderBottom: 'none'
  },
  buttonCancel: {
    width: 150,
    marginRight: 7
  },
  buttonVerify: {
    width: 150
  }
});

export const WithdrawLoanApproved = (
  props: RouteChildrenProps<{ token: string }>
) => {
  const history = historyBrowser;
  const token: string | undefined = props.match?.params.token;
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const handleRedirectPageAcount = () => {
    history.push(PATH.nexo.account);
  };
  const classes = styles();
  const loanContractSummary: ILoanContractSummaryInfo = useSelector(
    (state: IStoreState) => state.loanContractSummary
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const request: ILoadLoanContractSummaryRequest = {
      token: token ? token : '',
      type: WITHDRAWAL_TYPE.LOAN
    };
    dispatch(loadLoanContractSummaryAction(request));
  }, [token, dispatch]);
  const handleChangeCheckbox = () => {
    setCheckbox(!checkbox);
  };

  const handleWithdrawLoanApproved = () => {
    if (token) {
      dispatch(approveLoanWithdrawalAction(token));
    }
  };
  return (
    <Dialog open={true} maxWidth="lg">
      <Grid container style={{ padding: 50 }}>
        <Grid
          container
          justify="center"
          style={{
            borderBottom: '1px dashed #B2B6BA',
            paddingBottom: 20,
            height: 50
          }}
        >
          <Typography variant="h1">Loan contract summary</Typography>
        </Grid>
        <Grid
          style={{
            borderBottom: '1px dashed #B2B6BA',
            paddingBottom: 20,
            width: '100%'
          }}
        >
          <WithdrawApprovalContent
            title="Loan Amount:"
            value={`${formatNumberToFixed(loanContractSummary.amount, 2)} ${
              loanContractSummary.currency.symbol
            }`}
          />
          <WithdrawApprovalContent
            title="Loan Currency:"
            value={loanContractSummary.currency.name}
          />
          {loanContractSummary.currency.symbol === 'USD' ? (
            <React.Fragment>
              <WithdrawApprovalContent
                title="Name:"
                value={loanContractSummary.name}
              />
              <WithdrawApprovalContent
                title="Account Number:"
                value={loanContractSummary.accountNumber}
              />
              <WithdrawApprovalContent
                title="City:"
                value={loanContractSummary.city}
              />
              <WithdrawApprovalContent
                title="Address:"
                value={loanContractSummary.address}
              />
            </React.Fragment>
          ) : (
            <WithdrawApprovalContent
              title="Recipient Address:"
              value={loanContractSummary.recipientAddress}
            />
          )}
        </Grid>
        <Grid container alignItems="center">
          <Checkbox
            value={checkbox}
            onClick={handleChangeCheckbox}
            color="primary"
            style={{ paddingLeft: 0 }}
          />
          <Typography variant="h5">
            I have read and agree to the Loan Agreement
          </Typography>
        </Grid>

        <Grid container justify="center" style={{ marginTop: 30 }}>
          <Grid className={classes.buttonCancel}>
            <NexoButton
              handleRedirect={handleRedirectPageAcount}
              title="Cancel"
              variant="outlined"
              color="primary"
              heightButton={40}
            />
          </Grid>

          <Grid className={classes.buttonVerify}>
            <NexoButton
              disabled={checkbox === false}
              title="Approve"
              variant="contained"
              color="primary"
              heightButton={40}
              handleRedirect={handleWithdrawLoanApproved}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
