export const TEXT_PROCESSING = [
  {
    name:
      'Crypto: instantly, using a market order at market price on leading cryptocurrency exchanges without any additional fees from Crypto. The exact amount received from the sell order will be instantly applied towards your outstanding credit line.'
  },
  {
    name: 'Fiat: instantly, using the funds from your Crypto Account.'
  }
];
export const TEXT_REPAYMENT = [
  {
    name:
      'No minimum repayments are required, as interest is debited automatically from your available credit limit.'
  },
  {
    name:
      'You can repay all or some of your loan early at any time and you could save interest - as you only pay interest for the days you borrow.'
  },
  {
    name:
      'You can make repayments using all of the available funds in your Crypto Account, including the collateral.'
  },
  {
    name:
      'Repayments made less than 30 days after your last loan withdrawal, will be charged interest on the portion being repaid for the remaining 30 days. Repayments will be used to cover the earliest loan withdrawals and pay off interest before principal.'
  }
];
