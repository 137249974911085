import React from 'react';
import {
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Typography
} from '@material-ui/core';
import { NexoDialogComponent } from '../share/NexoDialog.component';
import { NexoButton } from '../share';
import iconAdvanced from '../../assets/images/iconAdvanced.svg';
import PinInput from 'react-pin-input';

const styles = () =>
  createStyles({
    button: {
      marginTop: 38
    },
    buttonCancel: {
      width: 210,
      marginRight: 7
    },
    buttonVerify: {
      width: 150
    },
    code: {
      textAlign: 'center',
      color: '#050F47',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 30,
      lineHeight: '30px',
      letterSpacing: '0.3em'
    },
    rootContent: {
      background: 'rgba(242, 153, 74, 0.05)',
      border: '1px solid #F2994A',
      boxSizing: 'border-box',
      borderRadius: 5,
      height: 50,
      paddingLeft: 18,
      marginTop: 10
    },
    rootPage: {
      border: '1px solid'
    }
  });
interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  handleCloseDialog: () => void;
  handleShowVerifyPhone: () => void;
  onChangeShowDialogBasicVerification: () => void;
  handleChangeInputCodeSMS: (codeInput: string) => void;
  textHeader: string;
  valueCodeSms: string;
  errorVerify: boolean;
}

interface IState {
  code: string;
}

class EnterVerifyPhone extends React.Component<IProps> {
  public state: IState = {
    code: ''
  };

  public render() {
    const {
      classes,
      handleCloseDialog,
      showDialog,
      textHeader,
      errorVerify
    } = this.props;
    return (
      <NexoDialogComponent
        handleCloseDialog={handleCloseDialog}
        showDialog={showDialog}
        textHeader={textHeader}
        maxWidth="xs"
        hiddenback={false}
      >
        <Grid>
          {errorVerify ? (
            <Grid container alignItems="center" className={classes.rootContent}>
              <img
                src={iconAdvanced}
                alt="icon advanced"
                width={15}
                height={15}
              />
              <Typography
                variant="h5"
                style={{
                  color: '#F2994A',
                  marginLeft: 8,
                  fontSize: 10,
                  fontWeight: 600
                }}
              >
                Code Error
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid container justify="center">
          <Typography
            variant="h5"
            style={{
              color: '#050F47',
              fontWeight: 600,
              marginTop: 14
            }}
          >
            Enter the SMS verification code you received:
          </Typography>

          <PinInput
            length={4}
            focus
            secret={false}
            type="numeric"
            onChange={this.props.handleChangeInputCodeSMS}
            style={{
              padding: '10px'
            }}
            inputStyle={{
              borderColor: '#D7DCE0',
              width: 40,
              height: 40,
              color: '#050F47',
              fontSize: 16
            }}
            inputFocusStyle={{ borderColor: '#34CEA9' }}
          />
        </Grid>

        <Grid
          container
          justify="flex-end"
          style={{ marginTop: 20, marginBottom: 30 }}
        >
          <Grid className={classes.buttonCancel}>
            <NexoButton
              title="Change phone number"
              variant="outlined"
              color="primary"
              heightButton={40}
              handleRedirect={this.props.handleShowVerifyPhone}
            />
          </Grid>

          <Grid className={classes.buttonVerify}>
            <NexoButton
              title="Verify code"
              variant="contained"
              color="primary"
              heightButton={40}
              handleRedirect={this.props.onChangeShowDialogBasicVerification}
            />
          </Grid>
        </Grid>
      </NexoDialogComponent>
    );
  }
}

export const EnterVerifyPhoneDialogComponent = withStyles(styles)(
  EnterVerifyPhone
);
