import React, { Dispatch } from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import {
  NexoInput,
  NexoPaper,
  NexoButton
} from '../../../components/share/index';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PATH } from '../../../constants/Path.constant';
import { History } from 'history';
import { ReCaptchaComponent } from '../../../components/share/ReCaptcha.component';
import {
  IForgotPasswordAction,
  forgotPasswordAction
} from '../../../actions/auth.action';
import { IForgotPasswordRequest } from '../../../types/auth.type';
import { validateEmail } from '../../../helper';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      alignSelf: 'center',
      padding: 0,
      '& .MuiPaper-elevation0': {
        minHeight: 420,
        height: 'auto !important'
      }
    },
    contentText: {
      margin: '15px 0px',
      '& .MuiTypography-root': {
        fontSize: 12
      }
    },
    textPrivacy: {
      color: '#3E399F',
      textDecoration: 'none'
    },
    marginBottomNoti: {
      marginBottom: 20
    },
    textCreate: {
      color: '#050F47'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History;
  forgotPasswordAction: (request: IForgotPasswordRequest) => void;
}

interface IState {
  checkCaptchaSusses: boolean;
  checkCaptchaError: boolean;
  email: string;
  emailError: {
    error: boolean;
    msgError: string;
  };
  disabledButton: boolean;
}

class ForgotPassword extends React.Component<IProps, IState> {
  public state: IState = {
    checkCaptchaError: false,
    checkCaptchaSusses: false,
    email: '',
    emailError: {
      error: false,
      msgError: ''
    },
    disabledButton: false
  };

  public handleChange = () => {
    this.setState({
      checkCaptchaSusses: true,
      checkCaptchaError: false,
      disabledButton: false
    });
  };

  public handleChangeInputEmail = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const email = event.target.value;
    let disabledButton = false;
    let emailError = {
      error: false,
      msgError: ''
    };

    if (!email.match(validateEmail)) {
      disabledButton = true;
      emailError = {
        error: true,
        msgError: 'You have entered an invalid email address!'
      };
    }

    return this.setState({ email, emailError, disabledButton });
  };

  public onClick = () => {
    let checkCaptchaError = false;
    let disabledButton = false;
    if (!this.state.checkCaptchaSusses) {
      checkCaptchaError = true;
      disabledButton = true;
      return this.setState({ checkCaptchaError, disabledButton });
    }

    const request: IForgotPasswordRequest = {
      email: this.state.email,
      url_callback: `${process.env.REACT_APP_URL}${PATH.nexo.resetPassword}`
    };

    this.props.forgotPasswordAction(request);
    return this.setState({ checkCaptchaError, disabledButton });
  };

  public render() {
    const { classes } = this.props;
    const {
      checkCaptchaError,
      checkCaptchaSusses,
      emailError,
      disabledButton,
      email
    } = this.state;
    return (
      <div className={classes.root}>
        <NexoPaper title="Forgot Password" numberElevation={0}>
          <NexoInput
            title="Email"
            type="text"
            error={emailError.error}
            msgError={emailError.msgError}
            value={email}
            handleChangeInput={this.handleChangeInputEmail}
          />

          <ReCaptchaComponent
            handleChange={this.handleChange}
            error={checkCaptchaError}
            susses={checkCaptchaSusses}
            checkPrivacyPolicy={false}
            titleError="Wrong Captcha, please try again"
            titleSusses="Verification succeeded"
          />
          <Grid>
            <NexoButton
              title="Reset Password"
              variant="contained"
              color="primary"
              size="large"
              handleRedirect={this.onClick}
              disabled={disabledButton}
            />
          </Grid>
        </NexoPaper>
        <Grid container justify="center" className={classes.contentText}>
          <Typography variant="h4" className={classes.textCreate}>
            Don’t have Account? &nbsp;
            <Link to={PATH.nexo.newAccount} className={classes.textPrivacy}>
              Create new account
            </Link>
            &nbsp; <span className={classes.textPrivacy}>-</span> &nbsp;
            <Link to={PATH.nexo.login} className={classes.textPrivacy}>
              Login
            </Link>
          </Typography>
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IForgotPasswordAction>) => {
  return {
    forgotPasswordAction: (request: IForgotPasswordRequest) =>
      dispatch(forgotPasswordAction(request))
  };
};

export const ForgotPasswordComponent = withRouter(
  connect(undefined, mapDispatchToProps)(withStyles(styles)(ForgotPassword))
);
