import React from 'react';
import {
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Table,
  makeStyles
} from '@material-ui/core';
import { formatNumberToFixed } from '../../helper';

const styles = makeStyles({
  tableCellEstimated: {
    background: '#F9FAFB',
    fontFamily: 'Montserrat',
    fontSize: 14,
    color: '#3E399F',
    borderRight: '1px solid #D7DCE0',
    width: '50%'
  },
  tableCellAvailable: {
    background: '#f5fafc',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 600,
    color: '#3E399F'
  },
  tableCellBefore: {
    background: '#F9FAFB',
    fontFamily: 'Montserrat',
    fontSize: 14,
    color: '#050F47',
    borderRight: '1px solid #D7DCE0',
    width: '50%'
  },
  textValueWithdrawlPositive: {
    color: '#48c4fc'
  },
  textValueWithdrawlMinus: {
    color: '#EB5757'
  }
});

interface IProps {
  valueUsd: string;
  availableCredit: number;
}

export const EstimateWithdrawCard = (props: IProps) => {
  const classes = styles();
  const valueAfterWithdrawal = props.availableCredit - Number(props.valueUsd);
  return (
    <Table
      style={{
        border: '1px solid #D7DCE0',
        boxSizing: 'border-box',
        borderRadius: 5
      }}
    >
      <TableHead>
        <TableRow style={{ width: '100%' }}>
          <TableCell className={classes.tableCellEstimated}>
            Estimated
          </TableCell>
          <TableCell className={classes.tableCellAvailable} align="right">
            Available Loan Limit
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.tableCellBefore}>Before</TableCell>
          <TableCell
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              color: '#050F47',
              width: '50%'
            }}
            align="right"
          >
            $ {formatNumberToFixed(props.availableCredit, 2)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tableCellBefore}>
            After Withdrawal
          </TableCell>
          <TableCell
            className={
              valueAfterWithdrawal > 0
                ? classes.textValueWithdrawlPositive
                : classes.textValueWithdrawlMinus
            }
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              width: '50%'
            }}
            align="right"
          >
            {valueAfterWithdrawal < 0 ? '-$' : '$'}{' '}
            {formatNumberToFixed(Math.abs(valueAfterWithdrawal), 2)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
