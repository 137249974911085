import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  rootBody: {
    background: '#FFFFFF',
    border: '1px solid #D7DCE0',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: 220,
    padding: '21px 31px 0px 31px'
  },
  depositLimit: {
    color: '#050F47',
    marginTop: 29
  },
  textNoLimit: {
    color: '#3E399F',
    fontWeight: 600,
    marginTop: 5
  },
  withdrawalLimit: {
    color: '#050F47',
    marginTop: 20
  },
  including: {
    color: '#3E399F',
    marginTop: 5,
    fontWeight: 600
  },
  dividendsCard: {
    color: '#050F47',
    fontWeight: 600,
    marginTop: 5
  },
  textCryptocurrency: {
    color: '#050F47',
    marginTop: 9
  }
});

export const ContentAccount = () => {
  const classes = styles();
  return (
    <Grid container className={classes.rootBody}>
      <Grid container justify="center" item xs={6}>
        <Grid>
          <Typography variant="h3"> Basic Verification</Typography>
          <Typography variant="h4" className={classes.depositLimit}>
            Cryptocurrency Deposit Limit
          </Typography>
          <Typography variant="h4" className={classes.textNoLimit}>
            No Limit
          </Typography>

          <Typography variant="h4" className={classes.depositLimit}>
            Bank Withdrawal Limit
          </Typography>
          <Typography variant="h4" className={classes.textNoLimit}>
            Not Applied
          </Typography>
        </Grid>
      </Grid>

      <Grid container justify="center" item xs={6}>
        <Grid>
          <Typography variant="h3"> Advanced Verification</Typography>
          <Typography variant="h5" className={classes.dividendsCard}>
            (required for dividends and cards)
          </Typography>
          <Typography variant="h4" className={classes.textCryptocurrency}>
            Cryptocurrency Deposit Limit
          </Typography>
          <Typography variant="h4" className={classes.textNoLimit}>
            No Limit
          </Typography>

          <Typography variant="h4" className={classes.depositLimit}>
            Bank Withdrawal Limit
          </Typography>
          <Typography variant="h4" className={classes.textNoLimit}>
            Up to $2,000,000 on request
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
