import React from 'react';
import {
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Typography
} from '@material-ui/core';
import { NexoDialogComponent } from '../share/NexoDialog.component';
import iconsucces from '../../assets/images/iconsuccesDialog.svg';
import { NexoButton } from '../share';

const styles = () =>
  createStyles({
    notification: {
      height: 80,
      border: '1px solid #34CEA9',
      background: 'rgba(52, 206, 169, 0.05)',
      boxSizing: 'border-box',
      borderRadius: 5,
      marginBottom: 58,
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '60px calc(100% - 60px)'
    },
    imgIconSucces: {
      marginLeft: 21,
      marginRight: 15
    },
    textCheckMail: {
      color: '#34CEA9',
      fontWeight: 500,
      paddingRight: 47
    }
  });
interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  handleCloseDialog: () => void;
  textHeader: string;
}

class BasicVerification extends React.Component<IProps> {
  public render() {
    const { classes, handleCloseDialog, showDialog, textHeader } = this.props;
    return (
      <NexoDialogComponent
        handleCloseDialog={handleCloseDialog}
        showDialog={showDialog}
        textHeader={textHeader}
        maxWidth="sm"
        hiddenback={false}
      >
        <Grid style={{ marginTop: 30 }}>
          <Grid className={classes.notification}>
            <img
              src={iconsucces}
              width={25}
              height={25}
              className={classes.imgIconSucces}
              alt="icon succes"
            />
            <Typography variant="h4" className={classes.textCheckMail}>
              Basic verification completed successfully!
            </Typography>
          </Grid>
          <Grid>
            <NexoButton
              title="Got it"
              variant="contained"
              color="primary"
              handleRedirect={this.props.handleCloseDialog}
            />
          </Grid>
        </Grid>
      </NexoDialogComponent>
    );
  }
}

export const BasicVerificationDialogComponent = withStyles(styles)(
  BasicVerification
);
