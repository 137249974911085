import React, { useRef, useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  Button
} from '@material-ui/core';
import classNames from 'classnames';
import { CalculatorCardComponent } from './share/CalculatorCard.dialog.component';
import Slider from 'react-slick';
// import { useOutSideClick } from './share/useOutsideClick.component';
import { selectAPR } from '../constants/Coin.constant';
import iconPassive from '../assets/images/iconPassive.svg';
import iconFree from '../assets/images/iconFree.svg';
import { FooterCalculator } from './calculator/FooterCalculator.component';
import { LoadingPage } from './share/LoadingPage';
import { ICoinInfo } from '../types/calculator.type';

const styles = makeStyles({
  root: {
    marginTop: 15,
    '&:focus': {
      '& .slick-initialized.slick-slide': {
        outlineColor: 'transparent'
      }
    },
    padding: '0px 30px',
    '& .slick-list': {
      marginLeft: 6
    }
  },
  slickPrev: {
    '& .slick-arrow.slick-prev': {
      display: 'none !important'
    }
  },
  slickPrevActive: {
    '& .slick-arrow.slick-prev': {
      left: '-31px',
      '&:before': {
        color: '#A5B2C0'
      }
    }
  },
  slickNext: {
    '& .slick-arrow.slick-next': {
      display: 'none !important'
    }
  },
  slickNextActive: {
    '& .slick-arrow.slick-next': {
      right: '-31px',
      '&:before': {
        color: '#A5B2C0'
      }
    }
  },
  selectTextField: {
    '& .MuiInputBase-input': {
      color: '#A5B2C0',
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      background: 'transparent'
    }
  },
  buttonGetStarted: {
    background: 'linear-gradient(167.33deg, #FFB26F 3.5%, #DF8A03 83.05%)',
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 600,
    fontFamily: 'Montserrat',
    height: 50,
    '&:hover': {
      boxShadow: 'none'
    }
  }
});

interface IProps {
  dataInterest: ICoinInfo[];
}

export const EarnInterestCard = (props: IProps) => {
  const wrapperRef = useRef(null);
  const [slickPrev, setSlickPrev] = useState(false);
  const [slickNext, setSlickNext] = useState(false);
  // const [usd, setUsd] = useState<string>('');
  // const [activeInputUsd, setActiveInputUsd] = useState(false);
  const [valueSelectAPR, setValueSelectAPR] = useState<string>('3');
  // const [valueAPR, setValueAPR] = useState<string>('0');
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [valueCoin, setValueCoin] = useState<any>({
    BTC: '',
    ETH: '',
    XRP: ''
  });

  const [activeInput, setActiveInput] = useState<any>({
    BTC: false,
    ETH: false,
    XRP: false
  });

  const [valueCoinToUsd, setValueCoinToUsd] = useState<any>({
    BTC: '',
    ETH: '',
    XRP: ''
  });

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(true);
    }, 500);
  }, []);

  // const handleChangeInputUsd = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setUsd(event.target.value);
  //   setValueAPR(String(getEarnInterest(event.target.value, valueSelectAPR)));
  // };
  // const onBlurTextFied = (key: string) => () => {
  //   setActiveInputUsd(true);
  // };

  // useOutSideClick(wrapperRef, () => {
  //   if (usd === '') {
  //     setActiveInputUsd(false);
  //   }
  // });

  const handleChangeSelectAPR = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueSelectAPR(event.target.value);

    // setValueAPR(String(getEarnInterest(usd, event.target.value)));
  };
  const handleChangeInput = (key: string, value: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueCoin({
      ...valueCoin,
      [key]: event.target.value
    });
    setValueCoinToUsd({
      ...valueCoinToUsd,
      [key]:
        event.target.value !== ''
          ? String(Number(value) * Number(event.target.value))
          : '0'
    });
  };

  const handleChangeActiveInput = (key: string) => () => {
    setActiveInput({
      ...activeInput,
      [key]: true
    });
  };

  const onBlurTextFiedOutSide = (key: string, valueInput: string) => () => {
    if (valueInput === '') {
      setActiveInput({
        ...activeInput,
        [key]: false
      });
    } else {
      setActiveInput({
        ...activeInput,
        [key]: true
      });
    }
  };

  const classes = styles();
  const settings = {
    dots: false,
    slidesToShow: 3,
    afterChange: (currentSlide: any) => {
      if (currentSlide > 0) {
        setSlickPrev(true);
      } else {
        setSlickPrev(false);
      }
      if (currentSlide > 2) {
        setSlickNext(true);
      } else {
        setSlickNext(false);
      }
    }
  };
  const { dataInterest } = props;
  return (
    <div>
      {loadingPage ? (
        <React.Fragment>
          <Grid>
            <Typography
              variant="h3"
              style={{
                color: ' #3E399F'
              }}
            >
              Select Your Crypto Assets
            </Typography>
          </Grid>
          <div
            ref={wrapperRef}
            className={classNames(
              classes.root,
              slickPrev === false ? classes.slickPrev : classes.slickPrevActive,
              slickNext === false ? classes.slickNextActive : classes.slickNext
            )}
          >
            <Slider {...settings}>
              {dataInterest.map(el => (
                <React.Fragment key={el.symbol}>
                  <CalculatorCardComponent
                    min={0}
                    step={0.001}
                    formatNumber={8}
                    active={activeInput[el.symbol]}
                    detailCoin={el}
                    value={valueCoin[el.symbol]}
                    handleChangeInput={handleChangeInput}
                    handleChangeActiveInput={handleChangeActiveInput}
                    onBlurTextFiedOutSide={onBlurTextFiedOutSide}
                  />
                </React.Fragment>
              ))}
            </Slider>
          </div>
          <Grid style={{ marginTop: 30 }}>
            <Typography
              variant="h2"
              style={{
                fontSize: 20,
                color: '#050F47'
              }}
            >
              Earn 8% Interest on Your Assets
            </Typography>
            <Grid container alignItems="center" style={{ marginTop: 30 }}>
              <Grid item xs={6} style={{ marginTop: 5 }}>
                <Typography
                  variant="h5"
                  style={{
                    color: '#A5B2C0',
                    fontWeight: 600
                  }}
                >
                  Compounding interest paid out daily
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    color: '#A5B2C0',
                    marginTop: 5
                  }}
                >
                  Withdraw assets at any time
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  borderLeft: '1px solid #D7DCE0',
                  paddingLeft: 20
                }}
              >
                <TextField
                  className={classes.selectTextField}
                  select
                  value={valueSelectAPR}
                  onChange={handleChangeSelectAPR}
                >
                  {selectAPR.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography
                  variant="h4"
                  style={{ fontWeight: 500, marginTop: 10 }}
                >
                  {/* $ {formatNumberToFixed(valueAPR, 2)} */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              marginTop: 30
            }}
          >
            <Button
              className={classes.buttonGetStarted}
              variant="contained"
              color="primary"
            >
              Get Started
            </Button>
          </Grid>
          <FooterCalculator
            iconLeft={iconPassive}
            contentLeftTop="High-Yield Savings"
            contentLeftBottom="Passive Income"
            iconRight={iconFree}
            contentRightTop="Free. No Fees"
            contentRightBottom="Spend at Any Time"
          />
        </React.Fragment>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
};
