import { Store } from 'redux';
import { IStoreState } from '../types';
import {
  ILoadCalculatorAction,
  setCalculatorAction,
  ILoadRatesAction,
  setRatesAction,
  ILoadInterestAction,
  setInterestAction
} from '../actions/calculator.action';
import { Dispatch } from 'react';
import {
  LOAD_CALCULATOR_ACTION,
  LOAD_RATES_ACTION,
  LOAD_INTEREST_ACTION
} from '../constants/culculator.constant';
import Axios, { AxiosResponse } from 'axios';
import {
  IObjectCalculatorInfo,
  IRatesInfo,
  IInterestInfo
} from '../types/calculator.type';

export const calculatorMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    ILoadCalculatorAction | ILoadRatesAction | ILoadInterestAction
  >
) => async (
  action: ILoadCalculatorAction | ILoadRatesAction | ILoadInterestAction
) => {
  switch (action.type) {
    case LOAD_CALCULATOR_ACTION: {
      Axios.get(`/calculator`)
        .then((data: AxiosResponse<IObjectCalculatorInfo>) => {
          if (data.data) {
            store.dispatch(setCalculatorAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case LOAD_RATES_ACTION: {
      Axios.get(`/rates`)
        .then((data: AxiosResponse<IRatesInfo[]>) => {
          if (data.data) {
            store.dispatch(setRatesAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
    case LOAD_INTEREST_ACTION: {
      Axios.get(`/interest`)
        .then((data: AxiosResponse<IInterestInfo[]>) => {
          if (data.data) {
            store.dispatch(setInterestAction(data.data));
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
      break;
    }
  }
  return dispatch(action);
};
