import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles
} from '@material-ui/core';
import { NexoItemCoinCredit, NexoButton } from './share';
import { TopUpDialog } from './dialog/TopUp.dialog.component';
import { CalculatorDialog } from './dialog/ListCalculator.dialog.component';
import classNames from 'classnames';
import { DepositDialogComponent } from './dialog/Deposit.dialog.component';
import { IUserInfo } from '../types/user.type';
import { WithDrawDialogComponent } from './dialog/Withdraw.dialog.component';
import { ICurrencyBalance } from '../types/currency.type';
import { formatNumberToFixed } from '../helper';
import { useHistory } from 'react-router';

const styles = makeStyles({
  root: {
    marginBottom: 11,
    marginTop: 21
  },
  buttonCalculator: {
    width: 100,
    height: 30,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 12,
    background:
      'linear-gradient(146.74deg, #4B72D5 -10.08%, #38238A 96.47%), linear-gradient(138.69deg, #67ACF1 -10.95%, #156EE4 104.81%)',
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderRadius: 5
  },
  text: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '12px',
    fontFamily: 'Montserrat',
    color: '#3E399F'
  },
  tableHead: {
    paddingLeft: 0
  },
  tableBody: {
    paddingLeft: 0,
    backgroundColor: '#FFFFFF'
  },
  button: {
    width: 100,
    '& .MuiButton-root': {
      height: 30,
      fontWeight: 600,
      fontSize: 12
    },
    '& .MuiButton-outlined': {
      color: '#A5B2C0 !important',
      '&:hover': {
        color: '#fff !important'
      },
      '&:focus': {
        color: '#fff !important'
      },
      '&:disabled': {
        color: '#fff !important'
      }
    }
  },
  rootTable: {
    '& table': {
      borderCollapse: 'separate',
      borderSpacing: '0 10px'
    },
    '& tr': {
      marginBottom: 10
    },
    '& td': {
      border: '1px solid #D7DCE0',
      '&:first-child': {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
      },
      '&:last-child': {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
      }
    }
  },
  buttonDeposit: {
    paddingTop: 0,
    paddingBottom: 0,
    background: 'linear-gradient(147.4deg, #FFB26F 3.5%, #DF8A03 83.05%)',
    width: 100,
    height: 30,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 600,
    fontFamily: 'Montserrat',
    '&:hover': {
      boxShadow: 'none'
    }
  }
});

interface IProps {
  isGoogleAuthenticator: boolean;
  userProfile: IUserInfo;
  currencies: ICurrencyBalance[];
}

export const ListItemCoinCreditComponent = (props: IProps) => {
  const history = useHistory<{ turnOffDialog: boolean }>();
  const [showDialogCalculator, setShowDialogCalculator] = useState<boolean>(
    false
  );
  const [showDialogTopUp, setShowDialogTopUp] = useState<boolean>(false);
  const [showDialogWithdraw, setShowDialogWithdraw] = useState<boolean>(false);
  const [showDeposit, setShowDeposit] = useState<boolean>(false);
  const [listCurrencies, setListCurrencies] = useState<ICurrencyBalance>({
    currency: {
      id: '',
      name: '',
      symbol: '',
      image: '',
      loanDisabled: false,
      loanHidden: false,
      interestDisabled: false,
      interestHidden: false,
      isShow: false,
      stablecoin: false
    },
    balance: '',
    creditLine: '',
    usdPrice: '',
    depositAddress: '',
    withdrawLimit: 0,
    repaymentAddress: ''
  });

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.turnOffDialog === true
    ) {
      setShowDialogWithdraw(false);
    }
  }, [history.location.state]);

  const handleShowDialogTopUp = (itemCurrencies: ICurrencyBalance) => () => {
    setShowDialogTopUp(true);
    setListCurrencies(itemCurrencies);
  };
  const handleShowDialogWithdraw = (itemCurrencies: ICurrencyBalance) => () => {
    setShowDialogWithdraw(true);
    setListCurrencies(itemCurrencies);
  };
  const handleShowDialogDeposit = (itemCurrencies: ICurrencyBalance) => () => {
    setShowDeposit(true);
    setListCurrencies(itemCurrencies);
  };

  const handleShowDialogCalculator = () => {
    setShowDialogCalculator(true);
    setShowDialogTopUp(false);
  };

  const handleCloseDialog = () => {
    setShowDialogTopUp(false);
    setShowDialogCalculator(false);
    setShowDialogWithdraw(false);
    setShowDeposit(false);
  };
  const handleRedirectTopUP = () => {
    setShowDialogTopUp(true);
    setShowDialogWithdraw(false);
  };
  const { isGoogleAuthenticator, userProfile, currencies } = props;
  const classes = styles();
  return (
    <div data-aos="fade-right" data-aos-offset="300" data-aos-duration="300">
      <Grid className={classes.rootTable}>
        <Table>
          <TableHead>
            <TableRow style={{ width: '100%' }}>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: '15%',
                  borderBottom: 'inherit'
                }}
              >
                <Grid style={{ width: 100 }}>
                  <Button
                    className={classes.buttonCalculator}
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={handleShowDialogCalculator}
                  >
                    Calculator
                  </Button>
                </Grid>
              </TableCell>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: '15%',
                  borderBottom: 'inherit'
                }}
              >
                <Typography className={classes.text}>Balance</Typography>
              </TableCell>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: '15%',
                  borderBottom: 'inherit'
                }}
              >
                <Typography className={classes.text}>Market Value</Typography>
              </TableCell>
              <TableCell
                className={classes.tableHead}
                style={{
                  width: '15%',
                  borderBottom: 'inherit'
                }}
              >
                <Typography className={classes.text}>Credit Line</Typography>
              </TableCell>

              <TableCell
                className={classes.tableHead}
                style={{
                  borderBottom: 'inherit'
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {currencies.length > 0 &&
              currencies.map(el => (
                <TableRow key={el.currency.name}>
                  <TableCell
                    style={{ paddingLeft: 16, borderRight: 'none' }}
                    className={classes.tableBody}
                  >
                    <NexoItemCoinCredit
                      imgSrc={el.currency.image}
                      id=""
                      name={el.currency.name}
                    />
                  </TableCell>
                  <TableCell
                    style={{ borderRight: 'none', borderLeft: 'none' }}
                    className={classes.tableBody}
                  >
                    <Typography className={classNames(classes.text)}>
                      {el.currency.symbol} {formatNumberToFixed(el.balance, 8)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ borderRight: 'none', borderLeft: 'none' }}
                    className={classes.tableBody}
                  >
                    <Typography className={classNames(classes.text)}>
                      ${' '}
                      {formatNumberToFixed(
                        Number(el.usdPrice) * Number(el.balance),
                        2
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ borderRight: 'none', borderLeft: 'none' }}
                    className={classes.tableBody}
                  >
                    <Typography className={classNames(classes.text)}>
                      ${' '}
                      {el.creditLine
                        ? formatNumberToFixed(el.creditLine, 2)
                        : 0}
                    </Typography>
                  </TableCell>

                  <TableCell
                    style={{ borderLeft: 'none' }}
                    className={classes.tableBody}
                    align="right"
                  >
                    <Grid container justify="flex-end">
                      <Grid className={classes.button}>
                        {Number(el.balance) > 0 ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleShowDialogDeposit(el)}
                            className={classes.buttonDeposit}
                          >
                            Deposit
                          </Button>
                        ) : (
                          <NexoButton
                            handleRedirect={handleShowDialogTopUp(el)}
                            disabled={!el.currency.isShow}
                            title="Top up"
                            variant="contained"
                            color="primary"
                            size="small"
                          />
                        )}
                      </Grid>
                      <Grid
                        className={classes.button}
                        style={{ marginLeft: 10 }}
                      >
                        <NexoButton
                          handleRedirect={handleShowDialogWithdraw(el)}
                          disabled={!el.currency.isShow}
                          title="Withdraw"
                          variant="outlined"
                          color="primary"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
      <TopUpDialog
        maxWidth="lg"
        showDialog={showDialogTopUp}
        handleClose={handleCloseDialog}
        isGoogleAuthenticator={isGoogleAuthenticator}
        itemCurrencies={listCurrencies}
        handleShowDialogCalculator={handleShowDialogCalculator}
      />
      <DepositDialogComponent
        maxWidth="lg"
        showDialog={showDeposit}
        handleClose={handleCloseDialog}
        isGoogleAuthenticator={isGoogleAuthenticator}
        itemCurrencies={listCurrencies}
      />
      <WithDrawDialogComponent
        userProfile={userProfile}
        showDialog={showDialogWithdraw}
        maxWidth="lg"
        handleClose={handleCloseDialog}
        handleRedirectTopUP={handleRedirectTopUP}
        itemCurrencies={listCurrencies}
      />
      <CalculatorDialog
        maxWidth="xl"
        showDialog={showDialogCalculator}
        handleClose={handleCloseDialog}
      />
    </div>
  );
};
