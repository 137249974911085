import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, Grid, Typography } from '@material-ui/core';
import iconBack from '../../assets/images/iconBack.svg';
interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  handleCloseDialog: () => void;
  handleBack?: () => void;
  textHeader: string;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  hiddenback: boolean;
}
const styles = () =>
  createStyles({
    dialogTitle: {
      padding: 0
    },
    content: {
      padding: '0px 30px'
    }
  });

class NexoDialog extends React.Component<IProps> {
  public render() {
    const {
      showDialog,
      handleCloseDialog,
      classes,
      textHeader,
      children,
      maxWidth,
      hiddenback
    } = this.props;
    return (
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        maxWidth={maxWidth}
      >
        <Grid className={classes.dialogTitle}>
          <Grid
            container
            justify="center"
            alignContent="center"
            style={{
              height: 80,
              borderBottom: '1px solid #E4EAEE',
              background: '#F9FAFB',
              boxSizing: 'border-box',
              position: 'relative'
            }}
          >
            {hiddenback ? (
              <Grid
                container
                style={{
                  position: 'absolute',
                  top: 32,
                  cursor: 'pointer'
                }}
                onClick={this.props.handleBack}
              >
                <img
                  src={iconBack}
                  alt="icon back"
                  style={{ marginRight: 9, marginLeft: 30 }}
                />
                <Typography variant="h5" style={{ color: '#A5B2C0' }}>
                  Back
                </Typography>
              </Grid>
            ) : null}

            <Typography variant="subtitle1" style={{ color: '#050F47' }}>
              {textHeader}
            </Typography>
          </Grid>
        </Grid>

        <Grid className={classes.content}>{children}</Grid>
      </Dialog>
    );
  }
}

export const NexoDialogComponent = withStyles(styles)(NexoDialog);
