import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import iconAdvanced from '../../assets/images/iconAdvanced.svg';

const styles = makeStyles({
  rootContent: {
    background: 'rgba(242, 153, 74, 0.05)',
    border: '1px solid #F2994A',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: 50,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 20,
    display: 'grid',
    gridTemplateColumns: '20px calc(100% - 20px)'
  }
});

interface IProps {
  message: string;
}
export const NotificationErrorPhone = (props: IProps) => {
  const classes = styles();
  const { message } = props;
  return (
    <Grid container alignItems="center" className={classes.rootContent}>
      <img src={iconAdvanced} alt="icon advanced" width={15} height={15} />
      <Typography
        variant="h5"
        style={{
          color: '#F2994A',
          marginLeft: 8,
          fontSize: 10,
          fontWeight: 600,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {message}
      </Typography>
    </Grid>
  );
};
