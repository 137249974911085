import { createStyles, IconButton, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import FirstPage from '@material-ui/icons/FirstPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import LastPage from '@material-ui/icons/LastPage';

const pagination = createStyles({
  root: {
    right: 10,
    display: 'flex'
  },
  button: {
    width: 30,
    height: 30,
    margin: '0 5px',
    padding: 0,
    cursor: 'pointer'
  },
  text: {
    width: 30,
    height: 30,
    margin: '0 3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F9FAFB',
    border: ' 1px solid #E4EAEE',
    boxSizing: 'border-box',
    borderRadius: 5,
    color: '#A5B2C0'
  }
});

interface IPagination extends WithStyles<typeof pagination> {
  count: number;
  rowsPerPage: number;
  page: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
}

class PaginationComponent extends React.Component<IPagination> {
  render() {
    const { count, rowsPerPage, page, onChangePage, classes } = this.props;
    return (
      <div
        className={classes.root}
        style={{
          flexShrink: 0,
          display: 'flex',
          position: 'absolute'
        }}
      >
        <IconButton
          className={classes.button}
          disabled={page === 0}
          aria-label="First Page"
          // tslint:disable-next-line
          onClick={() => onChangePage(null, 0)}
        >
          <FirstPage />
        </IconButton>

        <IconButton
          className={classes.button}
          disabled={page === 0}
          aria-label="Previous Page"
          // tslint:disable-next-line
          onClick={() => onChangePage(null, page - 1)}
        >
          <ChevronLeft />
        </IconButton>
        <Typography
          variant="subtitle2"
          className={classes.text}
          component="span"
        >
          {page + 1}
        </Typography>
        <IconButton
          className={classes.button}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
          // tslint:disable-next-line
          onClick={() => onChangePage(null, page + 1)}
        >
          <ChevronRight />
        </IconButton>

        <IconButton
          className={classes.button}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
          // tslint:disable-next-line
          onClick={() =>
            onChangePage(null, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
          }
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }
}
export default withStyles(pagination)(PaginationComponent);
