export const LOAD_WITHDRAWFUND_CONFIG_ACTION =
  'LOAD_WITHDRAWFUND_CONFIG_ACTION';
export type LOAD_WITHDRAWFUND_CONFIG_ACTION = typeof LOAD_WITHDRAWFUND_CONFIG_ACTION;

export const SET_WITHDRAWFUND_CONFIG_ACTION = 'SET_WITHDRAWFUND_CONFIG_ACTION';
export type SET_WITHDRAWFUND_CONFIG_ACTION = typeof SET_WITHDRAWFUND_CONFIG_ACTION;

export const LOAN_CREDIT_LINE_ACTION = 'LOAN_CREDIT_LINE_ACTION';
export type LOAN_CREDIT_LINE_ACTION = typeof LOAN_CREDIT_LINE_ACTION;

export const LOAD_LOAN_CONTRACT_SUMMARY_ACTION =
  'LOAD_LOAN_CONTRACT_SUMMARY_ACTION';
export type LOAD_LOAN_CONTRACT_SUMMARY_ACTION = typeof LOAD_LOAN_CONTRACT_SUMMARY_ACTION;

export const SET_LOAN_CONTRACT_SUMMARY_ACTION =
  'SET_LOAN_CONTRACT_SUMMARY_ACTION';
export type SET_LOAN_CONTRACT_SUMMARY_ACTION = typeof SET_LOAN_CONTRACT_SUMMARY_ACTION;

export const WITHDRAW_LOAN_APPROVED_ACTION = 'WITHDRAW_LOAN_APPROVED_ACTION';
export type WITHDRAW_LOAN_APPROVED_ACTION = typeof WITHDRAW_LOAN_APPROVED_ACTION;

export const LOAN_TETHER_ACTION = 'LOAN_TETHER_ACTION';
export type LOAN_TETHER_ACTION = typeof LOAN_TETHER_ACTION;

export const WITHDRAWAL_LOAN_MENU = {
  BANK_TRANSFER: 1,
  TETHER: 2
};
